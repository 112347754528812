import React, { useEffect, useState } from 'react'
import { all_routes } from '../../../router/all_routes'
import { Link, useNavigate } from 'react-router-dom'
import Table from "../../shared/dataTable/index";
import CommonSelect from '../../../core/common/commonSelect';
import { department_details } from '../../../core/data/json/department_details';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import departmentService from '../../../services/department/department.service';
import {DepartmentDto, DepartmentCreateDTO, DepartmentUpdateDTO, DepartmentStatus } from '../../../types/department.types';
import { Popconfirm } from 'antd';
import { createPdf, exportToExcel } from '../../../utils/exportUtils';
import { message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import LoadingSpinner from '../../shared/uiInterface/base-ui/LoadingSpinner';
import { handleApiError } from '../../../utils/errorHandler';
import dayjs from 'dayjs';
import FooterSection from '../../shared/components/footerSection';
type PasswordField = "password" | "confirmPassword";

const defaultFormState: DepartmentCreateDTO = {
  name: "",
};
const Department = () => {
    
  const {getDepartmentByBranchId,getDepartmentById,getDepartments,createDepartment,updateDepartment,deleteDepartment} = departmentService
  const {t} = useTranslation()


  const [departments, setDepartments] = useState<any[]>([]);
  const [formState, setFormState] = useState<DepartmentCreateDTO | DepartmentUpdateDTO>(
    defaultFormState
  );
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const navigate = useNavigate();
  useEffect(() => {
    const modalElement = document.getElementById("edit_department");

    const handleModalClose = () => {
      handleClear(); // ✅ Modal kapanınca formu temizle
    };

    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);
  
  const fetchDepartments = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const result = await getDepartments();
        setDepartments(result);
      } catch (err: any) {
        handleApiError(err,t);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleSave = async () => {
    try {
      // Form validasyonu
      if (!formState.name || formState.name.trim() === '') {
        message.error(t('departments.departmentNameError'));
        return;
      }
      if (formState.name.length < 2) {
        message.error(t('departments.departmentNameError1'));
        return;
      }
      if (formState.name.length > 50) {
        message.error(t('departments.departmentNameError2'));
        return;
      }
 

      if (selectedDepartment) {
        await updateDepartment(selectedDepartment.id, formState as DepartmentUpdateDTO);
        message.success(t('departments.departmentUpdateSuccess'));
      } else {
        await createDepartment(formState as DepartmentCreateDTO);
        message.success(t('departments.departmentSuccess'));
      }
      fetchDepartments();
      handleClear();
    } catch (err: any) {
      handleApiError(err,t);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteDepartment(id);
      message.success(t('departments.departmentDeleteSuccess'));
      fetchDepartments();
      handleClear();
    } catch (err: any) {
      handleApiError(err,t);
    }
  };

  const handleClear = () => {
    setSelectedDepartment(null);
    setFormState(defaultFormState);
  };


  const handleSelectDepartment = (department: DepartmentDto) => {
    setSelectedDepartment(department);
    setFormState(department);
  };

  const data = department_details;
  
  

  const columns = [
    {
      title: t('departments.department'),
      dataIndex: "name",
      render: (text: string, record: any) => (
        <Link 
        to={`${all_routes.employeeList}?department=${record.id}`}
        >
          {text}
        </Link>
      ),
      sorter: (a: any, b: any) => a.Department.length - b.Department.length,
    },
    {
      title: t('departments.numberOfEmployees'),
      dataIndex: "employeeCount",
      sorter: (a: any, b: any) => a.employeeCount - b.employeeCount,
    },
    {
      title: t('departments.status'),
      dataIndex: "isActive",
      render: (_:any, record: DepartmentDto) => (
        <span className={`badge ${record.status === DepartmentStatus.ACTIVE ? 'badge-success' : 'badge-danger'} d-inline-flex align-items-center badge-xs`}>
          <i className="ti ti-point-filled me-1" />
          {record.status === DepartmentStatus.ACTIVE?t('departments.active'):t('departments.inactive')}
        </span>

      ),
      sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    
    {
      title: "",
      dataIndex: "actions",
      render: (_: any, record: any) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-sm btn-primary"
            data-bs-toggle="modal" data-inert={true}
            data-bs-target="#edit_department"
            onClick={() => handleSelectDepartment(record)}
          >
            <i className="ti ti-edit" />
          </button>
          {/* <Popconfirm
            title={t('departments.departmentDeleteConfirm')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('departments.yes')}
            cancelText={t('departments.no')}
          >
            <button
              className="btn btn-sm btn-danger"
            >
              <i className="ti ti-trash" />
            </button>
          </Popconfirm> */}
        </div>

      ),
    },
  ]
  const statusChoose = [
    { value: DepartmentStatus.ACTIVE, label: t('departments.active') },
    { value: DepartmentStatus.INACTIVE, label: t('departments.inactive') },
  ];

  const getFilteredDepartments = () => {
    if (statusFilter === 'all') {
      return departments;
    }
    return departments.filter(dep => 
      statusFilter === 'active' ? dep.status === DepartmentStatus.ACTIVE : dep.status === DepartmentStatus.INACTIVE
    );
  };

  const statusOptions = [
    { value: 'all', label: t('departments.allDepartments') },
    { value: 'active', label: t('departments.activeDepartments') },
    { value: 'inactive', label: t('departments.inactiveDepartments') }
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('departments.pageTitle')}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('departments.breadcrumb.employee')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('departments.breadcrumb.departments')}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap  ">
            
                <Link
                  to="#"
                  data-bs-toggle="modal" data-inert={true}
                  data-bs-target="#add_department"
                  className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
                >
                    <i className="ti ti-plus me-1" />
                    {t('departments.addDepartment')}
                </Link>
              <div className="dropdown me-2">
                  <Link
                    to="#"
                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-file-export me-1" />
                    {t('common.buttons.export')}
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={() => {
                          // Departmanlar için sütun tanımları
                          type DepartmentDataType = {
                            name: string;
                            employeeCount: number;
                            status: string;
                          };

                          const columnsForPdf = [
                            { header: 'Departman Adi', property: 'name' as keyof DepartmentDataType, width: 100 },
                            { header: 'Calisan Sayisi', property: 'employeeCount' as keyof DepartmentDataType, width: 100 },
                            { header: 'Durum', property: 'status' as keyof DepartmentDataType, width: 80 }
                          ];

                          // PDF için formatlanmış veri
                          const formattedData = departments.map(dept => ({
                            name: dept.name || '-',
                            employeeCount: dept.employeeCount || 0,
                            status: dept.status === DepartmentStatus.ACTIVE ? t('departments.active') : t('departments.inactive')
                          }));

                          // Açıklama
                          let description = 'Departman listesi';
                          if (statusFilter !== 'all') {
                            description += statusFilter === 'active' 
                              ? ' - Yalnizca aktif departmanlar' 
                              : ' - Yalnizca pasif departmanlar';
                          }

                          // createPdf fonksiyonunu çağır
                          createPdf(
                            formattedData, 
                            {
                              title: 'Departman Listesi',
                              description: description,
                              headerColor: '#2c3e50', // Koyu lacivert
                              accentColor: '#3498db', // Mavi
                              columns: columnsForPdf,
                              showStatistics: false, // İstatistik kutuları gösterme
                              footer: {
                                text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
                                showPageNumbers: true
                              }
                            }
                          );
                        }}
                      >
                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                        {t('common.buttons.pdf')}
                      </Link>
                    </li>
                    <li className="mt-1">
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={() => {
                          // Excel için sütun tanımları
                          type DepartmentExcelType = {
                            name: string;
                            employeeCount: number;
                            status: string;
                            createdAt?: string;
                          };

                          const columnsForExcel = [
                            { header: 'Departman Adı', property: 'name' as keyof DepartmentExcelType, width: 30 },
                            { header: 'Çalışan Sayısı', property: 'employeeCount' as keyof DepartmentExcelType, width: 20 },
                            { header: 'Durum', property: 'status' as keyof DepartmentExcelType, width: 20 },
                            { header: 'Oluşturulma Tarihi', property: 'createdAt' as keyof DepartmentExcelType, width: 25 }
                          ];

                          // Excel için formatlanmış veri
                          const formattedData = departments.map(dept => ({
                            name: dept.name || '-',
                            employeeCount: dept.employeeCount || 0,
                            status: dept.status === DepartmentStatus.ACTIVE ? t('departments.active') : t('departments.inactive'),
                            createdAt: dept.createdAt ? dayjs(dept.createdAt).format('DD.MM.YYYY') : '-'
                          }));

                          // Excel dosyasını oluştur
                          exportToExcel(formattedData, 'Departman Listesi', columnsForExcel);
                        }}
                      >
                        <i className="ti ti-file-type-xls me-2 text-success" />
                        {t('common.buttons.excel')}
                      </Link>
                    </li>
                  </ul>
                </div>
    
            </div>
          </div>
          {/* /Breadcrumb */}
          {/* Performance Indicator list */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
              <h5>{t('departments.departmentList')}</h5>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
                <div className="me-3">
                  <CommonSelect
                    options={statusOptions}
                    value={statusOptions.find(option => option.value === statusFilter)}
                    onChange={(selected: any) => setStatusFilter(selected.value.toString())}
                    className="select"
                  />
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              {loading ? (
                <LoadingSpinner centered={true} />
              ) : (
                <Table dataSource={getFilteredDepartments()} columns={columns} Selection={false} />
              )}
            </div>
          </div>
          {/* /Performance Indicator list */}
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Department */}
      <div className="modal fade" id="add_department">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('departments.addDepartment')}</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{t('departments.departmentName')}</label>
                      <input
                      type="text" 
                      className="form-control" 
                      value={formState.name}
                      onChange={(e)=> setFormState({...formState,name:e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                  onClick={handleClear}
                >
                  {t('departments.cancelDepartment')}
                </button>
                <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={handleSave}>
                  {t('departments.addDepartment')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Department */}
      {/* Edit Department */}
      <div className="modal fade" id="edit_department">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{t('departments.editDepartment')}</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{t('departments.departmentName')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formState.name} // ✅ Güncellenen formState verisi
                        onChange={(e) =>
                          setFormState({ ...formState, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{t('departments.status')}</label>
                      <CommonSelect
                        className='select'
                        options={statusChoose}
                        value={formState.status !== undefined ? 
                          statusChoose.find(option => option.value === formState.status) : 
                          undefined
                        }
                        onChange={(selected) =>
                          setFormState({ ...formState, status: selected.value as DepartmentStatus })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  data-bs-dismiss="modal"
                  onClick={handleClear}
                >
                  {t('departments.cancelDepartment')}
                </button>
                <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={handleSave}>
                  {t('departments.saveDepartment')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Department */}
    </>


  )
}

export default Department
