import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { store_details } from "../../../core/data/json/storedetails";
import Table from "../../shared/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import{ ShopCreateDTO, ShopUpdateDTO,Store } from "../../../types/shop.types";
import shopService from "../../../services/shop/shop.service";
import { Popconfirm, message, Tag, Input, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../shared/uiInterface/base-ui/LoadingSpinner";
import { exportToExcel } from "../../../utils/exportUtils";
import { createPdf } from "../../../utils/exportUtils";
import { handleApiError } from "../../../utils/errorHandler";
import dayjs from "dayjs";
import FooterSection from "../../shared/components/footerSection";
const defaultFormState: ShopCreateDTO = {
  name: "",
  address: "",
  phone: "",
  ipAddresses: [],
};


const Shop = () => {
  const { t } = useTranslation();
  const { createShop, getShops, updateShop, deleteShop } = shopService;
  const [formState, setFormState] = useState<ShopCreateDTO | ShopUpdateDTO>(defaultFormState);
  const [shops, setShops] = useState<Store[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedShop, setSelectedShop] = useState<Store | null>(null);
  const [newIpAddress, setNewIpAddress] = useState<string>("");
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchShops();
  }, []);

  const fetchShops = async () => {
    try {
      setLoading(true);
      // 1 saniyelik gecikme ekleyerek yükleme göstergesinin görünmesini sağlayalım
      setTimeout(async () => {
        try {
          const result = await getShops();
          setShops(result);
        } catch (err: any) {
          handleApiError(err,t);
        } finally {
          setLoading(false);
        }
      }, 1000);
    } catch (err: any) {
      handleApiError(err,t);
    }
  };

  // IP adresi eklemek için fonksiyon
  const handleAddIpAddress = () => {
    // IP adresini doğrula
    const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!ipRegex.test(newIpAddress)) {
      message.error(t('branches.branchIpError'));
      return;
    }

    // IP adresi zaten varsa ekleme
    if (formState.ipAddresses.includes(newIpAddress)) {
      message.warning(t('branches.branchIpErrorInclude'));
      return;
    }

    // IP adresini ekle
    const updatedIps = [...formState.ipAddresses, newIpAddress];
    setFormState({ ...formState, ipAddresses: updatedIps });
    setNewIpAddress(""); // Input temizle
  };

  // IP adresini silmek için fonksiyon
  const handleRemoveIpAddress = (ip: string) => {
    const updatedIps = formState.ipAddresses.filter(address => address !== ip);
    setFormState({ ...formState, ipAddresses: updatedIps });
  };

  const handleSave = async () => {
    try {
      // Form validasyonu
      if (!formState.name || formState.name.trim() === '') {
        message.error(t('branches.branchNameError'));
        return;
      }
      if (formState.name.length < 2) {
        message.error(t('branches.branchNameError1'));
        return;
      }
      if (formState.name.length > 50) {
        message.error(t('branches.branchNameError2'));
        return;
      }

      // Adres validasyonu
      if (!formState.address || formState.address.trim() === '') {
        message.error(t('branches.branchAddressError'));
        return;
      }
      if (formState.address.length < 5) {
        message.error(t('branches.branchAddressError1'));
        return;
      }

      // Telefon validasyonu
      if (!formState.phone || formState.phone.trim() === '') {
        message.error(t('branches.branchPhoneError'));
        return;
      }
      const phoneRegex = /^[0-9]{10,11}$/;
      if (!phoneRegex.test(formState.phone.replace(/\D/g, ''))) {
        message.error(t('branches.branchPhoneError1'));
        return;
      }

      if (selectedShop) {
        const updateDto: ShopUpdateDTO = {
          ...formState,
          id: selectedShop.id
        };
        await updateShop(selectedShop.id, updateDto);
        message.success(t('branches.branchUpdateSuccess'));
      } else {
        const createDto = formState as ShopCreateDTO;
        await createShop(createDto);
        message.success(t('branches.branchSuccess'));
      }
      fetchShops();
      handleClear();
    } catch (error: any) {
      handleApiError(error,t);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteShop(id);
      fetchShops();
      handleClear();
    } catch (error: any) {
      handleApiError(error,t);
    }
  };

  const handleClear = () => {
    setSelectedShop(null);
    setFormState(defaultFormState);
    setNewIpAddress("");
  };

  const handleSelectShop = (shop: Store) => {
    setSelectedShop(shop);
    setFormState({
      name: shop.name,
      address: shop.address,
      phone: shop.phone,
      ipAddresses: shop.ipAddresses || [],
    });
  };
  
  const columns = [
    {
      title: "Store ID",
      dataIndex: "id",
      sorter: (a: any, b: any) => a.storeId - b.storeId,
    },
    {
      title: t('branches.table.name'),
      dataIndex: "name",
      render: (text: string, record: Store) => (
        <Link 
        to={`${all_routes.employeeList}?branch=${record.id}`}>
          {text}
        </Link>
      ),
      //sorter: (a: any, b: any) => a.storeName.localeCompare(b.storeName),
    },
    {
      title: t('branches.table.address'),
      dataIndex: "address",
      //sorter: (a: any, b: any) => a.storeLocation.localeCompare(b.storeLocation),
    },
    {
      title: t('branches.table.phone'),
      dataIndex: "phone",
      sorter: (a: any, b: any) => a.phone.localeCompare(b.phone),
    },
    {
      title: t('branches.table.numberOfEmployees'),
      dataIndex: "employeeCount",
      sorter: (a: any, b: any) => a.employeeCount - b.employeeCount,
    },
    {
      title: t('branches.table.ipAddresses'),
      dataIndex: "ipAddresses",
      render: (ipAddresses: string[]) => (
        <div>
          {ipAddresses && ipAddresses.length > 0 ? (
            <div className="d-flex flex-wrap gap-1">
              {ipAddresses.map(ip => (
                <Tag key={ip} color="blue">{ip}</Tag>
              ))}
            </div>
          ) : (
            <span className="text-muted">{t('branches.modal.noIpAddresses')}</span>
          )}
        </div>
      ),
    },
    {
      title: t('branches.table.actions'),
      render: (record: Store) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#add_store_modal"
            onClick={() => {
              handleSelectShop(record)
            }}
          >
            <i className="ti ti-edit" />
          </button>

          {/* <Popconfirm
            title={t('branches.branchDeleteConfirm')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('branches.ok')}
            cancelText={t('branches.cancel')}
          >
            <button
              className="btn btn-sm btn-danger"
            >
              <i className="ti ti-trash" />
            </button>
          </Popconfirm> */}
        </div>
      ),
    },

  ];


  
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('branches.pageTitle')}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('branches.breadcrumb.management')}</li>
                  <li className="breadcrumb-item active">{t('branches.breadcrumb.branches')}</li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <button
                className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
                data-bs-toggle="modal"
                data-bs-target="#add_store_modal"
                onClick={handleClear} // Yeni mağaza için varsayılan değerler
              >
                <i className="ti ti-plus me-1" /> {t('branches.addBranch')}
              </button>
              <div className="dropdown me-2">
                <Link
                  to="#"
                  className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <i className="ti ti-file-export me-1" />
                  {t('common.buttons.export')}
                </Link>
                <ul className="dropdown-menu dropdown-menu-end p-3">
                  <li>
                    <Link 
                    to="#" 
                    className="dropdown-item rounded-2 d-flex align-items-center py-2"
                    onClick={() => {
                      // Şubeler için sütun tanımları
                      type BranchDataType = {
                        name: string;
                        address: string;
                        phone: string;
                        employeeCount: number;
                        ipAddresses: string;
                      };

                      const columnsForPdf = [
                        { header: 'Sube Adi', property: 'name' as keyof BranchDataType, width: 100 },
                        { header: 'Adres', property: 'address' as keyof BranchDataType, width: 100 },
                        { header: 'Telefon', property: 'phone' as keyof BranchDataType, width: 100 },
                        { header: 'Calisan Sayisi', property: 'employeeCount' as keyof BranchDataType, width: 100 },
                        { header: 'IP Adresleri', property: 'ipAddresses' as keyof BranchDataType, width: 100 }
                      ];

                      // PDF için formatlanmış veri
                      const formattedData = shops.map(shop => ({
                        name: shop.name || '-',
                        address: shop.address || '-',
                        phone: shop.phone || '-',
                        employeeCount: shop.employeeCount || 0,
                        ipAddresses: shop.ipAddresses && shop.ipAddresses.length > 0 
                          ? shop.ipAddresses.join(', ') 
                          : '-'
                      }));

                      // Açıklama
                      const description = 'Sube listesi ve detaylari';

                      // createPdf fonksiyonunu çağır
                      createPdf(
                        formattedData, 
                        {
                          title: 'Sube Listesi', 
                          description: description,
                          headerColor: '#2c3e50', // Koyu lacivert
                          accentColor: '#3498db', // Mavi
                          columns: columnsForPdf,
                          showStatistics: false, // İstatistik kutuları gösterme
                          footer: {
                            text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
                            showPageNumbers: true
                          }
                        }
                      );
                    }}
                    >
                      <i className="ti ti-file-type-pdf me-2 text-danger" />
                      {t('common.buttons.pdf')}
                    </Link>
                  </li>
                  <li className="mt-1">
                    <Link 
                    to="#" 
                    className="dropdown-item rounded-2 d-flex align-items-center py-2"
                    onClick={() => {
                      // Excel için sütun tanımları
                      type BranchExcelType = {
                        id: number;
                        name: string;
                        address: string;
                        phone: string;
                        employeeCount: number;
                        ipAddresses: string;
                        createdAt?: string;
                      };

                      const columnsForExcel = [
                        { header: 'ID', property: 'id' as keyof BranchExcelType, width: 10 },
                        { header: 'Şube Adı', property: 'name' as keyof BranchExcelType, width: 30 },
                        { header: 'Adres', property: 'address' as keyof BranchExcelType, width: 50 },
                        { header: 'Telefon', property: 'phone' as keyof BranchExcelType, width: 20 },
                        { header: 'Çalışan Sayısı', property: 'employeeCount' as keyof BranchExcelType, width: 20 },
                        { header: 'IP Adresleri', property: 'ipAddresses' as keyof BranchExcelType, width: 40 },
                        { header: 'Oluşturulma Tarihi', property: 'createdAt' as keyof BranchExcelType, width: 25 }
                      ];

                      // Excel için formatlanmış veri
                      const formattedData = shops.map(shop => ({
                        id: shop.id,
                        name: shop.name || '-',
                        address: shop.address || '-',
                        phone: shop.phone || '-',
                        employeeCount: shop.employeeCount || 0,
                        ipAddresses: shop.ipAddresses && shop.ipAddresses.length > 0 
                          ? shop.ipAddresses.join(', ') 
                          : '-',
                        createdAt: shop.createdAt ? dayjs(shop.createdAt).format('DD.MM.YYYY') : '-'
                      }));

                      // Excel dosyasını oluştur
                      exportToExcel(formattedData, 'Şube Listesi', columnsForExcel);
                    }}
                    >
                      <i className="ti ti-file-type-xls me-2 text-success" />
                      {t('common.buttons.excel')}
                    </Link>
                  </li>
                </ul>
            </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
              <h5>{t('branches.branchList')}</h5>
            </div>
            <div className="card-body p-0">
              {loading ? (
                <LoadingSpinner centered={true} />
              ) : (
                <Table dataSource={shops} columns={columns} Selection={false} />
              )}
              </div>   
            </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>

      {/* Store Modal */}
      <div className="modal fade" id="add_store_modal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {selectedShop ? t('branches.editBranch') : t('branches.addBranch')}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClear} 
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">{t('branches.modal.name')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formState.name}
                    onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('branches.modal.address')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formState.address}
                    onChange={(e) => setFormState({ ...formState, address: e.target.value })}

                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{t('branches.modal.phone')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formState.phone}
                    onChange={(e) => setFormState({ ...formState, phone: e.target.value })}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">{t('branches.modal.ipAddresses')}</label>
                  <div className="input-group mb-2">
                    <Input
                      placeholder="192.168.1.1"
                      value={newIpAddress}
                      onChange={(e) => setNewIpAddress(e.target.value)}
                      onPressEnter={handleAddIpAddress}
                      className="form-control me-2"
                    />
                    <Button 
                      type="primary"
                      onClick={handleAddIpAddress}
                      icon={<PlusOutlined />}
                      className="rounded-pill shadow-sm"
                      >
                      {t('branches.modal.add')}
                    </Button>
                  </div>
                  <div className="mt-2">
                    {formState.ipAddresses && formState.ipAddresses.length > 0 ? (
                      <div className="d-flex flex-wrap gap-1">
                        {formState.ipAddresses.map(ip => (
                          <Tag 
                            key={ip} 
                            color="blue" 
                            closable
                            onClose={() => handleRemoveIpAddress(ip)}
                          >
                            {ip}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      <span className="text-muted">{t('branches.modal.noIpAddresses')}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-light border"
                data-bs-dismiss="modal"
                onClick={handleClear} 
              >
                {t('branches.modal.cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSave}
              >
                {t('branches.modal.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
