import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth.slice";
import sidebarReducer from "./slices/sidebar.slice";
import themeReducer from "./slices/theme.slice";
import socketReducer from "./slices/socket.slice";
// Store'un başlangıç durumu
const initialState = {
  auth: {
    user: null,
    token: localStorage.getItem("token"),
    isAuthenticated: !!localStorage.getItem("token"),
    loading: false,
    companyId: localStorage.getItem("companyId"),
    error: null,
  },
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: sidebarReducer,
    theme: themeReducer,
    socket: socketReducer,
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
