import React from 'react';
import { LeaveStats } from '../../../../types/leaves.types';
import { useTranslation } from 'react-i18next';
interface LeaveStatsProps {
  stats: LeaveStats;
}

const LeaveStatsComponent: React.FC<LeaveStatsProps> = ({ stats }) => {
  const { t } = useTranslation();
  return (
    <div className="row g-3">
      <div className="col-xl-3 col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-lg bg-light rounded">
                  <i className="ti ti-calendar-stats fs-2 text-primary"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-muted mb-1">{t('leaves.totalLeaves')}</p>
                <h4 className="mb-0 fw-semibold">{stats.total}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-lg bg-light rounded">
                  <i className="ti ti-clock-pause fs-2 text-warning"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-muted mb-1">{t('leaves.pending')}</p>
                <h4 className="mb-0 fw-semibold">{stats.pending}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-lg bg-light rounded">
                  <i className="ti ti-circle-check fs-2 text-success"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-muted mb-1">{t('leaves.approved')}</p>
                <h4 className="mb-0 fw-semibold">{stats.approved}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="avatar avatar-lg bg-light rounded">
                  <i className="ti ti-circle-x fs-2 text-danger"></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="text-muted mb-1">{t('leaves.rejected')}</p>
                <h4 className="mb-0 fw-semibold">{stats.rejected}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveStatsComponent;