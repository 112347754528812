import React from 'react';
import { Link } from 'react-router-dom';
import { FileDto } from '../../../../../types/file.types';
import { getFileUrl } from '../../../../../utils/file.utils';
import { useTranslation } from 'react-i18next';
interface EmployeesDocumentsProps {
  documents: FileDto[];
}

const EmployeesDocuments: React.FC<EmployeesDocumentsProps> = ({ documents }) => {
  const { t } = useTranslation();
  const getFileIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'pdf':
        return 'ti ti-file-type-pdf text-danger';
      case 'doc':
      case 'docx':
        return 'ti ti-file-type-doc text-primary';
      case 'xls':
      case 'xlsx':
        return 'ti ti-file-type-xls text-success';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'ti ti-file-type-jpg text-warning';
      default:
        return 'ti ti-file text-secondary';
    }
  };

  const getFileType = (path: string) => {
    return path.split('.').pop()?.toLowerCase() || 'unknown';
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" id="headingTwo">
        <div className="accordion-button">
          <div className="d-flex align-items-center justify-content-between flex-fill">
            <h5>{t('employeesDetail.documents.title')}</h5>
            <div className="d-flex">
              <Link
                to="#"
                className="d-flex align-items-center collapsed collapse-arrow"
                data-bs-toggle="collapse"
                data-bs-target="#primaryBorderOne"
                aria-expanded="false"
                aria-controls="primaryBorderOne"
              >
                <i className="ti ti-chevron-down fs-18" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        id="primaryBorderOne"
        className="accordion-collapse collapse border-top"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="row g-3">
            {documents.length > 0 ? (
              documents.map((doc) => (
                <div key={doc.id} className="col-sm-6 col-md-4 col-xl-3">
                  <div className="card h-100 shadow-sm hover-shadow-lg transition-all">
                    <div className="card-body p-3">
                      <div className="d-flex flex-column h-100">
                        {/* Üst Kısım - İkon ve İsim */}
                        <div className="document-icon-wrapper mb-3 text-center">
                          <i className={`${getFileIcon(getFileType(doc.path))} fs-1`}></i>
                          <h6 className="mt-2 mb-0 text-truncate" style={{ maxWidth: '100%' }} title={doc.name}>
                            {doc.name}
                          </h6>
                        </div>
                        
                        {/* Alt Kısım - Detaylar */}
                        <div className="document-details mt-auto">
                          <div className="d-flex justify-content-between align-items-center text-muted small mb-2">
                            <span>
                              <i className="ti ti-calendar me-1"></i>
                              {formatDate(doc.createdAt.toString())}
                            </span>
                            <span>
                              <i className="ti ti-file-size me-1"></i>
                              {formatFileSize(doc.size)}
                            </span>
                          </div>
                          
                          {/* İndir/Görüntüle Butonu */}
                          <a
                            href={getFileUrl(doc.path)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-light btn-sm w-100 hover-lift"
                          >
                            <i className="ti ti-download me-1"></i>
                            {t('employeesDetail.documents.view')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <div className="text-center py-4">
                  <i className="ti ti-file-off fs-2 text-muted mb-2 d-block"></i>
                  <p className="text-muted mb-0">{t('employeesDetail.documents.noDocuments')}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <style>
        {`
          .hover-shadow-lg {
            transition: all 0.3s ease;
          }
          .hover-shadow-lg:hover {
            transform: translateY(-3px);
            box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1) !important;
          }
          .hover-lift {
            transition: all 0.2s ease;
          }
          .hover-lift:hover {
            transform: translateY(-1px);
          }
          .document-icon-wrapper {
            min-height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .document-icon-wrapper i {
            font-size: 2.5rem;
          }
          .card {
            border: none;
            background: #fff;
            border-radius: 10px;
          }
          .card:hover {
            border-color: #e0e0e0;
          }
          .text-truncate {
            max-width: 150px;
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
};

export default EmployeesDocuments;
