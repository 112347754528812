import { Navigate } from "react-router-dom";
import { RolesEnum } from "../types/route.types";
import { PermissionType } from "../types/user.types";
interface ProtectedRouteProps {
  element: React.ReactNode;
  allowedRoles: PermissionType[]; // Dizi olarak değişti
  userRole?: PermissionType[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  allowedRoles,
  userRole
}) => {

  // console.log(userRole)
  // console.log(allowedRoles)
  
  if (!userRole || !allowedRoles.some(role => userRole.includes(role))) {
      return <Navigate to="/" replace />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;