import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <>
      <p className="mb-0">
        <Trans
          i18nKey="footer.copyright"
          values={{ year: new Date().getFullYear(), company: "Dinox" }}
        />
      </p>
      <p>
        <Trans
          i18nKey="footer.designed"
          values={{ company: "Doniglobal" }}
          components={{ company: <Link to="https://doniglobal.com" target="_blank" className="text-primary" /> }}
        />
      </p>
    </>
  );
};

export default FooterSection;
