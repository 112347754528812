import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import reportService from '../../../services/reports/report.service';
import { TicketReportDto } from '../../../types/report.types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table, Input, Button, Tag, Progress, Card, Row, Col, Statistic } from 'antd';
import { SearchOutlined, CalendarOutlined, ClockCircleOutlined, RiseOutlined, BarChartOutlined, AimOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import FooterSection from '../../shared/components/footerSection';
interface TicketTableData {
    key: string;
    date: string;
    count: number;
    percentage: number;
    formattedDate: string;
}

interface WeekDaySummary {
    day: string;
    count: number;
    percentage: number;
}

interface WeekdayCounts {
    [key: string]: number;
}

const TicketReport = () => {
    const {t} = useTranslation();
    const [reportData, setReportData] = useState<TicketReportDto | null>(null);
    const [tableData, setTableData] = useState<TicketTableData[]>([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await reportService.getTicketReport(
                    startDate.toISOString().split('T')[0],
                    endDate.toISOString().split('T')[0]
                );
                setReportData(data);
                
                // Tablo verisi olarak düzenle
                prepareTableData(data);
            } catch (error: any) {
                handleApiError(error,t);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate]);

    // Tarih verilerini düzenle ve tablo için hazırla
    const prepareTableData = (data: TicketReportDto) => {
        const total = Object.values(data.ticketFrequency).reduce((sum, count) => sum + count, 0);
        
        const tableData = Object.entries(data.ticketFrequency).map(([dateStr, count]) => {
            // ISO formatındaki tarihi daha okunabilir formata dönüştür
            const date = new Date(dateStr);
            const formattedDate = date.toLocaleDateString('tr-TR', { 
                day: 'numeric', 
                month: 'long', 
                year: 'numeric',
                weekday: 'long'
            });
            
            return {
                key: dateStr,
                date: dateStr,
                count,
                percentage: (count / total) * 100,
                formattedDate
            };
        }).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        
        setTableData(tableData);
    };

    // Arama fonksiyonu
    const handleSearch = (value: string) => {
        setSearchText(value);
    };

    // Filtreli veri
    const filteredData = tableData.filter(item => 
        item.formattedDate.toLowerCase().includes(searchText.toLowerCase())
    );

    // Tarih bazlı zaman serisi grafiği için veri ve ayarlar
    const timeSeriesData = useMemo(() => {
        return tableData.map(item => ({
            x: new Date(item.date).getTime(),
            y: item.count
        }));
    }, [tableData]);

    const timeSeriesOptions: ApexOptions = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            },
            animations: {
                enabled: true,
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.3,
                stops: [0, 90, 100]
            }
        },
        colors: ['#4318FF'],
        title: {
            text: t('ticketReport.timeDistributionChart'),
            align: 'left',
            style: {
                fontSize: '16px',
                fontWeight: 500
            }
        },
        xaxis: {
            type: 'datetime',
            title: {
                text: t('ticketReport.date'),
                style: {
                    fontSize: '12px',
                    fontWeight: 500
                }
            },
            labels: {
                datetimeUTC: false,
                format: 'dd MMM'
            }
        },
        yaxis: {
            title: {
                text: t('ticketReport.ticketCount'),
                style: {
                    fontSize: '12px',
                    fontWeight: 500
                }
            },
            min: 0,
            forceNiceScale: true,
            labels: {
                formatter: function(val) {
                    return val.toFixed(0);
                }
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },
        markers: {
            size: 4,
            colors: ['#4318FF'],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 6
            }
        }
    };


    // Özet istatistikleri hesapla
    const statsData = useMemo(() => {
        if (!reportData || tableData.length === 0) return null;

        // Toplam talep sayısı
        const totalTickets = Object.values(reportData.ticketFrequency).reduce((a, b) => a + b, 0);

        // Seçili tarih aralığındaki gün sayısı
        const daysDiff = Math.max(1, Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));
        
        // Günlük ortalama talep sayısı
        const dailyAvg = totalTickets / daysDiff;

        // En yoğun gün
        const busiestDay = tableData.reduce((a, b) => a.count > b.count ? a : b);

        // Ortalama çözüm süresini saat ve dakika formatına dönüştür
        const avgResHours = Math.floor(reportData.avgResolutionTime / 3600);
        const avgResMinutes = Math.floor((reportData.avgResolutionTime % 3600) / 60);

        return {
            totalTickets,
            dailyAvg,
            busiestDay,
            avgResHours,
            avgResMinutes
        };
    }, [reportData, tableData, startDate, endDate]);

    return (
        <div className="page-wrapper">
            <div className="content">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{t('ticketReport.pageTitle')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">{t('ticketReport.breadcrumb.reports')}</li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('ticketReport.breadcrumb.ticketReport')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center">
                        <div className="me-2">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => date && setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => date && setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('ticketReport.loading')}</span>
                        </div>
                    </div>
                ) : reportData && statsData ? (
                    <div className="row">
                        {/* Geliştirilmiş Özet Kartlar */}
                        <div className="col-xl-3 col-md-6 d-flex">
                            <div className="card flex-fill" style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                        <div>
                                            <span className="fs-14 fw-normal text-muted">{t('ticketReport.totalTickets')}</span>
                                            <h3 className="mb-0 fw-bold" style={{color: '#4318FF'}}>{statsData.totalTickets}</h3>
                                        </div>
                                        <div className="avatar avatar-md bg-primary-transparent rounded-lg p-2">
                                            <BarChartOutlined style={{fontSize: '24px', color: '#4318FF'}} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="fs-13 text-muted">
                                            {t('ticketReport.totalTicketsDescription')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex">
                            <div className="card flex-fill" style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                        <div>
                                            <span className="fs-14 fw-normal text-muted">{t('ticketReport.avgResolutionTime')}</span>
                                            <h3 className="mb-0 fw-bold" style={{color: '#05CD99'}}>
                                                {statsData.avgResHours} {t('ticketReport.hours')} {statsData.avgResMinutes} {t('ticketReport.minutes')}
                                            </h3>
                                        </div>
                                        <div className="avatar avatar-md bg-success-transparent rounded-lg p-2">
                                            <ClockCircleOutlined style={{fontSize: '24px', color: '#05CD99'}} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="fs-13 text-muted">
                                            {t('ticketReport.avgResolutionTimeDescription')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex">
                            <div className="card flex-fill" style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                        <div>
                                            <span className="fs-14 fw-normal text-muted">{t('ticketReport.dailyAverage')}</span>
                                            <h3 className="mb-0 fw-bold" style={{color: '#6AD2FF'}}>{statsData.dailyAvg.toFixed(1)}</h3>
                                        </div>
                                        <div className="avatar avatar-md bg-info-transparent rounded-lg p-2">
                                            <CalendarOutlined style={{fontSize: '24px', color: '#6AD2FF'}} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="fs-13 text-muted">
                                            {t('ticketReport.dailyAverageDescription')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex">
                            <div className="card flex-fill" style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                        <div>
                                            <span className="fs-14 fw-normal text-muted">{t('ticketReport.busiestDay')}</span>
                                            <h3 className="mb-0 fw-bold" style={{color: '#FFB547'}}>
                                                {new Date(statsData.busiestDay.date).toLocaleDateString('tr-TR', {day: 'numeric', month: 'short'})}
                                            </h3>
                                        </div>
                                        <div className="avatar avatar-md bg-warning-transparent rounded-lg p-2">
                                            <AimOutlined style={{fontSize: '24px', color: '#FFB547'}} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="fs-13 text-muted">
                                            {statsData.busiestDay.count} {t('ticketReport.ticketCount')} ile en yoğun gün
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Yeni Grafikler */}
                        <div className="col-xl-12 d-flex">
                            <div className="card flex-fill" style={{boxShadow: '0 4px 6px rgba(0,0,0,0.1)', borderRadius: '10px'}}>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={timeSeriesOptions}
                                        series={[{ name: t('ticketReport.ticketCount'), data: timeSeriesData }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-danger">
                        Rapor verileri yüklenirken bir hata oluştu.
                    </div>
                )}
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default TicketReport; 