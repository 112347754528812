import React, { useState } from 'react';
import { Upload, Button, message, UploadProps, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FileType } from '../../../../types/file.types';

interface FileUploadProps {
  fileType: FileType;
  onChange?: (file: File | null) => void;
  maxCount?: number;
  buttonText?: string;
  accept?: string;
  disabled?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  fileType,
  onChange,
  maxCount = 1,
  buttonText = 'Dosya Yükle',
  accept = '.pdf,.jpg,.jpeg,.png',
  disabled = false
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    
    // Sadece son dosyayı tut (maxCount=1 için)
    newFileList = newFileList.slice(-maxCount);
    
    setFileList(newFileList);
    
    // Dosya değişikliğini üst bileşene bildir
    if (onChange) {
      if (newFileList.length > 0 && newFileList[0].originFileObj) {
        onChange(newFileList[0].originFileObj);
      } else {
        onChange(null);
      }
    }
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      // Dosya tipini kontrol et
      const isValidType = accept.split(',').some(type => 
        file.type.includes(type.replace('.', '')) || 
        file.name.endsWith(type)
      );
      
      if (!isValidType) {
        message.error(`Sadece ${accept} formatında dosyalar yüklenebilir!`);
        return Upload.LIST_IGNORE;
      }
      
      // Dosya boyutunu kontrol et (5MB)
      const isLessThan5M = file.size / 1024 / 1024 < 5;
      if (!isLessThan5M) {
        message.error('Dosya boyutu 5MB\'dan küçük olmalıdır!');
        return Upload.LIST_IGNORE;
      }
      
      // Dosyayı hemen yükleme, sadece listeye ekle
      return false;
    },
    fileList,
    onChange: handleChange,
    maxCount,
    accept,
    showUploadList: true,
    disabled,
    customRequest: ({ onSuccess }) => {
      // Sahte bir başarı yanıtı döndür, gerçek yükleme form gönderildiğinde yapılacak
      if (onSuccess) onSuccess("ok", undefined as any);
    }
  };

  return (
    <Upload {...uploadProps}>
      <Button 
        icon={<UploadOutlined />} 
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </Upload>
  );
};

export default FileUpload; 