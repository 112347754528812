import React, { useState, useEffect } from 'react';
import { Card, DatePicker, Table, Button, Row, Col, Spin, Typography, Select, Input, Space, Tag, Avatar } from 'antd';
import { SearchOutlined, FileExcelOutlined, FilePdfOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import breakService from '../../../services/break/break.service';
import { BreakHistoryCompanyDto, BreakHistoryDto, BreakType } from '../../../types/break.types';
import { createPdf, exportToExcel } from '../../../utils/exportUtils';
import FilterDropdown from '../../shared/components/dropdown/FilterDropdown';
import { ExportColumn } from '../../../types/export.types';
import BreakFilters from './components/BreakFilters';
import departmentService from '../../../services/department/department.service';
import shopService from '../../../services/shop/shop.service';
import { DepartmentDto } from '../../../types/department.types';
import { Store } from '../../../types/shop.types';
import { getFileUrl } from '../../../utils/file.utils';
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import FooterSection from '../../shared/components/footerSection';
// Day.js ayarları
dayjs.locale('tr');
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const { Title } = Typography;
const { RangePicker } = DatePicker;

const BreakReport: React.FC = () => {
  // State tanımlamaları
  const [breakHistory, setBreakHistory] = useState<BreakHistoryCompanyDto | null>(null);
  const [filteredBreaks, setFilteredBreaks] = useState<BreakHistoryDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [filterBreakType, setFilterBreakType] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentDto[]>([]);
  const [shops, setShops] = useState<Store[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedShop, setSelectedShop] = useState<string>('all');
  const [selectedGender, setSelectedGender] = useState<string>('all');
  const [selectedDuration, setSelectedDuration] = useState<string | null>('all');
  const [selectedBreakType, setSelectedBreakType] = useState<string | null>('all');
  const {t} = useTranslation();
  // Verileri getiren fonksiyon
  const fetchBreakHistory = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const response = await breakService.getBreakHistoryCompany(startDate, endDate);
      const data = Array.isArray(response) ? response[0] : response;
      setBreakHistory(data);
      setFilteredBreaks(data.breaks || []);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  // Departman ve şube verilerini getir
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [deptData, shopData] = await Promise.all([
          departmentService.getDepartments(),
          shopService.getShops()
        ]);
        setDepartments(deptData);
        setShops(shopData);
      } catch (error: any) {
        handleApiError(error,t);
      }
    };
    fetchData();
  }, []);

  // Sayfa yüklendiğinde verileri getir
  useEffect(() => {
    const startDate = dayjs().startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs().endOf('day').format('YYYY-MM-DD');
    fetchBreakHistory(startDate, endDate);
  }, []);

  // Filtreleri uygula
  const applyFilters = () => {
    if (!breakHistory) return;
    
    let result = [...breakHistory.breaks];

    if (selectedDepartment !== 'all') {
      result = result.filter(breakRecord => 
        departments.find(d => d.id.toString() === selectedDepartment)?.name === breakRecord.department
      );
    }

    if (selectedShop !== 'all') {
      result = result.filter(breakRecord => 
        shops.find(s => s.id.toString() === selectedShop)?.name === breakRecord.branch
      );
    }

    if (dateRange) {
      const [start, end] = dateRange;
      result = result.filter(breakRecord => {
        const recordDate = dayjs(breakRecord.startTime);
        return recordDate.isSameOrAfter(start, 'day') && recordDate.isSameOrBefore(end, 'day');
      });
    }

    if (selectedBreakType !== 'all') {
      result = result.filter(breakRecord => {
        const breakTypeKey = Object.entries(BreakType).find(([_, value]) => value === breakRecord.type)?.[0];
        return breakTypeKey && breakRecord.type === selectedBreakType;
      });
    }

    if (selectedDuration !== 'all') {
      result = result.filter(breakRecord => {
        const duration = breakRecord.breakDuration;
        switch (selectedDuration) {
          case '15':
            return duration <= 15;
          case '30':
            return duration > 15 && duration <= 30;
          case '60':
            return duration > 30 && duration <= 60;
          case '60+':
            return duration > 60;
          default:
            return true;
        }
      });
    }

    setFilteredBreaks(result);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedDepartment, selectedShop, selectedBreakType, selectedDuration, dateRange, breakHistory]);

  // Filtreleri sıfırla
  const handleReset = () => {
    setDateRange(null);
    setSelectedDepartment('all');
    setSelectedShop('all');
    setSelectedGender('all');
    setSelectedBreakType('all');
    setSelectedDuration('all');
    if (breakHistory) {
      setFilteredBreaks([...breakHistory.breaks]);
    } else {
      setFilteredBreaks([]);
    }
  };

  // Tarihi formatla
  const formatTime = (time: string) => {
    if (!time) return '-';
    return dayjs(time).format('HH:mm:ss');
  };

  const formatDate = (date: string) => {
    if (!date) return '-';
    return dayjs(date).format('DD MMMM YYYY');
  };

  const formatDuration = (minutes: number) => {
    if (minutes === 0) return t('breakReport.table.zero');
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0 ? `${hours} ${t('breakReport.table.hours')} ${mins} ${t('breakReport.table.minutes')}` : `${mins} ${t('breakReport.table.minutes')}`;
  };

  // PDF dışa aktarma
  const handleExportPdf = () => {
    type BreakDataType = {
      userName: string;
      department: string;
      branch: string;
      date: string;
      breakType: string;
      startTime: string;
      endTime: string;
      duration: string;
    };

    // PDF için sütun tanımları - Türkçe karakter sorununu önlemek için özel karakter içermeyen başlıklar
    const columnsForPdf = [
      { header: 'Ad Soyad', property: 'userName' as keyof BreakDataType, width: 140 },
      { header: 'Departman', property: 'department' as keyof BreakDataType, width: 130 },
      { header: 'Sube', property: 'branch' as keyof BreakDataType, width: 130 },
      { header: 'Tarih', property: 'date' as keyof BreakDataType, width: 80 },
      { header: 'Mola Tipi', property: 'breakType' as keyof BreakDataType, width: 120 },
      { header: 'Baslangic', property: 'startTime' as keyof BreakDataType, width: 50 },
      { header: 'Bitis', property: 'endTime' as keyof BreakDataType, width: 50 },
      { header: 'Sure', property: 'duration' as keyof BreakDataType, width: 50 }
    ];

    // Mola tipi Türkçe karşılıkları - Türkçe karakter içermeyen versiyonlar
    const breakTypeLabelsForPdf: Record<string, string> = {
      [BreakType.BREAK]: t('breakTypes.short'),
      [BreakType.LUNCH]: t('breakTypes.lunch'),
      [BreakType.PERSONAL]: t('breakTypes.personal'),
      [BreakType.OTHER]: t('breakTypes.other')
    };

    // PDF için formatlanmış veri
    const formattedData = filteredBreaks.map((record: BreakHistoryDto) => ({
      userName: record.userName || '-',
      department: record.department || '-',
      branch: record.branch || '-',
      date: formatDate(record.startTime),
      breakType: breakTypeLabelsForPdf[record.type] || record.type,
      startTime: formatTime(record.startTime),
      endTime: formatTime(record.endTime),
      duration: formatDuration(record.breakDuration)
    }));

    // Mola istatistikleri
    const totalBreakCount = getTotalBreakCount();
    const avgBreakDuration = getAverageBreakDuration();
    const activeBreakCount = getActiveBreakCount();
    const totalBreakDuration = getTotalBreakDuration();

    // İstatistik bilgileri için uygun format
    const statistics = [
      { label: 'Toplam Mola Sayisi', value: totalBreakCount.toString() },
      { label: 'Ortalama Mola Suresi', value: formatDuration(avgBreakDuration) },
      { label: 'Aktif Mola Sayisi', value: activeBreakCount.toString() },
      { label: 'Toplam Mola Suresi', value: formatDuration(totalBreakDuration) }
    ];

    // Filtre açıklaması oluştur
    let description = 'Mola Kayitlari Raporu';
    
    if (dateRange) {
      const [start, end] = dateRange;
      description += ` - Tarih: ${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`;
    }
    
    if (selectedDepartment !== 'all') {
      const deptName = departments.find(d => d.id.toString() === selectedDepartment)?.name;
      if (deptName) {
        description += ` - Departman: ${deptName}`;
      }
    }
    
    if (selectedShop !== 'all') {
      const shopName = shops.find(s => s.id.toString() === selectedShop)?.name;
      if (shopName) {
        description += ` - Sube: ${shopName}`;
      }
    }

    if (selectedBreakType !== 'all') {
      const breakTypeName = selectedBreakType?.replace(/ö/g, 'o').replace(/ü/g, 'u').replace(/ı/g, 'i').replace(/ş/g, 's').replace(/ç/g, 'c') || '';
      description += ` - Mola Tipi: ${breakTypeName}`;
    }

    // createPdf fonksiyonunu çağır
    createPdf(formattedData, {
      title: 'Mola Kayitlari Raporu',
      description: description,
      headerColor: '#2c3e50', // Koyu lacivert
      accentColor: '#3498db', // Mavi
      columns: columnsForPdf,
      showStatistics: true,
      statistics: statistics,
      footer: {
        text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
        showPageNumbers: true
      }
    });
  };

  // Excel dışa aktarma
  const handleExportExcel = () => {
    type ExcelColumns = {
      header: string;
      property: 'userName' | 'department' | 'branch' | 'date' | 'breakType' | 'startTime' | 'endTime' | 'duration' | 'breakId' | 'userId';
      width?: number;
    };

    const columnsForExcel: ExcelColumns[] = [
      { header: 'Çalışan', property: 'userName' },
      { header: 'Departman', property: 'department' },
      { header: 'Şube', property: 'branch' },
      { header: 'Tarih', property: 'date' },
      { header: 'Mola Tipi', property: 'breakType' },
      { header: 'Başlangıç', property: 'startTime' },
      { header: 'Bitiş', property: 'endTime' },
      { header: 'Süre (dk)', property: 'duration' },
      { header: 'Mola ID', property: 'breakId' },
      { header: 'Kullanıcı ID', property: 'userId' }
    ];

    const data = filteredBreaks.map((record: BreakHistoryDto) => ({
      userName: record.userName || '-',
      department: record.department || '-',
      branch: record.branch || '-',
      date: formatDate(record.startTime),
      breakType: t(`breakTypes.${record.type}`),
      startTime: formatTime(record.startTime),
      endTime: formatTime(record.endTime),
      duration: record.breakDuration,
      breakId: record.breakId,
      userId: record.userId
    }));

    exportToExcel(data, 'Mola Kayitlari Raporu', columnsForExcel);
  };

  // İstatistikler için yardımcı fonksiyonlar
  const getTotalBreakCount = () => filteredBreaks.length;

  const getAverageBreakDuration = () => {
    const totalDuration = filteredBreaks.reduce((acc, curr) => acc + curr.breakDuration, 0);
    return Math.round(totalDuration / (filteredBreaks.length || 1));
  };

  const getActiveBreakCount = () => filteredBreaks.filter(break_ => !break_.endTime).length;

  const getTotalBreakDuration = () => filteredBreaks.reduce((acc, curr) => acc + curr.breakDuration, 0);

  // Tablo sütunları
  const columns = [
    {
      title: t('breakReport.table.employee'),
      dataIndex: 'userName',
      key: 'userName',
      render: (text: string, record: BreakHistoryDto) => (
        <div className="d-flex align-items-center">
          <div className="avatar me-2">
            {record.profilePicture ? (
              <Avatar src={getFileUrl(record.profilePicture)} size="large" />
            ) : (
              <Avatar icon={<UserOutlined />} size="large" />
            )}
          </div>
          <div>
            <Link to={all_routes.employeedetails.replace(':id', record.userId?.toString() || '')}>
              <h6 className="mb-0">{text}</h6>
              <small className="text-muted">{record.department}</small>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: t('breakReport.table.branch'),
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: t('breakReport.table.breakType'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => {
        const colorMap: Record<string, string> = {
          [BreakType.BREAK]: 'blue',
          [BreakType.LUNCH]: 'green',
          [BreakType.PERSONAL]: 'orange',
          [BreakType.OTHER]: 'geekblue'
        };
        
        return (
          <Tag color={colorMap[text] || 'default'}>
            {t(`breakTypes.${text}`)}
          </Tag>
        );
      },
    },
    {
      title: t('breakReport.table.date'),
      dataIndex: 'startTime',
      key: 'date',
      render: (text: string) => formatDate(text),
    },
    {
      title: t('breakReport.table.start'),
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text: string) => formatTime(text),
    },
    {
      title: t('breakReport.table.end'),
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text: string) => formatTime(text),
    },
    {
      title: t('breakReport.table.duration'),
      dataIndex: 'breakDuration',
      key: 'breakDuration',
      render: (minutes: number) => formatDuration(minutes),
    }
  ];

  const handleBreakTypeChange = (type: string | null) => {
    setSelectedBreakType(type || 'all');
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Breadcrumb */}
        <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
          <div className="my-auto mb-2">
            <h2 className="mb-1">{t('breakReport.pageTitle')}</h2>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={all_routes.adminDashboard}>
                    <i className="ti ti-smart-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">{t('breakReport.breadcrumb.reports')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t('breakReport.pageTitle')}
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <div className="dropdown me-3">
              <Link
                to="#"
                className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                data-bs-toggle="dropdown"
              >
                <i className="ti ti-file-export me-1" />
                {t('common.buttons.export')}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end p-3">
                <li>
                  <Link
                    to="#"
                    className="dropdown-item rounded-2 d-flex align-items-center py-2"
                    onClick={()=>handleExportPdf()}
                  >
                    <i className="ti ti-file-type-pdf me-2 text-danger" />
                    {t('common.buttons.pdf')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link
                    to="#"
                    className="dropdown-item rounded-2 d-flex align-items-center py-2"
                    onClick={()=>handleExportExcel()}
                  >
                    <i className="ti ti-file-type-xls me-2 text-success" />
                    {t('common.buttons.excel')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* İstatistikler */}
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-primary">
                    <i className="ti ti-coffee text-primary fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('breakReport.totalBreaks')}</div>
                    <div className="dash-counts">
                      <h4>{getTotalBreakCount()}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-warning">
                    <i className="ti ti-clock text-warning fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('breakReport.averageBreakDuration')}</div>
                    <div className="dash-counts">
                      <h4>{formatDuration(getAverageBreakDuration())}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-success">
                    <i className="ti ti-users text-success fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('breakReport.employeesOnBreak')}</div>
                    <div className="dash-counts">
                      <h4>{getActiveBreakCount()}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-info">
                    <i className="ti ti-chart-pie text-info fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('breakReport.totalBreakDuration')}</div>
                    <div className="dash-counts">
                      <h4>{formatDuration(getTotalBreakDuration())}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Filtreler ve Tablo */}
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              <div className="row align-items-center">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end">
                <BreakFilters
                    selectedDateRange={dateRange ? [dateRange[0].toISOString(), dateRange[1].toISOString()] : null}
                    selectedDepartment={selectedDepartment}
                    selectedShop={selectedShop}
                    selectedBreakType={selectedBreakType}
                    selectedGender={selectedGender}
                    selectedDuration={selectedDuration}
                    departments={departments}
                    shops={shops}
                    onDateRangeChange={(dates) => {
                      if (dates) {
                        const [start, end] = dates;
                        setDateRange([dayjs(start), dayjs(end)]);
                        fetchBreakHistory(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
                      } else {
                        setDateRange(null);
                        const today = dayjs();
                        fetchBreakHistory(
                          today.startOf('day').format('YYYY-MM-DD'),
                          today.endOf('day').format('YYYY-MM-DD')
                        );
                      }
                    }}
                    onDepartmentChange={setSelectedDepartment}
                    onShopChange={setSelectedShop}
                    onBreakTypeChange={handleBreakTypeChange}
                    onGenderChange={setSelectedGender}
                    onDurationChange={setSelectedDuration}
                    onReset={handleReset}
                  />
                </div>
              </div>
            </div>
              <div className="table-responsive">
                <Spin spinning={loading}>
                  <Table 
                    columns={columns} 
                    dataSource={filteredBreaks} 
                    rowKey={(record) => `${record.breakId}-${record.userId}`}
                    pagination={{ 
                      pageSize: 10,
                      showSizeChanger: false,
                      showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}` 
                    }}
                  />
                </Spin>
              </div>
            </div>
        </div>
      </div>
      <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
    </div>
  );
};

export default BreakReport; 