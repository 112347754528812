import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { base_path } from "./environment";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/style/css/feather.css";
import "../src/index.scss";
// import store from "./core/data/redux/store";
import { Provider } from "react-redux";
import "../src/style/icon/boxicons/boxicons/css/boxicons.min.css";
import "../src/style/icon/weather/weathericons.css";
import "../src/style/icon/typicons/typicons.css";
import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "../src/style/icon/ionic/ionicons.css";
import "../src/style/icon/tabler-icons/webfont/tabler-icons.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRouter from "./router/router";
import { store } from "./store/store";
import { checkAuth } from "./store/slices/auth.slice";
import "./i18n/config";
import { useSocket } from "./hooks/useSocket";
import ErrorBoundary from "./components/shared/ErrorBoundary";

store.dispatch(checkAuth())

const AppWithSocketIntegration:React.FC = () =>{
  const {connected} = useSocket();

  React.useEffect(()=>{
    if(connected){
      console.log("Connected to socket");
    }
    else{
      console.log("Disconnected from socket");
    }
  },[connected]);

  return <AppRouter />
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const savedTheme = localStorage.getItem("dataTheme")||"light";
document.documentElement.setAttribute("data-theme", savedTheme);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={base_path}>
      <ErrorBoundary>
        <AppWithSocketIntegration />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
