export const getFileUrl = (path: string | undefined | null): string => {
    if (!path) return '';
    
    // API'nin base URL'ini process.env'den alın veya sabit olarak tanımlayın
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    
    // Path zaten tam URL içeriyorsa, direkt döndür
    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }
    
    // Path'in başındaki ve sonundaki tüm slash'ları temizle
    const normalizedPath = path.replace(/^\/+|\/+$/g, '');
    
    // uploads/ prefix'i zaten varsa onu da kaldır
    const finalPath = normalizedPath.startsWith('uploads/') 
        ? normalizedPath.substring(8) 
        : normalizedPath;
        
    return `${API_BASE_URL}/uploads/${finalPath}`;
};