import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import authService from "../../../../services/auth/auth.service";
import { message } from "antd";
import { handleApiError } from "../../../../utils/errorHandler";
const ResetPassword2 = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordResponce, setPasswordResponce] = useState({
    passwordResponceText: "8 veya daha fazla karakter, harf, rakam ve sembol karışımı kullanın.",
    passwordResponceKey: "",
  });

  useEffect(() => {
    // Email bilgisini location state'inden alıyoruz
    const state = location.state as { email?: string };
    if (state?.email) {
      setEmail(state.email);
    } else {
      // Email bilgisi yoksa kullanıcıyı şifremi unuttum sayfasına yönlendir
      message.error("Email bilgisi bulunamadı. Lütfen tekrar deneyin.");
      navigate(routes.forgotPassword2);
    }
  }, [location, navigate, routes.forgotPassword2]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      message.error("Email bilgisi bulunamadı.");
      return;
    }
    
    if (!password) {
      message.error("Lütfen şifrenizi girin.");
      return;
    }
    
    if (password !== confirmPassword) {
      message.error("Şifreler eşleşmiyor.");
      return;
    }
    
    // Zayıf şifre kontrolü
    if (passwordResponce.passwordResponceKey !== "strong") {
      message.warning("Daha güçlü bir şifre kullanmanız önerilir.");
    }
    
    try {
      setLoading(true);
      await authService.resetPassword(email, password);
      message.success("Şifreniz başarıyla sıfırlandı.");
      navigate(routes.resetPasswordSuccess2);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = (field: "password" | "confirmPassword") => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onChangePassword = (password: string) => {
    setPassword(password);
    if (password.match(/^$|\s+/)) {
      setPasswordResponce({
        passwordResponceText: "8 veya daha fazla karakter, harf, rakam ve sembol karışımı kullanın.",
        passwordResponceKey: "",
      });
    } else if (password.length === 0) {
      setPasswordResponce({
        passwordResponceText: "",
        passwordResponceKey: "",
      });
    } else if (
      password.length < 8 ||
      !password.match(/[A-Z]/) ||
      !password.match(/[a-z]/) ||
      !password.match(/[0-9]/) ||
      !password.match(/[^A-Za-z0-9]/)
    ) {
      setPasswordResponce({
        passwordResponceText: "Şifreniz zayıf. Lütfen daha güçlü bir şifre oluşturun.",
        passwordResponceKey: "weak",
      });
    } else if (
      password.length < 12 ||
      !password.match(/[A-Z]/) ||
      !password.match(/[a-z]/) ||
      !password.match(/[0-9]/) ||
      !password.match(/[^A-Za-z0-9]/)
    ) {
      setPasswordResponce({
        passwordResponceText: "Şifreniz orta seviyede.",
        passwordResponceKey: "average",
      });
    } else {
      setPasswordResponce({
        passwordResponceText: "Şifreniz güçlü.",
        passwordResponceKey: "strong",
      });
    }
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-5">
            <div className="d-lg-flex align-items-center justify-content-center d-none flex-wrap vh-100 bg-primary-transparent">
              <div>
                <ImageWithBasePath src="assets/img/bg/authentication-bg-05.svg" alt="Img" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-7 mx-auto vh-100">
                <form className="vh-100" onSubmit={handleSubmit}>
                <div className="vh-100 d-flex flex-column justify-content-between" style={{paddingTop: "35%", paddingRight: "1.5rem",paddingLeft: "1.5rem",paddingBottom: "1.5rem"}}>
                  
                  <div className="flex-grow-0">
                    <div className="text-center mb-4">
                    <div className="mx-auto text-center mb-4" style={{width: "60%"}}>
                    <ImageWithBasePath src="assets/img/dinox-light.png" alt="Logo"/>
                  </div>
                        <h2 className="mb-2">Şifre Sıfırlama</h2>
                        <p className="mb-0">
                          {email && (
                            <span><strong>{email}</strong> için yeni şifre oluşturun.</span>
                          )}
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Yeni Şifre</label>
                        <div className="input-group">
                          <input
                            type={passwordVisibility.password ? "text" : "password"}
                            className="form-control border-end-0"
                            value={password}
                            onChange={(e) => onChangePassword(e.target.value)}
                            required
                          />
                          <span
                            className="input-group-text border-start-0"
                            onClick={() => togglePasswordVisibility("password")}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                passwordVisibility.password
                                  ? "ti ti-eye-filled"
                                  : "ti ti-eye-off-filled"
                              }
                            />
                          </span>
                        </div>
                        <div className="mt-2">
                          {passwordResponce.passwordResponceKey && (
                            <div
                              className={
                                passwordResponce.passwordResponceKey === "weak"
                                  ? "alert alert-danger py-2 px-3 mb-0"
                                  : passwordResponce.passwordResponceKey === "average"
                                  ? "alert alert-warning py-2 px-3 mb-0"
                                  : "alert alert-success py-2 px-3 mb-0"
                              }
                            >
                              {passwordResponce.passwordResponceText}
                            </div>
                          )}
                          {!passwordResponce.passwordResponceKey && password.length > 0 && (
                            <div className="alert alert-info py-2 px-3 mb-0">
                              {passwordResponce.passwordResponceText}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Şifre Tekrarı</label>
                        <div className="input-group">
                          <input
                            type={passwordVisibility.confirmPassword ? "text" : "password"}
                            className="form-control border-end-0"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          <span
                            className="input-group-text border-start-0"
                            onClick={() => togglePasswordVisibility("confirmPassword")}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                passwordVisibility.confirmPassword
                                  ? "ti ti-eye-filled"
                                  : "ti ti-eye-off-filled"
                              }
                            />
                          </span>
                        </div>
                        {password && confirmPassword && password !== confirmPassword && (
                          <div className="alert alert-danger py-2 px-3 mt-2 mb-0">
                            Şifreler eşleşmiyor.
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <button 
                          type="submit" 
                          className="btn btn-primary w-100"
                          disabled={loading || !password || !confirmPassword || password !== confirmPassword}
                        >
                          {loading ? "İşleniyor..." : "Şifreyi Sıfırla"}
                        </button>
                      </div>
                    </div>
                    <div className="mt-5 pb-4 text-center">
                      <p className="mb-0 text-gray-9">Copyright © Dinox - {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword2;
