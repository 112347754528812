import React, { useMemo } from 'react';
import { LeaveStatus, LeaveType } from '../../../../types/leaves.types';
import { LEAVE_TYPES, LEAVE_STATUSES } from '../../../../constants/leaveConstants';
import { Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import FilterDropdown from '../../../shared/components/filter/FilterDropdown';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

interface LeaveFiltersProps {
  selectedType: LeaveType | 'all';
  selectedStatus: LeaveStatus | 'all';
  selectedDateRange?: [string, string] | null;
  onTypeChange: (type: LeaveType | 'all') => void;
  onStatusChange: (status: LeaveStatus | 'all') => void;
  onDateRangeChange: (dates: [string, string] | null) => void;
  onReset: () => void;
}

const EmployeeLeaveFilters: React.FC<LeaveFiltersProps> = ({
  selectedType,
  selectedStatus,
  selectedDateRange,
  onTypeChange,
  onStatusChange,
  onDateRangeChange,
  onReset,
}) => {
  const { t } = useTranslation();
  const filterGroups = [
    {
      label: t('leaveEmployee.leaveType'),
      type: 'select' as const,
      value: selectedType,
      onChange: onTypeChange,
      options: [
        { value: 'all', label: t('leaveEmployee.allLeaveTypes') },
        ...Object.entries(LEAVE_TYPES).map(([value, label]) => ({
          value,
          label
        }))
      ]
    },
    {
      label: t('leaveEmployee.status'),
      type: 'select' as const,
      value: selectedStatus,
      onChange: onStatusChange,
      options: [
        { value: 'all', label: t('leaveEmployee.allStatuses') },
        ...Object.entries(LEAVE_STATUSES).map(([value, label]) => ({
          value,
          label
        }))
      ]
    },
    {
      label: t('leaveEmployee.dateRange'),
      type: 'dateRange' as const,
      value: selectedDateRange ? [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])] : null,
      onChange: (dates: any) => {
        if (dates) {
          onDateRangeChange([dates[0].toISOString(), dates[1].toISOString()]);
        } else {
          onDateRangeChange(null);
        }
      }
    }
  ];

  return (
    <FilterDropdown
      groups={filterGroups}
      onReset={onReset}
      buttonType="primary"
    />
  );
};

export default EmployeeLeaveFilters;