// src/components/features/dashboard/components/clockInOutStatus.tsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSocket } from "../../../../hooks/useSocket";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import socketService from "../../../../services/socket/socket.service";
import { CompanyActivityDto } from "../../../../types/attendanceDto.types";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import dailyRecordService from "../../../../services/dailyRecord/dailyRecord.service";
import {all_routes} from "../../../../router/all_routes";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../../../../utils/file.utils";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { handleApiError } from "../../../../utils/errorHandler";
const ClockInOutStatus: React.FC = () => {
  const [activities, setActivities] = useState<CompanyActivityDto[]>([]);
  const { connected, on } = useSocket();
  const {companyId} = useSelector((state: RootState) => state.auth);
  const [checkedInCount, setCheckedInCount] = useState<number>(0);
  const routes = all_routes;
  const { t } = useTranslation();
  // API'den başlangıç verilerini al
  useEffect(() => {
    if (companyId) {
      const fetchCompanyActivity = async () => {
        try {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          
          // Sadece giriş/çıkış aktivitelerini filtrele
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type.includes("check_in") || activity.type.includes("check_out")
          );
          
          // Aktiviteleri en yeniden en eskiye doğru sırala
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          
          setActivities(sortedActivities);
          
          // Mevcut çalışan sayısını hesapla
          const checkedIn = sortedActivities.filter(
            (a: CompanyActivityDto) => a.type === "check_in" && 
            !sortedActivities.some(b => b.type === "check_out" && b.user.id === a.user.id && new Date(b.createdAt) > new Date(a.createdAt))
          ).length;
          
          setCheckedInCount(checkedIn);
        } catch (error: any) {
          handleApiError(error,t);
        }
      };
      
      fetchCompanyActivity();
    }
  }, [companyId]);

  // Socket'ten yeni aktiviteleri dinle
  useEffect(() => {
    if (connected) {
      // Sadece newActivity olayını dinle
      const handleNewActivity = (data: CompanyActivityDto) => {
        
        if (data.type.includes("check_in") || data.type.includes("check_out")) {
          
          // Aktiviteyi listeye ekle
          setActivities(prev => {
            // Aynı ID'li aktivite zaten varsa, ekleme
            if (prev.some(a => a.id === data.id)) {
              return prev;
            }
            
            // Yeni aktiviteyi ekle
            const newActivities = [data, ...prev];
            
            // Aktif giriş yapmış çalışan sayısını hesapla
            const checkedIn = newActivities.filter(
              a => a.type === "check_in" && 
              !newActivities.some(b => b.type === "check_out" && b.user.id === a.user.id && new Date(b.createdAt) > new Date(a.createdAt))
            ).length;
            
            setCheckedInCount(checkedIn);
            
            return newActivities;
          });
        }
      };

      // Sadece newActivity dinleyicisini ekle
      on("newActivity", handleNewActivity);
    }
  }, [connected, on]);

  // Aktivite tipine göre badge rengi belirle
  const getBadgeClass = (type: string) => {
    switch (type) {
      case "check_in":
        return "badge-success";
      case "check_out":
        return "badge-danger";
      default:
        return "badge-secondary";
    }
  };

  // Aktivite tipine göre ikon belirle
  const getActivityIcon = (type: string) => {
    switch (type) {
      case "check_in":
        return "ti ti-login";
      case "check_out":
        return "ti ti-logout";
      default:
        return "ti ti-clock";
    }
  };

  // Zaman formatını düzenle
  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="col-xxl-4 col-xl-6 d-flex">
    <div className="card flex-fill">
      <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
        <h5 className="mb-2">{t('dashboard.inOutStatus.title')}</h5>
        {/* <div className="d-flex align-items-center">
          <span className="badge bg-success text-white me-2">
            <i className="ti ti-user-check me-1"></i>
            Mevcut Çalışan: {checkedInCount}
          </span>
        </div> */}
      </div>
      <div className="card-body">
        <div style={{ maxHeight: '350px', overflowY: 'auto',marginBottom: '10px' }}>
          {activities.length > 0 ? (
            activities.map((activity) => (
              <div key={activity.id} className="d-flex align-items-center justify-content-between mb-3 p-2 border border-dashed br-5">
                <div className="d-flex align-items-center">
                  <Link to="#" className="avatar flex-shrink-0">
                    {activity.profilePicture ? (
                      <Avatar src={getFileUrl(activity.profilePicture)} size="large" />
                    ) : (
                      <Avatar icon={<UserOutlined />} size="default" />
                    )}
                  </Link>
                  <div className="ms-2">
                    <h6 className="fs-14 fw-medium text-truncate">
                      {activity.user.firstName + " " + activity.user.lastName}
                    </h6>
                    <p className="fs-13">
                      {activity.description.split('-').pop()?.trim()}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="#" className="link-default me-2">
                    <i className={getActivityIcon(activity.type)} />
                  </Link>
                  <span className={`fs-10 fw-medium d-inline-flex align-items-center badge ${getBadgeClass(activity.type)}`}>
                    <i className="ti ti-circle-filled fs-5 me-1" />
                    {formatTime(activity.createdAt)}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-3">
              <p className="mb-0">{t('dashboard.inOutStatus.noInOutRecords')}</p>
            </div>
          )}
        </div>
        
        <Link
          to={routes.attendance}
          className="btn btn-light btn-md w-100 mt-2"
        >
          {t('dashboard.inOutStatus.allRecords')}
        </Link>
      </div>
    </div>
    </div>
  );
};

export default ClockInOutStatus;