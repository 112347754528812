import { FileDto, FileType, FileUploadResponse } from "../../types/file.types";
import { FileUploadRequest } from "../../types/file.types";
import apiService from "../api/api.service";

class FileService {
    private static instance: FileService;

    private constructor() {}

    public static getInstance(): FileService {
        if (!FileService.instance) {
            FileService.instance = new FileService();
        }
        return FileService.instance;
    }

    public async uploadFile(file: File, type: FileType, name?: string): Promise<FileUploadResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        if(name){
            formData.append('name', name);
        }
        
        const response = await apiService.post<FileUploadResponse>('/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }

    public async getFiles(): Promise<FileDto[]> {
        const response = await apiService.get<FileDto[]>('/files');
        return response;
    }

    public async getFile(id: number): Promise<FileDto> {
        const response = await apiService.get<FileDto>(`/files/${id}`);
        return response;
    }

    public async deleteFile(id: number): Promise<void> {
        await apiService.delete(`/files/${id}`);
    }

    public async getFileByUserDocuments(fileType: FileType,userId:number): Promise<FileDto[]> {
        const response = await apiService.get<FileDto[]>(`/files/user/${userId}`,{params:{type:fileType}});
        return response;
    }

    public async updateFileByUserDocuments(fileId: number, fileType: FileType, name?: string,file?:File): Promise<FileUploadResponse> {
        const formData = new FormData();
        formData.append('type', fileType);
        formData.append('name', name || '');
        if (file) {
            formData.append('file', file);
        }
        const response = await apiService.put<FileUploadResponse>(`/files/${fileId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }
    
}
export default FileService;
