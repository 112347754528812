import apiService from '../../services/api/api.service';
import { AuthResponse, LoginRequest } from '../../types/auth.types';
import { PermissionType } from '../../types/user.types';

class AuthService {
    private static instance: AuthService;

    private constructor() {}

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    public async login(credentials: LoginRequest): Promise<AuthResponse> {
        return apiService.post<AuthResponse>('/auth/login', credentials);
    }

    public async logout(): Promise<void> {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('menuOpened')
        localStorage.removeItem('companyId')
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        return !!token;
    }

    public getToken(): string | null {
        return localStorage.getItem('token');
    }

    public setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    public getUser(): string | null {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    }

    public setUser(user: string): void {
        localStorage.setItem('user', JSON.stringify(user));
    }

    public async sendOtp(email:string):Promise<void>{
        return apiService.post('/auth/send-otp',{email});
    }

    public async verifyOtp(email:string,otp:string):Promise<void>{
        return apiService.post('/auth/verify-otp',{email,otp});
    }

    public async resetPassword(email:string,newPassword:string):Promise<void>{
        return apiService.post('/auth/reset-password',{email,newPassword});
    }

    public async changePassword(currentPassword:string,newPassword:string):Promise<void>{
        return apiService.post('/auth/change-password',{currentPassword,newPassword});
    }

    public async getUserPermissions(userId:number):Promise<PermissionType[]>{
        return apiService.get(`/auth/${userId}/permissions`);
    }
    public async updateUserPermissions(userId:number,permissions:PermissionType[]):Promise<void>{
        return apiService.put(`/auth/${userId}/permissions`,{permissions});
    }
}

export default AuthService.getInstance(); 