export const taskDetails = [
  {
    key:"1",
    TaskName: "Patient Appointment Booking",
    ProjectName: "Hospital Administration",
    CreatedDate: "14/01/2024",
    DueDate: "15/01/2024",
    Priority: "Low",
    Status: "Completed",
  },
  {
    key:"2",
    TaskName: "Payment Gateway",
    ProjectName: "Educational Platform",
    CreatedDate: "21/01/2024",
    DueDate: "25/01/2024",
    Priority: "Medium",
    Status: "Inprogress",
  },
  {
    key:"3",
    TaskName: "Doctor available module",
    ProjectName: "Clinic Management",
    CreatedDate: "20/02/2024",
    DueDate: "22/02/2024",
    Priority: "High",
    Status: "Completed",
  },
  {
    key:"4",
    TaskName: "Video Conferencing Module",
    ProjectName: "Chat & Call Mobile App",
    CreatedDate: "15/03/2024",
    DueDate: "17/03/2024",
    Priority: "Low",
    Status: "On Hold",
  },
  {
    key:"5",
    TaskName: "Private Chat Module",
    ProjectName: "Travel Planning Website",
    CreatedDate: "12/04/2024",
    DueDate: "16/04/2024",
    Priority: "High",
    Status: "Completed",
  },
  {
    key:"6",
    TaskName: "Services List & Grid View",
    ProjectName: "Service Booking Software",
    CreatedDate: "20/05/2024",
    DueDate: "21/05/2024",
    Priority: "Low",
    Status: "Pending",
  },
  {
    key:"7",
    TaskName: "Car Detail Moodule",
    ProjectName: "Car & Bike Rental Software",
    CreatedDate: "06/07/2024",
    DueDate: "06/07/2024",
    Priority: "Medium",
    Status: "Inprogress",
  },
  {
    key:"8",
    TaskName: "Location Module",
    ProjectName: "Food Order App",
    CreatedDate: "02/09/2024",
    DueDate: "04/09/2024",
    Priority: "Low",
    Status: "Completed",
  },
  {
    key:"9",
    TaskName: "Hotel List & Grid View",
    ProjectName: "Hotel Booking App",
    CreatedDate: "15/11/2024",
    DueDate: "15/11/2024",
    Priority: "Medium",
    Status: "Completed",
  },
  {
    key:"10",
    TaskName: "Warehouse Module",
    ProjectName: "POS Admin Software",
    CreatedDate: "10/12/2024",
    DueDate: "11/12/2024",
    Priority: "Low",
    Status: "Pending",
  },
];
