import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import TicketListModal from "../../shared/modals/ticketListModal";
import TicketService from "../../../services/ticket/ticket.service";
import { TicketDto, TicketCreateDto } from "../../../types/ticket.types";
import { User } from "../../../types/user.types";
import { Form, Input, Select, Button, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingSpinner } from "../../../utils/LoadingSpinner";
import Loader from "../../shared/loader";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";

const { TextArea } = Input;
const { Option } = Select;

const TicketsEmployee = () => {
  const routes = all_routes;
  const { t } = useTranslation();
  const {user} = useSelector((state: RootState) => state.auth);
  const { getTicketsByUser, createTicket } = TicketService;
  const [tickets, setTickets] = useState<TicketDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [visibleTickets, setVisibleTickets] = useState<number>(6);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  // Filtrelenmiş biletleri hesapla
  const filteredTickets = tickets.filter(ticket => {
    if (!selectedStatus) return true;
    return ticket.status.toLowerCase() === selectedStatus.toLowerCase();
  });

  // Görünen biletleri sınırla
  const displayedTickets = filteredTickets.slice(0, visibleTickets);

  // Status sayılarını hesapla
  const statusCounts = {
    created: tickets.filter(ticket => ticket.status.toLowerCase() === 'created').length,
    reviewed: tickets.filter(ticket => ticket.status.toLowerCase() === 'reviewed').length,
  };

  // Daha fazla bilet yükleme fonksiyonu
  const loadMoreTickets = () => {
    setVisibleTickets(prev => prev + 6);
  };

  const fetchTickets = async () => {
    try {
      setLoading(true);
      const data = await getTicketsByUser(Number(user?.id));
      setTickets(data);
      setLoading(false);
      
    } catch (err: any) {
      handleApiError(err,t);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  };  
  
  useEffect(() => {
    fetchTickets();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        setSubmitting(true);
        
        const ticketData: TicketCreateDto = {
          title: values.title,
          description: values.description,
          status: "created"
        };
        
        console.log(ticketData);
        
        createTicket(ticketData)
          .then(() => {
            message.success(t('ticketUser.success'));
            form.resetFields();
            setIsModalVisible(false);
            fetchTickets();
          })
          .catch(error => {
            handleApiError(error,t);
          })
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch(info => {
        handleApiError(info,t);
      });
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper bg-light">
        <div className="content container-fluid py-4">
          {/* Başlık ve Butonlar */}
          <div className="page-header d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4">
            <div className="mb-3 mb-md-0 animate__animated animate__fadeIn">
              <h1 className="page-title fw-bold mb-2 text-gradient">{t('ticketUser.title')}</h1>
              <nav>
                <ol className="breadcrumb mb-0 p-0 bg-transparent">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard} className="text-decoration-none">
                      <i className="ti ti-smart-home me-1" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('ticketUser.breadcrumb.employee')}</li>
                  <li className="breadcrumb-item active fw-semibold">{t('ticketUser.breadcrumb.tickets')}</li>
                </ol>
              </nav>
            </div>
            <div className="d-flex gap-2 flex-wrap animate__animated animate__fadeIn">
              <div className="dropdown">
                <button
                  className="btn btn-glass d-flex align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <i className="ti ti-file-export me-1" />
                  <span>{t('common.buttons.export')}</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end shadow-lg p-2">
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item rounded-2 py-2 px-3 d-flex align-items-center dropdown-item-hover"
                      // onClick={handleOnExportPdf}
                    >
                      <i className="ti ti-file-type-pdf fs-5 me-2 text-danger" />
                      <span>{t('common.buttons.pdf')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item rounded-2 py-2 px-3 d-flex align-items-center dropdown-item-hover"
                      // onClick={handleOnExportExcel}
                    >
                      <i className="ti ti-file-type-xls fs-5 me-2 text-success" />
                      <span>{t('common.buttons.excel')}</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                onClick={showModal}
                className="btn btn-gradient d-flex align-items-center"
              >
                <i className="ti ti-plus me-2" />
                <span>{t('ticketUser.newTicketRequest')}</span>
              </button>
            </div>
          </div>
         
          {/* Status Filter */}
          <div className="card glass-card border-0 mb-4 animate__animated animate__fadeInUp">
            <div className="card-body p-0">
              <div className="filter-tabs d-flex flex-wrap" role="group">
                <button
                  className={`filter-tab-btn ${!selectedStatus ? 'active' : ''}`}
                  onClick={() => setSelectedStatus(null)}
                >
                  <div className="filter-tab-icon all-icon">
                    <i className="ti ti-tickets"></i>
                  </div>
                  <div className="filter-tab-content">
                    <div className="filter-tab-title">{t('ticketUser.allTickets')}</div>
                    <div className="filter-tab-count">{tickets.length} {t('ticketUser.tickets')}</div>
                  </div>
                </button>
                
                <button
                  className={`filter-tab-btn ${selectedStatus === 'created' ? 'active' : ''}`}
                  onClick={() => setSelectedStatus('created')}
                >
                  <div className="filter-tab-icon new-icon">
                    <i className="ti ti-file-plus"></i>
                  </div>
                  <div className="filter-tab-content">
                    <div className="filter-tab-title">{t('ticketUser.newTickets')}</div>
                    <div className="filter-tab-count">{statusCounts.created} {t('ticketUser.tickets')}</div>
                  </div>
                </button>

                <button
                  className={`filter-tab-btn ${selectedStatus === 'reviewed' ? 'active' : ''}`}
                  onClick={() => setSelectedStatus('reviewed')}
                >
                  <div className="filter-tab-icon reviewed-icon">
                    <i className="ti ti-eye-check"></i>
                  </div>
                  <div className="filter-tab-content">
                    <div className="filter-tab-title">{t('ticketUser.reviewedTickets')}</div>
                    <div className="filter-tab-count">{statusCounts.reviewed} {t('ticketUser.tickets')}</div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <style>
            {`
              /* Animasyonlar ve geçişler */
              @keyframes fadeIn {
                from { opacity: 0; transform: translateY(10px); }
                to { opacity: 1; transform: translateY(0); }
              }
              
              @keyframes floating {
                0% { transform: translateY(0px); }
                50% { transform: translateY(-8px); }
                100% { transform: translateY(0px); }
              }
              
              @keyframes pulse {
                0% { transform: scale(1); }
                50% { transform: scale(1.05); }
                100% { transform: scale(1); }
              }
              
              @keyframes spin {
                to { transform: rotate(360deg); }
              }
              
              /* Temel stil bileşenleri */
              .bg-light {
                background: linear-gradient(to bottom, #f8faff, #f0f4fc);
              }
              
              .text-gradient {
                background: linear-gradient(90deg, var(--bs-primary), #6366f1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: relative;
              }
              
              .btn-gradient {
                background: linear-gradient(135deg, var(--bs-primary), #6366f1);
                border: none;
                color: white;
                box-shadow: 0 4px 10px rgba(99, 102, 241, 0.3);
                transition: all 0.3s ease;
              }
              
              .btn-gradient:hover {
                transform: translateY(-2px);
                box-shadow: 0 6px 15px rgba(99, 102, 241, 0.4);
                color: white;
              }
              
              .btn-glass {
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(4px);
                border: 1px solid rgba(255, 255, 255, 0.6);
                color: #4b5563;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
                transition: all 0.3s ease;
              }
              
              .btn-glass:hover {
                background: rgba(255, 255, 255, 0.95);
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
                transform: translateY(-2px);
              }
              
              .glass-card {
                background: rgba(255, 255, 255, 0.85);
                backdrop-filter: blur(10px);
                box-shadow: 0 8px 32px rgba(0, 0, 0, 0.07);
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.3);
                overflow: hidden;
              }
              
              .dropdown-menu {
                backdrop-filter: blur(10px);
                background: rgba(255, 255, 255, 0.95);
                border-radius: 12px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                animation: fadeIn 0.25s ease-out;
              }
              
              .dropdown-item-hover {
                transition: all 0.2s ease;
              }
              
              .dropdown-item-hover:hover {
                background: rgba(99, 102, 241, 0.08);
                transform: translateX(3px);
              }
              
              /* Filtre tab tasarımı */
              .filter-tabs {
                width: 100%;
                overflow-x: auto;
                scrollbar-width: none;
              }
              
              .filter-tabs::-webkit-scrollbar {
                display: none;
              }
              
              .filter-tab-btn {
                display: flex;
                align-items: center;
                padding: 20px 24px;
                border: none;
                background: transparent;
                position: relative;
                flex: 1;
                min-width: 180px;
                text-align: left;
                transition: all 0.3s ease;
                color: #64748b;
                overflow: hidden;
              }
              
              .filter-tab-btn::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 3px;
                background: linear-gradient(90deg, var(--bs-primary), #6366f1);
                transition: width 0.3s ease;
                border-radius: 3px;
              }
              
              .filter-tab-btn.active {
                color: #1e293b;
                background-color: rgba(99, 102, 241, 0.04);
              }
              
              .filter-tab-btn.active::before {
                width: 80%;
              }
              
              .filter-tab-btn:hover:not(.active) {
                background-color: rgba(0, 0, 0, 0.02);
              }
              
              .filter-tab-btn:hover::before {
                width: 20%;
              }
              
              .filter-tab-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 12px;
                margin-right: 16px;
                transition: all 0.3s ease;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
              }
              
              .filter-tab-btn:hover .filter-tab-icon {
                transform: scale(1.05) rotate(5deg);
              }
              
              .filter-tab-icon i {
                font-size: 20px;
                transition: all 0.3s ease;
              }
              
              .filter-tab-btn.active .filter-tab-icon.all-icon {
                background: linear-gradient(135deg, rgba(var(--bs-primary-rgb), 0.15), rgba(99, 102, 241, 0.15));
                color: var(--bs-primary);
              }
              
              .filter-tab-btn.active .filter-tab-icon.new-icon {
                background: linear-gradient(135deg, rgba(var(--bs-warning-rgb), 0.15), rgba(245, 158, 11, 0.15));
                color: var(--bs-warning);
              }
              
              .filter-tab-btn.active .filter-tab-icon.reviewed-icon {
                background: linear-gradient(135deg, rgba(var(--bs-success-rgb), 0.15), rgba(16, 185, 129, 0.15));
                color: var(--bs-success);
              }
              
              .filter-tab-content {
                display: flex;
                flex-direction: column;
              }
              
              .filter-tab-title {
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 4px;
                transition: all 0.2s ease;
              }
              
              .filter-tab-btn.active .filter-tab-title {
                transform: translateY(-2px);
              }
              
              .filter-tab-count {
                font-size: 13px;
                opacity: 0.8;
                transition: all 0.2s ease;
              }
              
              .filter-tab-btn.active .filter-tab-count {
                transform: translateY(-2px);
              }
              
              /* Kart tasarımları */
              .ticket-card {
                border: none;
                border-radius: 16px;
                background: rgba(255, 255, 255, 0.95);
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
                margin-bottom: 20px;
                transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                overflow: hidden;
                animation: fadeIn 0.5s ease-out;
                position: relative;
                z-index: 1;
              }
              
              .ticket-card::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                border-radius: 2px 0 0 2px;
                background: linear-gradient(180deg, var(--bs-primary), #6366f1);
                opacity: 0;
                transition: opacity 0.3s ease;
              }
              
              .ticket-card:hover {
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
                transform: translateY(-4px);
              }
              
              .ticket-card:hover::before {
                opacity: 1;
              }
              
              .ticket-card-body {
                padding: 24px;
              }
              
              .ticket-title {
                color: #1e293b;
                font-size: 19px;
                font-weight: 600;
                margin-bottom: 12px;
                transition: color 0.3s;
                text-decoration: none;
                display: block;
                line-height: 1.4;
              }
              
              .ticket-title:hover {
                color: var(--bs-primary);
                transform: translateX(2px);
              }
              
              .ticket-meta {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
                margin-bottom: 16px;
              }
              
              .ticket-meta-item {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #64748b;
                padding: 4px 8px;
                background: rgba(226, 232, 240, 0.5);
                border-radius: 6px;
                transition: all 0.2s ease;
              }
              
              .ticket-meta-item:hover {
                background: rgba(226, 232, 240, 0.9);
                transform: translateY(-2px);
              }
              
              .ticket-meta-item i {
                margin-right: 6px;
                font-size: 16px;
                opacity: 0.7;
              }
              
              .ticket-description {
                color: #475569;
                margin-bottom: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 1.6;
                font-size: 15px;
                background: rgba(241, 245, 249, 0.5);
                padding: 14px;
                border-radius: 8px;
              }
              
              .ticket-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
                border-top: 1px solid rgba(0, 0, 0, 0.04);
              }
              
              .ticket-id {
                font-size: 13px;
                color: #64748b;
                display: flex;
                align-items: center;
                font-weight: 500;
              }
              
              .ticket-id i {
                margin-right: 6px;
              }
              
              .ticket-status {
                padding: 6px 12px;
                border-radius: 30px;
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
                transition: all 0.3s ease;
              }
              
              .ticket-status:hover {
                transform: translateY(-2px);
              }
              
              .ticket-status i {
                margin-right: 6px;
                font-size: 14px;
              }
              
              .ticket-status.created {
                background: linear-gradient(135deg, rgba(var(--bs-warning-rgb), 0.2), rgba(245, 158, 11, 0.2));
                color: #b45309;
              }
              
              .ticket-status.reviewed {
                background: linear-gradient(135deg, rgba(var(--bs-success-rgb), 0.2), rgba(16, 185, 129, 0.2));
                color: #047857;
              }
              
              .ticket-status.rejected {
                background: linear-gradient(135deg, rgba(var(--bs-danger-rgb), 0.2), rgba(220, 38, 38, 0.2));
                color: #b91c1c;
              }
              
              /* Boş durum tasarımı */
              .empty-state {
                padding: 80px 20px;
                text-align: center;
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(10px);
                border-radius: 24px;
                box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                animation: fadeIn 0.5s ease-out;
              }
              
              .empty-state-icon {
                width: 120px;
                height: 120px;
                border-radius: 60px;
                background: linear-gradient(135deg, rgba(var(--bs-primary-rgb), 0.1), rgba(99, 102, 241, 0.1));
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 32px;
                animation: pulse 3s ease-in-out infinite;
                box-shadow: 0 10px 30px rgba(var(--bs-primary-rgb), 0.15);
              }
              
              .empty-state-icon i {
                font-size: 56px;
                background: linear-gradient(90deg, var(--bs-primary), #6366f1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              
              .empty-state-title {
                font-size: 28px;
                font-weight: 700;
                color: #1e293b;
                margin-bottom: 16px;
              }
              
              .empty-state-desc {
                font-size: 17px;
                color: #64748b;
                max-width: 450px;
                margin: 0 auto 32px;
                line-height: 1.6;
              }
              
              /* Yükleme butonu */
              .load-more-btn {
                padding: 14px 32px;
                border-radius: 50px;
                transition: all 0.4s ease;
                background: linear-gradient(135deg, var(--bs-primary), #6366f1);
                border: none;
                box-shadow: 0 6px 15px rgba(var(--bs-primary-rgb), 0.25);
                font-weight: 500;
              }
              
              .load-more-btn:hover {
                transform: translateY(-3px);
                box-shadow: 0 10px 20px rgba(var(--bs-primary-rgb), 0.3);
              }
              
              .load-more-btn:active {
                transform: translateY(-1px);
              }
              
              /* Loading spinner */
              .loading-spinner-container {
                padding: 100px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              }
              
              .loading-spinner {
                position: relative;
                width: 60px;
                height: 60px;
                margin-bottom: 24px;
              }
              
              .loading-spinner div {
                position: absolute;
                width: 100%;
                height: 100%;
                border: 4px solid transparent;
                border-radius: 50%;
                animation: spinner-anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-top-color: var(--bs-primary);
              }
              
              .loading-spinner div:nth-child(1) {
                animation-delay: -0.45s;
              }
              
              .loading-spinner div:nth-child(2) {
                animation-delay: -0.3s;
              }
              
              .loading-spinner div:nth-child(3) {
                animation-delay: -0.15s;
              }
              
              @keyframes spinner-anim {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
              
              .loading-text {
                font-size: 16px;
                color: #64748b;
                position: relative;
              }
              
              .loading-text::after {
                content: '...';
                position: absolute;
                animation: dots 1.5s infinite;
                width: 24px;
                text-align: left;
              }
              
              @keyframes dots {
                0%, 20% { content: '.'; }
                40% { content: '..'; }
                60%, 100% { content: '...'; }
              }
              
              /* Modal tasarımı */
              .ticket-modal .ant-modal-content {
                border-radius: 20px;
                overflow: hidden;
                box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
              }
              
              .ticket-modal .ant-modal-header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.04);
                padding: 24px 30px;
                background: linear-gradient(to right, rgba(var(--bs-primary-rgb), 0.02), rgba(99, 102, 241, 0.02));
              }
              
              .ticket-modal .ant-modal-title {
                font-size: 20px;
                font-weight: 600;
              }
              
              .ticket-modal .ant-modal-body {
                padding: 30px;
              }
              
              .ticket-modal .ant-form-item-label > label {
                font-weight: 500;
                font-size: 15px;
                color: #334155;
              }
              
              .ticket-modal .ant-input, 
              .ticket-modal .ant-input-textarea {
                border-radius: 10px;
                padding: 12px 16px;
                border: 1px solid rgba(0, 0, 0, 0.08);
                transition: all 0.3s ease;
                background: rgba(248, 250, 252, 0.6);
              }
              
              .ticket-modal .ant-input:hover, 
              .ticket-modal .ant-input-textarea:hover {
                border-color: rgba(var(--bs-primary-rgb), 0.3);
                background: white;
              }
              
              .ticket-modal .ant-input:focus, 
              .ticket-modal .ant-input-textarea:focus {
                border-color: var(--bs-primary);
                box-shadow: 0 0 0 2px rgba(var(--bs-primary-rgb), 0.2);
                background: white;
              }
              
              .ticket-modal .ant-form-item-explain-error {
                margin-top: 6px;
                font-size: 13px;
              }
              
              .ticket-modal .ant-form-item:last-child {
                margin-bottom: 0;
              }
              
              .modal-actions {
                display: flex;
                justify-content: flex-end;
                gap: 12px;
                margin-top: 10px;
              }
              
              .modal-btn {
                padding: 10px 20px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 46px;
                min-width: 140px;
                border-radius: 10px;
                transition: all 0.3s ease;
              }
              
              .modal-btn.cancel {
                background-color: #f1f5f9;
                color: #475569;
                border: none;
              }
              
              .modal-btn.cancel:hover {
                background-color: #e2e8f0;
                transform: translateY(-2px);
              }
              
              .btn-primary.modal-btn {
                background: linear-gradient(135deg, var(--bs-primary), #6366f1);
                border: none;
                box-shadow: 0 4px 10px rgba(var(--bs-primary-rgb), 0.25);
              }
              
              .btn-primary.modal-btn:hover:not(:disabled) {
                transform: translateY(-2px);
                box-shadow: 0 6px 15px rgba(var(--bs-primary-rgb), 0.35);
              }
              
              /* Responsive */
              @media (max-width: 768px) {
                .filter-tab-btn {
                  padding: 14px 16px;
                }
                
                .filter-tab-icon {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                }
                
                .filter-tab-title {
                  font-size: 14px;
                }
                
                .filter-tab-count {
                  font-size: 12px;
                }
                
                .ticket-card-body {
                  padding: 18px;
                }
                
                .ticket-title {
                  font-size: 17px;
                }
                
                .empty-state {
                  padding: 60px 16px;
                }
                
                .empty-state-icon {
                  width: 90px;
                  height: 90px;
                }
                
                .empty-state-title {
                  font-size: 24px;
                }
                
                .empty-state-desc {
                  font-size: 15px;
                }
              }
            `}
          </style>

          <div className="row">
            <div className="col-xl-12 col-md-12">
              {loading ? (
                <Loader/>
              ) : error ? (
                <div className="alert alert-danger shadow-sm" role="alert">
                  <div className="d-flex align-items-center">
                    <i className="ti ti-alert-circle fs-5 me-2"></i>
                    <div>
                      <strong>{t('common.errors.error')}</strong> {error}
                    </div>
                  </div>
                </div>
              ) 
              : displayedTickets.length === 0 ? (
                <div className="empty-state animate__animated animate__fadeIn">
                  <div className="empty-state-icon">
                    <i className="ti ti-ticket-off"></i>
                  </div>
                  <h4 className="empty-state-title">{t('ticketUser.noTickets')}</h4>
                  <p className="empty-state-desc">
                    {t('ticketUser.noTicketsDescription')}
                  </p>
                  <button 
                    className="btn btn-gradient btn-lg" 
                    onClick={() => setSelectedStatus(null)}
                  >
                    <i className="ti ti-layout-list me-2"></i>
                    {t('ticketUser.showAllTickets')}
                  </button>
                </div>
              ) 
              : (
                <>
                  <div className="row">
                    {displayedTickets.map((ticket, index) => (
                      <div 
                        className="col-md-6 animate__animated animate__fadeInUp"
                        style={{ animationDelay: `${index * 0.05}s` }}
                        key={ticket.id}
                      >
                        <EnhancedTicketCard
                          ticketid={ticket.id}
                          ticketmaintitle={ticket.title}
                          tickettitle={ticket.title}
                          ticketdescription={ticket.description}
                          employee={ticket.user}
                          ticketstatus={ticket.status}
                          ticketpriority={ticket.status}
                          ticketdate={ticket.createdAt}
                        />
                      </div>
                    ))}
                  </div>

                  {/* Daha fazla yükle butonu */}
                  {visibleTickets < filteredTickets.length && (
                    <div className="col-12 text-center mt-4 mb-5 animate__animated animate__fadeInUp">
                      <button 
                        className="btn btn-lg load-more-btn" 
                        onClick={loadMoreTickets}
                      >
                        <i className="ti ti-loader me-2" />
                        {t('ticketUser.loadMoreTickets')} ({filteredTickets.length - visibleTickets} {t('ticketUser.tickets')})
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}

      {/* Bilet Oluşturma Modal */}
      <Modal
        title={<div className="d-flex align-items-center gap-2"><i className="ti ti-plus-circle text-primary fs-4"></i> Yeni Talep Oluştur</div>}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={700}
        className="ticket-modal"
        maskStyle={{ backdropFilter: 'blur(8px)' }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="pt-3"
        >
          <Form.Item
            name="title"
            label={t('ticketUser.title')}
            rules={[{ required: true, message: t('common.errors.required') }]}
          >
            <Input 
              placeholder={t('ticketUser.titlePlaceholder')} 
              size="large"
              prefix={<i className="ti ti-forms me-2 text-muted"></i>}
            />
          </Form.Item>
          
          <Form.Item
            name="description"
            label={t('ticketUser.description')}
            rules={[{ required: true, message: t('common.errors.required') }]}
          >
            <TextArea 
              rows={5} 
              placeholder={t('ticketUser.descriptionPlaceholder')} 
              size="large"
            />
          </Form.Item>
          
          <div className="modal-actions">
            <button 
              type="button"
              onClick={handleCancel} 
              className="btn modal-btn cancel"
            >
              <i className="ti ti-x me-2"></i>
              {t('common.buttons.cancel')}
            </button>
            <button 
              type="submit"
              disabled={submitting}
              className="btn btn-primary modal-btn"
            >
              {submitting ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  {t('ticketUser.processing')}
                </>
              ) : (
                <>
                  <i className="ti ti-send me-2"></i>
                  {t('ticketUser.sendTicket')}
                </>
              )}
            </button>
          </div>
        </Form>
      </Modal>

      <TicketListModal />
    </>
  );
};

export default TicketsEmployee;

// Geliştirilmiş Ticket Card Komponenti
export const EnhancedTicketCard = ({ ticketid, ticketmaintitle, tickettitle, ticketdescription, employee, ticketstatus, ticketpriority, ticketdate }: {
  ticketid: number, ticketmaintitle: string, tickettitle: string, ticketdescription: string, employee: User, ticketstatus: string, ticketpriority: string, ticketdate: string 
}) => {
  const { t } = useTranslation();

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  const getStatusDetails = (status: string) => {
    if (!status) return { text: 'Belirtilmemiş', icon: 'question-mark', className: '' };
    const statusLower = status.toLowerCase();
    
    switch (statusLower) {
      case 'created':
        return { 
          text: t('ticketUser.new'), 
          icon: 'file-plus',
          className: 'created'
        };
      case 'reviewed':
        return { 
          text: t('ticketUser.reviewed'), 
          icon: 'eye-check',
          className: 'reviewed'
        };
      case 'rejected':
        return { 
          text: t('ticketUser.rejected'), 
          icon: 'x',
          className: 'rejected'
        };
      default:
        return { 
          text: status.charAt(0).toUpperCase() + status.slice(1), 
          icon: 'info-circle',
          className: ''
        };
    }
  };

  const statusDetails = getStatusDetails(ticketstatus);

  return (
    <div className="ticket-card">
      <div className="ticket-card-body">
        <span
          className="ticket-title"
        >
          {ticketmaintitle}
        </span>
        
        <div className="ticket-meta">
          <div className="ticket-meta-item">
            <i className="ti ti-user"></i>
            <span>{employee?.firstName} {employee?.lastName}</span>
          </div>
          
          {employee?.department && (
            <div className="ticket-meta-item">
              <i className="ti ti-building"></i>
              <span>{employee.department.name}</span>
            </div>
          )}
          
          {employee?.branch && (
            <div className="ticket-meta-item">
              <i className="ti ti-map-pin"></i>
              <span>{employee.branch.name}</span>
            </div>
          )}
          
          <div className="ticket-meta-item">
            <i className="ti ti-calendar"></i>
            <span>{formatDate(ticketdate)}</span>
          </div>
        </div>
        
        <div className="ticket-description">
          {ticketdescription}
        </div>
        
        <div className="ticket-footer">
          <div className="ticket-id">
            <i className="ti ti-ticket"></i>
            <span>Talep #{ticketid}</span>
          </div>
          
          <div className={`ticket-status ${statusDetails.className}`}>
            <i className={`ti ti-${statusDetails.icon}`}></i>
            <span>{statusDetails.text}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Eski TicketCard komponenti hala export edilmiş durumda, başka yerlerde kullanılıyorsa
export const TicketCard = ({ ticketid, ticketmaintitle, tickettitle, ticketdescription, employee, ticketstatus, ticketpriority, ticketdate }:
  { ticketid: number, ticketmaintitle: string, tickettitle: string, ticketdescription: string, employee: User, ticketstatus: string, ticketpriority: string, ticketdate: string }) => {

  const getStatusBadgeClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'created':
        return 'badge bg-warning text-white';
      case 'reviewed':
        return 'badge bg-success text-white';
      case 'rejected':
        return 'badge bg-danger text-white';
      default:
        return 'badge bg-info text-white';
    }
  };

  const getPriorityBadgeClass = (priority: string) => {
    switch (priority?.toLowerCase()) {
      case 'high':
        return 'badge bg-danger text-white';
      case 'medium':
        return 'badge bg-warning text-white';
      case 'low':
        return 'badge bg-info text-white';
      default:
        return 'badge bg-primary text-white';
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  const getStatusText = (status: string) => {
    if (!status) return 'Belirtilmemiş';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  return (
    <div className="card mb-3 hover-shadow-sm transition-all">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <div>
            <Link 
              to={`/tickets/ticket-details/${ticketid}`}
              className="h5 text-primary mb-1 d-block text-decoration-none"
            >
              {ticketmaintitle}
            </Link>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <span className="text-muted fs-13">
                <i className="ti ti-user me-1"></i>
                {employee?.firstName} {employee?.lastName}
              </span>
              {employee?.department && (
                <span className="text-muted fs-13">
                  <i className="ti ti-building me-1"></i>
                  {employee.department.name}
                </span>
              )}
              {employee?.branch && (
                <span className="text-muted fs-13">
                  <i className="ti ti-map-pin me-1"></i>
                  {employee.branch.name}
                </span>
              )}
              <span className="text-muted fs-13">
                <i className="ti ti-calendar me-1"></i>
                {formatDate(ticketdate)}
              </span>
            </div>
          </div>
          <div className="d-flex gap-2">
            <span className={getPriorityBadgeClass(ticketpriority)}>
              <i className="ti ti-flag me-1"></i>
              {getStatusText(ticketpriority)}
            </span>
          </div>
        </div>
        <p className="mb-0 text-gray-600 line-clamp-2">
          {ticketdescription}
        </p>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex align-items-center gap-3">
            <span className="text-muted fs-13">
              <i className="ti ti-ticket me-1"></i>
              #{ticketid}
            </span>
          </div>
          
        </div>
      </div>
    </div>
  );
};