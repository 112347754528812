import { useTranslation } from "react-i18next";
import { all_routes } from "../../../router/all_routes";
import { RolesEnum } from "../../../types/route.types";
import { SidebarItem } from "../../../types/sidebar.types";
import { route } from "../../common/selectoption/selectoption";
import { PermissionType } from "../../../types/user.types";
const routes = all_routes;
export const SidebarDataTest:SidebarItem[] = [
  {
    tittle: 'sidebar_menu.main_menu.adminTitle',
    icon: 'airplay',
    showAsTab: true,
    separateRoute: false,
    role:[PermissionType.CONTROL_PANEL_USER],
    submenuItems: [
     
      {
        label: 'sidebar_menu.main_menu.dashboard',
        link: routes.employeeDashboard,
        submenu: false,
        showSubRoute: false,
        icon: 'smart-home',
        base: 'dashboard',
        role:[PermissionType.CONTROL_PANEL_USER],
        materialicons: 'start',
      },


      {
        label: 'sidebar_menu.hrm.tickets',
        link: routes.ticketEmployee,
        base: 'ticket-list',
        role:[PermissionType.VIEW_TICKETS_USER],
        icon: 'ticket',
      },

      {
        label: 'sidebar_menu.hrm.leaves',
        link: routes.leaveemployee,
        submenu: false,
        showSubRoute: false,
        role:[PermissionType.LEAVE_REQUEST],
        icon: 'check',
      },
 


    ],
  },

  {
    tittle: 'sidebar_menu.main_menu.adminTitle',
    icon: 'file',
    showAsTab: false,
    separateRoute: false,
    role:[PermissionType.CONTROL_PANEL_ADMIN],
    submenuItems: [
      {
        label: 'sidebar_menu.main_menu.dashboard',
        link: routes.adminDashboard,
        submenu: false,
        showSubRoute: false,
        icon: 'smart-home',
        base: 'dashboard',
        role:[PermissionType.CONTROL_PANEL_ADMIN],
        materialicons: 'start',
      },
      {
        label: 'sidebar_menu.hrm.employee_list',
        link: routes.employeeList,
        submenu: false,
        showSubRoute: false,
        base: 'dashboard',
        role:[PermissionType.VIEW_USERS],
        icon: 'users',
      },
      {
        label: 'sidebar_menu.hrm.departments',
        link: routes.departments,
        base: 'departments',
        role:[PermissionType.VIEW_DEPARTMENTS],
        icon: 'category',
      },
      {
        link:routes.branches,
        label: 'sidebar_menu.administration.branch',
        base: 'stores',
        submenu: false,
        showSubRoute: false,
        icon: 'building',
        role:[PermissionType.VIEW_BRANCHES],
      },
      {
        link:routes.attendance,
        label: 'sidebar_menu.administration.attendance',
        base: 'attendance-report',
        icon: 'receipt',
        role:[PermissionType.VIEW_REPORTS],
      },
      {
        label: 'sidebar_menu.administration.shift',
        link: routes.shift,
        base: 'shift',
        role:[PermissionType.MANAGE_SHIFTS],
        icon: 'calendar',
      },
      {
        label: 'sidebar_menu.hrm.leaves',
        link: routes.leaveadmin,
        role:[PermissionType.VIEW_LEAVE],
        icon: 'check',
      },
      {
        label: 'sidebar_menu.hrm.tickets',
        link: routes.ticketAdmin,
        base: 'ticket-list',
        role:[PermissionType.VIEW_TICKETS_ADMIN],
        icon: 'ticket',
      }
      ]
    },
 
  {
    tittle: 'sidebar_menu.main_menu.reportsTitle',
    icon: 'file',
    showAsTab: true,
    separateRoute: false,
    role:[PermissionType.VIEW_REPORTS],
    submenuItems: [
      {
        link:routes.employeeReport,
        label: 'sidebar_menu.administration.employee_report',
        base: 'employee-report',
        icon: 'users',
        role:[PermissionType.VIEW_REPORTS],
      },
 

      {
        link:routes.shiftReport,
        label: 'sidebar_menu.administration.shift_report',
        base: 'shift-report',
        icon: 'calendar',
        role:[PermissionType.VIEW_REPORTS],
      },
      {
        link:routes.ticketReport,
        label: 'sidebar_menu.administration.ticket_report',
        base: 'ticket-report',
        icon: 'ticket',
        role:[PermissionType.VIEW_TICKETS_ADMIN],
      },
      
      // {
      //   link:routes.attendanceReport,
      //   label: 'sidebar_menu.administration.attendance_report',
      //   base: 'attendance-report',
      //   icon: 'receipt',
      //   role:RolesEnum.ADMIN,
      // },
   
      {
        link:routes.leaveReport,
        label: 'sidebar_menu.administration.leave_report',
        base: 'leave-report',
        icon: 'check',
        role:[PermissionType.VIEW_LEAVE],
      },
      {
        label: 'sidebar_menu.administration.break_report',
        link: routes.breakReport,
        base: 'employeesReports',
        role:[PermissionType.VIEW_REPORTS],
        icon: 'receipt',
      },
 
      ]
    },
  {
    tittle: 'sidebar_menu.main_menu.settingsTitle',
    icon: 'file',
    showAsTab: false,
    separateRoute: false,
    role:[PermissionType.COMPANY],
    submenuItems: [
      {
        link:routes.companySettings,
        label: 'sidebar_menu.administration.company_settings',
        base: 'company-settings',
        icon: 'settings',
        role:[PermissionType.COMPANY],
      },
      {
        link:routes.notification,
        label: 'sidebar_menu.administration.notification',
        base: 'notification',
        icon: 'bell',
        role:[PermissionType.CREATE_NOTIFICATIONS],
      },
     
      
 
      ]
    },

    {
      tittle: 'sidebar_menu.main_menu.superAdminTitle',
      icon: 'file',
      showAsTab: false,
      separateRoute: false,
      role:[PermissionType.MANAGE_COMPANY],
      submenuItems: [
        {
          link:routes.superAdminDashboard,
          label: 'sidebar_menu.administration.dashboard',
          base: 'dashboard',
          icon: 'smart-home',
          role:[PermissionType.MANAGE_COMPANY],
        },
        {
          link:routes.superAdminCompanies,
          label: 'sidebar_menu.administration.companies',
          base: 'companies',
          icon: 'users',
          role:[PermissionType.MANAGE_COMPANY],
        },
        {
          link:routes.superAdminCompanyCreate,
          label: 'sidebar_menu.administration.company_create',
          base: 'company-create',
          icon: 'plus',
          role:[PermissionType.MANAGE_COMPANY],
        },
      ]
    }




  // {
  //   tittle: 'HRM',
  //   icon: 'file',
  //   showAsTab: false,
  //   separateRoute: false,
  //   submenuItems: [
  //     {
  //       label: 'Employees',
  //       link: routes.employeeList,
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'users',
  //       base: 'employees',
  //       role:RolesEnum.ADMIN,
  //       materialicons: 'people',
  //       submenuItems: [
  //         {
  //           label: 'Employees List',
  //           link: routes.employeeList,
  //           base: 'employees',
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Employees Grid',
  //           link: routes.employeeGrid,
  //           base: 'employees',
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Employees Details',
  //           link: routes.employeedetails,
  //           base: 'employees',
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Departments',
  //           link: routes.departments,
  //           base: 'departments',
  //           role:RolesEnum.ADMIN
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Tickets',
  //       link: 'ticketList',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'ticket',
  //       base: 'tickets',
  //       materialicons: 'leaderboard',
  //       submenuItems: [
  //         {
  //           label: 'Tickets',
  //           link: routes.ticketAdmin,
  //           base: 'ticket-list',
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Tickets',
  //           link: routes.ticketEmployee,
  //           base: 'ticket-list',
  //           role:RolesEnum.USER
  //         },
  //       ],
  //     },

  //     {
  //       label: 'Attendance',
  //       link: 'sales',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'file-time',
  //       base: 'sales',
  //       materialicons: 'track_changes',
  //       submenuItems: [
  //         {
  //           label: 'Leaves',
  //           submenu: true,
  //           showSubRoute: false,
  //           link: routes.leaveadmin,
  //           base: 'leaves',
  //           submenuItems: [
  //             {
  //               label: 'Leaves (Admin)',
  //               link: routes.leaveadmin,
  //               submenu: false,
  //               showSubRoute: false,
  //               role:RolesEnum.ADMIN
  //             },
  //             {
  //               label: 'Leaves (Employee)',
  //               link: routes.leaveemployee,
  //               submenu: false,
  //               showSubRoute: false,
  //               role:RolesEnum.USER
  //             },
  //             {
  //               label: 'Leave Settings',
  //               link: routes.leavesettings,
  //               submenu: false,
  //               showSubRoute: false,
  //               role:RolesEnum.ADMIN
  //             },
  //           ],
  //         },

  //         {
  //           label: 'Attendance (Admin)',
  //           link: routes.attendanceadmin,
  //           base: 'attendance-admin',
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Attendance (Employee)',
  //           link: routes.attendanceemployee,
  //           base: 'attendance-employee',
  //           role:RolesEnum.USER
  //         },
  //       ],
  //     },
  //   ],
  // },


  // {
  //   tittle: 'Administration',
  //   showAsTab: false,
  //   separateRoute: false,
  //   role:RolesEnum.ADMIN,
  //   submenuItems: [
  //     {
  //       link:'reports',
  //       label: 'Reports',
  //       base: 'reports',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'user-star',
  //       submenuItems: [
  //         {
  //           label: 'Attendance Report',
  //           link: routes.attendancereport,
  //           base: 'attendance-report',
  //         },
  //         {
  //           label: 'Leave Report',
  //           link: routes.leavereport,
  //           base: 'leave-report',
  //         },
  //         {
  //           label: 'Daily Report',
  //           link: routes.dailyreport,
  //           base: 'daily-report',
  //         },
  //       ],
  //     },
  //     {
  //       link:routes.store,
  //       label: 'Store',
  //       base: 'stores',
  //       submenu: false,
  //       showSubRoute: false,
  //       icon: 'user-star',
  //     }

  //   ],
  // },

  // {
  //   tittle: 'Pages',
  //   showAsTab: false,
  //   separateRoute: false,
  //   submenuItems: [
  //     {
  //       label: 'Starter',
  //       link: routes.starter,
  //       base: 'starter',
  //       submenu: false,
  //       showSubRoute: false,
  //       icon: 'layout-sidebar',
  //       submenuItems: [],
  //       role:RolesEnum.ADMIN
  //     },
  
  //   ],
  // },



  // {
  //   tittle: 'Authentication',
  //   showAsTab: false,
  //   separateRoute: false,
  //   submenuItems: [
  //     {
  //       link:'login',
  //       label: 'Login',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'login',
  //       submenuItems: [
  //         {
  //           label: 'Cover',
  //           link: routes.login,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Illustration',
  //           link: routes.login2,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Basic',
  //           link: routes.login3,
  //           role:RolesEnum.ADMIN
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Forgot Password',
  //       submenu: true,
  //       link:'forgot-password',
  //       showSubRoute: false,
  //       icon: 'help-triangle',
  //       submenuItems: [
  //         {
  //           label: 'Cover',
  //           link: routes.forgotPassword,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Illustration',
  //           link: routes.forgotPassword2,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Basic',
  //           link: routes.forgotPassword3,
  //           role:RolesEnum.ADMIN
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Reset Password',
  //       link:'reset-password',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'restore',
  //       submenuItems: [
  //         {
  //           label: 'Cover',
  //           link: routes.resetPassword,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Illustration',
  //           link: routes.resetPassword2,
  //           role:RolesEnum.ADMIN
  //         },
  //         {
  //           label: 'Basic',
  //           link: routes.resetPassword3,
  //           role:RolesEnum.ADMIN
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   tittle: 'UI Interface',
  //   icon: 'file',
  //   showAsTab: false,
  //   separateRoute: false,
  //   submenuItems: [
  //     {
  //       label: 'Base UI',
  //       link: 'index',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'hierarchy-2',
  //       base: 'base-ui',
  //       materialicons: 'description',
  //       submenuItems: [
  //         {
  //           label: 'Alerts',
  //           link: routes.alert,
  //           base: 'ui-alerts',
  //         },
  //         {
  //           label: 'Accordions',
  //           link: routes.accordion,
  //           base: 'ui-accordion',
  //         },
  //         { label: 'Avatar', link: routes.avatar, base: 'ui-avatar' },
  //         { label: 'Badges', link: routes.badges, base: 'ui-badges' },
  //         { label: 'Borders', link: routes.border, base: 'ui-borders' },
  //         {
  //           label: 'Buttons',
  //           link: routes.button,
  //           base: 'ui-buttons',
  //         },
  //         {
  //           label: 'Button Group',
  //           link: routes.buttonGroup,
  //           base: 'ui-buttons-group',
  //         },
  //         {
  //           label: 'Breadcrumb',
  //           link: routes.breadcrums,
  //           base: 'ui-breadcrumb',
  //         },
  //         { label: 'Cards', link: routes.cards, base: 'ui-cards' },
  //         {
  //           label: 'Carousel',
  //           link: routes.carousel,
  //           base: 'ui-carousel',
  //         },
  //         {
  //           label: 'Colors',
  //           link: routes.colors,
  //           base: 'ui-colors',
  //         },
  //         {
  //           label: 'Dropdowns',
  //           link: routes.dropdowns,
  //           base: 'ui-dropdowns',
  //         },
  //         { label: 'Grid', link: routes.grid, base: 'ui-grid' },
  //         { label: 'Images', link: routes.images, base: 'ui-images' },
  //         {
  //           label: 'Lightbox',
  //           link: routes.lightbox,
  //           base: 'ui-lightbox',
  //         },
  //         { label: 'Media', link: routes.media, base: 'ui-media' },
  //         { label: 'Modals', link: routes.modals, base: 'ui-modals' },
  //         {
  //           label: 'Offcanvas',
  //           link: routes.offcanvas,
  //           base: 'ui-offcanvas',
  //         },
  //         {
  //           label: 'Pagination',
  //           link: routes.pagination,
  //           base: 'ui-pagination',
  //         },

  //         {
  //           label: 'Progress Bars',
  //           link: routes.progress,
  //           base: 'ui-progress',
  //         },
  //         {
  //           label: 'Placeholders',
  //           link: routes.placeholder,
  //           base: 'ui-placeholders',
  //         },

  //         {
  //           label: 'Spinner',
  //           link: routes.spinner,
  //           base: 'ui-spinner',
  //         },
  //         {
  //           label: 'Range Slider',
  //           link: routes.rangeSlider,
  //           base: 'ui-rangeslider',
  //         },

  //         { label: 'Toasts', link: routes.toasts, base: 'ui-toasts' },
  //         {
  //           label: 'Tooltip',
  //           link: routes.tooltip,
  //           base: 'ui-tooltips',
  //         },
  //         {
  //           label: 'Typography',
  //           link: routes.typography,
  //           base: 'ui-typography',
  //         },
  //         { label: 'Videos', link: routes.video, base: 'ui-video' },
  //         { label: 'Sortable', link: routes.sortable, base: 'ui-sortable' },
  //         { label: 'SwiperJs', link: routes.swiperjs, base: 'ui-swiperjs' },
  //       ],
  //     },
  //     {
  //       label: 'Advanced Ui',
  //       submenu: true,
  //       link:'#',
  //       showSubRoute: false,
  //       base: 'advancedUi',
  //       icon: 'hierarchy-3',
  //       materialicons: 'sync_alt',
  //       submenuItems: [
  //         { label: 'Ribbon', link: routes.ribbon, base: 'ui-ribbon' },
  //         {
  //           label: 'Clipboard',
  //           link: routes.clipboard,
  //           base: 'ui-clipboard',
  //         },
  //         {
  //           label: 'Drag & Drop',
  //           link: routes.dragandDrop,
  //           base: 'ui-drag-drop',
  //         },
  //         {
  //           label: 'Rating',
  //           link: routes.rating,
  //           base: 'ui-rating',
  //         },
  //         {
  //           label: 'Text Editor',
  //           link: routes.textEditor,
  //           base: 'ui-text-editor',
  //         },
  //         {
  //           label: 'Counter',
  //           link: routes.counter,
  //           base: 'ui-counter',
  //         },
  //         {
  //           label: 'Scrollbar',
  //           link: routes.scrollBar,
  //           base: 'ui-scrollbar',
  //         },
  //         {
  //           label: 'Timeline',
  //           link: routes.timeLine,
  //           base: 'ui-timeline',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Charts',
  //       submenu: true,
  //       showSubRoute: false,
  //       link:'#',

  //       base: 'charts',
  //       icon: 'chart-line',
  //       materialicons: 'library_add_check',
  //       submenuItems: [
  //         {
  //           label: 'Apex Charts',
  //           link: routes.apexChart,
  //           base: 'apex-charts',
  //         },
  //         {
  //           label: 'Prime Charts',
  //           link: routes.primeChart,
  //           base: 'prime-charts',
  //         },
  //         {
  //           label: 'Chart JS',
  //           link: routes.chartJs,
  //           base: 'chart-js',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Icons',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'icons',
  //       link:'#',

  //       base: 'icon',
  //       materialicons: 'people',
  //       submenuItems: [
  //         {
  //           label: 'Fontawesome Icons',
  //           link: routes.fontawesome,
  //           base: 'icon-fontawesome',
  //         },
  //         {
  //           label: 'Bootstrap Icons',
  //           link: routes.bootstrapIcons,
  //           base: 'icon-bootstrap',
  //         },
  //         {
  //           label: 'Remix Icons',
  //           link: routes.RemixIcons,
  //           base: 'icon-remix',
  //         },
  //         {
  //           label: 'Feather Icons',
  //           link: routes.featherIcons,
  //           base: 'icon-feather',
  //         },
  //         {
  //           label: 'Ionic Icons',
  //           link: routes.iconicIcon,
  //           base: 'icon-ionic',
  //         },
  //         {
  //           label: 'Material Icons',
  //           link: routes.materialIcon,
  //           base: 'icon-material',
  //         },
  //         { label: 'pe7 Icons', link: routes.pe7icon, base: 'icon-pe7' },
  //         {
  //           label: 'Simpleline Icons',
  //           link: routes.simpleLineIcon,
  //           base: 'icon-simple-line',
  //         },
  //         {
  //           label: 'Themify Icons',
  //           link: routes.themifyIcon,
  //           base: 'icon-themify',
  //         },
  //         {
  //           label: 'Weather Icons',
  //           link: routes.weatherIcon,
  //           base: 'icon-weather',
  //         },
  //         {
  //           label: 'Typicon Icons',
  //           link: routes.typicon,
  //           base: 'icon-typicon',
  //         },
  //         { label: 'Flag Icons', link: routes.FlagIcons, base: 'icon-flag' },
  //       ],
  //     },
  //     {
  //       label: 'Forms',
  //       icon: 'input-search',
  //       base: 'forms',
  //       link:'#',
  //       submenu: true,
  //       showSubRoute: false,
  //       materialicons: 'view_day',
  //       submenuItems: [
  //         {
  //           label: 'Form Elements',
  //           base: 'form-elements',
  //           link:'#',
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             {
  //               label: 'Basic Inputs',
  //               link: routes.basicInput,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-basic-inputs',
  //             },
  //             {
  //               label: 'Checkbox & Radios',
  //               link: routes.checkboxandRadion,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-checkbox-radios',

  //             },
  //             {
  //               label: 'Input Groups',
  //               link: routes.inputGroup,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-input-groups',

  //             },
  //             {
  //               label: 'Grid & Gutters',
  //               link: routes.gridandGutters,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-grid-gutters',
  //             },
  //             {
  //               label: 'Form Select',
  //               link: routes.formSelect,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-select',

  //             },
  //             {
  //               label: 'Input Masks',
  //               link: routes.formMask,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-mask',
  //             },
  //             {
  //               label: 'File Uploads',
  //               link: routes.fileUpload,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-fileupload',
  //             },
  //           ],
  //         },
  //         {
  //           label: 'Layouts',
  //           link:'#',
  //           base: 'layouts',
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             {
  //               label: 'Horizontal Form',
  //               link: routes.horizontalForm,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-horizontal'
  //             },
  //             {
  //               label: 'Vertical Form',
  //               link: routes.verticalForm,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-vertical',
  //             },
  //             {
  //               label: 'Floating Labels',
  //               link: routes.floatingLable,
  //               submenu: false,
  //               showSubRoute: false,
  //               base: 'form-floating-labels',

  //             },
  //           ],
  //         },
  //         {
  //           label: 'Form Validation',
  //           link: routes.formValidation,
  //           submenu: false,
  //           showSubRoute: false,
  //           base: 'form-validation',
  //         },
  //         {
  //           label: 'Form Wizard',
  //           link: routes.formWizard,
  //           submenu: false,
  //           showSubRoute: false,
  //           base: 'form-wizard',
  //         },
  //         {
  //           label: 'Form Picker',
  //           link: routes.formPicker,
  //           submenu: false,
  //           showSubRoute: false,
            
  //           base: 'form-picker',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Tables',
  //       link: 'tables',
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: 'table',
  //       base: 'tables',
  //       materialicons: 'table_rows',
  //       submenuItems: [
  //         {
  //           label: 'Basic Tables',
  //           link: routes.tablesBasic,
  //           base: 'tables-basic',
  //         },
  //         {
  //           label: 'Data Tables',
  //           link: routes.dataTables,
  //           base: 'data-basic',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Maps',
  //       submenu: true,
  //       link:'#',
  //       showSubRoute: false,
  //       icon: 'table-plus',
  //       base: 'maps',
  //       materialicons: 'people',
  //       submenuItems: [
  //         {
  //           label: 'Leaflets',
  //           link: routes.mapLeaflet,
  //           base: 'leaflets',
  //         },

  //       ],
  //     },
  //   ],
  // },

];