import React from 'react';
import { Dropdown as AntDropdown, Popconfirm } from 'antd';
import { DropdownProps, DropdownItem } from './types';
import { useTranslation } from 'react-i18next';
const Dropdown: React.FC<DropdownProps> = ({
  label,
  items,
  icon,
  buttonType = 'primary',
  buttonVariant = 'default',
  buttonSize = 'md',
  className = '',
  disabled = false,
  menuClassName = '',
  onSelect,
  isDropdownEnabled = true,
}) => {
  const { t } = useTranslation();
  const getButtonClasses = () => {
    const baseClass = 'btn';
    const typeClass = buttonVariant === 'outline' ? `btn-outline-${buttonType}` : `btn-${buttonType}`;
    const sizeClass = buttonSize === 'md' ? '' : `btn-${buttonSize}`;
    const dropdownClass = 'dropdown-toggle';
    
    return `${baseClass} ${typeClass} ${sizeClass} ${dropdownClass}`.trim();
  };

  const menu = (
    <ul className={`dropdown-menu show ${menuClassName}`}>
      {items.map((item, index) => (
        
        <React.Fragment key={index}>
          {item.divider ? (
            <li><hr className="dropdown-divider" /></li>
          ) : (
            <li>
              {item.value==='approved'&&(
                <Popconfirm
                  title={t('employees.filters.popconfirmApprove')}
                  onConfirm={() => onSelect?.(item.value)}
                >
                  <button
                    className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
                  >
                    {item.icon && <i className={`${item.icon} me-2`} />}
                    {item.label}
                  </button>
                </Popconfirm>
              )}
              {item.value==='rejected'&&(
                <Popconfirm
                  title={t('employees.filters.popconfirmReject')}
                  onConfirm={() => onSelect?.(item.value)}
                >
                  <button
                    className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
                  >
                    {item.icon && <i className={`${item.icon} me-2`} />}
                    {item.label}
                  </button>
                </Popconfirm>
              )}
              {!(item.value==='rejected'||item.value==='approved')&&(
                <button
                  className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
                  onClick={() => !item.disabled && onSelect?.(item.value)}
                  disabled={item.disabled}
              >

                {item.icon && <i className={`${item.icon} me-2`} />}
                {item.label}
              </button>
              )}
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );

  return (
    <AntDropdown 
      overlay={menu}
      trigger={['click']}
      disabled={disabled || !isDropdownEnabled}
      placement="bottomLeft"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      overlayClassName={`custom-dropdown ${className}`}
      overlayStyle={{
        minWidth: '300px',
        padding: '0',
        border: '1px solid #e9ecef',
        borderRadius: '0.375rem',
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        backgroundColor: '#fff',
      }}
    >
      <button
        type="button"
        className={getButtonClasses()}
        disabled={disabled}
      >
        {icon && <i className={`${icon} me-2`} />}
        {label}
      </button>
    </AntDropdown>
  );
};

export default Dropdown; 