import React, { useState } from 'react';
import { LeaveStatus, LeaveAdminUpdateDTO } from '../../../../types/leaves.types';
import { LEAVE_STATUSES } from '../../../../constants/leaveConstants';
import { Select, Popconfirm } from 'antd';
import { SelectProps } from 'antd/lib/select';
import './LeaveStatusDropdown.css'; // Eğer gerekirse bir CSS dosyası ekleyebiliriz
import { useTranslation } from 'react-i18next';
interface LeaveStatusDropdownProps {
  status: LeaveStatus;
  onStatusChange: (dto: LeaveAdminUpdateDTO) => void;
  disabled?: boolean;
  text?: boolean;
}

const LeaveStatusDropdown: React.FC<LeaveStatusDropdownProps> = ({
  status,
  onStatusChange,
  disabled = false,
  text = true
}) => {
  const { t } = useTranslation();
  // Seçilen yeni durumu saklayacak state
  const [selectedValue, setSelectedValue] = useState<LeaveStatus | null>(null);
  // Popconfirm'in görünürlüğünü yönetecek state
  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);

  const getStatusColor = (status: LeaveStatus) => {
    const colors: Record<LeaveStatus, string> = {
      pending: '#f0ad4e', // warning rengi
      approved: '#5cb85c', // success rengi
      rejected: '#d9534f', // danger rengi
    };
    return colors[status];
  };

  const getStatusClass = (status: LeaveStatus) => {
    const classes: Record<LeaveStatus, string> = {
      pending: 'status-pending',
      approved: 'status-approved',
      rejected: 'status-rejected',
    };
    return classes[status];
  };

  // i18n ile durum metinlerini getir
  const getStatusText = (status: LeaveStatus): string => {
    return t(`leaves.statusOptions.${status}`);
  };

  const items = Object.keys(LEAVE_STATUSES).map((value) => ({
    label: getStatusText(value as LeaveStatus),
    value,
    className: `status-option status-${value.toLowerCase()}`,
    disabled: value === status
  }));

  // Select bileşeni için özel stiller
  const selectStyles: React.CSSProperties = {
    width: '100%',
    backgroundColor: getStatusColor(status),
    color: '#fff',
    borderColor: getStatusColor(status),
    borderRadius: '4px',
    fontWeight: 'bold',
  };

  // Select dropdown için özel olarak CSS sınıfı kullanacağız
  const selectClassName = `leave-status-select ${getStatusClass(status)}`;

  // Status pending değilse veya disabled prop'u true ise, select bileşeni disabled olacak
  const isDisabled = disabled || status !== 'pending';

  // Durum değişikliği işleyicisi
  const handleStatusChange = (value: LeaveStatus) => {
    // Seçilen değeri state'e kaydet
    setSelectedValue(value);
    // Popconfirm'i göster
    setIsPopconfirmVisible(true);
  };

  // Popconfirm onay işleyicisi
  const handleConfirm = () => {
    // Seçilen değer varsa durum değişikliğini yap
    if (selectedValue) {
      onStatusChange({ status: selectedValue });
    }
    // Popconfirm'i kapat
    setIsPopconfirmVisible(false);
  };

  // Popconfirm iptal işleyicisi
  const handleCancel = () => {
    // State'i temizle
    setSelectedValue(null);
    // Popconfirm'i kapat
    setIsPopconfirmVisible(false);
  };

  // Onay mesajını duruma göre belirleme
  const getConfirmMessage = (value: LeaveStatus | null) => {
    if (!value) return '';
    
    switch (value) {
      case 'approved':
        return t('leaves.statusOptions.approved');
      case 'rejected':
        return t('leaves.statusOptions.rejected');
      default:
        return t('leaves.statusOptions.pending');
    }
  };

  // Onay başlığını duruma göre belirleme
  const getConfirmTitle = (value: LeaveStatus | null) => {
    if (!value) return '';
    
    switch (value) {
      case 'approved':
        return t('leaves.statusOptions.approved');
      case 'rejected':
        return t('leaves.statusOptions.rejected');
      default:
        return t('leaves.statusOptions.pending');
    }
  };

  // Onay butonunu duruma göre belirleme
  const getConfirmButtonText = (value: LeaveStatus | null) => {
    if (!value) return t('common.buttons.ok');
    
    switch (value) {
      case 'approved':
        return text ? t('leaves.statusOptions.approved') : <i className="ti ti-check"></i>;
      case 'rejected':
        return text ? t('leaves.statusOptions.rejected') : <i className="ti ti-x"></i>;
      default:
        return text ? t('leaves.statusOptions.pending') : <i className="ti ti-edit"></i>;
    }
  };

  // Select props
  const selectProps: SelectProps = {
    options: items,
    value: status,
    onChange: (value) => handleStatusChange(value as LeaveStatus),
    disabled: isDisabled,
    className: selectClassName,
    style: selectStyles,
    dropdownStyle: { minWidth: '150px' },
    popupClassName: 'leave-status-dropdown',
    bordered: true,
    showArrow: status === 'pending',
  };

  return (
    <div className="leave-status-dropdown-container">
      <Popconfirm
        title={getConfirmTitle(selectedValue)}
        description={getConfirmMessage(selectedValue)}
        open={isPopconfirmVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText={getConfirmButtonText(selectedValue)}
        cancelText={t('common.buttons.cancel')}
        okButtonProps={{ 
          style: { 
            backgroundColor: selectedValue ? getStatusColor(selectedValue) : undefined 
          } 
        }}
      >
        <Select {...selectProps} />
      </Popconfirm>
    </div>
  );
};

export default LeaveStatusDropdown;