import { useState,useCallback } from 'react';

interface AsyncState<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
}

export function useAsync<T>(){
    const [state, setState] = useState<AsyncState<T>>({
        data: null,
        loading: false,
        error: null,
    });

    const execute = useCallback(async (asyncFunction: () => Promise<T>) => {
        setState({ data: null, loading: true, error: null });
        try {
          setTimeout(async () => {
            const result = await asyncFunction();
            setState({ data: result, loading: false, error: null });
            return result;
          }, 1500);
          
        } catch (error) {
          setState({ data: null, loading: false, error: error as Error });
          throw error;
        }
      }, []);
    

    return {
        ...state,
        execute,
    };
}
