import dayjs from 'dayjs';
import 'dayjs/locale/tr'; // Türkçe dil desteği
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('tr');

export const formatDate = (date: string | Date, format: string = 'DD MMM YYYY'): string => {
  if (!date) return '-';
  return dayjs(date).format(format);
};

export const formatDateTime = (date: string | Date): string => {
  if (!date) return '-';
  return dayjs(date).format('DD MMM YYYY HH:mm');
};

export const formatTime = (time: string): string => {
  if (!time) return '-';
  return dayjs(time, 'HH:mm').format('HH:mm');
};

export const isDateValid = (date: string | Date): boolean => {
  return dayjs(date).isValid();
};

export const getDateDifference = (startDate: string | Date, endDate: string | Date): number => {
  return dayjs(endDate).diff(dayjs(startDate), 'day');
};

export const addDays = (date: string | Date, days: number): Date => {
  return dayjs(date).add(days, 'day').toDate();
};

export const subtractDays = (date: string | Date, days: number): Date => {
  return dayjs(date).subtract(days, 'day').toDate();
};

// İzin süresi hesaplama (iş günü bazında)
export const calculateWorkingDays = (startDate: string | Date, endDate: string | Date): number => {
  let current = dayjs(startDate);
  const end = dayjs(endDate);
  let workingDays = 0;

  while (current.isBefore(end) || current.isSame(end, 'day')) {
    // 0 = Pazar, 6 = Cumartesi
    if (current.day() !== 0 && current.day() !== 6) {
      workingDays++;
    }
    current = current.add(1, 'day');
  }

  return workingDays;
};

// Tarih aralığı kontrolü
export const isDateInRange = (date: string | Date, startDate: string | Date, endDate: string | Date): boolean => {
  const checkDate = dayjs(date);
  return checkDate.isAfter(startDate) && checkDate.isBefore(endDate);
};

// Ay başı ve sonu tarihleri
export const getMonthStartEnd = (date: string | Date = new Date()) => {
  const start = dayjs(date).startOf('month');
  const end = dayjs(date).endOf('month');
  
  return {
    startDate: start.format('YYYY-MM-DD'),
    endDate: end.format('YYYY-MM-DD')
  };
};

// Yaş hesaplama
export const calculateAge = (birthDate: string | Date): number => {
  return dayjs().diff(birthDate, 'year');
};