import React, { useEffect, useState } from 'react';
import { Form, Select, DatePicker, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { EmploymentType, UserRole } from '../../../../../types/user.types';
import dayjs from 'dayjs';
import { DepartmentDto } from '../../../../../types/department.types';
import { Store } from '../../../../../types/shop.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { useTranslation } from 'react-i18next';
interface WorkInfoStepProps {
  form: FormInstance;
  departments: DepartmentDto[];
  shops: Store[];
  annualLimit: number;
}

const WorkInfoStep: React.FC<WorkInfoStepProps> = ({ form, departments, shops, annualLimit }) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.department')}</label>
          <Form.Item
            name="departmentId"
            rules={[{ required: true, message: t('employees.validationErrors.department') }]}
          >
            <Select
              placeholder={t('employees.modals.department')}
              options={departments.map(dept => ({
                value: dept.id,
                label: dept.name
              }))}
            />
          </Form.Item>
        </div>
      </div>

      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.branch')}</label>
          <Form.Item
            name="branchId"
            rules={[{ required: true, message: t('employees.validationErrors.branch') }]}
          >
            <Select
              placeholder={t('employees.modals.branch')}
              options={shops.map(shop => ({
                value: shop.id,
                label: shop.name
              }))}
            />
          </Form.Item>
        </div>
      </div>

      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.annualLeave', { default: annualLimit })}</label>
          <Form.Item
              name="annualLeaveLimit"
            >
              <Input />
            </Form.Item>
        </div>
      </div>

      <div className="col-md-6">  
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.workType')}</label>
          <Form.Item
            name="isFixedShift"
          >
            <Select
              options={[
                { value: true, label: t('employees.modals.fixedShift') },
                { value: false, label: t('employees.modals.shiftBased') },
              ]}
            />
          </Form.Item>
        </div>
      </div>
      <div className="col-md-6">
        <div className="mb-3">
          <label className="form-label">{t('employees.modals.hireDate')}</label>
          <Form.Item
            name="hireDate"
            rules={[{ required: true, message: t('employees.validationErrors.hireDate') }]}
            getValueProps={(i) => ({
              value: i ? dayjs(i) : null
            })}
            getValueFromEvent={(date) => date ? date.format('YYYY-MM-DD') : null}
          >
            <DatePicker
              className="form-control"
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default WorkInfoStep; 