import React from "react";
import Header from "../../components/shared/header/index";
import Sidebar from "../../components/shared/sidebar/index";
import { Outlet, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import HorizontalSidebar from "../../components/shared/horizontal-sidebar/index";
// import TwoColumnSidebar from "../../components/shared/two-column/index";
// import StackedSidebar from "../../components/shared/stacked-sidebar/index";
import DeleteModal from "../../components/shared/modals/deleteModal";
import { RootState } from "../../store/store";

const Feature = () => {
  const [showLoader, setShowLoader] = useState(true);
  const theme = useSelector((state: RootState) => state.theme);
  const headerCollapse = useSelector(
    (state: RootState) => state.theme.headerCollapse
  );
  const mobileSidebar = useSelector(
    (state: RootState) => state.sidebar.mobileSidebar
  );
  const miniSidebar = useSelector(
    (state: RootState) => state.sidebar.miniSidebar
  );
  const sidebar = useSelector((state: RootState) => state.sidebar);
  const expandMenu = useSelector(
    (state: RootState) => state.sidebar.expandMenu
  );
  const dataWidth = useSelector((state: RootState) => state.theme.dataWidth);
  const dataLayout = useSelector((state: RootState) => state.theme.dataLayout);
  const dataLoader = useSelector((state: RootState) => state.theme.dataLoader);

  const dataTheme = useSelector((state: RootState) => state.theme.dataTheme);
  const dataSidebarAll = useSelector(
    (state: RootState) => state.theme.dataSidebarAll
  );
  const dataColorAll = useSelector(
    (state: RootState) => state.theme.dataColorAll
  );

  const dataTopBarColorAll = useSelector(
    (state: RootState) => state.theme.dataTopBarColorAll
  );
  const dataTopbarAll = useSelector(
    (state: RootState) => state.theme.dataTopbarAll
  );
  const location = useLocation();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', dataTheme);
  }, [dataTheme]);

  useEffect(() => {
    if (dataLoader === "enable") {
      // Show the loader when navigating to a new route
      setShowLoader(true);

      // Hide the loader after 2 seconds
      const timeoutId = setTimeout(() => {
        setShowLoader(false);
      }, 0);

      return () => {
        clearTimeout(timeoutId); // Clear the timeout when component unmounts
      };
    } else {
      setShowLoader(false);
    }
    window.scrollTo(0, 0);
  }, [location.pathname, dataLoader]);

  const Preloader = () => {
    return (
      <div id="global-loader">
       <div className="page-loader">
        <img src="/assets/img/normal.png" alt="Loading"/>
      </div>
      </div>
    );
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    const loader = () => {
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    };
    loader();
  }, []);

  return (
    <>
      <style>
        {`
      :root {
        --sidebar--rgb-picr: ${dataSidebarAll};
        --topbar--rgb-picr:${dataTopbarAll};
        --topbarcolor--rgb-picr:${dataTopBarColorAll};
        --primary-rgb-picr:${dataColorAll};
      }
    `}
      </style>
      <div
        className={`
        ${dataLayout === "mini" || dataWidth === "box" ? "mini-sidebar" : ""}
        ${
          dataLayout === "horizontal" ||
          dataLayout === "horizontal-single" ||
          dataLayout === "horizontal-overlay" ||
          dataLayout === "horizontal-box"
            ? "menu-horizontal"
            : ""
        }
       ${miniSidebar && dataLayout !== "mini" ? "mini-sidebar" : ""}
       ${dataWidth === "box" ? "layout-box-mode" : ""} ${
          headerCollapse ? "header-collapse" : ""
        }
      ${
        (expandMenu && miniSidebar) || (expandMenu && dataLayout === "mini")
          ? "expand-menu"
          : ""
      }
       `}
      >
        <>
          {showLoader ? (
            <>
              <Preloader />
              <div
                className={`main-wrapper ${mobileSidebar ? "slide-nav" : ""}`}
              >
                <Header />
                <Sidebar />
                {/* <HorizontalSidebar />
                <TwoColumnSidebar />
                <StackedSidebar /> */}
                <Outlet />
                <DeleteModal />
              </div>
            </>
          ) : (
            <>
              <div
                className={`main-wrapper ${mobileSidebar ? "slide-nav" : ""}`}
              >
                <Header />
                {/* {theme.dataLayout === "horizontal" ? (
                  <HorizontalSidebar />
                ) : theme.dataLayout === "two-column" ? (
                  <TwoColumnSidebar />
                ) : theme.dataLayout === "stacked" ? (
                  <StackedSidebar />
                ) : ( */}
                  <Sidebar />
                {/* // )} */}
                <Outlet />
              </div>
            </>
          )}
        </>

        <div className="sidebar-overlay"></div>
      </div>
    </>
  );
};

export default Feature;
