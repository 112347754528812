import React from 'react';
import { useTranslation } from 'react-i18next';
interface LoadingSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
  centered?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'md',
  color = 'primary',
  centered = false,
}) => {
  const { t } = useTranslation();
  const spinnerSizeClass = {
    sm: 'spinner-border-sm',
    md: '',
    lg: 'spinner-border-lg',
  }[size];

  const spinnerColorClass = `text-${color}`;
  
  const spinner = (
    <div >
        <div className="page-loader">
        <img src="/assets/img/normal.png" alt="Loading"/>
      </div>
          </div>
  );

  if (centered) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        {spinner}
      </div>
    );
  }

  return spinner;
};

export default LoadingSpinner; 