import React, { useState, useEffect } from 'react';
import { Form, DatePicker, Select, Input, Modal, message, TimePicker } from 'antd';
import { LeaveUserCreateDTO, LeaveUserUpdateDTO, LeaveType } from '../../../types/leaves.types';
import { LEAVE_TYPES } from '../../../constants/leaveConstants';
import leaveService from '../../../services/leave/leave.service';
import dayjs from 'dayjs';
import FileUpload from '../components/upload/FileUpload';
import { FileType } from '../../../types/file.types';
import FileService from '../../../services/file/file.service';
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface EmployeeLeaveModalProps {
  leaveDataForEdit: LeaveUserUpdateDTO | null;
  onSuccess: () => void;
  open: boolean;
  onCancel: () => void;
}

const EmployeeLeaveModal: React.FC<EmployeeLeaveModalProps> = ({
  leaveDataForEdit,
  onSuccess,
  open,
  onCancel
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (open) {
      if (leaveDataForEdit) {
        form.setFieldsValue({
          ...leaveDataForEdit,
          startDate: leaveDataForEdit.startDate ? dayjs(leaveDataForEdit.startDate) : null,
          endDate: leaveDataForEdit.endDate ? dayjs(leaveDataForEdit.endDate) : null,
          startTime: leaveDataForEdit.startTime ? dayjs(leaveDataForEdit.startTime, 'HH:mm') : null,
          endTime: leaveDataForEdit.endTime ? dayjs(leaveDataForEdit.endTime, 'HH:mm') : null,
        });
        setSelectedLeaveType(leaveDataForEdit.type);
      } else {
        form.resetFields();
        setSelectedLeaveType(LeaveType.ANNUAL);
      }
    }
  }, [open, leaveDataForEdit, form]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Önce dosyayı yükleyelim (eğer varsa)
      let documentUrl = undefined;
      if (selectedFile) {
        try {
          const fileType = FileType.LEAVE;
          const uploadResponse = await FileService.getInstance().uploadFile(selectedFile, fileType);
          if (uploadResponse.filePath.path && uploadResponse.filePath) {
            documentUrl = uploadResponse.filePath.path;
            console.log('Dosya yükleme başarılı:', documentUrl);
          } else {
            console.error('Dosya yükleme yanıtı geçersiz');
            message.error('Dosya yüklenirken bir hata oluştu');
            setLoading(false);
            return;
          }
        } catch (error: any) {
          handleApiError(error,t);
          setLoading(false);
          return;
        }
      }

      let startDate, endDate;
      if (values.type === 'hourly') {
        const date = values.date;
        const [startTime, endTime] = values.timeRange;
        
        startDate = date.clone()
          .hour(startTime.hour())
          .minute(startTime.minute())
          .toISOString();
        
        endDate = date.clone()
          .hour(endTime.hour())
          .minute(endTime.minute())
          .toISOString();
      } else {
        [startDate, endDate] = values.dateRange;
        startDate = startDate.toISOString();
        endDate = endDate.toISOString();
      }

      const leaveData: LeaveUserCreateDTO = {
        type: values.type,
        startDate,
        endDate,
        reason: values.reason,
        documentUrl: documentUrl
      };

      console.log('Gönderilecek izin verisi:', leaveData);

      if (leaveDataForEdit?.id) {
        await leaveService.updateLeaveByUser(leaveDataForEdit.id, leaveData as LeaveUserUpdateDTO);
        message.success('İzin talebi başarıyla güncellendi');
      } else {
        await leaveService.createLeave(leaveData);
        message.success('İzin talebi başarıyla oluşturuldu');
      }

      onSuccess();
      form.resetFields();
      setSelectedFile(null);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleLeaveTypeChange = (value: LeaveType) => {
    setSelectedLeaveType(value);
    form.setFieldsValue({
      dateRange: undefined,
      date: undefined,
      timeRange: undefined
    });
  };

  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
  };

  return (
    <Modal
      title={leaveDataForEdit ? 'İzin Talebini Düzenle' : 'Yeni İzin Talebi'}
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={[
        <button
          key="cancel"
          type="button"
          className="btn btn-light"
          onClick={handleCancel}
          disabled={loading}
        >
          İptal
        </button>,
        <button
          key="submit"
          type="button"
          className="btn btn-primary ms-2"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm me-1" />
              Kaydediliyor...
            </>
          ) : 'Kaydet'}
        </button>
      ]}
      width={600}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={leaveDataForEdit ? {
          type: leaveDataForEdit.type,
          dateRange: leaveDataForEdit.type === 'hourly' 
            ? undefined 
            : [dayjs(leaveDataForEdit.startDate), dayjs(leaveDataForEdit.endDate)],
          date: leaveDataForEdit.type === 'hourly' ? dayjs(leaveDataForEdit.startDate) : undefined,
          timeRange: leaveDataForEdit.type === 'hourly' 
            ? [dayjs(leaveDataForEdit.startTime, 'HH:mm'), dayjs(leaveDataForEdit.endTime, 'HH:mm')] 
            : undefined,
          reason: leaveDataForEdit.reason
        } : undefined}
      >
        <Form.Item
          name="type"
          label="İzin Türü"
          rules={[{ required: true, message: 'İzin türü seçiniz' }]}
        >
          <Select 
            placeholder="İzin türü seçiniz"
            onChange={handleLeaveTypeChange}
          >
            {Object.entries(LEAVE_TYPES).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedLeaveType === 'hourly' ? (
          <>
            <Form.Item
              name="date"
              label="Tarih"
              rules={[{ required: true, message: 'Tarih seçiniz' }]}
            >
              <DatePicker 
                className="w-100"
                format="DD.MM.YYYY"
                placeholder="Tarih seçiniz"
                disabledDate={(current) => current && current < dayjs().startOf('day')}
              />
            </Form.Item>

            <Form.Item
              name="timeRange"
              label="Saat Aralığı"
              rules={[{ required: true, message: 'Saat aralığı seçiniz' }]}
            >
              <TimePicker.RangePicker 
                className="w-100"
                format="HH:mm"
                minuteStep={30}
              />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            name="dateRange"
            label="Tarih Aralığı"
            rules={[{ required: true, message: 'Tarih aralığı seçiniz' }]}
          >
            <RangePicker 
              className="w-100"
              format="DD.MM.YYYY"
              placeholder={['Başlangıç', 'Bitiş']}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
            />
          </Form.Item>
        )}

        <Form.Item
          name="reason"
          label="Açıklama"
          rules={[
            { required: true, message: 'Açıklama giriniz' },
            { min: 10, message: 'Açıklama en az 10 karakter olmalıdır' }
          ]}
        >
          <TextArea 
            rows={4} 
            placeholder="İzin açıklaması giriniz"
            showCount
            maxLength={500}
          />
        </Form.Item>

        <Form.Item
          name="document"
          label="Destekleyici Belge"
          extra="İzin talebinizi destekleyecek belge yükleyebilirsiniz (PDF, JPG, PNG, max 5MB)"
        >
          <FileUpload
            fileType={FileType.LEAVE}
            // fileType={FileType.OTHER}
            onChange={handleFileChange}
            maxCount={1}
            buttonText="Belge Yükle"
            accept=".pdf,.jpg,.jpeg,.png"
            disabled={loading}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeLeaveModal;