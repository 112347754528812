import { User, UserCreateDTO, UserStatus, UserUpdateDTO, WorkScheduleResponseDto, YearlyDetailsForEmployeeResponseDto, YearlyWorkStatsResponseDto } from "../../types/user.types";
import { Salary, SalaryUpdateDTO } from "../../types/salary.types";
import apiService from "../api/api.service";
import { UserBirthdaysResponseDto } from "../../types/user.types";

class UserService {
  private static instance: UserService;

  private constructor() {}

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  /**
   * Get all users
   */
  public async getUsers(): Promise<User[]> {
    const response = await apiService.get<User[]>(`/users`);
    return response;
  }

  public async getUsersByBranchId(companyId:number,branchId:number): Promise<User[]> {
    const response = await apiService.get<User[]>(`/users/branchId=${branchId}`);
    return response;
  }

  /**
   * Get a user by ID
   * @param id User ID
   */
  public async getUserById(id: number): Promise<User> {
    const response = await apiService.get<User>(`/users/${id}`);
    return response;
  }

  /**
   * Create a new user
   * @param data User data
   */
  public async createUser(data: UserCreateDTO): Promise<User> {
    const response = await apiService.post<User>("/users", data);
    console.log(response);
    
    return response;
  }

  /**
   * Update an existing user
   * @param id User ID
   * @param data Updated user data
   */
  public async updateUser(id: number, data: UserUpdateDTO): Promise<User> {
    const response = await apiService.put<User>(`/users/${id}`, data);
    return response;
  }
  public async updateUserStatus(id: number, status: UserStatus): Promise<User> {
    const response = await apiService.patch<User>(`/users/status/${id}`, { status });
    return response;
  }
  /**
   * Delete a user
   * @param id User ID
   */
  public async deleteUser(id: number): Promise<void> {
    await apiService.delete(`/users/${id}`);
  }

  public async getSalaryByUserId(userId: number): Promise<Salary[]> {
    const response = await apiService.get<Salary[]>(`/salary/${userId}`);
    return response;
  }

  public async getUsersBirthdays(companyId:number): Promise<UserBirthdaysResponseDto> {
    const response = await apiService.get<UserBirthdaysResponseDto>(`/users/birthdays/${companyId}`);
    return response;
  }

  public async getYearlyDetails(userId:number):Promise<YearlyDetailsForEmployeeResponseDto>{
    const response = await apiService.get<YearlyDetailsForEmployeeResponseDto>(`/users/annual-report/${userId}`);
    return response;
  }

  public async getYearlyWorkStats(userId:number):Promise<YearlyWorkStatsResponseDto>{
    const response = await apiService.get<YearlyWorkStatsResponseDto>(`/users/work-stats/${userId}`);
    return response;
  }

  public async getWorkSchedule(userId:number):Promise<WorkScheduleResponseDto>{
    const response = await apiService.get<WorkScheduleResponseDto>(`/users/work-schedule/${userId}`);
    return response;
  }

  public async getUserByEmail(email:string):Promise<User>{
    const response = await apiService.get<User>(`/users/email/${email}`);
    return response;
  }

  public async updateSalary(userId:number,newAmount:number):Promise<SalaryUpdateDTO>{
    const response = await apiService.post<SalaryUpdateDTO>(`/salary/update-salary`,{amount:newAmount,userId});
    return response;
  }
}

export default UserService.getInstance();
