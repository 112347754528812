import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import reportService from '../../../services/reports/report.service';
import { AttendanceReportDto } from '../../../types/report.types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table, Select, Button, Progress, Tag } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../utils/errorHandler';
import FooterSection from '../../shared/components/footerSection';
const { Option } = Select;

const AttendanceReport = () => {
    const [reportData, setReportData] = useState<AttendanceReportDto[]>([]);
    const [filteredData, setFilteredData] = useState<AttendanceReportDto[]>([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [departments, setDepartments] = useState<string[]>([]);
    const [branches, setBranches] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(undefined);
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await reportService.getAttendanceReport(
                    startDate.toISOString().split('T')[0],
                    endDate.toISOString().split('T')[0]
                );
                const reportList = Array.isArray(data) ? data : [data];
                setReportData(reportList);
                setFilteredData(reportList);
                
                // Departman ve şube listelerini oluştur
                const uniqueDepartments = Array.from(new Set(reportList.map(item => item.department)));
                const uniqueBranches = Array.from(new Set(reportList.map(item => item.branch)));
                setDepartments(uniqueDepartments);
                setBranches(uniqueBranches);
            } catch (error: any) {
                handleApiError(error,t);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate]);

    // Filtre değiştiğinde veriyi güncelle
    useEffect(() => {
        filterData();
    }, [selectedDepartment, selectedBranch, reportData]);

    // Filtreleme fonksiyonu
    const filterData = () => {
        let filtered = [...reportData];
        
        if (selectedDepartment) {
            filtered = filtered.filter(item => item.department === selectedDepartment);
        }
        
        if (selectedBranch) {
            filtered = filtered.filter(item => item.branch === selectedBranch);
        }
        
        setFilteredData(filtered);
    };

    // Filtre sıfırlama
    const resetFilters = () => {
        setSelectedDepartment(undefined);
        setSelectedBranch(undefined);
    };

    // Tablo için sütun tanımları
    const columns: ColumnsType<AttendanceReportDto> = [
        {
            title: 'Ad Soyad',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Departman',
            dataIndex: 'department',
            key: 'department',
            filters: departments.map(dept => ({ text: dept, value: dept })),
            onFilter: (value, record) => record.department === value,
            sorter: (a, b) => a.department.localeCompare(b.department),
        },
        {
            title: 'Şube',
            dataIndex: 'branch',
            key: 'branch',
            filters: branches.map(branch => ({ text: branch, value: branch })),
            onFilter: (value, record) => record.branch === value,
            sorter: (a, b) => a.branch.localeCompare(b.branch),
        },
        {
            title: 'Devam Oranı',
            dataIndex: 'attendanceRate',
            key: 'attendanceRate',
            sorter: (a, b) => parseFloat(a.attendanceRate.replace('%', '')) - parseFloat(b.attendanceRate.replace('%', '')),
            render: (attendanceRate) => {
                const rate = parseFloat(attendanceRate.replace('%', ''));
                let status: 'success' | 'normal' | 'exception' | 'active' = 'normal';
                if (rate >= 90) status = 'success';
                else if (rate >= 70) status = 'normal';
                else if (rate < 50) status = 'exception';
                
                return (
                    <div className="d-flex align-items-center">
                        <Progress 
                            percent={rate} 
                            status={status} 
                            size="small" 
                            style={{ width: 120 }} 
                            format={() => attendanceRate}
                        />
                    </div>
                );
            }
        },
        {
            title: 'İzin Günleri',
            dataIndex: 'totalLeaves',
            key: 'totalLeaves',
            sorter: (a, b) => a.totalLeaves - b.totalLeaves,
            render: (totalLeaves) => (
                <Tag color="error" style={{ fontSize: '14px', padding: '2px 10px' }}>
                    {totalLeaves} gün
                </Tag>
            )
        },
    ];

    // Departman bazlı devam durumu grafiği için veri hazırlama
    const getDepartmentStats = () => {
        const deptStats: { [key: string]: { total: number, attendance: number } } = {};
        
        reportData.forEach(record => {
            if (!deptStats[record.department]) {
                deptStats[record.department] = { total: 0, attendance: 0 };
            }
            deptStats[record.department].total++;
            deptStats[record.department].attendance += parseFloat(record.attendanceRate.replace('%', ''));
        });

        return {
            departments: Object.keys(deptStats),
            rates: Object.values(deptStats).map(s => s.attendance / s.total)
        };
    };

    // Departman performans grafiği
    const deptStats = getDepartmentStats();
    const departmentChartOptions: ApexOptions = {
        chart: {
            type: 'bar' as const,
            height: 350,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 4,
                dataLabels: {
                    position: 'bottom'
                }
            }
        },
        colors: ['#4318FF'],
        dataLabels: {
            enabled: true,
            formatter: function (val: number) {
                return val.toFixed(1) + '%';
            },
            offsetX: 30
        },
        xaxis: {
            categories: deptStats.departments,
            labels: {
                formatter: function (val) {
                    return val + '%';
                }
            }
        },
        yaxis: {
            title: {
                text: 'Departmanlar'
            }
        }
    };

    // Performans dağılımı grafiği için veri hazırlama
    const getPerformanceDistribution = () => {
        const ranges = {
            'Mükemmel (90-100%)': 0,
            'İyi (70-89%)': 0,
            'Orta (50-69%)': 0,
            'Düşük (0-49%)': 0
        };

        reportData.forEach(record => {
            const rate = parseFloat(record.attendanceRate.replace('%', ''));
            if (rate >= 90) ranges['Mükemmel (90-100%)']++;
            else if (rate >= 70) ranges['İyi (70-89%)']++;
            else if (rate >= 50) ranges['Orta (50-69%)']++;
            else ranges['Düşük (0-49%)']++;
        });

        return ranges;
    };

    const performanceDistribution = getPerformanceDistribution();
    const performanceChartOptions: ApexOptions = {
        chart: {
            type: 'pie' as const,
            height: 350
        },
        labels: Object.keys(performanceDistribution),
        colors: ['#05CD99', '#4318FF', '#FFB547', '#E31A1A'],
        legend: {
            position: 'bottom'
        }
    };

    // Özet istatistikler


    return (
        <div className="page-wrapper">
            <div className="content">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">Devam/Devamsızlık Raporu</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">Raporlar</li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Devam/Devamsızlık Raporu
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center">
                        <div className="me-2">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => date && setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => date && setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                        <div className="dropdown me-2">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="ti ti-file-export me-1" />
                                    {t('common.buttons.export')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                    >
                                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                                        {t('common.buttons.pdf')}
                                    </Link>
                                    </li>
                                    <li className="mt-1">
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                    >
                                        <i className="ti ti-file-type-xls me-2 text-success" />
                                        {t('common.buttons.excel')}
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="head-icons ms-2">
                            <CollapseHeader />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Yükleniyor...</span>
                        </div>
                    </div>
                ) : filteredData.length > 0 ? (
                    <div className="row">
                        {/* Özet Kartlar */}
                       

                        {/* Departman Bazlı Devam Grafiği */}
                        <div className="col-xl-8 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Departman Bazlı Devam Oranı</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={departmentChartOptions}
                                        series={[{
                                            name: 'Devam Oranı',
                                            data: deptStats.rates.map(rate => rate * 100)
                                        }]}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Performans Dağılımı */}
                        <div className="col-xl-4 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">Performans Dağılımı</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={performanceChartOptions}
                                        series={Object.values(performanceDistribution)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Detay Tablosu - Ant Design Table */}
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                  
                                </div>
                                <div className="card-body">
                                    <Table
                                        columns={columns}
                                        dataSource={filteredData}
                                        rowKey="userId"
                                        pagination={{ 
                                            pageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '20', '50'],
                                            showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} kayıt`
                                        }}
                                        bordered
                                        size="middle"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-info">
                        Seçili tarih aralığında veri bulunamadı.
                    </div>
                )}
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default AttendanceReport; 