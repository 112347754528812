import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../store/slices/auth.slice";
import { all_routes } from "../../../../router/all_routes";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { AppDispatch, RootState } from "../../../../store/store";
import { handleApiError } from "../../../../utils/errorHandler";
import { message } from "antd";
interface LoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login2 = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { error, loading } = useSelector((state: RootState) => state.auth);

  const [formData, setFormData] = useState<LoginForm>({
    email: "",
    password: "",
    rememberMe: false
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };




 const handleSubmit = (e: React.FormEvent) => {
  // Bu çok önemli! Form gönderimini kesinlikle engellenmeli
  e.preventDefault();
  
  // Form içeriğini kontrol edin
  if (!formData.email || !formData.password) {
    message.error("Lütfen tüm alanları doldurunuz");
    return;
  }
  
  // Asenkron işlemi ayrı bir fonksiyona çıkarın
  doLogin();
};

// Asenkron işlemi ayrı fonksiyona ayırın
const doLogin = async () => {
  try {
    const result = await dispatch(login({ 
      email: formData.email, 
      password: formData.password 
    })).unwrap();
    
    if (result.tokens.accessToken) {
      navigate(routes.adminDashboard);
    }
  } catch (error) {
    if (typeof error === 'string') {
      message.error(error);
    } else if (error && typeof error === 'object') {
      handleApiError(error);
    } else {
      message.error("Beklenmeyen bir hata oluştu");
    }
  }
};

  const togglePasswordVisibility = () => {
    setPasswordVisibility(prev => ({
      password: !prev.password
    }));
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-5">
            <div className="d-lg-block align-items-center justify-content-center d-none flex-wrap vh-100">
              <div>
                <ImageWithBasePath src="assets/img/bg/authentication-bg-03.svg" alt="Img" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap">
              <div className="col-md-7 mx-auto vh-100">
                <form className="vh-80" onSubmit={handleSubmit}>
                  <div className="vh-100 d-flex flex-column justify-content-between" style={{paddingTop: "35%", paddingRight: "1.5rem",paddingLeft: "1.5rem",paddingBottom: "1.5rem"}}>
                  
                    <div className="flex-grow-0">
                      <div className="text-center mb-4">
                      <div className="mx-auto text-center mb-4" style={{width: "60%"}}>
                      <ImageWithBasePath src="assets/img/dinox-light.png" alt="Logo"/>
                    </div>
                        <h2 className="mb-2">Giriş Yap</h2>
                        <p className="mb-0">Lütfen giriş bilgilerinizi giriniz</p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email Adresi</label>
                        <div className="input-group">
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control border-end-0"
                          />
                          <span className="input-group-text border-start-0">
                            <i className="ti ti-mail" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Şifre</label>
                        <div className="pass-group">
                          <input
                            type={passwordVisibility.password ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            className="pass-input form-control"
                          />
                          <span
                            className={`ti toggle-passwords ${passwordVisibility.password ? "ti-eye" : "ti-eye-off"}`}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center">
                          <div className="form-check form-check-md mb-0">
                            <input
                              className="form-check-input"
                              id="remember_me"
                              name="rememberMe"
                              type="checkbox"
                              checked={formData.rememberMe}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="remember_me" className="form-check-label mt-0">
                              Beni Hatırla
                            </label>
                          </div>
                        </div>
                        <div className="text-end">
                          <Link to={routes.forgotPassword2} className="link-danger">
                            Şifremi Unuttum
                          </Link>
                        </div>
                      </div>
                      <div className="mb-3">
                      <button 
                        type="submit" // "button" yerine "submit" kullanın
                        className="btn btn-primary w-100"
                        disabled={loading}
                      >
                        {loading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
                      </button>
                      </div>
                      {/* <div className="text-center">
                        <h6 className="fw-normal text-dark mb-0">
                          Hesabınız yok mu?
                          <Link to={routes.register2} className="hover-a">
                            {" "}
                            Hesap Oluştur
                          </Link>
                        </h6>
                      </div>
                      <div className="login-or">
                        <span className="span-or">Veya</span>
                      </div>
                      <div className="mt-2">
                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                          <div className="text-center me-2 flex-fill">
                            <Link to="#" className="br-10 p-2 btn btn-info d-flex align-items-center justify-content-center">
                              <ImageWithBasePath className="img-fluid m-1" src="assets/img/icons/facebook-logo.svg" alt="Facebook" />
                            </Link>
                          </div>
                          <div className="text-center me-2 flex-fill">
                            <Link to="#" className="br-10 p-2 btn btn-outline-light border d-flex align-items-center justify-content-center">
                              <ImageWithBasePath className="img-fluid m-1" src="assets/img/icons/google-logo.svg" alt="Google" />
                            </Link>
                          </div>
                          <div className="text-center flex-fill">
                            <Link to="#" className="bg-dark br-10 p-2 btn btn-dark d-flex align-items-center justify-content-center">
                              <ImageWithBasePath className="img-fluid m-1" src="assets/img/icons/apple-logo.svg" alt="Apple" />
                            </Link>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="mt-5 pb-4 text-center">
                      <p className="mb-0 text-gray-9">Copyright © Dinox - {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login2;
