import { message } from 'antd';

// t fonksiyonunu parametre olarak alıyoruz
export const handleApiError = (error: any, t?: (key: string) => string) => {
    if(error.standartError){
        const errorMessage = error.standartError.description || error.standartError.message;
        message.error(errorMessage);
        return errorMessage;
    }

    if(error.response?.data?.error?.description){
        message.error(error.response.data.error.description);
        return error.response.data.error.description;
    }

    // Eğer t fonksiyonu verilmişse çeviriyi kullan, yoksa sabit mesaj kullan
    const defaultMessage = t ? t('common.error.defaultErrorMessage') : 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.';
    message.error(defaultMessage);
    return defaultMessage;
}