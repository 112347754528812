import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatCardProps {
  stats: {
    approved: number;
    pending: number;
    rejected: number;
    total: number;
  };
}

export const EmployeeLeaveStats: React.FC<StatCardProps> = ({ stats }) => {
  const { t } = useTranslation();

    const statCards = [
      {
        title: t('leaveEmployee.approved'),
        count: stats.approved,
        icon: "ti ti-calendar-check",
        color: "bg-success-transparent",
        textColor: "text-success"
      },
      {
        title: t('leaveEmployee.pending'),
        count: stats.pending,
        icon: "ti ti-clock",
        color: "bg-warning-transparent",
        textColor: "text-warning"
      },
      {
        title: t('leaveEmployee.rejected'),
        count: stats.rejected,
        icon: "ti ti-calendar-x",
        color: "bg-danger-transparent",
        textColor: "text-danger"
      },
      {
        title: t('leaveEmployee.total'),
        count: stats.total,
        icon: "ti ti-calendar-stats",
        color: "bg-primary-transparent",
        textColor: "text-primary"
      }
      ];

      return (
        <div className="row">
      {statCards.map((card, index) => (
        <div key={index} className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h5 className="mb-1">{card.count}</h5>
                  <p className="text-muted mb-0">{card.title}</p>
                </div>
                <div className={`avatar avatar-lg ${card.color} rounded-circle`}>
                  <i className={`${card.icon} fs-3 ${card.textColor}`}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
      );
};
