import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import leaveService from "../../../../../services/leave/leave.service";
import { LeaveAdminUpdateDTO, LeaveDto, LeaveResponse, LeaveStatus, LeaveType } from "../../../../../types/leaves.types";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../shared/uiInterface/base-ui/LoadingSpinner";
import dayjs from "dayjs";
import { Button, Tabs, DatePicker, Badge, Tooltip, message, Typography } from "antd";
import Table from "../../../../shared/dataTable/index";
import { OverlayTrigger } from "react-bootstrap";
import { all_routes } from "../../../../../router/all_routes";
import { handleApiError } from "../../../../../utils/errorHandler";
import { useLeaves } from "../../../../../hooks/useLeaves";
import { formatDate } from "../../../../../utils/date";
import { getFileUrl } from "../../../../../utils/file.utils";
import LeaveStatusDropdown from "../../../leaves/components/LeaveStatusDropdown";
import AdminLeaveModal from "../../../leaves/components/AdminLeaveModal";
import FooterSection from "../../../../shared/components/footerSection";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

// Tip güvenliği için izin öğeleri için arabirim
interface ExtendedLeaveDto {
    id: number;
    type: LeaveType;
    startDate: string;
    endDate: string;
    reason: string;
    documentUrl?: string;
    approvedBy?: any;
    days?: number;
    status: string; // 'approved', 'pending', 'rejected'
    [key: string]: any; // Diğer olası alanlar için
}

const AllLeaveUser = () => {
    const { t } = useTranslation();
    const {leavesError,stats,updateLeaveStatus, createLeave} = useLeaves();
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const { getLeaveByUserId } = leaveService;
    const userId = searchParams.get('user');
    const [leaves, setLeaves] = useState<LeaveResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [userName, setUserName] = useState<string>("Çalışan");
    const [activeTab, setActiveTab] = useState<string>("all");
    const [selectedLeave, setSelectedLeave] = useState<LeaveDto | undefined>();

    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
        dayjs().startOf('year'),
        dayjs().endOf('year')
    ]);
    
    const handleOpenLeaveModal = () => {
        setModalVisible(true);
      };
    
      const handleCloseLeaveModal = () => {
        setModalVisible(false);
      };
    const fetchLeaves = async () => {
        if (!userId) return;
        const userIdNumber = Number(userId);
        if (isNaN(userIdNumber)) {
            navigate('/');
            return;
        }
        setLoading(true);
        try {
            const startDate = dateRange[0].format('YYYY-MM-DD');
            const endDate = dateRange[1].format('YYYY-MM-DD');
            const response = await getLeaveByUserId(Number(userId), startDate, endDate);
            setLeaves(response);
            
            // Kullanıcı adını backend'den alamadığımız için şimdilik statik değer kullanıyoruz
            // Gerçek uygulamada, kullanıcı adını API'den almalısınız
            setUserName(`Çalışan #${userId}`);
        } catch (error) {
            handleApiError(error,t);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmit = async (values: any) => {
        try {
          if (selectedLeave) {
            // Düzenleme
            await updateLeaveStatus(selectedLeave.id, values);
          } else {
            // Yeni oluşturma
            await createLeave(values);
          }
          if(selectedLeave){
            message.success(t('leaves.modals.updated'));
          }else{
            message.success(t('leaves.modals.created'));
          }
          handleCloseLeaveModal();
          fetchLeaves(); // Listeyi yenile
        } catch (error) {
          handleApiError(error,t);
        }
      };
    const handleStatusChange = async (leaveId:number,dto:LeaveAdminUpdateDTO)=>{
        try{
          await updateLeaveStatus(leaveId,dto);
          message.success(t('leaves.modals.statusUpdated'));
          fetchLeaves();
        }catch(error: any){
          handleApiError(error,t);
        }
      }
    const handleDateRangeChange = (dates: any) => {
        if (dates && dates.length === 2) {
            setDateRange([dates[0], dates[1]]);
        }
    };

    useEffect(() => {
        fetchLeaves();
    }, [userId, dateRange]);


    const columns = [
        {
        title: t('leaves.table.leaveType'),
        dataIndex: "type",
        key: "type",
        render: (text: string, record: LeaveDto) => (
            <div className="d-flex align-items-center">
            <p className="fs-14 fw-medium d-flex align-items-center mb-0">
                {t(`leaveTypes.${record.type}`)}
            </p>
            </div>
        ),
        sorter: (a: any, b: any) => a.type.length - b.type.length,
        },
        {
            title: t('leaves.table.date'),
            dataIndex: "startDate",
            render: (_: string, record: LeaveDto) => (
              record.type === 'hourly' ? (
                <p className="fs-14 mb-0">
                  {formatDate(record.startDate)}
                  <br />
                  <small className="text-muted">
                    {record.startTime} - {record.endTime}
                  </small>
                </p>
              ) : (
                <p className="fs-14 mb-0">
                  {formatDate(record.startDate)} -
                  <br />
                  {formatDate(record.endDate)}
                </p>
              )
            ),
          },
        {
            title: t('leaves.table.description'),
            dataIndex: "reason", // Assuming `Description` is the field in your data
            render: (reason: string) => (
              reason.length > 30 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip title={reason} placement="top">{reason}</Tooltip>}
                >
                  <div className="position-relative">
                    <p 
                      className="fs-14 mb-0" 
                      style={{ 
                        maxWidth: "100px", 
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "22px"
                      }}
                    >
                      {reason}
                      <span 
                        className="text-primary"
                        style={{ 
                          position: "absolute", 
                          right: 0, 
                          top: "50%", 
                          transform: "translateY(-50%)",
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "12px",
                          opacity: 0.8
                        }}
                      >
                        <i className="ti ti-info-circle" />
                      </span>
                    </p>
                  </div>
                </OverlayTrigger>
              ) : (
                <p className="fs-14 mb-0">{reason}</p>
              )
            ),
            sorter: (a: any, b: any) => a.reason.length - b.reason.length,
          },
          {
            title: t('leaves.table.file'),
            dataIndex: "file",
            render: (_: string, record: LeaveDto) => (
              <p className="fs-14 mb-0">
                {record.documentUrl ?  
                <a 
                  className="dropdown-item" 
                  href={getFileUrl(record.documentUrl)} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Görüntüle
                </a> : '-'}
              </p>
            ),
          },
          {
            title: t('leaves.table.status'),
            dataIndex: "status", // Assuming `AdminStatus` is the field in your data
            render: (status: LeaveStatus, record: LeaveDto) => (
              <LeaveStatusDropdown
                status={status}
                onStatusChange={(dto) => handleStatusChange(record.id, dto)}
                disabled={!canUpdateStatus(record)}
              />
            ),
          },
    ];
    const canUpdateStatus = (leave: LeaveDto): boolean => {
        // Implement the logic to determine if the status can be updated
        return true; // Placeholder return, actual implementation needed
      };
    // Tüm izinleri birleştir
    const getAllLeaves = (): ExtendedLeaveDto[] => {
        const approvedLeaves = (leaves?.approved || []).map(leave => ({ ...leave, status: 'approved' } as ExtendedLeaveDto));
        const pendingLeaves = (leaves?.pending || []).map(leave => ({ ...leave, status: 'pending' } as ExtendedLeaveDto));
        const rejectedLeaves = (leaves?.rejected || []).map(leave => ({ ...leave, status: 'rejected' } as ExtendedLeaveDto));
        
        return [...approvedLeaves, ...pendingLeaves, ...rejectedLeaves];
    };

    const getFilteredLeaves = (): ExtendedLeaveDto[] => {
        const allLeaves = getAllLeaves();
        
        switch (activeTab) {
            case 'approved':
                return allLeaves.filter(leave => leave.status === 'approved');
            case 'pending':
                return allLeaves.filter(leave => leave.status === 'pending');
            case 'rejected':
                return allLeaves.filter(leave => leave.status === 'rejected');
            default:
                return allLeaves;
        }
    };

    if (loading && !leaves) {
        return <LoadingSpinner />;
    }

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{userName} - {t('leaveUserAdminPage.title')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.employeeList}>{t('leaveUserAdminPage.employeeList')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('leaveUserAdminPage.title')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center flex-wrap ">
              <button
                  className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
                  onClick={handleOpenLeaveModal}
                >
                  <i className="ti ti-plus me-1" />
                  {t('leaves.addLeave')}
                </button>
                </div>
                </div>

                {/* İzin İstatistikleri */}
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-green-img">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <span className="avatar avatar-md rounded-circle bg-white d-flex align-items-center justify-content-center">
                                                <i className="ti ti-check text-success fs-18" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <p className="mb-1">{t('leaveUserAdminPage.approvedLeaves')}</p>
                                        <h4>{leaves?.approvedCount || 0}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-yellow-img">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <span className="avatar avatar-md rounded-circle bg-white d-flex align-items-center justify-content-center">
                                                <i className="ti ti-clock text-warning fs-18" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <p className="mb-1">{t('leaveUserAdminPage.pendingLeaves')}</p>
                                        <h4>{leaves?.pendingCount || 0}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-pink-img">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <span className="avatar avatar-md rounded-circle bg-white d-flex align-items-center justify-content-center">
                                                <i className="ti ti-x text-danger fs-18" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <p className="mb-1">{t('leaveUserAdminPage.rejectedLeaves')}</p>
                                        <h4>{leaves?.rejectedCount || 0}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card bg-blue-img">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <span className="avatar avatar-md rounded-circle bg-white d-flex align-items-center justify-content-center">
                                                <i className="ti ti-calendar text-info fs-18" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <p className="mb-1">{t('leaveUserAdminPage.totalLeaves')}</p>
                                        <h4>{leaves?.totalLeaveCount || 0}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* İzin Listesi */}
                <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h5 className="mb-0">{t('leaveUserAdminPage.leaveList')}</h5>
                        <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
                            <div className="me-3">
                                <RangePicker
                                    format="DD-MM-YYYY"
                                    defaultValue={dateRange}
                                    onChange={handleDateRangeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Tabs 
                            activeKey={activeTab} 
                            onChange={(key) => setActiveTab(key)}
                            className="mb-4"
                        >
                            <TabPane tab={t('leaveUserAdminPage.allLeaves')} key="all" />
                            <TabPane tab={`${t('leaveUserAdminPage.approvedLeaves')} (${leaves?.approvedCount || 0})`} key="approved" />
                            <TabPane tab={`${t('leaveUserAdminPage.pendingLeaves')} (${leaves?.pendingCount || 0})`} key="pending" />
                            <TabPane tab={`${t('leaveUserAdminPage.rejectedLeaves')} (${leaves?.rejectedCount || 0})`} key="rejected" />
                        </Tabs>

                        {loading ? (
                            <LoadingSpinner />
                        ) : error ? (
                            <div className="alert alert-danger">{error}</div>
                        ) : getFilteredLeaves().length === 0 ? (
                            <div className="text-center p-5">
                                <i className="ti ti-calendar-off fs-2 text-muted mb-3 d-block"></i>
                                <Text type="secondary">{t('leaveUserAdminPage.noLeaves')}</Text>
                            </div>
                        ) : (
                            <Table 
                                dataSource={getFilteredLeaves()} 
                                columns={columns} 
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
            <AdminLeaveModal
                leave={selectedLeave}
                onSubmit={handleSubmit}
                onClose={handleCloseLeaveModal}
                open={modalVisible}
            />
        </div>
    );
};

export default AllLeaveUser;