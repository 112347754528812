import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, DatePicker, Table, Badge, Modal, Tooltip, message, Typography } from "antd";
import { all_routes } from "../../../../../router/all_routes";
import LoadingSpinner from "../../../../shared/uiInterface/base-ui/LoadingSpinner";
import dayjs from "dayjs";
import dailyRecordService from "../../../../../services/dailyRecord/dailyRecord.service";
import { UserRecordDto } from "../../../../../types/attendanceDto.types";
import { handleApiError } from "../../../../../utils/errorHandler";
import FooterSection from "../../../../shared/components/footerSection";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

// Devam kayıtları için arayüz
interface AttendanceRecord {
    entryTime: string;
    exitTime: string;
    isLate: boolean;
    lateDuration: string;
    workMinutes: number;
}

const AllAttendanceUser = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('user');
    const [userRecords, setUserRecords] = useState<UserRecordDto>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userName, setUserName] = useState<string>("Çalışan");
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isUpdatingRecord, setIsUpdatingRecord] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<AttendanceRecord | null>(null);
    const [editedRecord, setEditedRecord] = useState<AttendanceRecord | null>(null);
    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
        dayjs().startOf('month'),
        dayjs().endOf('month')
    ]);

    // Kayıtları getir
    const fetchUserRecords = async () => {
        if (!userId) return;
        
        setLoading(true);
        try {
            const startDate = dateRange[0].format('YYYY-MM-DD');
            const endDate = dateRange[1].format('YYYY-MM-DD');
            const records = await dailyRecordService.getUserRecords(Number(userId), startDate, endDate);
            setUserRecords(records);
            
            // Kullanıcı adını statik olarak ayarlıyoruz (gerçek uygulamada API'den gelmeli)
            setUserName(`${t('attendanceUserAdminPage.employee')} #${userId}`);
        } catch (error: any) {
            handleApiError(error,t);
        } finally {
            setLoading(false);
        }
    };

    const handleDateRangeChange = (dates: any) => {
        if (dates && dates.length === 2) {
            setDateRange([dates[0], dates[1]]);
        }
    };

    useEffect(() => {
        fetchUserRecords();
    }, [userId, dateRange]);

    // Kayıt düzenleme modalını aç
    const handleEditRecord = (record: AttendanceRecord) => {
        setSelectedRecord(record);
        setEditedRecord({ ...record });
        setIsEditModalVisible(true);
    };

    // Saat değişikliklerini işle
    const handleTimeChange = (field: 'entryTime' | 'exitTime', time: any) => {
        if (!time || !editedRecord) return;
        
        const currentDate = new Date(editedRecord[field]);
        const newTime = time.toDate();
        
        currentDate.setHours(newTime.getHours());
        currentDate.setMinutes(newTime.getMinutes());
        
        setEditedRecord({
            ...editedRecord,
            [field]: currentDate.toISOString()
        });
    };

    // Kaydı güncelle
    const handleUpdateRecord = async () => {
        if (!editedRecord) return;
        
        try {
            setIsUpdatingRecord(true);
            message.success(t('attendanceUserAdminPage.recordUpdatedSuccessfully'));
            setIsEditModalVisible(false);
            fetchUserRecords(); // Kayıtları yenile
        } catch (error: any) {
            handleApiError(error,t);
        } finally {
            setIsUpdatingRecord(false);
        }
    };

    // Tablo sütunları
    const columns = [
        {
            title: t('attendanceUserAdminPage.date'),
            key: 'date',
            render: (record: AttendanceRecord) => new Date(record.entryTime).toLocaleDateString('tr-TR')
        },
        {
            title: t('attendanceUserAdminPage.entryTime'),
            key: 'entryTime',
            render: (record: AttendanceRecord) => new Date(record.entryTime).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' })
        },
        {
            title: t('attendanceUserAdminPage.exitTime'),
            key: 'exitTime',
            render: (record: AttendanceRecord) => new Date(record.exitTime).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' })
        },
        {
            title: t('attendanceUserAdminPage.status'),
            key: 'status',
            render: (record: AttendanceRecord) => (
                <Badge 
                    status={record.isLate ? "error" : "success"} 
                    text={record.isLate ? t('attendanceUserAdminPage.late') : t('attendanceUserAdminPage.onTime')} 
                />
            )
        },
        {
            title: t('attendanceUserAdminPage.lateDuration'),
            key: 'lateDuration',
            dataIndex: 'lateDuration'
        },
        {
            title: t('attendanceUserAdminPage.workDuration'),
            key: 'workMinutes',
            render: (record: AttendanceRecord) => `${Math.floor(record.workMinutes / 60)} saat ${record.workMinutes % 60} dakika`
        },
        {
            title: t('attendanceUserAdminPage.action'),
            key: 'action',
            render: (record: AttendanceRecord) => (
                <Button 
                    type="primary" 
                    size="small"
                    onClick={() => handleEditRecord(record)}
                >
                    {t('attendanceUserAdminPage.edit')}
                </Button>
            )
        }
    ];

    if (loading && !userRecords) {
        return <LoadingSpinner />;
    }

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{userName} - {t('attendanceUserAdminPage.attendanceHistory')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.employeeList}>{t('attendanceUserAdminPage.employeeList')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('attendanceUserAdminPage.attendanceHistory')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                        <div className="me-2 mb-2">
                            <div className="dropdown">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="ti ti-file-export me-1" />
                                    {t('common.buttons.export')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1">
                                            <i className="ti ti-file-type-pdf me-1" />
                                            {t('common.buttons.pdf')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1">
                                            <i className="ti ti-file-type-xls me-1" />
                                            {t('common.buttons.excel')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* İstatistik Kartları */}
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <div className="card h-100 border-0 shadow-sm hover-shadow">
                            <div className="card-body text-center p-4">
                                <div className="mb-4">
                                    <div className="avatar avatar-xl bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                        <span className="fs-2">⏰</span>
                                    </div>
                                </div>
                                <h2 className="mb-2 text-primary">
                                    {userRecords?.totalWorkMinutes ? 
                                        `${Math.floor(userRecords.totalWorkMinutes / 60)}s ${userRecords.totalWorkMinutes % 60}dk` : 
                                        '0s 0dk'}
                                </h2>
                                <p className="fw-medium mb-0 text-muted">{t('attendanceUserAdminPage.totalWork')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card h-100 border-0 shadow-sm hover-shadow">
                            <div className="card-body text-center p-4">
                                <div className="mb-4">
                                    <div className="avatar avatar-xl bg-danger-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                        <span className="fs-2">⚠️</span>
                                    </div>
                                </div>
                                <h2 className="mb-2 text-danger">{userRecords?.lateDaysCount || 0}</h2>
                                <p className="fw-medium mb-0 text-muted">{t('attendanceUserAdminPage.lateCount')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card h-100 border-0 shadow-sm hover-shadow">
                            <div className="card-body text-center p-4">
                                <div className="mb-4">
                                    <div className="avatar avatar-xl bg-warning-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                        <span className="fs-2">🏃</span>
                                    </div>
                                </div>
                                <h2 className="mb-2 text-warning">{userRecords?.earlyLeaveDaysCount || 0}</h2>
                                <p className="fw-medium mb-0 text-muted">{t('attendanceUserAdminPage.earlyLeaveCount')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="card h-100 border-0 shadow-sm hover-shadow">
                            <div className="card-body text-center p-4">
                                <div className="mb-4">
                                    <div className="avatar avatar-xl bg-success-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                        <span className="fs-2">📅</span>
                                    </div>
                                </div>
                                <h2 className="mb-2 text-success">{userRecords?.totalRecords || 0}</h2>
                                <p className="fw-medium mb-0 text-muted">{t('attendanceUserAdminPage.totalRecords')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Devam Kayıtları Listesi */}
                <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h5 className="mb-0">{t('attendanceUserAdminPage.attendanceHistory')}</h5>
                        <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
                            <div className="me-3">
                                <RangePicker
                                    format="DD-MM-YYYY"
                                    defaultValue={dateRange}
                                    onChange={handleDateRangeChange}
                                />
                            </div>
                            <div className="dropdown me-3">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    {t('attendanceUserAdminPage.allStatus')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1">
                                            {t('attendanceUserAdminPage.all')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1">
                                            {t('attendanceUserAdminPage.onTimeStatus')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1">
                                            {t('attendanceUserAdminPage.lateStatus')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {loading ? (
                            <LoadingSpinner />
                        ) : error ? (
                            <div className="alert alert-danger">{error}</div>
                        ) : userRecords && userRecords.records.length === 0 ? (
                            <div className="text-center p-5">
                                <i className="ti ti-calendar-off fs-2 text-muted mb-3 d-block"></i>
                                <Text type="secondary">{t('attendanceUserAdminPage.noAttendance')}</Text>
                            </div>
                        ) : (
                            <Table 
                                dataSource={userRecords?.records || []}
                                columns={columns}
                                rowKey={(record) => record.entryTime}
                                pagination={{ pageSize: 10 }}
                                className="table-striped"
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Düzenleme Modalı */}
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        <i className="ti ti-edit fs-4 me-2 text-primary"></i>
                        <span>{t('attendanceUserAdminPage.editAttendance')}</span>
                    </div>
                }
                open={isEditModalVisible}
                onCancel={() => setIsEditModalVisible(false)}
                footer={[
                    <Button 
                        key="cancel" 
                        onClick={() => setIsEditModalVisible(false)}
                    >
                        {t('common.buttons.cancel')}
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={isUpdatingRecord}
                        onClick={handleUpdateRecord}
                    >
                        {t('common.buttons.update')}
                    </Button>
                ]}
                width={500}
            >
                <div className="edit-record-form">
                    <div className="mb-4">
                        <h6 className="text-muted mb-3">{t('attendanceUserAdminPage.date')}</h6>
                        <div className="p-3 bg-light rounded-3 border">
                            <p className="mb-0 fw-medium">
                                {selectedRecord ? new Date(selectedRecord.entryTime).toLocaleDateString('tr-TR', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                }) : ''}
                            </p>
                        </div>
                    </div>
                    <div className="time-picker-container p-4 bg-light rounded-3 border">
                        <div className="d-flex align-items-center mb-4">
                            <div className="avatar avatar-sm bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center me-2">
                                <i className="ti ti-clock text-primary"></i>
                            </div>
                            <h6 className="mb-0">{t('attendanceUserAdminPage.workHours')}</h6>
                        </div>
                        <div className="time-display">
                            <div className="row g-4">
                                <div className="col-6">
                                    <div className="time-card p-4 bg-white rounded-3 border text-center">
                                        <div className="time-header mb-4">
                                            <div className="time-icon mb-3">
                                                <div className="avatar avatar-lg bg-primary-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                    <i className="ti ti-login text-primary fs-4"></i>
                                                </div>
                                            </div>
                                            <h6 className="text-primary mb-0">{t('attendanceUserAdminPage.entryTime')}</h6>
                                        </div>
                                        <DatePicker.TimePicker
                                            className="time-picker-input"
                                            format="HH:mm"
                                            value={editedRecord ? dayjs(editedRecord.entryTime) : null}
                                            onChange={(time) => handleTimeChange('entryTime', time)}
                                            minuteStep={1}
                                            showNow={false}
                                            allowClear={false}
                                            placeholder="Saat seçin"
                                            popupClassName="time-picker-popup"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="time-card p-4 bg-white rounded-3 border text-center">
                                        <div className="time-header mb-4">
                                            <div className="time-icon mb-3">
                                                <div className="avatar avatar-lg bg-danger-subtle rounded-circle d-inline-flex align-items-center justify-content-center">
                                                    <i className="ti ti-logout text-danger fs-4"></i>
                                                </div>
                                            </div>
                                            <h6 className="text-danger mb-0">{t('attendanceUserAdminPage.exitTime')}</h6>
                                        </div>
                                        <DatePicker.TimePicker
                                            className="time-picker-input"
                                            format="HH:mm"
                                            value={editedRecord ? dayjs(editedRecord.exitTime) : null}
                                            onChange={(time) => handleTimeChange('exitTime', time)}
                                            minuteStep={1}
                                            showNow={false}
                                            allowClear={false}
                                            placeholder={t('attendanceUserAdminPage.selectTime')}
                                            popupClassName="time-picker-popup"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style>
                        {`
                            .time-picker-container {
                                transition: all 0.3s;
                                background: linear-gradient(145deg, #f8f9fa, #ffffff);
                            }
                            .time-picker-container:hover {
                                transform: translateY(-2px);
                                box-shadow: 0 8px 24px rgba(0,0,0,0.08);
                            }
                            .time-card {
                                transition: all 0.3s;
                                height: 100%;
                                background: #ffffff;
                                border: 1px solid rgba(0,0,0,0.05);
                            }
                            .time-card:hover {
                                transform: translateY(-2px);
                                box-shadow: 0 4px 16px rgba(0,0,0,0.06);
                                border-color: rgba(67, 97, 238, 0.2);
                            }
                            .time-header {
                                transition: all 0.3s;
                            }
                            .time-icon {
                                transition: all 0.3s;
                            }
                            .time-card:hover .time-icon {
                                transform: scale(1.05);
                            }
                            .time-picker-input {
                                width: 100%;
                                height: 48px;
                                font-size: 1.1rem;
                                border-radius: 8px;
                                border: 2px solid #e9ecef;
                                transition: all 0.3s;
                            }
                            .time-picker-input:hover {
                                border-color: #4361ee;
                            }
                            .time-picker-input:focus {
                                border-color: #4361ee;
                                box-shadow: 0 0 0 4px rgba(67, 97, 238, 0.1);
                            }
                            .time-picker-popup .ant-picker-panel {
                                border-radius: 8px;
                            }
                            .time-picker-popup .ant-picker-time-panel-column {
                                scrollbar-width: thin;
                                scrollbar-color: #4361ee #f8f9fa;
                            }
                            .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar {
                                width: 6px;
                            }
                            .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar-track {
                                background: #f8f9fa;
                                border-radius: 3px;
                            }
                            .time-picker-popup .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
                                background-color: #4361ee;
                                border-radius: 3px;
                            }
                            .ant-picker-ok {
                                margin-top: 8px;
                            }
                            .ant-picker-ok button {
                                background-color: #4361ee;
                                border-color: #4361ee;
                            }
                            .ant-picker-ok button:hover {
                                background-color: #3651d4;
                                border-color: #3651d4;
                            }
                        `}
                    </style>
                </div>
            </Modal>

            {/* Footer */}
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default AllAttendanceUser;