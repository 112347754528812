import { useState, useCallback, useEffect } from 'react';
import { message } from 'antd';
import { User, UserCreateDTO, UserStatus, UserUpdateDTO } from '../types/user.types';
import userService from '../services/user/user.service';
import departmentService from '../services/department/department.service';
import shopService from '../services/shop/shop.service';
import { DepartmentDto, DepartmentStatus } from '../types/department.types';
import { Store } from '../types/shop.types';
import { useAsync } from './useAsync';

export const useEmployee = () => {
  const {data:users,loading,error,execute} = useAsync<User[]>();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [departments, setDepartments] = useState<DepartmentDto[]>([]);
  const [shops, setShops] = useState<Store[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedShop, setSelectedShop] = useState<string>('all');
  const [selectedStatus, setSelectedStatus] = useState<UserStatus|string>('all');
  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  const fetchUsers = useCallback(async () => {
    return execute(()=>userService.getUsers());
  }, [execute]);

  const fetchDepartmentsAndShops = useCallback(async () => {
    try {
      const [depsResult, shopsResult] = await Promise.all([
        departmentService.getDepartments(),
        shopService.getShops()
      ]);
      setDepartments(depsResult.filter(dep => dep.status === DepartmentStatus.ACTIVE));
      setShops(shopsResult);
    } catch (err: any) {
      message.error("Veriler yüklenirken bir hata oluştu");
    }
  }, []);


  const handleStatusChange = (status: UserStatus|string) => {
    setSelectedStatus(status);
    filterUsers(selectedDepartment, selectedShop, status);
  };

  const handleDepartmentChange = (departmentId: string) => {
    setSelectedDepartment(departmentId);
    filterUsers(departmentId, selectedShop, selectedStatus);
  };

  const handleShopChange = (shopId: string) => {
    setSelectedShop(shopId);
    filterUsers(selectedDepartment, shopId, selectedStatus);
  };

  const filterUsers = (departmentId: string, shopId: string, status: UserStatus|string) => {
    if(!users) return;
    let filtered = [...users];

    if (departmentId !== 'all') {
      filtered = filtered.filter(user => user.department?.id === Number(departmentId));
    }

    if (shopId !== 'all') {
      filtered = filtered.filter(user => user.branch?.id === Number(shopId));
    }

    if (status !== 'all') {
      filtered = filtered.filter(user => user.status === status);
    }

    setFilteredUsers(filtered);
  };

  const resetFilters = () => {
    setSelectedDepartment('all');
    setSelectedShop('all');
    setSelectedStatus('all');
    if(!users) return;
    setFilteredUsers(users);
  };

  const createEmployee = async (data: UserCreateDTO) => {
    try {
      await userService.createUser(data);
      message.success('Çalışan başarıyla eklendi');
      await fetchUsers();
    } catch (err: any) {
      message.error("Çalışan eklenirken bir hata oluştu");
      throw err;
    }
  };

  const updateEmployee = async (id: number, data: UserUpdateDTO) => {
    try {
      await userService.updateUser(id, data);
      message.success('Çalışan başarıyla güncellendi');
      await fetchUsers();
    } catch (err: any) {
      message.error("Çalışan güncellenirken bir hata oluştu");
      throw err;
    }
  };

  const updateEmployeeStatus = async (id: number, status: UserStatus) => {
    try {
      await userService.updateUserStatus(id, status);
      message.success('Çalışan durumu başarıyla güncellendi');
      await fetchUsers();
    } catch (err: any) {
      message.error("Çalışan durumu güncellenirken bir hata oluştu");
      throw err;
    }
  };

  const deleteEmployee = async (id: number) => {
    try {
      await userService.deleteUser(id);
      message.success('Çalışan başarıyla silindi');
      await fetchUsers();
    } catch (err: any) {
      message.error("Çalışan silinirken bir hata oluştu");
      throw err;
    }
  };

  return {
    users: filteredUsers,
    departments,
    shops,
    loading,
    error,
    selectedDepartment,
    selectedShop,
    selectedStatus,
    handleDepartmentChange,
    handleShopChange,
    resetFilters,
    fetchUsers,
    fetchDepartmentsAndShops,
    createEmployee,
    updateEmployee,
    deleteEmployee,
    updateEmployeeStatus,
    handleStatusChange
  };
}; 