import { Link } from "react-router-dom";
import ImageWithBasePath from "../core/common/imageWithBasePath";
import { all_routes } from "../router/all_routes";
import { User } from "../types/user.types";
import { LeaveDto } from "../types/leaves.types";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
export const renderUserNameCell = (text: string, record: User) => (
  <div className="d-flex align-items-center">
    <div className="ms-2">
      <p className="text-dark mb-0">
        <Link
          to={all_routes.employeedetails.replace(':id', record.id.toString())}
        >
          {record.firstName} {record.lastName}
        </Link>
      </p>
      <span className="fs-12">{record.title}</span>
    </div>
  </div>
);

export const renderLeaveUserCell = (text: string, record: LeaveDto) => (
  <div className="d-flex align-items-center file-name-icon">
    <Link 
      to={all_routes.employeedetails.replace(':id', record.user?.id.toString() || '')} 
      className="avatar avatar-md border avatar-rounded"
    >
      <ImageWithBasePath
        src={`assets/img/users/user-1.jpg`}
        className="img-fluid"
        alt="img"
      />
    </Link>
    <div className="ms-2">
      <h6 className="fw-medium">
        <Link to={all_routes.employeedetails.replace(':id', record.user?.id.toString() || '')}>
          {record.user?.firstName} {record.user?.lastName}
        </Link>
      </h6>
      <span className="fs-12 fw-normal">{record.user?.title}</span>
    </div>
  </div>
); 