import { ExcuseDto } from "../../types/excuse.types";
import { ExcuseCreateDTO } from "../../types/excuse.types";
import apiService from "../api/api.service";

class ExcuseService {
  private static instance: ExcuseService;

  private constructor() {}
  
  public static getInstance(): ExcuseService {
    if (!ExcuseService.instance) {
      ExcuseService.instance = new ExcuseService();
    }
    return ExcuseService.instance;
  }
  
  public async createExcuse(data: ExcuseCreateDTO): Promise<ExcuseDto> {
    const response = await apiService.post<ExcuseDto>("/excuses", data);
    return response;
  }

  public async getExcuseList(): Promise<ExcuseDto[]> {
    const response = await apiService.get<ExcuseDto[]>("/excuses");
    return response;
  }

  public async getExcusesByUserId(userId: number): Promise<ExcuseDto[]> {
    const response = await apiService.get<ExcuseDto[]>(`/excuses/user/${userId}`);
    return response;
  }
  
}   

export default ExcuseService.getInstance();