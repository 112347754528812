import {
    Store,
    ShopCreateDTO,
    ShopUpdateDTO,
  } from "../../types/shop.types";
  import apiService from "../api/api.service";
  
  class ShopService {
    private static instance: ShopService;
  
    private constructor() {}
  
    public static getInstance(): ShopService {
      if (!ShopService.instance) {
        ShopService.instance = new ShopService();
      }
      return ShopService.instance;
    }
  
    public async getShops(): Promise<Store[]> {
      const response = await apiService.get<Store[]>(`/branches`);
      return response;
    }
  
    public async getShopById(id: number): Promise<Store> {
      const response = await apiService.get<Store>(`/branches`);
      return response;
    }
  
    public async createShop(
      data: ShopCreateDTO
    ): Promise<Store> {
      const response = await apiService.post<Store>("/branches", data);
      return response;
    }
  
    public async updateShop(
      id: number,
      data: ShopUpdateDTO
    ): Promise<Store> {
      const response = await apiService.put<Store>(
        `/branches/${id}`,
        data
      );
      return response;
    }
  
    public async deleteShop(id: number): Promise<void> {
      await apiService.delete(`/branches/${id}`);
    }
  
    public async getShopByCompanyId(
      branchId: number
    ): Promise<Store[]> {
      const response = await apiService.get<Store[]>(
        `/departments/branch/${branchId}`
      );
      return response;
    }
  }
  
  export default ShopService.getInstance();
  