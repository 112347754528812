export const attendance_admin_details = [
  {
    key: "1",
    Employee: "Anthony Lewis",
    Image: "user-49.jpg",
    Role: "UI/UX Team",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "06:45 PM",
    Break: "30 Min",
    Late: "32 Min",
    Production: 1,
    ProductionHours: "8.55 Hrs",
  },
  {
    key: "2",
    Employee: "Brian Villalobos",
    Image: "user-09.jpg",
    Role: "Development",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "06:12 PM",
    Break: "20 Min",
    Late: "20 Min",
    Production: 3,
    ProductionHours: "7.54 Hrs",
  },
  {
    key: "3",
    Employee: "Harvey Smith",
    Image: "user-01.jpg",
    Role: "HR",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "06:13 PM",
    Break: "50 Min",
    Late: "23 Min",
    Production: 1,
    ProductionHours: "8.45 Hrs",
  },
  {
    key: "4",
    Employee: "Stephan Peralt",
    Image: "user-33.jpg",
    Role: "Management",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "06:23 PM",
    Break: "41 Min",
    Late: "50 Min",
    Production: 1,
    ProductionHours: "8.35 Hrs",
  },
  {
    key: "5",
    Employee: "Doglas Martini",
    Image: "user-34.jpg",
    Role: "Development",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "06:43 PM",
    Break: "23 Min",
    Late: "10 Min",
    Production: 1,
    ProductionHours: "8.22 Hrs",
  },
  {
    key: "6",
    Employee: "Linda Ray",
    Image: "user-02.jpg",
    Role: "UI/UX Team",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "07:15 PM",
    Break: "03 Min",
    Late: "30 Min",
    Production: 1,
    ProductionHours: "8.32 Hrs",
  },
  {
    key: "7",
    Employee: "Elliot Murray",
    Image: "user-35.jpg",
    Role: "UI/UX Team",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "07:13 PM",
    Break: "32 Min",
    Late: "41 Min",
    Production: 2,
    ProductionHours: "9.15 Hrs",
  },
  {
    key: "8",
    Employee: "Rebecca Smith",
    Image: "user-30.jpg",
    Role: "UI/UX Team",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "09:17 PM",
    Break: "14 Min",
    Late: "12 Min",
    Production: 1,
    ProductionHours: "9.25 Hrs",
  },
  {
    key: "9",
    Employee: "Connie Waters",
    Image: "user-36.jpg",
    Role: "Management",
    Status: "Present",
    CheckIn: "09:00 AM",
    CheckOut: "08:15 PM",
    Break: "12 Min",
    Late: "03 Min",
    Production: 1,
    ProductionHours: "8.35 Hrs",
  },
  {
    key: "10",
    Employee: "Lori Broaddus",
    Image: "user-38.jpg",
    Role: "Finance",
    Status: "Absent",
    CheckIn: "-",
    CheckOut: "-",
    Break: "-",
    Late: "-",
    Production: 3,
    ProductionHours: "0.00 Hrs",
  },
];
