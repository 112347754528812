import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Card,
  Row,
  Col,
  Button,
  Tabs,
  Badge,
  Descriptions,
  Statistic,
  Divider,
  Tag,
  Tooltip,
  Space,
  Spin,
  Alert,
  Modal,
  message,
  Empty,
  Table
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  BuildOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  BankOutlined,
  CalendarOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { Company, CompanyStatus } from "../../../types/company.types";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import moment from "moment";
import "./styles.css";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";

const { TabPane } = Tabs;
const { confirm } = Modal;

const CompanyDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation(); 

  useEffect(() => {
    fetchCompany();
  }, [id]);

  const fetchCompany = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await companyService.getCompanyById(Number(id));
      setCompany(data);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    confirm({
      title: "Bu şirketi silmek istediğinize emin misiniz?",
      icon: <ExclamationCircleOutlined />,
      content: "Bu işlem geri alınamaz!",
      okText: "Evet",
      okType: "danger",
      cancelText: "Hayır",
      onOk: async () => {
        try {
          await companyService.deleteCompany(Number(id));
          message.success("Şirket başarıyla silindi.");
          navigate(all_routes.superAdminCompanies);
        } catch (error: any) {
          handleApiError(error,t);
        }
      },
    });
  };

  const getStatusBadge = (status: CompanyStatus) => {
    switch (status) {
      case CompanyStatus.ACTIVE:
        return <Badge status="success" text="Aktif" />;
      case CompanyStatus.INACTIVE:
        return <Badge status="default" text="Pasif" />;
      case CompanyStatus.SUSPENDED:
        return <Badge status="error" text="Askıya Alınmış" />;
      default:
        return <Badge status="processing" text="Bilinmiyor" />;
    }
  };

  const usersColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Ad',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Soyad',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    }
  ];

  const branchesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Şube Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Çalışan Sayısı',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      render: (_: any, record: any) => record.users?.length || 0,
    }
  ];

  const departmentsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Departman Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Çalışan Sayısı',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      render: (_: any, record: any) => record.users?.length || 0,
    }
  ];

  if (loading) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spin size="large" />
        <p className="mt-3">Şirket bilgileri yükleniyor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Alert
          message="Hata"
          description={error}
          type="error"
          showIcon
          action={
            <Button type="primary" onClick={() => navigate(all_routes.superAdminCompanies)}>
              Şirketlere Dön
            </Button>
          }
        />
      </div>
    );
  }

  if (!company) {
    return (
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-center align-items-center">
        <Empty
          description="Şirket bulunamadı"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
        <Button
          type="primary"
          onClick={() => navigate(all_routes.superAdminCompanies)}
          style={{ marginTop: 16 }}
        >
          Şirketlere Dön
        </Button>
      </div>
    );
  }

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Şirket Detayları</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminCompanies}>Şirketler</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {company.name}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-2">
              <Link
                to={all_routes.superAdminCompanies}
                className="btn btn-outline-primary me-2"
              >
                <ArrowLeftOutlined /> Geri
              </Link>
              <Link
                to={`${all_routes.superAdminCompanies}/edit/${id}`}
                className="btn btn-primary me-2"
              >
                <EditOutlined /> Düzenle
              </Link>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={handleDelete}
              >
                Sil
              </Button>
            </div>
          </div>
          {/* /Breadcrumb */}

          {/* Şirket Bilgileri Kartı */}
          <Card className="company-details-card mb-4">
            <div className="details-header">
              <div className="d-flex">
                <div className="company-logo d-flex align-items-center justify-content-center bg-light">
                  <BuildOutlined style={{ fontSize: 36 }} />
                </div>
                <div className="company-info">
                  <h2 className="company-name">
                    {company.name} <span>{getStatusBadge(company.status)}</span>
                  </h2>
                  <div className="company-meta">
                    <span>
                      <BankOutlined className="me-1" /> Vergi No: {company.taxNumber}
                    </span>
                    <span className="ms-3">
                      <BankOutlined className="me-1" /> Vergi Dairesi: {company.taxOffice}
                    </span>
                  </div>
                  <div className="company-meta">
                    <span>
                      <CalendarOutlined className="me-1" /> Kayıt Tarihi:{" "}
                      {moment(company.createdAt).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Çalışan Sayısı"
                    value={company.users?.length || 0}
                    prefix={<TeamOutlined className="stats-icon bg-primary-light text-primary" />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Şube Sayısı"
                    value={company.branches?.length || 0}
                    prefix={<HomeOutlined className="stats-icon bg-success-light text-success" />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card className="company-stats-card">
                  <Statistic
                    title="Departman Sayısı"
                    value={company.departments?.length || 0}
                    prefix={<ApartmentOutlined className="stats-icon bg-warning-light text-warning" />}
                  />
                </Card>
              </Col>
            </Row>
          </Card>

          {/* Sekme Sistemi */}
          <Card className="company-details-card">
            <Tabs defaultActiveKey="info">
              <TabPane tab={<span><UserOutlined /> Genel Bilgiler</span>} key="info">
                <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
                  <Descriptions.Item label="Şirket Adı" span={3}>
                    {company.name}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="E-posta">
                    {company.email || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Telefon">
                    {company.phone || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Durum">
                    {getStatusBadge(company.status)}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Adres" span={3}>
                    {company.address || "-"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Vergi No">
                    {company.taxNumber}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Vergi Dairesi">
                    {company.taxOffice}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Varsayılan Mola Süresi">
                    {company.defaultBreakDuration} dakika
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Kayıt Tarihi">
                    {moment(company.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Son Güncelleme">
                    {moment(company.updatedAt).format("DD.MM.YYYY HH:mm")}
                  </Descriptions.Item>
                </Descriptions>
                
                <Divider orientation="left">Şirket Ayarları</Divider>
                
                <Descriptions bordered column={{ xs: 1, sm: 2, md: 3 }} layout="vertical">
                  <Descriptions.Item label="Resmi Tatillerde Çalışma">
                    {company.settings?.workOnHolidays ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Hafta Sonları Çalışma">
                    {company.settings?.workOnWeekends ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Geç Kalma Toleransı">
                    {company.settings?.lateTolerance ? (
                      <Badge status="success" text={`Evet (${company.settings.lateTolerationMinutes} dakika)`} />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="İzin Talepleri için E-posta">
                    {company.settings?.sendLeaveRequestEmails ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="IP Kontrolü">
                    {company.settings?.ipControl ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Yıllık İzin Limiti">
                    {company.settings?.annualLeaveLimit || 20} gün
                  </Descriptions.Item>
                </Descriptions>
                
                <Divider orientation="left">Vardiya Ayarları</Divider>
                
                <Descriptions bordered column={{ xs: 1, sm: 2 }} layout="vertical">
                  <Descriptions.Item label="Vardiya Modu">
                    {company.settings?.shiftMode === "assignment" && "Atama Bazlı"}
                    {company.settings?.shiftMode === "fixed" && "Sabit"}
                    {company.settings?.shiftMode === "both" && "Her İkisi"}
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="Sabit Vardiya Kullanımı">
                    {company.settings?.useFixedShift ? (
                      <Badge status="success" text="Evet" />
                    ) : (
                      <Badge status="default" text="Hayır" />
                    )}
                  </Descriptions.Item>
                  
                  {company.settings?.useFixedShift && (
                    <>
                      <Descriptions.Item label="Sabit Vardiya Günleri" span={2}>
                        {company.settings?.fixedShiftDays?.map((day) => (
                          <Tag key={day} color="blue">
                            {day === "Monday" && "Pazartesi"}
                            {day === "Tuesday" && "Salı"}
                            {day === "Wednesday" && "Çarşamba"}
                            {day === "Thursday" && "Perşembe"}
                            {day === "Friday" && "Cuma"}
                            {day === "Saturday" && "Cumartesi"}
                            {day === "Sunday" && "Pazar"}
                          </Tag>
                        ))}
                      </Descriptions.Item>
                      
                      <Descriptions.Item label="Sabit Vardiya Başlangıç Saati">
                        {company.settings?.fixedShiftStartTime || "-"}
                      </Descriptions.Item>
                      
                      <Descriptions.Item label="Sabit Vardiya Bitiş Saati">
                        {company.settings?.fixedShiftEndTime || "-"}
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
              </TabPane>
              
              <TabPane tab={<span><TeamOutlined /> Çalışanlar ({company.users?.length || 0})</span>} key="users">
                {company.users && company.users.length > 0 ? (
                  <Table
                    dataSource={company.users}
                    columns={usersColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı çalışan bulunmuyor" />
                )}
              </TabPane>
              
              <TabPane tab={<span><HomeOutlined /> Şubeler ({company.branches?.length || 0})</span>} key="branches">
                {company.branches && company.branches.length > 0 ? (
                  <Table
                    dataSource={company.branches}
                    columns={branchesColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı şube bulunmuyor" />
                )}
              </TabPane>
              
              <TabPane tab={<span><ApartmentOutlined /> Departmanlar ({company.departments?.length || 0})</span>} key="departments">
                {company.departments && company.departments.length > 0 ? (
                  <Table
                    dataSource={company.departments}
                    columns={departmentsColumns}
                    rowKey="id"
                    pagination={{
                      defaultPageSize: 10,
                    }}
                  />
                ) : (
                  <Empty description="Bu şirkete kayıtlı departman bulunmuyor" />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CompanyDetails;
