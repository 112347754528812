import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import { LeaveDto } from '../types/leaves.types';
import { User } from '../types/user.types';
import { LEAVE_TYPES, LEAVE_STATUSES } from '../constants/leaveConstants';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { logoDark, logoLight } from './logoBase64';

// Yardımcı formatlama fonksiyonları
const formatLeaveType = (type: string): string => {
  switch (type) {
    case 'sick': return 'Hastalik Izni';
    case 'annual': return 'Yillik Izni';
    case 'unpaid': return 'Ucretsiz Izni';
    case 'special': return 'Ozel Izni';
    case 'hourly': return 'Saatlik Izni';
    case 'medical': return 'Saglik Izni';
    case 'marriage': return 'Evlilik Izni';
    case 'birth': return 'Dogum Izni';
    case 'death': return 'Olum Izni';
    default: return type;
  }
};

const formatLeaveStatus = (status: string): string => {
  switch (status) {
    case 'pending': return 'Beklemede';
    case 'approved': return 'Onaylandi';
    case 'rejected': return 'Reddedildi';
    default: return status;
  }
};

// Leave exports
export const handleExportExcelLeave = (leaves: LeaveDto[]) => {
  // Excel için veri hazırlama
  const excelData = leaves.map((leave) => {
    return {
      'Kullanici': leave.user ? `${leave.user.firstName} ${leave.user.lastName}` : 'Belirsiz',
      'Departman': leave.user?.department?.name || 'Belirsiz',
      'Sube': leave.user?.branch?.name || 'Belirsiz',
      'Izin Turu': formatLeaveType(leave.type),
      'Baslangic Tarihi': dayjs(leave.startDate).format('DD/MM/YYYY'),
      'Bitis Tarihi': dayjs(leave.endDate).format('DD/MM/YYYY'),
      'Saat': leave.startTime && leave.endTime ? `${leave.startTime} - ${leave.endTime}` : '-',
      'Durum': formatLeaveStatus(leave.status),
      'Aciklama': leave.reason || '-',
      'Olusturulma': leave.createdAt ? dayjs(leave.createdAt).format('DD/MM/YYYY HH:mm') : '-',
    };
  });

  // Excel workbook oluşturma
  const workbook = XLSX.utils.book_new();
  
  // Kitap özellikleri ayarlama
  workbook.Props = {
    Title: 'Izin Raporu',
    Subject: 'Izin Verileri',
    Author: 'DINOX Sistem',
    CreatedDate: new Date()
  };

  // Workshet oluşturma ve ekleme
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  
  // Sütun genişliklerini ayarlama
  const wscols = [
    { wch: 20 }, // Kullanıcı
    { wch: 15 }, // Departman
    { wch: 15 }, // Şube
    { wch: 15 }, // İzin Türü
    { wch: 15 }, // Başlangıç Tarihi
    { wch: 15 }, // Bitiş Tarihi
    { wch: 15 }, // Saat
    { wch: 12 }, // Durum
    { wch: 30 }, // Açıklama
    { wch: 20 }, // Oluşturulma
  ];
  
  worksheet['!cols'] = wscols;
  
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Izin Verileri');
  
  // Excel dosyasını kaydetme
  const currentDate = new Date().toLocaleDateString('tr-TR').replace(/\./g, '-');
  XLSX.writeFile(workbook, `Izin_Raporu_${currentDate}.xlsx`);
};

export const handleExportPdfLeave = (leaves: LeaveDto[]) => {
  // A4 boyutunda yatay (landscape) PDF oluştur
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4'
  });
  
  // PDF başlık ve metadata ayarları - Türkçe karakter desteği
  doc.setLanguage("tr");
  doc.setProperties({
    title: "Izin Listesi Raporu",
    subject: "Izin Yönetim Sistemi",
    author: "DINOX HR",
    keywords: "izin, rapor, insan kaynakları",
    creator: "DINOX HR Sistemi"
  });
  
  // ===== HEADER (ÜST BİLGİ) =====
  // Modern gradient header arka planı
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  
  // Gradient header arka planı
  const gradientColors = {
    start: [41, 128, 185], // Mavi başlangıç rengi (#2980b9)
    end: [52, 152, 219]    // Daha açık mavi bitiş rengi (#3498db)
  };
  
  // Header arka plan gradient
  for (let i = 0; i < 25; i++) {
    const factor = i / 25;
    const r = Math.floor(gradientColors.start[0] + (gradientColors.end[0] - gradientColors.start[0]) * factor);
    const g = Math.floor(gradientColors.start[1] + (gradientColors.end[1] - gradientColors.start[1]) * factor);
    const b = Math.floor(gradientColors.start[2] + (gradientColors.end[2] - gradientColors.start[2]) * factor);
    
    doc.setFillColor(r, g, b);
    doc.rect(0, i, width, 1, 'F');
  }
  
  // Logo bölümü için koyu arka plan
  doc.setFillColor(25, 80, 120); // Koyu mavi
  doc.roundedRect(10, 5, 45, 15, 3, 3, 'F');
  
  // Logo yerine şirket adı (logo eklenebilir)
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor(255, 255, 255);
  doc.text("Dinox HR", 18, 15);
  
  // Rapor başlığı
  doc.setFont("helvetica", "bold");
  doc.setFontSize(20);
  doc.setTextColor(255, 255, 255);
  doc.text("IZIN Listesi Raporu", 70, 15);
  
  // Tarih bilgisi
  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.setTextColor(255, 255, 255);
  const dateText = `Olusturulma Tarihi: ${dayjs().format('DD MMMM YYYY')}`;
  doc.text(dateText, width - 15 - doc.getTextWidth(dateText), 15);
  
  // Header ve içerik arasına ayırıcı çizgi
  doc.setDrawColor(255, 255, 255);
  doc.setLineWidth(0.5);
  // doc.line(10, 25, width - 10, 25);
  
  // ===== İSTATİSTİK BÖLÜMÜ =====
  // İstatistik kutusu
  // doc.setFillColor(245, 245, 245); // Açık gri
  //doc.roundedRect(10, 30, width - 20, 30, 2, 2, 'F');
  
  // // İstatistik başlığı
  // doc.setFont("helvetica", "bold");
  // doc.setFontSize(12);
  // doc.setTextColor(80, 80, 80);
  // doc.text("IZIN ISTATISTIKLERI", 20, 40);
  
  // İstatistik verileri
  const pendingCount = leaves.filter(leave => leave.status === 'pending').length;
  const approvedCount = leaves.filter(leave => leave.status === 'approved').length;
  const rejectedCount = leaves.filter(leave => leave.status === 'rejected').length;
  
  // İstatistik kutuları
  const boxWidth = 50;
  const boxMargin = 10;
  const startX = 10; // Soldan başlayacak şekilde
  const startY = 43; // İstatistik başlığının altına
  
  // Toplam izin kutusu
  doc.setFillColor(52, 152, 219); // Mavi
  doc.roundedRect(startX, startY, boxWidth, 14, 2, 2, 'F');
  doc.setTextColor(255, 255, 255);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.text(`Toplam: ${leaves.length}`, startX + 10, startY + 9);
  
  // Bekleyen izin kutusu
  doc.setFillColor(243, 156, 18); // Turuncu
  doc.roundedRect(startX + boxWidth + boxMargin, startY, boxWidth, 14, 2, 2, 'F');
  doc.text(`Bekleyen: ${pendingCount}`, startX + boxWidth + boxMargin + 10, startY + 9);
  
  // Onaylanan izin kutusu
  doc.setFillColor(46, 204, 113); // Yeşil
  doc.roundedRect(startX + 2 * (boxWidth + boxMargin), startY, boxWidth, 14, 2, 2, 'F');
  doc.text(`Onaylanan: ${approvedCount}`, startX + 2 * (boxWidth + boxMargin) + 5, startY + 9);
  
  // Reddedilen izin kutusu
  doc.setFillColor(231, 76, 60); // Kırmızı
  doc.roundedRect(startX + 3 * (boxWidth + boxMargin), startY, boxWidth, 14, 2, 2, 'F');
  doc.text(`Reddedilen: ${rejectedCount}`, startX + 3 * (boxWidth + boxMargin) + 5, startY + 9);
  
  // ===== TABLO BÖLÜMÜ =====
  // Tablo sütun başlıkları - Türkçe karakter desteği ile
  const tableColumn = [
    ['Kullanici', 'Departman', 'Izin Turu', 'Baslangic', 'Bitis', 'Saat', 'Durum', 'Aciklama']
  ];
  
  // Tablo satırları
  const tableRows = leaves.map(leave => [
    leave.user ? `${leave.user.firstName} ${leave.user.lastName}` : '-',
    leave.user?.department?.name || '-',
    formatLeaveType(leave.type),
    dayjs(leave.startDate).format('DD.MM.YYYY'),
    dayjs(leave.endDate).format('DD.MM.YYYY'),
    leave.startTime && leave.endTime ? `${leave.startTime} - ${leave.endTime}` : '-',
    formatLeaveStatus(leave.status),
    leave.reason ? (leave.reason.length > 40 ? leave.reason.substring(0, 40) + '...' : leave.reason) : '-'
  ]);
  
  // Gelişmiş tablo oluşturma
  autoTable(doc, {
    head: tableColumn,
    body: tableRows,
    startY: 60, // İstatistikler sonrası başlangıç (Y değeri artırıldı)
    theme: 'grid', // Izgaralı tema
    styles: {
      font: "helvetica",
      fontSize: 10,
      cellPadding: 6,
      lineColor: [220, 220, 220],
      lineWidth: 0.1,
      textColor: [50, 50, 50],
      valign: 'middle'
    },
    headStyles: {
      fillColor: [41, 128, 185], // Mavi başlık
      textColor: 255,
      fontSize: 11,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
      minCellHeight: 14
    },
    columnStyles: {
      0: { cellWidth: 35 },  // Kullanıcı
      1: { cellWidth: 28 },  // Departman
      2: { cellWidth: 30 },  // İzin Türü
      3: { cellWidth: 22, halign: 'center' }, // Başlangıç
      4: { cellWidth: 22, halign: 'center' }, // Bitiş
      5: { cellWidth: 25, halign: 'center' }, // Saat
      6: { cellWidth: 25, halign: 'center' }, // Durum
      7: { cellWidth: 50 }   // Açıklama
    },
    alternateRowStyles: {
      fillColor: [245, 250, 254] // Açık mavi alternatif satır
    },
    // Her sayfada header ve footer
    didDrawPage: (data) => {
      // Sayfa bilgisi
      const pageCount = doc.getNumberOfPages();
      const currentPage = data.pageNumber;
      
      // Sonraki sayfalarda (ilk sayfa hariç) header tekrarı
      if (currentPage > 1) {
        // Header arka plan gradient
        for (let i = 0; i < 25; i++) {
          const factor = i / 25;
          const r = Math.floor(gradientColors.start[0] + (gradientColors.end[0] - gradientColors.start[0]) * factor);
          const g = Math.floor(gradientColors.start[1] + (gradientColors.end[1] - gradientColors.start[1]) * factor);
          const b = Math.floor(gradientColors.start[2] + (gradientColors.end[2] - gradientColors.start[2]) * factor);
          
          doc.setFillColor(r, g, b);
          doc.rect(0, i, width, 1, 'F');
        }
        
        // Logo bölümü
        doc.setFillColor(25, 80, 120);
        doc.roundedRect(10, 5, 45, 15, 3, 3, 'F');
        
        // Logo metni
        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.setTextColor(255, 255, 255);
        doc.text("DINOX HR", 18, 15);
        
        // Başlık metni
        doc.setFontSize(18);
        doc.text("IZIN LISTESI RAPORU", 70, 15);
        
        // Header çizgisi
        doc.setDrawColor(255, 255, 255);
        doc.setLineWidth(0.5);
        // doc.line(10, 25, width - 10, 25);
      }
      
      // Footer arka planı
      doc.setFillColor(245, 245, 245);
      doc.rect(0, height - 15, width, 15, 'F');
      
      // Footer üst çizgisi
      doc.setDrawColor(200, 200, 200);
      doc.setLineWidth(0.5);
      doc.line(0, height - 15, width, height - 15);
      
      // Sayfa bilgisi
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.setTextColor(100, 100, 100);
      doc.text(`Sayfa ${currentPage} / ${pageCount}`, width - 25, height - 5);
      
      // Telif hakkı
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.setTextColor(100, 100, 100);
      doc.text('© Dinox Insan Kaynaklari Yonetim Sistemi', 15, height - 5);
    },
    margin: { top: 55, bottom: 20, left: 10, right: 10 }
  });
  
  // PDF'i kaydet
  const currentDate = new Date().toLocaleDateString('tr-TR').replace(/\./g, '-');
  doc.save(`Izin_Raporu_${currentDate}.pdf`);
};

// Employee exports
export const handleEmployeeExportExcel = (employees: User[]) => {
  const exportData = employees.map(employee => ({
    'ID': employee.id,
    'Ad Soyad': `${employee.firstName} ${employee.lastName}`,
    'Email': employee.email,
    'Telefon': employee.phone || '-',
    'Departman': employee.department?.name || '-',
    'Sube': employee.branch?.name || '-',
    'Rol': employee.role,
    //'Durum': employee.isActive ? 'Aktif' : 'Pasif',
    'Ise Baslama': employee.hireDate ? dayjs(employee.hireDate).format('DD.MM.YYYY') : '-'
  }));

  const wb = utils.book_new();
  const ws = utils.json_to_sheet(exportData);
  utils.book_append_sheet(wb, ws, 'Calisanlar');
  writeFile(wb, `Calisan_Listesi_${dayjs().format('DD.MM.YYYY')}.xlsx`);
};

export const handleEmployeeExportPdf = (employees: User[]) => {
  // A4 boyutunda yatay (landscape) PDF oluştur
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4'
  });
  
  // Başlık
  doc.setFontSize(22);
  doc.setTextColor(41, 128, 185);
  // Türkçe karakterler için encoding kullan
  doc.text('CALISAN LISTESI', 20, 20);
  
  // Çizgi ekle
  doc.setDrawColor(41, 128, 185);
  doc.setLineWidth(0.5);
  doc.line(20, 25, 280, 25);
  
  // Tarih ve çalışan sayısı
  doc.setFontSize(11);
  doc.setTextColor(80, 80, 80);
  doc.text(`Olusturulma Tarihi: ${dayjs().format('DD.MM.YYYY')}`, 20, 35);
  doc.text(`Toplam Calisan: ${employees.length}`, 20, 42);
  
  // Tablo sütunları - Türkçe karakter içermeyen başlıklar
  const tableColumn = [
    ['ID', 'Ad Soyad', 'Email', 'Telefon', 'Departman', 'Sube', 'Rol', 'Ise Baslama']
  ];
  
  // Tablo satırları
  const tableRows = employees.map(employee => [
    employee.id,
    `${employee.firstName} ${employee.lastName}`,
    employee.email,
    employee.phone || '-',
    employee.department?.name || '-',
    employee.branch?.name || '-',
    employee.role,
    employee.hireDate ? dayjs(employee.hireDate).format('DD.MM.YYYY') : '-'
  ]);

  // Gelişmiş tablo
  autoTable(doc, {
    head: tableColumn,
    body: tableRows,
    startY: 50,
    theme: 'striped',
    styles: {
      fontSize: 9,
      cellPadding: 4,
      overflow: 'linebreak',
      lineColor: [80, 80, 80],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: [41, 128, 185],
      textColor: 255,
      fontSize: 10,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
      cellPadding: 5,
    },
    alternateRowStyles: {
      fillColor: [240, 245, 255],
    },
    columnStyles: {
      0: { cellWidth: 15, halign: 'center' }, // ID
      1: { cellWidth: 40 }, // Ad Soyad
      2: { cellWidth: 50 }, // Email
      3: { cellWidth: 25, halign: 'center' }, // Telefon
      4: { cellWidth: 30 }, // Departman
      5: { cellWidth: 30 }, // Şube
      6: { cellWidth: 25, halign: 'center' }, // Rol
      7: { cellWidth: 25, halign: 'center' }, // İşe Başlama
    },
    didDrawPage: (data) => {
      // Sayfa numarası
      const pageCount = doc.getNumberOfPages();
      const currentPage = data.pageNumber;
      
      // Altbilgi çizgisi
      doc.setDrawColor(200, 200, 200);
      doc.setLineWidth(0.3);
      doc.line(15, doc.internal.pageSize.height - 15, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 15);
      
      // Sayfa numarası
      doc.setFontSize(9);
      doc.setTextColor(100, 100, 100);
      doc.text(
        `Sayfa ${currentPage} / ${pageCount}`,
        doc.internal.pageSize.width - 25, 
        doc.internal.pageSize.height - 10
      );
      
      // Alt bilgi - Türkçe karakter içermeyen metin
      doc.setFontSize(9);
      doc.setTextColor(100, 100, 100);
      doc.text('© Dinox Insan Kaynaklari Yonetim Sistemi', 15, doc.internal.pageSize.height - 10);
      
      // Üstbilgi (ilk sayfa hariç)
      if (currentPage > 1) {
        doc.setFontSize(12);
        doc.setTextColor(41, 128, 185);
        doc.setDrawColor(200, 200, 200);
        doc.setLineWidth(0.3);
      }
    }
  });

  doc.save(`Calisan_Listesi_${dayjs().format('DD.MM.YYYY')}.pdf`);
};

/**
 * Herhangi bir veri tipi için genel Excel export fonksiyonu
 * @param data Tabloya dönüştürülecek veri dizisi
 * @param title Excel dosyası için başlık (isteğe bağlı)
 * @param columns Tablo sütun başlıkları ve özellikleri (isteğe bağlı)
 */
export const exportToExcel = <T extends Record<string, any>>(
  data: T[], 
  title: string = 'Rapor', 
  columns?: { header: string; property: keyof T; width?: number }[]
) => {
  // Veriyi Excel formatına dönüştür
  let excelData: Record<string, any>[] = [];
  
  // Eğer sütun bilgileri sağlanmışsa, onları kullan
  if (columns && columns.length > 0) {
    excelData = data.map(item => {
      const row: Record<string, any> = {};
      columns.forEach(col => {
        row[col.header] = item[col.property] !== undefined ? item[col.property] : '';
      });
      return row;
    });
  } else {
    // Değilse, veri nesnesinin tüm anahtarlarını otomatik kullan
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      excelData = data.map(item => {
        const row: Record<string, any> = {};
        keys.forEach(key => {
          // İlk harfi büyük yap
          const header = key.charAt(0).toUpperCase() + key.slice(1);
          row[header] = item[key] !== undefined ? item[key] : '';
        });
        return row;
      });
    }
  }
  
  // Excel workbook oluşturma
  const workbook = XLSX.utils.book_new();
  
  // Kitap özellikleri ayarlama
  workbook.Props = {
    Title: title,
    Subject: "DINOX Yönetim Sistemi Raporu",
    Author: "DINOX Sistem",
    CreatedDate: new Date()
  };
  
  // Veri yoksa boş bir satır ekle
  if (excelData.length === 0) {
    excelData = [{ "Bilgi": "Bu rapor için veri bulunamadı." }];
  }
  
  // Worksheet oluşturma ve ekleme
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  
  // Sütun genişliklerini ayarlama
  const wscols: { wch: number }[] = [];
  
  // Eğer sütun bilgileri sağlanmışsa, genişlikleri kullan
  if (columns && columns.length > 0) {
    columns.forEach(col => {
      wscols.push({ wch: col.width || 15 }); // Genişlik belirtilmemişse 15 kullan
    });
  } else if (excelData.length > 0) {
    // İlk satırın tüm anahtarları için genişlik belirle
    Object.keys(excelData[0]).forEach(key => {
      // Ortalama uzunluğa göre genişlik belirle
      const maxLength = Math.max(
        key.length,
        ...excelData.map(row => String(row[key] || '').length)
      );
      wscols.push({ wch: Math.min(Math.max(10, maxLength + 2), 50) }); // En az 10, en fazla 50 genişlik
    });
  }
  
  worksheet['!cols'] = wscols;
  
  // Çalışma sayfası adı için başlığı düzenle (30 karakterle sınırla)
  const sheetName = title.length > 30 ? title.substring(0, 27) + "..." : title;
  
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  
  // Excel dosyasını kaydetme
  const fileName = title.replace(/\s+/g, '_').toLowerCase();
  const currentDate = new Date().toLocaleDateString('tr-TR').replace(/\./g, '-');
  XLSX.writeFile(workbook, `${fileName}_${currentDate}.xlsx`);
};

/**
 * Türkçe karakterleri destekleyen, temiz görünümlü PDF oluşturma fonksiyonu
 * @param data PDF'e aktarılacak veri dizisi
 * @param title PDF başlığı ve dosya adı
 * @param options PDF özelleştirme seçenekleri
 */
export const exportToPdf = <T extends Record<string, any>>(
  data: T[],
  title: string,
  options: {
    columns: Array<{
      header: string; // Sütun başlığı
      property: keyof T; // Veri içindeki özellik adı
      width?: number; // Sütun genişliği (opsiyonel)
    }>;
    description?: string; // PDF açıklaması (opsiyonel)
    headerColor?: string; // Üst bilgi rengi (opsiyonel, varsayılan: '#1e40af')
    accentColor?: string; // Vurgu rengi (opsiyonel, varsayılan: '#3b82f6')
    companyName?: string; // Şirket adı (opsiyonel, varsayılan: 'DINOX')
    companyLogo?: string; // Şirket logosu URL (opsiyonel)
    showDate?: boolean; // Tarih gösterme (opsiyonel, varsayılan: true)
    dateFormat?: string; // Tarih formatı (opsiyonel, varsayılan: 'DD.MM.YYYY')
    showPageNumbers?: boolean; // Sayfa numarası gösterme (opsiyonel, varsayılan: true)
    additionalInfo?: Array<{ // Ek bilgiler/istatistikler (opsiyonel)
      label: string;
      value: string;
    }>;
  }
) => {
  // Varsayılan seçenekleri belirle
  const defaultOptions = {
    headerColor: '#1e40af', // Koyu mavi
    accentColor: '#3b82f6', // Mavi
    companyName: 'DINOX',
    showDate: true,
    dateFormat: 'DD.MM.YYYY',
    showPageNumbers: true
  };

  // Kullanıcı seçeneklerini varsayılanlarla birleştir
  const settings = { ...defaultOptions, ...options };

  // Renkleri RGB'ye dönüştür (jsPDF bu formatta kullanıyor)
  const headerColorRGB = hexToRgb(settings.headerColor);
  const accentColorRGB = hexToRgb(settings.accentColor);

  // A4 boyutunda PDF oluştur
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4'
  });

  // PDF başlık ve metadata ayarları - Türkçe karakter desteği
  doc.setLanguage("tr");
  doc.setProperties({
    title: title,
    subject: "DINOX Yönetim Sistemi Raporu",
    author: settings.companyName,
    keywords: `rapor, ${title.toLowerCase()}, pdf`,
    creator: settings.companyName
  });

  // Sayfa boyutları
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();

  // ===== HEADER (ÜST BİLGİ) =====
  // Header arka planı
  doc.setFillColor(headerColorRGB.r, headerColorRGB.g, headerColorRGB.b);
  doc.rect(0, 0, width, 25, 'F');

  // Logo veya Şirket Adı
  if (settings.companyLogo) {
    try {
      // Logo resmi
      doc.addImage(settings.companyLogo, 'PNG', 10, 5, 40, 15);
    } catch (error) {
      // Logo yüklenemezse şirket adını göster
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.setTextColor(255, 255, 255);
      doc.text(settings.companyName, 20, 15);
    }
  } else {
    // Logo yerine şirket adı
    doc.setFillColor(Math.max(0, headerColorRGB.r - 30), Math.max(0, headerColorRGB.g - 30), Math.max(0, headerColorRGB.b - 30));
    doc.roundedRect(10, 5, 45, 15, 3, 3, 'F');
    
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(255, 255, 255);
    doc.text(settings.companyName, 20, 15);
  }

  // Başlık metni (ortada)
  doc.setFont("helvetica", "bold");
  doc.setFontSize(20);
  doc.setTextColor(255, 255, 255);
  
  // Başlığı sayfanın ortasına yerleştir
  const titleWidth = doc.getTextWidth(title.toUpperCase());
  const titleXPos = (width - titleWidth) / 2;
  doc.text(title.toUpperCase(), titleXPos, 15);

  // Oluşturulma tarihi (sağ üst)
  if (settings.showDate) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255);
    const dateText = `Oluşturulma Tarihi: ${dayjs().format(settings.dateFormat)}`;
    doc.text(dateText, width - 15 - doc.getTextWidth(dateText), 15);
  }

  // ===== AÇIKLAMA BÖLÜMÜ =====
  let currentY = 30; // Header'dan sonraki başlangıç Y pozisyonu

  if (settings.description) {
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.setTextColor(60, 60, 60);
    
    // İki satırlık bir açıklama alanı oluştur
    const splitDescription = doc.splitTextToSize(settings.description, width - 30);
    doc.text(splitDescription, 15, currentY);
    
    // Açıklama satır sayısına göre Y pozisyonunu güncelle
    currentY += splitDescription.length * 6 + 5;
  }

  // ===== EK BİLGİLER BÖLÜMÜ (İSTATİSTİKLER) =====
  if (settings.additionalInfo && settings.additionalInfo.length > 0) {
    // İstatistik kutuları
    const infoCount = settings.additionalInfo.length;
    const boxWidth = Math.min(60, (width - 30) / infoCount - 5);
    const boxHeight = 18;
    const boxMargin = 5;
    let startX = 15;
    
    // İstatistik kutuları için arka plan renkleri
    const colors = [
      { bg: lightenColor(settings.headerColor, 0.8), text: settings.headerColor },
      { bg: lightenColor(settings.headerColor, 0.7), text: settings.headerColor },
      { bg: lightenColor(settings.headerColor, 0.6), text: settings.headerColor },
      { bg: lightenColor(settings.headerColor, 0.5), text: settings.headerColor },
    ];
    
    settings.additionalInfo.forEach((info, index) => {
      const colorIndex = index % colors.length;
      const colorRGB = hexToRgb(colors[colorIndex].bg);
      const textColorRGB = hexToRgb(colors[colorIndex].text);
      
      // İstatistik kutusu
      doc.setFillColor(colorRGB.r, colorRGB.g, colorRGB.b);
      doc.roundedRect(startX, currentY, boxWidth, boxHeight, 2, 2, 'F');
      
      // İstatistik değeri (büyük yazı)
      doc.setFont("helvetica", "bold");
      doc.setFontSize(14);
      doc.setTextColor(textColorRGB.r, textColorRGB.g, textColorRGB.b);
      
      // Değeri kutunun ortasına hizala
      const valueWidth = doc.getTextWidth(info.value);
      doc.text(info.value, startX + (boxWidth - valueWidth) / 2, currentY + 10);
      
      // İstatistik etiketi (küçük yazı)
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.setTextColor(textColorRGB.r, textColorRGB.g, textColorRGB.b);
      
      // Etiketi kutunun altına hizala
      const labelWidth = doc.getTextWidth(info.label);
      doc.text(info.label, startX + (boxWidth - labelWidth) / 2, currentY + 16);
      
      // Sonraki kutu için X pozisyonunu güncelle
      startX += boxWidth + boxMargin;
    });
    
    // Y pozisyonunu güncelle
    currentY += boxHeight + 10;
  }

  // ===== TABLO BÖLÜMÜ =====
  // Tablonun sütun başlıkları
  const tableHeaders = settings.columns.map(col => col.header);
  
  // Tablo verileri
  const tableData = data.map(item => 
    settings.columns.map(col => {
      const value = item[col.property];
      return value !== undefined && value !== null 
        ? String(value) 
        : '-';
    })
  );
  
  // Sütun genişlikleri
  const columnStyles: Record<number, { cellWidth?: number, halign?: 'left' | 'center' | 'right' }> = {};
  settings.columns.forEach((col, index) => {
    if (col.width) {
      columnStyles[index] = { cellWidth: col.width };
    }
  });

  // Tablo başlık rengi
  const tableHeaderColor = headerColorRGB;
  
  // Alternatif satır rengi (hafif renkli)
  const alternateRowColor = hexToRgb(lightenColor(settings.headerColor, 0.95));
  
  // Gelişmiş tablo oluşturma
  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
    startY: currentY,
    theme: 'grid',
    styles: {
      font: "helvetica",
      fontSize: 10,
      cellPadding: 6,
      lineColor: [220, 220, 220],
      lineWidth: 0.1,
      textColor: [50, 50, 50],
      valign: 'middle'
    },
    headStyles: {
      fillColor: [tableHeaderColor.r, tableHeaderColor.g, tableHeaderColor.b],
      textColor: 255,
      fontSize: 11,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
      minCellHeight: 14
    },
    alternateRowStyles: {
      fillColor: [alternateRowColor.r, alternateRowColor.g, alternateRowColor.b]
    },
    columnStyles: columnStyles,
    // Her sayfada header ve footer
    didDrawPage: (data) => {
      // Sayfa bilgisi
      const pageCount = doc.getNumberOfPages();
      const currentPage = data.pageNumber;
      
      // Sonraki sayfalarda (ilk sayfa hariç) header tekrarı
      if (currentPage > 1) {
        // Header
        doc.setFillColor(headerColorRGB.r, headerColorRGB.g, headerColorRGB.b);
        doc.rect(0, 0, width, 25, 'F');
        
        // Logo veya Şirket Adı
        if (settings.companyLogo) {
          try {
            doc.addImage(settings.companyLogo, 'PNG', 10, 5, 40, 15);
          } catch {
            doc.setFont("helvetica", "bold");
            doc.setFontSize(16);
            doc.setTextColor(255, 255, 255);
            doc.text(settings.companyName, 20, 15);
          }
        } else {
          doc.setFillColor(Math.max(0, headerColorRGB.r - 30), Math.max(0, headerColorRGB.g - 30), Math.max(0, headerColorRGB.b - 30));
          doc.roundedRect(10, 5, 45, 15, 3, 3, 'F');
          
          doc.setFont("helvetica", "bold");
          doc.setFontSize(16);
          doc.setTextColor(255, 255, 255);
          doc.text(settings.companyName, 20, 15);
        }
        
        // Başlık
        doc.setFontSize(18);
        doc.text(title.toUpperCase(), titleXPos, 15);
        
        // Tarih
        if (settings.showDate) {
          doc.setFont("helvetica", "normal");
          doc.setFontSize(10);
          doc.setTextColor(255, 255, 255);
          const dateText = `Olusturulma Tarihi: ${dayjs().format(settings.dateFormat)}`;
          doc.text(dateText, width - 15 - doc.getTextWidth(dateText), 15);
        }
      }
      
      if (settings.showPageNumbers) {
        // Footer arka planı
        doc.setFillColor(245, 245, 245);
        doc.rect(0, height - 15, width, 15, 'F');
        
        // Footer üst çizgisi
        doc.setDrawColor(200, 200, 200);
        doc.setLineWidth(0.5);
        doc.line(0, height - 15, width, height - 15);
        
        // Sayfa numarası
        doc.setFont("helvetica", "normal");
        doc.setFontSize(9);
        doc.setTextColor(100, 100, 100);
        doc.text(`Sayfa ${currentPage} / ${pageCount}`, width - 25, height - 5);
        
        // Telif hakkı
        doc.setFont("helvetica", "normal");
        doc.setFontSize(9);
        doc.setTextColor(100, 100, 100);
        doc.text('© Dinox Insan Kaynaklari Yonetim Sistemi', 15, height - 5);
      }
    },
    margin: { top: 55, bottom: 20, left: 10, right: 10 }
  });
  
  // PDF'i kaydet
  const currentDate = new Date().toLocaleDateString('tr-TR').replace(/\./g, '-');
  doc.save(`${title.replace(/\s+/g, '_')}_${currentDate}.pdf`);
};

// Yardımcı fonksiyonlar

// Hex renk kodunu RGB'ye çevirme
function hexToRgb(hex: string): { r: number, g: number, b: number } {
  // # işaretini kaldır
  hex = hex.replace(/^#/, '');
  
  // Hex kodunu RGB'ye dönüştür
  const bigint = parseInt(hex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255
  };
}

// Rengi açma/koyulaştırma
function lightenColor(color: string, amount: number): string {
  const rgb = hexToRgb(color);
  const lighten = (value: number) => Math.min(255, Math.round(value + (255 - value) * amount));
  
  const r = lighten(rgb.r);
  const g = lighten(rgb.g);
  const b = lighten(rgb.b);
  
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

/**
 * Genel kullanım için gelişmiş PDF oluşturma fonksiyonu.
 * Her türlü veri için, Türkçe karakter desteği ve modern tasarım ile PDF oluşturur.
 * 
 * @param data Tabloya dönüştürülecek veri dizisi
 * @param options PDF özelleştirme seçenekleri
 * @returns void - PDF otomatik olarak indirilir
 */
export const createPdf = <T extends Record<string, any>>(
  data: T[],
  options: {
    title: string; // PDF başlığı (zorunlu)
    subtitle?: string; // PDF alt başlığı (opsiyonel)
    filename?: string; // Dosya adı (opsiyonel, verilmezse title kullanılır)
    
    // Genel PDF Ayarları
    companyName?: string; // Şirket adı
    companyLogo?: string; // Şirket logosu URL'si
    orientation?: 'portrait' | 'landscape'; // Yatay/Dikey (varsayılan: landscape)
    headerColor?: string; // Üst bilgi rengi
    accentColor?: string; // Vurgu rengi
    textColor?: string; // Ana metin rengi
    dateFormat?: string; // Tarih formatı
    includeDate?: boolean; // Tarih gösterimi (varsayılan: true)
    
    // İçerik Ayarları
    description?: string; // Açıklama metni
    
    // Tablo Ayarları
    columns: Array<{
      header: string; // Sütun başlığı
      property: keyof T | ((item: T) => string); // Özellik adı veya değer fonksiyonu
      width?: number; // Sütun genişliği
      align?: 'left' | 'center' | 'right'; // Hizalama
    }>;
    
    // İstatistik Kutuları - artık header altında değil, tamamen opsiyonel
    showStatistics?: boolean; // İstatistik kutularını göster/gizle (varsayılan: false)
    statistics?: Array<{
      label: string; // İstatistik etiketi
      value: string | number; // İstatistik değeri
      color?: string; // Kutu rengi (verilmezse varsayılan renkler kullanılır)
    }>;
    
    // Altbilgi Ayarları
    footer?: {
      text?: string; // Altbilgi metni
      showPageNumbers?: boolean; // Sayfa numaraları (varsayılan: true)
    };
  }
) => {
  // Varsayılan değerler
  const defaults = {
    companyName: 'DINOX',
    orientation: 'landscape',
    companyLogo: logoDark,
    headerColor: '#2c3e50', // Koyu lacivert
    accentColor: '#3498db', // Mavi
    textColor: '#374151', // Koyu gri
    dateFormat: 'DD MMMM YYYY',
    includeDate: true,
    showStatistics: false,
    footer: {
      showPageNumbers: true
    }
  };
  
  // Seçenekleri birleştir
  const settings = { ...defaults, ...options, footer: { ...defaults.footer, ...options.footer } };
  // Sayfa yönlendirmesi
  const orientation = settings.orientation === 'portrait' ? 'p' : 'l';
  
  // PDF dokümanı oluştur - Türkçe karakter desteği için özel ayarlarla
  const doc = new jsPDF({
    orientation: orientation,
    unit: 'pt',
    putOnlyUsedFonts: true,
    compress: true
  });
  
  // Türkçe karakter desteği
  doc.setLanguage("tr");
  
  // Sayfa boyutları
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  
  // Renkler için RGB değerlerini hesapla
  const headerRGB = hexToRgb(settings.headerColor);
  const accentRGB = hexToRgb(settings.accentColor);
  const textRGB = hexToRgb(settings.textColor);
  
  // PDF metaverileri
  doc.setProperties({
    title: settings.title,
    subject: settings.subtitle || settings.title,
    author: settings.companyName,
    keywords: settings.title,
    creator: `${settings.companyName} Yonetim Sistemi`
  });
  
  // ===== HEADER (ÜST BİLGİ) =====
  // Header arka planı
  doc.setFillColor(headerRGB.r, headerRGB.g, headerRGB.b);
  doc.rect(0, 0, width, 60, 'F'); // 60pt yükseklik
  
  // Logo alanı için koyu arka plan
  const darkerHeader = hexToRgb(lightenColor(settings.headerColor, -0.3)); // Daha koyu ton
  doc.setFillColor(darkerHeader.r, darkerHeader.g, darkerHeader.b);
  
  // Logo veya şirket adı
  if (settings.companyLogo) {
    try {
      // Logo yükleme
      doc.addImage(settings.companyLogo, 'PNG', 20, 10, 115, 40);
    } catch (error) {
      // Logo yüklenemezse metin kullan
      doc.setFont("times", "bold");
      doc.setFontSize(18);
      doc.setTextColor(255, 255, 255);
      doc.text("DINOX Personel Yonetim Sistemi", 32, 35);
    }
  } else {
    // Logo yoksa şirket adı
    doc.setFont("times", "bold");
    doc.setFontSize(18);
    doc.setTextColor(255, 255, 255);
    doc.text("DINOX Personel Yonetim Sistemi", 32, 35);
  }
  
  // Başlık metni - ortalanmış
  doc.setFont("times", "bold");
  doc.setFontSize(22);
  doc.setTextColor(255, 255, 255);
  
  // Başlığı merkeze hizala
  const titleText = settings.title.toUpperCase();
  const titleWidth = doc.getTextWidth(titleText);
  doc.text(titleText, (width - titleWidth) / 2, 35);
  
  // Tarih bilgisi - sağa hizalı
  if (settings.includeDate) {
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.setTextColor(255, 255, 255);
    const date = dayjs().format(settings.dateFormat);
    const dateText = `Olusturulma Tarihi: ${date}`;
    doc.text(dateText, width - doc.getTextWidth(dateText) - 30, 35);
  }
  
  // ===== AÇIKLAMA BÖLÜMÜ =====
  let currentY = 60; // Header sonrası başlangıç pozisyonu
  
  if (settings.description) {
    doc.setFillColor(245, 245, 245);
    doc.rect(0, 60, width, 30, 'F');
    
    doc.setFont("times", "normal");
    doc.setFontSize(12);
    doc.setTextColor(80, 80, 80);
    
    // Açıklamayı sayfa genişliğine göre ayarla
    doc.text(settings.description, 30, 80);
    
    // Açıklama yüksekliği
    currentY = 100;
  }
  
  // ===== TABLO BÖLÜMÜ =====
  // Sütun başlıkları
  const tableHeaders = settings.columns.map(column => column.header);
  
  // Tablo verileri
  const tableData = data.map(item => {
    return settings.columns.map(column => {
      // Eğer property bir fonksiyon ise, çağır
      if (typeof column.property === 'function') {
        return column.property(item);
      } else {
        // Değilse, özelliği al
        const value = item[column.property as keyof T];
        return value !== undefined && value !== null ? String(value) : '-';
      }
    });
  });
  
  // Sütun stilleri
  const columnStyles: Record<number, { cellWidth?: number, halign?: 'left' | 'center' | 'right' }> = {};
  
  settings.columns.forEach((column, index) => {
    columnStyles[index] = {
      cellWidth: column.width,
      halign: column.align || 'left'
    };
  });
  
  // Tablo oluştur
  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
    startY: currentY,
    styles: {
      fontSize: 10,
      cellPadding: 6,
      lineColor: [220, 220, 220],
      lineWidth: 0.5,
      font: "times", // Türkçe karakter desteği için times fontu
      textColor: [50, 50, 50]
    },
    headStyles: {
      fillColor: [headerRGB.r, headerRGB.g, headerRGB.b], // Başlık rengi
      textColor: [255, 255, 255],
      fontSize: 11,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
      minCellHeight: 30 // Daha yüksek başlık satırı
    },
    bodyStyles: {
      valign: 'middle'
    },
    columnStyles: columnStyles,
    alternateRowStyles: {
      fillColor: [240, 245, 250] // Açık mavi - daha iyi okunabilirlik
    },
    // Her sayfa için üstbilgi ve altbilgi
    didDrawPage: (data) => {
      // Sonraki sayfalar için header (ilk sayfa hariç)
      if (data.pageNumber > 1) {
        // Header arka planı
        doc.setFillColor(headerRGB.r, headerRGB.g, headerRGB.b);
        doc.rect(0, 0, width, 40, 'F'); // Daha kısa header (sonraki sayfalarda)
        
        // Logo metni
        doc.setFont("times", "bold");
        doc.setFontSize(16);
        doc.setTextColor(255, 255, 255);
        doc.addImage(settings.companyLogo, 'PNG', 20, 2, 115, 40);
        
        // Başlık metni
        doc.setFontSize(18);
        
        // Başlığı merkeze hizala
        const titleWidth = doc.getTextWidth(settings.title.toUpperCase());
        doc.text(settings.title.toUpperCase(), (width - titleWidth) / 2, 25);
      }
      
      // Footer
      doc.setFillColor(240, 240, 240);
      doc.rect(0, height - 35, width, 35, 'F');
      
      // Sayfa numarası
      doc.setFont("times", "normal");
      doc.setFontSize(10);
      doc.setTextColor(80, 80, 80);
      
      if (settings.footer?.showPageNumbers) {
        doc.text(
          `Sayfa ${data.pageNumber} / ${doc.getNumberOfPages()}`,
          width - 80,
          height - 15
        );
      }
      
      // Footer bilgisi
      doc.setFontSize(10);
      const footerText = settings.footer?.text || `© ${new Date().getFullYear()} DINOX Personel Yonetim Sistemi`;
      doc.text(footerText, 30, height - 15);
    },
    margin: { top: currentY, bottom: 40 }
  });
  
  // Dosya adını belirle
  const filename = settings.filename || 
                   `${settings.title.replace(/\s+/g, '_')}_${dayjs().format('YYYY-MM-DD')}.pdf`;
  
  // PDF'i kaydet
  doc.save(filename);
};