import { AttendanceReportDto, EmployeeReportDto, LeaveReportDto, ShiftReportDto, TicketReportDto } from "../../types/report.types";
import apiService from "../api/api.service";

class ReportService {
    private static instance: ReportService;
    
    private constructor() {}

    public static getInstance(): ReportService {
        if (!ReportService.instance) {
            ReportService.instance = new ReportService();
        }
        return ReportService.instance;
    }

    public async getEmployeeReport(): Promise<EmployeeReportDto> {
        const response = await apiService.get<EmployeeReportDto>(`/reports/personal-info`);
        return response;
    }

    public async getLeaveReport(startDate: string, endDate: string): Promise<LeaveReportDto> {
        const response = await apiService.get<LeaveReportDto>(`/reports/leave?startDate=${startDate}&endDate=${endDate}`);
        return response;
    }

    public async getShiftReport(startDate: string, endDate: string): Promise<ShiftReportDto> {
        const response = await apiService.get<ShiftReportDto>(`/reports/shift-analysis?startDate=${startDate}&endDate=${endDate}`);
        return response;
    }

    public async getTicketReport(startDate: string, endDate: string): Promise<TicketReportDto> {
        const response = await apiService.get<TicketReportDto>(`/reports/tickets?startDate=${startDate}&endDate=${endDate}`);
        return response;
    }

    public async getAttendanceReport(startDate: string, endDate: string): Promise<AttendanceReportDto> {
        const response = await apiService.get<AttendanceReportDto>(`/reports/attendance?startDate=${startDate}&endDate=${endDate}`);
        return response;
    }
}

export default ReportService.getInstance();