import {io,Socket} from "socket.io-client";

class SocketService {
    private static instance: SocketService;
    private socket: Socket | null = null;
     
    private constructor() {}

    public static getInstance(): SocketService {
        if(!SocketService.instance) {
            SocketService.instance = new SocketService();
        }
        return SocketService.instance;
    }

    public initializeSocket(token: string):Socket {
        const url = process.env.REACT_APP_SOCKET_URL;
        if(!this.socket) {
            this.socket = io(url!, {
                auth: {
                    token
                },
                transports: ["websocket"],
                autoConnect: true,
            });
            this.socket.on("connect", ()=>{
                console.log("Connected to server");
            });
            this.socket.on("newActivity", (data:any)=>{
                console.log("New activity", data);
            });
            this.socket.on("disconnect", ()=>{
                console.log("Disconnected from server");
            });
            this.socket.on("error", (error)=>{
                console.error("Socket error", error);
            });
        }
        return this.socket;
    }

    public getSocket():Socket | null {
        return this.socket;
    }

    public disconnect():void {
        if(this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    public on(event: string, callback: Function):void {
        if(this.socket) {
            this.socket.on(event, callback as any);
        }
    }
    
    public emit(event: string, data?: any):void {
        if(this.socket) {
            this.socket.emit(event, data);
        }
    }

    public joinRoom(roomId: string):void {
        if(this.socket) {
            this.socket.emit("join-room", roomId);
        }
    }

    public leaveRoom(roomId: string):void {
        if(this.socket) {
            this.socket.emit("leave-room", roomId);
        }
    }
    
    
        
}

export default SocketService.getInstance();