import React from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

interface ContactInfoStepProps {
  form: FormInstance;
}

const ContactInfoStep: React.FC<ContactInfoStepProps> = ({ form }) => {
  const { t } = useTranslation();
  // const renderContactInfoTab = () => {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.city')}</label>
            <Form.Item
              name="city"
              rules={[{ required: true, message: t('employees.validationErrors.city') }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.email')}</label>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('employees.validationErrors.email') },
                { type: 'email', message: t('employees.validationErrors.email') }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.phone')}</label>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: t('employees.validationErrors.phone') }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">{t('employees.modals.emergencyPhone')}</label>
            <Form.Item
              name="emergencyContact"
              rules={[{ required: true, message: t('employees.validationErrors.emergencyPhone') }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };
// };

export default ContactInfoStep; 