import { TicketCreateDto, TicketDto, TicketResponse } from "../../types/ticket.types";
import apiService from "../api/api.service";

class TicketService {
    private static instance: TicketService;

    private constructor() {}

    public static getInstance(): TicketService {
        if (!TicketService.instance) {
            TicketService.instance = new TicketService();
        }
        return TicketService.instance;
    }
    
    public async getTicketsByUser(userId:number): Promise<TicketDto[]> {
        const response = await apiService.get<TicketDto[]>(`/ticket/${userId}`);
        return response;
    }

    public async getAllTickets(companyId: number): Promise<TicketResponse> {
        const response = await apiService.get<TicketResponse>(`/ticket/company/${companyId}`);
        return response;
    }

    public async getTicketById(id: number): Promise<TicketDto> {
        const response = await apiService.get<TicketDto>(`/ticket/${id}`);
        return response;
    }
    
    public async createTicket(ticket: TicketCreateDto): Promise<TicketDto> {
        const response = await apiService.post<TicketDto>("/ticket", ticket);
        return response;
    }

    public async updateTicket(id: number, ticket: TicketCreateDto): Promise<TicketDto> {
        const response = await apiService.put<TicketDto>(`/ticket/${id}`, ticket);
        return response;
    }

    public async approveTicket(status: string, id: number): Promise<TicketDto> {
        const response = await apiService.put<TicketDto>(`/ticket/${id}/status`, {status});
        return response;
    }

    public async deleteTicket(id: number): Promise<void> {
        await apiService.delete(`/ticket/${id}`);
    }
}   

export default TicketService.getInstance();