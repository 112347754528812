import React, { useEffect, useRef, useState } from 'react';

interface CircleProgressProps {
  value: number;
  type?: 'attendance' | 'break';
  totalHours?: string;
  currentTime?: string;
  remainingTime?: string;
  totalBreakTime?: string;
}

const CircleProgress: React.FC<CircleProgressProps> = ({ 
  value, 
  type = 'attendance',
  totalHours = '08:00:00', // Varsayılan çalışma süresi 8 saat
  currentTime = '00:00:00',
  remainingTime = '--:--',
  totalBreakTime = '00:00'
}) => {
  const [Class, setClass] = useState<any>('');
  const circleRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLSpanElement>(null);
  const rightRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (circleRef.current && leftRef.current && rightRef.current) {
      const percentageToDegrees = (percentage: number): number => (percentage / 100) * 360;

      if (value > 0) {
        if (value <= 50) {
          rightRef.current.style.transform = `rotate(${percentageToDegrees(value)}deg)`;
        } else {
          rightRef.current.style.transform = 'rotate(180deg)';
          leftRef.current.style.transform = `rotate(${percentageToDegrees(value - 50)}deg)`;
        }
      }
    }
    if (type === 'attendance') {
      if (value >= 80) {
        setClass('border-success');
      } else if (value >= 50) {
        setClass('border-info');
      } else if (value >= 25) {
        setClass('border-warning');
      } else {
        setClass('border-danger');
      }
    } else {
      // Mola için farklı renk şeması
      if (value >= 75) {
        setClass('border-danger');
      } else if (value >= 50) {
        setClass('border-warning');
      } else if (value >= 25) {
        setClass('border-info');
      } else {
        setClass('border-success');
      }
    }
  }, [value, type]);


  return (
    <>
    <div
        className={`attendance-circle-progress attendance-progress mx-auto mb-3`}
        data-value={value} 
        ref={circleRef}
      >
        <span className="progress-left">
          <span className={`progress-bar ${Class}`} ref={leftRef}/>
        </span>
        <span className="progress-right">
          <span className={`progress-bar ${Class}`} ref={rightRef}/>
        </span>
        <div className="total-work-hours text-center w-100">
        {type === 'attendance' ? (
          <>
            <span className="fs-13 d-block mb-1">Çalışma Süresi</span>
            <h6>{currentTime}</h6>
            <span className="fs-12 text-muted">Hedef: {totalHours}</span>
          </>
        ) : (
          <>
            <span className="fs-13 d-block mb-1">Mola Süresi</span>
            <h6>{currentTime}</h6>
            <span className="fs-12 d-block text-muted">Toplam: {totalBreakTime}</span>
          </>
        )}
        </div>
      </div>
    </>
  );
};

export default CircleProgress;
