import { useEffect,useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../store/store"
import socketService from "../services/socket/socket.service"
import { setConnected,addNotification } from "../store/slices/socket.slice"
export const useSocket = () =>{
    const dispatch = useDispatch();
    const {token,isAuthenticated} = useSelector((state:RootState) => state.auth);
    const {connected} = useSelector((state:RootState) => state.socket);

    useEffect(()=>{
        if(isAuthenticated && token){
            const socket = socketService.initializeSocket(token);

            socket.on('connect',()=>{
                dispatch(setConnected(true));
            });
            socket.on('disconnect',()=>{
                dispatch(setConnected(false));
            });

            return ()=>{
                socketService.disconnect();
            }
        }
    },[isAuthenticated,token,dispatch]);

    return {
        connected,
        emit:socketService.emit.bind(socketService),
        on:socketService.on.bind(socketService),
        joinRoom:socketService.joinRoom.bind(socketService),
        leaveRoom:socketService.leaveRoom.bind(socketService),
    };
}