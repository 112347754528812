import React, { useEffect, useState } from 'react'
import { all_routes } from '../../../router/all_routes'
import { Link, useSearchParams } from 'react-router-dom'
import { User, UserCreateDTO, UserRole, UserStatus, UserUpdateDTO } from '../../../types/user.types';
import dayjs from 'dayjs';

import { useEmployee } from '../../../hooks/useEmployee';
import { useUsers } from '../../../hooks/useUsers';
import EmployeeTable from './components/EmployeeTable';
import EmployeeFilters from './components/EmployeeFilters';
import EmployeeForm from './components/EmployeeForm';
import { handleEmployeeExportExcel, handleEmployeeExportPdf, exportToPdf, createPdf } from '../../../utils/exportUtils';
import LoadingSpinner from '../../shared/uiInterface/base-ui/LoadingSpinner';
import PersonalInfoStep from './components/form-steps/PersonalInfoStep';
import ContactInfoStep from './components/form-steps/ContactInfoStep';
import WorkInfoStep from './components/form-steps/WorkInfoStep';
import SecurityStep from './components/form-steps/SecurityStep';
import { Form, Modal, message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import CompanyService from '../../../services/company/company.service';
import { AnnualLeaveLimitResponse } from '../../../types/company.types';
import { Trans, useTranslation } from 'react-i18next';
import companyService from '../../../services/company/company.service';
import { handleApiError } from '../../../utils/errorHandler';
import FooterSection from '../../shared/components/footerSection';
type PasswordField = "password" | "confirmPassword";

const EmployeeList: React.FC = () => {
  const {t} = useTranslation();
  const {
    users,
    departments,
    shops,
    loading,
    error,
    selectedDepartment,
    selectedShop,
    selectedStatus,
    handleDepartmentChange,
    handleShopChange,
    handleStatusChange,
    resetFilters,
    fetchUsers,
    fetchDepartmentsAndShops,
    createEmployee,
    updateEmployee,
    deleteEmployee,
    updateEmployeeStatus
  } = useEmployee();
  const [searchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [activeTab, setActiveTab] = useState<'personal' | 'contact' | 'work' | 'security'>('personal');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [annualLimit, setAnnualLimit] = useState<AnnualLeaveLimitResponse | null>(null);
  const {companyId} = useSelector((state: RootState) => state.auth);
  // Form instance'ı oluştur
  const [form] = Form.useForm();
  const [initialFiltersApplied, setInitialFiltersApplied] = useState(false);

  
  
  useEffect(() => {
    const fetchAnnualLimit = async () => {
      if (companyId) {
        const response = await companyService.getAnnualLimit(Number(companyId));
        setAnnualLimit(response);
      }
    };
    fetchAnnualLimit();
  }, [companyId]);
  
  // Component mount olduğunda verileri çek
  const fetchData = async () => {
    await Promise.all([
      fetchUsers(),
      fetchDepartmentsAndShops()
    ]);
  };

  // İlk veri yüklemesi
  useEffect(() => {
    fetchData();
  }, []);

  // URL parametrelerinden gelen departman veya şube ID'sine göre filtreleme yap
  useEffect(() => {
    const departmentParam = searchParams.get('department');
    const branchParam = searchParams.get('branch');
    
    // Veriler yüklenmişse ve henüz ilk filtreleme uygulanmamışsa
    if (departments.length > 0 && shops.length > 0 && !loading && !initialFiltersApplied) {
      console.log("URL parametrelerinden filtreleme yapılıyor:", { departmentParam, branchParam });
      
      // İlk olarak, filtreleri varsayılan değerlere sıfırla
      resetFilters();
      
      let filtersChanged = false;
      
      // Önce şube parametresini kontrol et ve uygula
      if (branchParam && shops.some(shop => shop.id.toString() === branchParam)) {
        setTimeout(() => {
          handleShopChange(branchParam);
        }, 0);
        filtersChanged = true;
      }
      
      // Sonra departman parametresini kontrol et ve uygula
      if (departmentParam && departments.some(dept => dept.id.toString() === departmentParam)) {
        setTimeout(() => {
          handleDepartmentChange(departmentParam);
        }, 0);
        filtersChanged = true;
      }
      
      // Filtrelerin uygulandığını işaretle (tekrar tekrar uygulanmasını önlemek için)
      if (filtersChanged || (!departmentParam && !branchParam)) {
        setInitialFiltersApplied(true);
      }
    }
  }, [departments, shops, loading, searchParams, handleDepartmentChange, handleShopChange, resetFilters, initialFiltersApplied]);
  
  const showModal = () => {
    resetForm();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
  };
  const handleStatusChangeTable = async (id: number, status: UserStatus) => {
    try {
      // userService'in updateUserStatus metodunu kullan
      await updateEmployeeStatus(id, status);
      
      // Başarılı olursa kullanıcı listesini güncelle
      await fetchUsers();
      
      // UX için bildirim gösteriliyor (EmployeeTable içinde de gösteriliyor)
    } catch (error: any) {
      handleApiError(error,t);
    }
  };
  
  const handleEdit = (user: User) => {
    
    form.resetFields();
    setSelectedUser(user);
    
    // Form değerlerini doğru şekilde set et
    const formValues = {
      ...user,
      departmentId: user.department?.id, // department ID'sini set et
      branchId: user.branch?.id, // branch ID'sini set et
      birthDate: user.birthDate ? dayjs(user.birthDate) : null,
      hireDate: user.hireDate ? dayjs(user.hireDate) : null,
      role: user.role || UserRole.USER,
    };

    form.setFieldsValue(formValues);
    
    setIsModalOpen(true);
  };
  const handleExportPdf = () => {
    // Dışa aktarım için özelleştirilmiş kullanıcı verileri oluştur
    const formattedUsers = users.map(user => ({
      firstName: user.firstName || '-',
      lastName: user.lastName || '-',
      email: user.email || '-',
      department: user.department?.name || '-',
      branch: user.branch?.name || '-',
      phone: user.phone || '-',
      status: user.status === 'active' ? 'Aktif' : 'Pasif',
      role: user.role === 'ADMIN' ? 'Yonetici' : 'Kullanici',
      hireDate: user.hireDate ? dayjs(user.hireDate).format('DD.MM.YYYY') : '-',
      title: user.title || '-'
    }));

    // PDF için sütun tanımları
    const columns = [
      { header: 'Ad', property: 'firstName' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Soyad', property: 'lastName' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'E-posta', property: 'email' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Departman', property: 'department' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Sube', property: 'branch' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Pozisyon', property: 'title' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Telefon', property: 'phone' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Ise Baslama', property: 'hireDate' as keyof typeof formattedUsers[0], width: 80 },
      { header: 'Durum', property: 'status' as keyof typeof formattedUsers[0], width: 60 },
      { header: 'Rol', property: 'role' as keyof typeof formattedUsers[0], width: 60 }
    ];

    // İstatistikler
    const statistics = [
      { label: 'Toplam Calisan', value: users.length, color: '#3b82f6' }, // Mavi
      { label: 'Aktif Calisan', value: users.filter(u => u.status === 'active').length, color: '#10b981' }, // Yeşil
      { label: 'Pasif Calisan', value: users.filter(u => u.status !== 'active').length, color: '#f59e0b' } // Turuncu
    ];
    
    // Açıklama metni
    let description = 'Tum calisanlarin listesi';
    if (selectedDepartment !== 'all' && departments.length > 0) {
      const deptName = departments.find(d => d.id.toString() === selectedDepartment)?.name;
      description += ` - Departman: ${deptName}`;
    }
    if (selectedShop !== 'all' && shops.length > 0) {
      const shopName = shops.find(s => s.id.toString() === selectedShop)?.name;
      description += ` - Sube: ${shopName}`;
    }

    // createPdf fonksiyonunu çağır
    createPdf(
      formattedUsers,
      {
        title: 'Calisan Listesi',
        companyName: 'DINOX HR',
        headerColor: '#2c3e50', // Koyu lacivert
        accentColor: '#3498db', // Mavi
        description: description,
        columns: columns,
        showStatistics: true,
        statistics: statistics,
        footer: {
          text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
          showPageNumbers: true
        }
      }
    );
  };
  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      const formData: UserCreateDTO | UserUpdateDTO = {
        ...values,
        department: values.departmentId,
        branch: values.branchId,
        birthDate: values.birthDate ? dayjs(values.birthDate).format('YYYY-MM-DD') : undefined,
        hireDate: values.hireDate ? dayjs(values.hireDate).format('YYYY-MM-DD') : new Date().toISOString(),
        role: UserRole.USER,
      };

      const createData: UserCreateDTO = {
        ...formData,
        password: values.password,
      };
      
      if (selectedUser) {
        await updateEmployee(selectedUser.id, formData);
      } else {
        await createEmployee(createData);
      }
      setIsModalOpen(false);
      resetForm();
      await fetchUsers();
    } catch (error: any) {
      handleApiError(error,t);
      // Hangi alanların validasyondan geçemediğini görmek için
      if (error instanceof Error && 'errorFields' in error) {
        console.log(t('employees.validationErrors.errors'), error.errorFields);
      }
    }
  };

  const resetForm = () => {
    setSelectedUser(null);
    setActiveTab('personal');
    form.resetFields();
    form.setFieldsValue({
      firstName: '',
      lastName: '',
      birthDate: null,
      nationalId: '',
      gender: undefined,
      maritalStatus: undefined,
      educationLevel: undefined,
      email: '',
      phone: '',
      address: '',
      city: '',
      title: '',
      emergencyContact: '',
      departmentId: undefined,
      branchId: undefined,
      position: '',
      username: '',
      password: '',
    });
  };

  // Modal içeriğini düzenleyelim
  const renderModalContent = () => {
    return (
      <Form 
        form={form} 
        layout="vertical" 
        initialValues={selectedUser || {}}
      >
        <div className="contact-grids-tab">
          <ul className="nav nav-underline" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'personal' ? 'active' : ''}`}
                onClick={() => setActiveTab('personal')}
                type="button"
                role="tab"
              >
                {t('employees.modals.personal')}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`}
                onClick={() => setActiveTab('contact')}
                type="button"
                role="tab"
              >
                {t('employees.modals.contact')}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'work' ? 'active' : ''}`}
                onClick={() => setActiveTab('work')}
                type="button"
              >
                {t('employees.modals.work')}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                onClick={() => setActiveTab('security')}
                type="button"
                role="tab"
              >
                {t('employees.modals.security')}
              </button>
            </li>
          </ul>
        </div>
        <div className="modal-body">
          {/* Tüm form alanlarını her zaman render et, sadece görünürlüğü değiştir */}
          <div style={{ display: activeTab === 'personal' ? 'block' : 'none' }}>
            <PersonalInfoStep form={form} />
          </div>
          <div style={{ display: activeTab === 'contact' ? 'block' : 'none' }}>
            <ContactInfoStep form={form} />
          </div>
          <div style={{ display: activeTab === 'work' ? 'block' : 'none' }}>
            <WorkInfoStep form={form} departments={departments} shops={shops} annualLimit={annualLimit?.annualLeaveLimit || 0} />
          </div>
          <div style={{ display: activeTab === 'security' ? 'block' : 'none' }}>
            <SecurityStep form={form} isEdit={!!selectedUser} />
          </div>
        </div>
      </Form>
    );
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">
                {t('employees.pageTitle')}
                {selectedDepartment !== 'all' && departments.length > 0 && (
                  <> - {departments.find(d => d.id.toString() === selectedDepartment)?.name}</>
                )}
                {selectedShop !== 'all' && shops.length > 0 && (
                  <> - {shops.find(s => s.id.toString() === selectedShop)?.name}</>
                )}
              </h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('employees.breadcrumb.employee')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('employees.breadcrumb.employees')}
                  </li>
                </ol>
              </nav>
            </div>
            
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap ">
              <button
                    className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
                    onClick={showModal}
                  >
                    <i className="ti ti-plus me-1" />
                    {t('employees.addEmployee')}
                  </button>
                  <div className="dropdown me-2">
                  <Link
                    to="#"
                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-file-export me-1" />
                    {t('common.buttons.export')}
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={()=>handleExportPdf()}
                      >
                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                        {t('common.buttons.pdf')}
                      </Link>
                    </li>
                    <li className="mt-1">
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={()=>handleEmployeeExportExcel(users)}
                      >
                        <i className="ti ti-file-type-xls me-2 text-success" />
                        {t('common.buttons.excel')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          
          {/* Filters */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-end flex-wrap row-gap-3">
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
     
              <EmployeeFilters
                departments={departments}
                shops={shops}
                selectedDepartment={selectedDepartment}
                selectedShop={selectedShop}
                selectedStatus={selectedStatus}
                onDepartmentChange={handleDepartmentChange}
                onShopChange={handleShopChange}
                onStatusChange={handleStatusChange}
                onReset={resetFilters}
                />
              </div>
            </div>

          {/* Employee Table */}
        <div className="card-body">
                  {loading ? (
                    <LoadingSpinner size="lg" color="primary" centered />
                  ) : (
                    <EmployeeTable
                      users={users || []}
                      onEdit={handleEdit}
                      onDelete={deleteEmployee}
                      onStatusChange={handleStatusChangeTable}
                    />
                  )}
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error.message}
                    </div>
                  )}
            </div>
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>

      <Modal
        title={
          <div className="d-flex align-items-center">
            <h4 className="modal-title me-2 mb-0">
              {selectedUser ? "Çalışan Düzenle" : "Çalışan Ekle"}
            </h4>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
        footer={[
          <button
            key="cancel"
            type="button"
            className="btn btn-outline-light border me-2"
            onClick={handleCancel}
          >
            İptal Et
          </button>,
          <button
            key="submit"
            type="button"
            onClick={handleFormSubmit}
            className="btn btn-primary"
          >
            Kaydet
          </button>
        ]}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default EmployeeList;
