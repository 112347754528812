import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Store } from "../../../types/shop.types";
import { DepartmentDto } from "../../../types/department.types";
import { useEmployee } from "../../../hooks/useEmployee";
import { CompanyDailyLeaveUsersDto, CompanyDailyStatsDto, CompanyActiveBreakUsersDto, CompanyDailyLateUsersDto } from "../../../types/attendanceDto.types";
import dailyRecordService from "../../../services/dailyRecord/dailyRecord.service";
import userService from "../../../services/user/user.service";
import { BirthdayUserDto, UserBirthdaysResponseDto } from "../../../types/user.types";
import { User } from "../../../types/user.types";
import BreakStatus from "./components/breakStatus";
import ClockInOutStatus from "./components/clockInOutStatus";
import { Avatar, Modal } from 'antd';
import { getFileUrl } from '../../../utils/file.utils';
import { UserOutlined } from '@ant-design/icons';
import LeaveStatus from "./components/leaveStatus";
import TicketStatus from "./components/ticketStatus";
import { Trans, useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { handleApiError } from "../../../utils/errorHandler";
import FooterSection from "../../shared/components/footerSection";
const AdminDashboard = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [empDepartment, setEmpDepartment] = useState<any>({
    chart: {
      height: 235,
      type: "bar",
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: ["#F26522"], // Fill color for the bars
      opacity: 1, // Adjust opacity (1 is fully opaque)
    },
    colors: ["#F26522"],
    grid: {
      borderColor: "#E5E7EB",
      strokeDashArray: 5,
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        barHeight: "35%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        data: [
          
        ],
        name: t("dashboard.departments.employee"),
      },
    ],
    xaxis: {
      categories: [
        
      ],
      labels: {
        style: {
          colors: "#111827",
          fontSize: "13px",
        },
      },
    },
  });

  const [empBranch, setEmpBranch] = useState<any>({
    chart: {
      height: 235,
      type: "bar",
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: ["#4CAF50"],
      opacity: 1,
    },
    colors: ["#4CAF50"],
    grid: {
      borderColor: "#E5E7EB",
      strokeDashArray: 5,
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        barHeight: "35%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        data: [
         
        ],
        name: t("dashboard.branches.employee"),
      },
    ],
    xaxis: {
      categories: [
        
      ],
      labels: {
        style: {
          colors: "#111827",
          fontSize: "13px",
        },
      },
    },
  });

  const routes = all_routes;
  const { user } = useSelector((state: RootState) => state.auth);
  const { departments, shops, fetchDepartmentsAndShops } = useEmployee();
  const { getUsersBirthdays, getUsers } = userService;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [companyDailyStats, setCompanyDailyStats] = useState<CompanyDailyStatsDto | null>(null);
  const { getCompanyDailyStats } = dailyRecordService;
  const [birthdayUsers, setBirthdayUsers] = useState<{
    today: BirthdayUserDto[];
    thisWeek: BirthdayUserDto[];
    thisMonth: BirthdayUserDto[];
  }>({ today: [], thisWeek: [], thisMonth: [] });
  const [companyDailyLeaveUsers, setCompanyDailyLeaveUsers] = useState<CompanyDailyLeaveUsersDto[]>([]);
  const [isLeaveUsersModalVisible, setIsLeaveUsersModalVisible] = useState(false);
  const [isBreakUsersModalVisible, setIsBreakUsersModalVisible] = useState(false);
  const [activeBreakUsers, setActiveBreakUsers] = useState<CompanyActiveBreakUsersDto[]>([]);
  const [companyDailyLateUsers, setCompanyDailyLateUsers] = useState<CompanyDailyLateUsersDto[]>([]);
  const [isLateUsersModalVisible, setIsLateUsersModalVisible] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);
  const [checkedInEmployees, setCheckedInEmployees] = useState<any[]>([]);
  const [isCheckedInEmployeesModalVisible, setIsCheckedInEmployeesModalVisible] = useState(false);
  const handleCompanyDailyLeaveUsers = () => {
    if (user?.companyId) {
      setIsLeaveUsersModalVisible(true);
      dailyRecordService.getCompanyDailyLeaveUsers(user?.companyId)
        .then(setCompanyDailyLeaveUsers)
        .catch(err => handleApiError(err,t));
    }
  }
  
  const handleActiveBreakUsers = () => {
      setIsBreakUsersModalVisible(true);
      dailyRecordService.getCompanyActiveBreakUsers()
        .then(setActiveBreakUsers)
        .catch((err: Error) => handleApiError(err,t));
  }

  const handleCompanyDailyLateUsers = () => {
    setIsLateUsersModalVisible(true);
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD formatında bugünün tarihi
    dailyRecordService.getCompanyDailyLateUsers(today)
      .then(setCompanyDailyLateUsers)
      .catch(err => handleApiError(err,t));
  }

  const handleCheckedInEmployees = () => {
    setIsCheckedInEmployeesModalVisible(true);
    dailyRecordService.checkInEmployees()
      .then(setCheckedInEmployees)
      .catch(err => handleApiError(err,t));
  }
  
  // Veri çekme işlemleri
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dailyStats] = await Promise.all([
          getCompanyDailyStats(),
          fetchDepartmentsAndShops() // useEmployee hook'undaki state'leri günceller
        ]);
        
        setCompanyDailyStats(dailyStats);
        
        // Şirket ID'si varsa doğum günü verileri
        const companyId = user?.companyId;
        if (companyId) {
          getUsersBirthdays(companyId)
            .then(setBirthdayUsers)
            .catch(err => handleApiError(err,t));
        }
      } catch (error: any) {
        handleApiError(error,t);
      }
    };
    
    fetchData();
  }, [user?.companyId]);

  // Grafikler için veri işleme (departments, shops veya companyDailyStats değiştiğinde)
  useEffect(() => {
    // Departman ve şube grafikleri
    updateDepartmentAndShopCharts();
    
    // Donut grafik verilerini güncelle
    updateAttendanceChart();
  }, [departments, shops, companyDailyStats,t]);

  useEffect(() => {
      getUsers()
        .then(setCompanyUsers)
        .catch(err => handleApiError(err,t));
  }, []);
  // Departman ve şube grafik verilerini güncelleme işlevi
  const updateDepartmentAndShopCharts = () => {
    if (departments.length > 0) {
      const topDepartments = departments.slice(0, 5);
      setEmpDepartment((prevState: any) => ({
        ...prevState,
        series: [{
          data: topDepartments.map(dept => dept.employeeCount.toString()),
          name: t("dashboard.departments.employee"),
        }],
        xaxis: {
          ...prevState.xaxis,
          categories: topDepartments.map(dept => dept.name),
        },
      }));
    }
    
    if (shops.length > 0) {
      const topShops = shops.slice(0, 5);
      setEmpBranch((prevState: any) => ({
        ...prevState,
        series: [{
          data: topShops.map((shop: any) => shop.employeeCount.toString()),
          name: t("dashboard.branches.employee"),
        }],
        xaxis: {
          ...prevState.xaxis,
          categories: topShops.map(shop => shop.name),
        },
      }));
    }
  };

  // Donut grafik verilerini güncelleme işlevi
  const updateAttendanceChart = () => {
    const data = {
      labels: [t("dashboard.attendanceStatus.late"), t("dashboard.attendanceStatus.onLeave"), t("dashboard.attendanceStatus.onBreak"), t("dashboard.attendanceStatus.didNotCome")],
      datasets: [{
        label: t("dashboard.departments.employee"),
        data: [
          companyDailyStats?.lateEmployees, 
          companyDailyStats?.onLeaveEmployees, 
          companyDailyStats?.activeBreaks, 
          companyDailyStats ? (companyDailyStats.totalEmployees - companyDailyStats.checkedInEmployees) : 0
        ],
        backgroundColor: ["#0C4B5E", "#007bff", "#FFC107", "#E70D0D"],
        borderWidth: 5,
        borderRadius: 10,
        borderColor: "#fff", 
        hoverBorderWidth: 0, 
        cutout: "60%",
      }],
    };
    
    const options = {
      rotation: -100,
      circumference: 200,
      layout: {
        padding: {
          top: -20,
          bottom: -20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    
    setChartData(data);
    setChartOptions(options);
  };

  // Doğum Günleri bölümü için JSX
  const renderBirthdaySection = () => (
    <div className="col-xxl-4 col-xl-6 d-flex">
      <div className="card flex-fill">
        <div className="card-header pb-2 d-flex align-items-center justify-content-start flex-wrap">
          <i className="ti ti-cake me-2"></i>
          <h5 className="mb-2">{t("dashboard.birthdays.title")}</h5>
        </div>
        <div className="card-body pb-1">
          <h6 className="mb-2">{t("dashboard.birthdays.today")}</h6>
          {birthdayUsers.today.length > 0 ? (
            birthdayUsers.today.map((user) => (
              <div key={user.userId} className="bg-light p-2 border border-dashed rounded-top mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                  {user.profilePicture ? (
                    <Avatar src={getFileUrl(user.profilePicture)} size="large" />
                  ) : (
                    <Avatar icon={<UserOutlined />} size="default" />
                  )}
                    <div className="ms-2 overflow-hidden">
                      <h6 className="fs-medium">{user.name}</h6>
                      <p className="fs-13">{user.department} - {user.branch}</p>
                    </div>
                  </div>
                  <div>
                    <span className="fs-10 fw-medium d-inline-flex align-items-center badge badge-info">
                      <i className="ti ti-cake me-1" />
                      {user?.birthDate}
                    </span>
                  </div>
                  {/* <Link to="#" className="btn btn-secondary btn-xs">
                    <i className="ti ti-cake me-1" />
                    Tebrik Et
                  </Link> */}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-light p-2 border border-dashed rounded-top mb-3">
              <p className="text-center mb-0">{t("dashboard.birthdays.noBirthdayToday")}</p>
            </div>
          )}

          <h6 className="mb-2">{t("dashboard.birthdays.thisWeek")}</h6>
          {birthdayUsers.thisWeek.length > 0 ? (
            birthdayUsers.thisWeek.map((user) => (
              <div key={user.userId} className="bg-light p-2 border border-dashed rounded-top mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {user.profilePicture ? (
                      <Link to={all_routes.employeedetails.replace(':id', user.userId.toString())} className="avatar">
                        <ImageWithBasePath
                          src={getFileUrl(user.profilePicture)}
                          className="rounded-circle"
                          alt="img"
                        />
                      </Link>
                    ) : (
                      <Link to={all_routes.employeedetails.replace(':id', user.userId.toString())} className="avatar">
                        <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                      </Link> 
                    )}
          
                    <div className="ms-2 overflow-hidden">
                      <h6 className="fs-medium">{user.name}</h6>
                      <p className="fs-13">{user.department} - {user.branch}</p>
                    </div>
                  </div>
                  <div>
                    <span className="fs-10 fw-medium d-inline-flex align-items-center badge badge-info">
                      <i className="ti ti-cake me-1" />
                      {user?.birthDate}
                    </span>
                  </div>
                  {/* <Link to="#" className="btn btn-secondary btn-xs">
                    <i className="ti ti-cake me-1" />
                    Tebrik Et
                  </Link> */}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-light p-2 border border-dashed rounded-top mb-3">
              <p className="text-center mb-0">{t("dashboard.birthdays.noBirthdayThisWeek")}</p>
            </div>
          )}

          <h6 className="mb-2">{t("dashboard.birthdays.thisMonth")}</h6>
          {birthdayUsers.thisMonth.length > 0 ? (
            birthdayUsers.thisMonth.map((user) => (
              <div key={user.userId} className="bg-light p-2 border border-dashed rounded-top mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="avatar">
                      {user.profilePicture ? (
                        <Link to={all_routes.employeedetails.replace(':id', user.userId.toString())}>
                          <Avatar
                            src={getFileUrl(user.profilePicture)}
                            className="rounded-circle"
                            alt="img"
                          />
                        </Link>
                      ) : (
                        <Link to={all_routes.employeedetails.replace(':id', user.userId.toString())}>
                          <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                        </Link>
                      )}
                    </span>
                    <div className="ms-2 overflow-hidden">
                      <h6 className="fs-medium">{user.name}</h6>
                      <p className="fs-13">{user.department} - {user.branch}</p>
                    </div>
                  </div>
                  <div>
                    <span className="fs-10 fw-medium d-inline-flex align-items-center badge badge-info">
                      <i className="ti ti-cake me-1" />
                      {user?.birthDate}
                    </span>
                  </div>
                  {/* <Link to="#" className="btn btn-secondary btn-xs">
                    <i className="ti ti-cake me-1" />
                    Tebrik Et
                  </Link> */}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-light p-2 border border-dashed rounded-top mb-3">
              <p className="text-center mb-0">{t("dashboard.birthdays.noBirthdayThisMonth")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderLeaveUsersModal = () => (
    <Modal
      title={t("dashboard.leaveRequests.title")}
      open={isLeaveUsersModalVisible}
      onCancel={() => setIsLeaveUsersModalVisible(false)}
      footer={null}
      width={700}
    >
      <div className="table-responsive">
        <table className="table table-nowrap mb-0">
          <thead>
            <tr>
              <th>{t("dashboard.leaveRequests.employee")}</th>
              <th>{t("dashboard.leaveRequests.leaveType")}</th>
              <th>{t("dashboard.leaveRequests.startDate")}</th>
              <th>{t("dashboard.leaveRequests.endDate")}</th>
            </tr>
          </thead>
          <tbody>
            {companyDailyLeaveUsers.map((leaveUser) => (
              <tr key={leaveUser.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-sm me-2">
                      {leaveUser.user?.profilePicture ? (
                        <Avatar src={getFileUrl(leaveUser.user.profilePicture)} size="large" />
                      ) : (
                        <div className="avatar-initial rounded-circle bg-light d-flex align-items-center justify-content-center">
                          <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                        </div>
                      )}
                    </div>
                    <div>
                      <Link to={all_routes.employeedetails.replace(':id', leaveUser.user?.id.toString() || '')}>
                        <h6 className="mb-0">{leaveUser.user?.name}</h6>
                        <small>{leaveUser.user?.department}</small>
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-capitalize">
                    {t(`leaveTypes.${leaveUser.type}`)}                  
                  </span>
                </td>
                <td>{dayjs(leaveUser.startDate, "DD.MM.YYYY").format("L")}</td>
                <td>{dayjs(leaveUser.endDate, "DD.MM.YYYY").format("L")}</td>
                
               
              </tr>
            ))}
            {companyDailyLeaveUsers.length === 0 && (
              <tr>
                <td colSpan={5} className="text-center">
                  {t("dashboard.leaveRequests.noLeaveToday")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );

  const renderBreakUsersModal = () => (
    <Modal
      title={t("modals.onBreakEmployees.title")}
      open={isBreakUsersModalVisible}
      onCancel={() => setIsBreakUsersModalVisible(false)}
      footer={null}
      width={700}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
    >
      <div className="table-responsive">
        <table className="table table-nowrap mb-0">
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <tr>
              <th>{t("modals.onBreakEmployees.employee")}</th>
              <th>{t("modals.onBreakEmployees.breakType")}</th>
              <th>{t("modals.onBreakEmployees.startTime")}</th>
              <th>{t("modals.onBreakEmployees.duration")}</th>
            </tr>
          </thead>
          <tbody>
            {activeBreakUsers.map((breakUser) => (
              <tr key={breakUser.breakId}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-sm me-2">
                      {breakUser.profilePicture ? (
                        <Avatar src={getFileUrl(breakUser.profilePicture)} size="large" />
                      ) : (
                        <div className="avatar-initial rounded-circle bg-light d-flex align-items-center justify-content-center">
                          <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                        </div>
                      )}
                    </div>
                    <div>
                      <Link to={all_routes.employeedetails.replace(':id', breakUser.userId.toString() || '')}>
                        <h6 className="mb-0">{breakUser.userName}</h6>
                        <small>{breakUser.department}</small>
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-capitalize">
                    {t(`breakTypes.${breakUser.type}`)}
                  </span>
                </td>
                <td>{dayjs(breakUser.startTime, "HH:mm").format("HH:mm")}</td>
                <td>{breakUser.minutesOnBreak}</td>
              </tr>
            ))}
            {activeBreakUsers.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  {t("modals.onBreakEmployees.noBreakToday")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );

  const renderLateUsersModal = () => (
    <Modal
      title={t("modals.lateEmployees.title")}
      open={isLateUsersModalVisible}
      onCancel={() => setIsLateUsersModalVisible(false)}
      footer={null}
      width={700}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
    >
      <div className="table-responsive">
        <table className="table table-nowrap mb-0">
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <tr>
              <th>{t("modals.lateEmployees.employee")}</th>
              <th>{t("modals.lateEmployees.shiftStartTime")}</th>
              <th>{t("modals.lateEmployees.delay")}</th>
              <th>{t("modals.lateEmployees.reason")}</th>
            </tr>
          </thead>
          <tbody>
            {companyDailyLateUsers.map((lateUser) => (
              <tr key={lateUser.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-sm me-2">
                      {lateUser.profilePicture ? (
                       <Avatar src={getFileUrl(lateUser.profilePicture)} size="large" />
                      ) : (
                        <div className="avatar-initial rounded-circle bg-light d-flex align-items-center justify-content-center">
                          <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                        </div>
                      )}
                    </div>
                    <div>
                      <Link to={all_routes.employeedetails.replace(':id', lateUser.id.toString())}>
                        <h6 className="mb-0">{lateUser.name}</h6>
                        <small>{lateUser.department}</small>
                      </Link>
                    </div>
                  </div>
                </td>
                <td>{lateUser.shiftStartTime}</td>
                <td>{lateUser.lateDuration}</td>
                <td>
                  {lateUser.excuse ? (
                    <div 
                      style={{ cursor: 'pointer' }}
                      title={lateUser.excuse}
                    >
                      <i className="ti ti-info-circle me-1" />
                      {lateUser.excuse}
                    </div>
                  ) : (
                    <span className="text-muted">-</span>
                  )}
                </td>
              </tr>
            ))}
            {companyDailyLateUsers.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  {t("modals.lateEmployees.noLateToday")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );

  const renderCheckedInEmployeesModal = () => (
    <Modal
      title={t("modals.checkedInEmployees.title", "Ofiste Olan Çalışanlar")}
      open={isCheckedInEmployeesModalVisible}
      onCancel={() => setIsCheckedInEmployeesModalVisible(false)}
      footer={null}
      width={700}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
    >
      <div className="table-responsive">
        <table className="table table-nowrap mb-0">
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <tr>
              <th>{t("modals.checkedInEmployees.employee", "Çalışan")}</th>
              <th>{t("modals.checkedInEmployees.department", "Departman")}</th>
              <th>{t("modals.checkedInEmployees.branch", "Şube")}</th>
              <th>{t("modals.checkedInEmployees.checkInTime", "Giriş Saati")}</th>
            </tr>
          </thead>
          <tbody>
            {checkedInEmployees.map((employee) => (
              <tr key={employee.userId}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-sm me-2">
                      {employee.profilePicture ? (
                        <Avatar src={getFileUrl(employee.profilePicture)} size="large" />
                      ) : (
                        <div className="avatar-initial rounded-circle bg-light d-flex align-items-center justify-content-center">
                          <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                        </div>
                      )}
                    </div>
                    <div>
                      <Link to={all_routes.employeedetails.replace(':id', employee.userId.toString())}>
                        <h6 className="mb-0">{employee.name}</h6>
                        <small>{employee.title}</small>
                      </Link>
                    </div>
                  </div>
                </td>
                <td>{employee.department}</td>
                <td>{employee.branch}</td>
                <td>{employee.checkInTime}</td>
              </tr>
            ))}
            {checkedInEmployees.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  {t("modals.checkedInEmployees.noEmployee", "Bugün giriş yapan çalışan bulunmuyor.")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('dashboard.pageTitle', {type: user?.role})}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('dashboard.breadcrumb.dashboard')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('dashboard.breadcrumb.adminDashboard')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Breadcrumb */}
          {/* Welcome Wrap */}
          <div className="card border-0">
            <div className="card-body d-flex align-items-center justify-content-between flex-wrap pb-1">
              <div className="d-flex align-items-center mb-3">
                {/* <span className="avatar avatar-xl flex-shrink-0">
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-31.jpg"
                    className="rounded-circle"
                    alt="img"
                  />
                </span> */}
                <div className="ms-3">
                  <h3 className="mb-2">
                    {t('dashboard.welcomeBack', {name: user?.firstName})}
                    {/* <Link to="#" className="edit-icon">
                      <i className="ti ti-edit fs-14" />
                    </Link> */}
                  </h3>
                  <p>
                    <Trans
                      i18nKey="dashboard.ticketsLeaves"
                      values={{
                        tickets: companyDailyStats?.createdTickets,
                        leaves: companyDailyStats?.pendingLeaves,
                      }}
                      components={{
                        tickets: (
                          <span
                            className="text-primary "
                            onClick={() => navigate(all_routes.ticketAdmin)}
                            style={{ cursor: 'pointer' }}
                          />
                        ),
                        leaves: (
                          <span
                            className="text-primary "
                            onClick={() => navigate(all_routes.leaveadmin)}
                            style={{ cursor: 'pointer' }}
                          />
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* /Welcome Wrap */}
          <div className="row">
            {/* Widget Info */}
            <div className="col-xxl-4 d-flex">
              <div className="row flex-fill">
                <div className="col-md-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="avatar rounded-circle bg-primary mb-2">
                        <i className="ti ti-calendar-share fs-16" />
                      </span>
                      <h6 className="fs-13 fw-medium text-default mb-1">
                        {t('dashboard.stats.employeeCount')}
                      </h6>
                      <h3 className="mb-3">
                        {companyDailyStats?.checkedInEmployees}/{companyDailyStats?.totalEmployees}
                        <span className="fs-12 fw-medium text-success">
                          {/* <i className="fa-solid fa-caret-up me-1" />
                          +2.1% */}
                        </span>
                      </h3>
                      <Link
                        to="#"
                        className="link-default"
                        onClick={handleCheckedInEmployees}
                      >
                        {t('dashboard.departments.viewAll')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="avatar rounded-circle bg-secondary mb-2">
                        <i className="ti ti-browser fs-16" />
                      </span>
                      <h6 className="fs-13 fw-medium text-default mb-1">
                      {t('dashboard.stats.lateEmployees')}
                      </h6>
                      <h3 className="mb-3">
                        {companyDailyStats?.lateEmployees}
                        <span className="fs-12 fw-medium text-danger">
                          {/* <i className="fa-solid fa-caret-down me-1" />
                          -2.1% */}
                        </span>
                      </h3>
                      <Link to="#" className="link-default" onClick={handleCompanyDailyLateUsers}>
                        {t('dashboard.departments.viewAll')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="avatar rounded-circle bg-info mb-2">
                        <i className="ti ti-users-group fs-16" />
                      </span>
                      <h6 className="fs-13 fw-medium text-default mb-1">
                        {t('dashboard.stats.onLeaveEmployees')}
                      </h6>
                      <h3 className="mb-3">
                        {companyDailyStats?.onLeaveEmployees}
                        <span className="fs-12 fw-medium text-danger">
                          {/* <i className="fa-solid fa-caret-down me-1" />
                          -11.2% */}
                        </span>
                      </h3>
                      <Link to="#" className="link-default" onClick={handleCompanyDailyLeaveUsers}>
                        {t('dashboard.departments.viewAll')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <span className="avatar rounded-circle bg-pink mb-2">
                        <i className="ti ti-checklist fs-16" />
                      </span>
                      <h6 className="fs-13 fw-medium text-default mb-1">
                        {t('dashboard.stats.onBreakEmployees')}
                      </h6>
                      <h3 className="mb-3">
                        {companyDailyStats?.activeBreaks}
                        <span className="fs-12 fw-medium text-success">
                        </span>
                      </h3>
                      <Link to="#" className="link-default" onClick={handleActiveBreakUsers}>
                        {t('dashboard.departments.viewAll')}
                      </Link>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
            {/* /Widget Info */}
            {/* Employees By Department */}
            <div className="col-xxl-4 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
                  <h5 className="mb-2">{t('dashboard.departments.title')}</h5>
                  <Link to="/departments" className="btn btn-light btn-md mb-2">
                    {t('dashboard.departments.viewAll')}
                  </Link>
                </div>
                <div className="card-body">
                  <ReactApexChart
                    id="emp-department"
                    options={empDepartment}
                    series={empDepartment.series}
                    type="bar"
                    height={220}
                  />
                </div>
              </div>
            </div>
            {/* Employees By Branch - Aktifleştirildi */}
            <div className="col-xxl-4 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
                  <h5 className="mb-2">{t('dashboard.branches.title')}</h5>
                  <Link to="/branches" className="btn btn-light btn-md mb-2">
                    {t('dashboard.departments.viewAll')}
                  </Link>
                </div>
                <div className="card-body">
                  <ReactApexChart
                    id="emp-branch"
                    options={empBranch}
                    series={empBranch.series}
                    type="bar"
                    height={220}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Attendance Overview */}
            <div className="col-xxl-4 col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
                  <h5 className="mb-2">{t('dashboard.attendanceStatus.title')}</h5>
                  
                </div>
                <div className="card-body">
                  <div className="chartjs-wrapper-demo position-relative mb-4">
                    <Chart
                      type="doughnut"
                      data={chartData}
                      options={chartOptions}
                      className="w-full attendence-chart md:w-30rem"
                    />
                    <div className="position-absolute text-center attendance-canvas">
                      <p className="fs-13 mb-1">{t('dashboard.attendanceStatus.totalEmployees')}</p>
                      <h3>{companyDailyStats?.totalEmployees}</h3>
                    </div>
                  </div>
                  <h6 className="mb-3">{t('dashboard.attendanceStatus.title')}</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="f-13 mb-2">
                      <i className="ti ti-circle-filled text-danger me-1" />
                      {t('dashboard.attendanceStatus.didNotCome')}
                    </p>
                    <p className="f-13 fw-medium text-gray-9 mb-2">
                      {companyDailyStats?.totalEmployees ? 
                        `${Math.round((companyDailyStats.totalEmployees - companyDailyStats.checkedInEmployees) / companyDailyStats.totalEmployees * 100)}%` : '0%'}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="f-13 mb-2">
                      <i className="ti ti-circle-filled text-secondary me-1" />
                      {t('dashboard.attendanceStatus.late')}
                    </p>
                    <p className="f-13 fw-medium text-gray-9 mb-2">
                      {companyDailyStats?.totalEmployees ? 
                        `${Math.round((companyDailyStats.lateEmployees / companyDailyStats.totalEmployees) * 100)}%` : '0%'}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="f-13 mb-2">
                      <i className="ti ti-circle-filled text-primary me-1" />
                      {t('dashboard.attendanceStatus.onLeave')}
                    </p>
                    <p className="f-13 fw-medium text-gray-9 mb-2">
                      {companyDailyStats?.totalEmployees ? 
                        `${Math.round((companyDailyStats.onLeaveEmployees / companyDailyStats.totalEmployees) * 100)}%` : '0%'}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="f-13 mb-2">
                      <i className="ti ti-circle-filled text-warning me-1" />
                      {t('dashboard.attendanceStatus.onBreak')}
                    </p>
                    <p className="f-13 fw-medium text-gray-9 mb-2">
                      {companyDailyStats?.totalEmployees ? 
                        `${Math.round((companyDailyStats.activeBreaks / companyDailyStats.totalEmployees) * 100)}%` : '0%'}
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
            
            <ClockInOutStatus />
            <BreakStatus />
            <LeaveStatus />
            <TicketStatus />
            {/* Employees */}
            <div className="col-xxl-4 col-xl-6 d-flex">
              <div className="card flex-fill">
                <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
                  <h5 className="mb-2">{t("dashboard.employees.title")}</h5>
                  <Link
                    to={routes.employeeList}
                    className="btn btn-light btn-md mb-2"
                  >
                    {t("dashboard.employees.viewAll")}
                  </Link>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>{t("dashboard.employees.name")}</th>
                          <th>{t("dashboard.employees.branch")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyUsers.sort((a, b) => new Date(a.hireDate).getTime() - new Date(b.hireDate).getTime()).slice(0, 5).map((user) => (
                          <tr key={user.id}>
                            <td>
                              <div className="d-flex align-items-center">
                              {user.profilePicture ? (
                              <Link to={routes.employeedetails.replace(':id', user.id.toString())} className="avatar">
                                <Avatar
                                  src={getFileUrl(user.profilePicture)}
                                  className="rounded-circle"
                                  alt="img"
                                />
                              </Link>
                              ) : (
                                <Link to={routes.employeedetails.replace(':id', user.id.toString())} className="avatar">
                                  <UserOutlined style={{ fontSize: '16px', color: '#666' }} />
                                </Link>
                              )}

                              <Link to={routes.employeedetails.replace(':id', user.id.toString())} className="ms-2">
                                <h6 className="fw-medium">
                                  {user?.firstName} {user?.lastName}
                                </h6>
                                <span className="fs-12">{user?.department?.name}</span>
                                </Link>
                            </div>
                          </td>
                          <td>
                            <span className="badge badge-secondary-transparent badge-xs">
                              {user?.branch?.name}
                            </span>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /Employees */}
          {renderBirthdaySection()}
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {renderLeaveUsersModal()}
      {renderBreakUsersModal()}
      {renderLateUsersModal()}
      {renderCheckedInEmployeesModal()}
    </>
  );
};

export default AdminDashboard;
