import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { all_routes } from "../../../../router/all_routes";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { InputOtp, InputOtpChangeEvent } from "primereact/inputotp";
import { Link } from "react-router-dom";
import authService from "../../../../services/auth/auth.service";
import { message } from "antd";
import { handleApiError } from "../../../../utils/errorHandler";
const TwoStepVerification2 = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(179); // 9:59 in seconds
  
  useEffect(() => {
    // Email bilgisini location state'inden alıyoruz
    const state = location.state as { email?: string };
    if (state?.email) {
      setEmail(state.email);
    } else {
      // Email bilgisi yoksa kullanıcıyı şifremi unuttum sayfasına yönlendir
      message.error("Email bilgisi bulunamadı. Lütfen tekrar deneyin.");
      navigate(routes.forgotPassword2);
    }
  }, [location, navigate, routes.forgotPassword2]);

  useEffect(() => {
    // Geri sayım için timer
    if (timeLeft <= 0) return;
    
    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);
    
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleResendOtp = async () => {
    if (!email) {
      message.error("Email bilgisi bulunamadı.");
      return;
    }
    
    try {
      setLoading(true);
      await authService.sendOtp(email);
      message.success("Yeni doğrulama kodu email adresinize gönderildi");
      setTimeLeft(179); // Reset timer
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      message.error("Email bilgisi bulunamadı.");
      return;
    }
    
    if (!token || token.length < 4) {
      message.error("Lütfen geçerli bir doğrulama kodu giriniz.");
      return;
    }
    
    try {
      setLoading(true);
      await authService.verifyOtp(email, token);
      message.success("Doğrulama başarılı!");
      // Doğrulama başarılı olduğunda email bilgisini de resetPassword2 sayfasına gönderiyoruz
      navigate(routes.resetPassword2, { state: { email } });
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  // Dakika ve saniye formatını oluştur
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleOtpChange = (e: InputOtpChangeEvent) => {
    setToken(e.value as string || "");
  };

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-5">
            <div className="d-lg-flex align-items-center justify-content-center d-none flex-wrap vh-100 bg-primary-transparent">
              <div>
                <ImageWithBasePath src="assets/img/bg/authentication-bg-08.svg" alt="Img" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="row justify-content-between align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-7 mx-auto vh-100">
                <form className="vh-100" onSubmit={handleVerify}>
                <div className="vh-100 d-flex flex-column justify-content-between" style={{paddingTop: "35%", paddingRight: "1.5rem",paddingLeft: "1.5rem",paddingBottom: "1.5rem"}}>
                  
                  <div className="flex-grow-0">
                    <div className="text-center mb-4">
                    <div className="mx-auto text-center mb-4" style={{width: "60%"}}>
                    <ImageWithBasePath src="assets/img/dinox-light.png" alt="Logo"/>
                  </div>
                        <h2 className="mb-2">Doğrulama Kodu</h2>
                        <p className="mb-0">
                          Lütfen hesap sahipliğinizi onaylamak için aldığınız doğrulama kodunu giriniz. 
                          {email && (
                            <span> Kod, <strong>{email.replace(/(.{3})(.*)(?=@)/, "$1***")}</strong> adresine gönderildi.</span>
                          )}
                        </p>
                      </div>
                      <div className="text-center otp-input">
                        <div className="d-flex align-items-center mb-3">
                          <InputOtp 
                            value={token} 
                            onChange={handleOtpChange} 
                            integerOnly 
                            length={4}
                            className="py-sm-3 py-2 text-center fs-26 fw-bold"
                          />
                        </div>
                        <div>
                          <div className="badge bg-danger-transparent mb-3">
                            <p className="d-flex align-items-center ">
                              <i className="ti ti-clock me-1" />
                              {formatTime()}
                            </p>
                          </div>
                          <div className="mb-3 d-flex justify-content-center">
                            <p className="text-gray-9">
                              Kod almadınız mı?{" "}
                              <Link
                                to="#"
                                className="text-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (timeLeft <= 0) {
                                    handleResendOtp();
                                  } else {
                                    message.info(`Lütfen ${formatTime()} saniye sonra tekrar deneyin.`);
                                  }
                                }}
                              >
                                {timeLeft <= 0 ? "Kodu Tekrar Gönder" : "Lütfen bekleyin"}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button 
                          type="submit" 
                          className="btn btn-primary w-100"
                          disabled={loading || !token || token.length < 4}
                        >
                          {loading ? "Doğrulanıyor..." : "Doğrula ve Devam Et"}
                        </button>
                      </div>
                    </div>
                    <div className="mt-5 pb-4 text-center">
                      <p className="mb-0 text-gray-9">Copyright © Dinox - {new Date().getFullYear()}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoStepVerification2;
