import { useState, useEffect ,useCallback} from "react";

import departmentService from "../services/department/department.service";
import { DepartmentDto } from "../types/department.types";

export const useDepartments = () => {
    const [departments, setDepartments] = useState<DepartmentDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const {getDepartments} = departmentService
    const fetchDepartments = useCallback(async () => {
        try {
            setLoading(true);
            const departments = await getDepartments();
            setDepartments(departments);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments]);

    return {
        departments,
        loading,
        error,
        fetchDepartments,
    }
}