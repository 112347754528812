import { RouteObject } from "react-router";

export enum RolesEnum {
    SUPERADMIN = 'superAdmin',
    ADMIN = 'admin',
    USER = 'user',
  }

export interface ProtectedRouteProps {
    element: JSX.Element; // Gösterilecek bileşen
    allowedRoles: string[]; // İzin verilen roller listesi
    userRole: string | undefined; // Kullanıcının rolü
  }

  export interface Roles {
    path: string,
    element: JSX.Element,
    route?: RouteObject,
    role?:RolesEnum
  }