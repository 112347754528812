export const timesheet_details = [
  {
    key:"1",
    Employee: "Anthony Lewis",
    Image: "user-32.jpg",
    Role: "UI/UX Team",
    Date: "14 Jan 2024",
    Project: "Office Management",
    AssignedHours: "32",
    WorkedHours: "13",
  },
  {
    key:"2",
    Employee: "Brian Villalobos",
    Image: "user-09.jpg",
    Role: "Development",
    Date: "21 Jan 2024",
    Project: "Project Management",
    AssignedHours: "45",
    WorkedHours: "14",
  },
  {
    key:"3",
    Employee: "Harvey Smith",
    Image: "user-01.jpg",
    Role: "HR",
    Date: "20 Feb 2024",
    Project: "Project Management",
    AssignedHours: "45",
    WorkedHours: "22",
  },
  {
    key:"4",
    Employee: "Stephan Peralt",
    Image: "user-33.jpg",
    Role: "Management",
    Date: "15 Mar 2024",
    Project: "Hospital Administration",
    AssignedHours: "45",
    WorkedHours: "78",
  },
  {
    key:"5",
    Employee: "Doglas Martini",
    Image: "user-34.jpg",
    Role: "Development",
    Date: "12 Apr 2024",
    Project: "Office Management",
    AssignedHours: "36",
    WorkedHours: "45",
  },
  {
    key:"6",
    Employee: "Linda Ray",
    Image: "user-02.jpg",
    Role: "UI/UX Team",
    Date: "20 May 2024",
    Project: "Hospital Administration",
    AssignedHours: "49",
    WorkedHours: "14",
  },
  {
    key:"7",
    Employee: "Elliot Murray",
    Image: "user-35.jpg",
    Role: "Developer",
    Date: "06 Jul 2024",
    Project: "Video Calling App",
    AssignedHours: "57",
    WorkedHours: "16",
  },
  {
    key:"8",
    Employee: "Rebecca Smtih",
    Image: "user-36.jpg",
    Role: "UI/UX Team",
    Date: "02 Sep 2024",
    Project: "Office Management",
    AssignedHours: "21",
    WorkedHours: "18",
  },
  {
    key:"9",
    Employee: "Connie Waters",
    Image: "user-37.jpg",
    Role: "Management",
    Date: "15 Nov 2024",
    Project: "Project Management",
    AssignedHours: "32",
    WorkedHours: "19",
  },
  {
    key:"10",
    Employee: "Connie Waters",
    Image: "user-38.jpg",
    Role: "Management",
    Date: "15 Nov 2024",
    Project: "Project Management",
    AssignedHours: "32",
    WorkedHours: "19",
  },
];
