import { User } from "./user.types";
 
export enum BreakType {
  BREAK = "short",
  LUNCH = "yemek",
  PERSONAL = "kişisel",
  OTHER = "diğer"
}

export interface Break {
  id: number;
  user: User;
  startTime: Date;
  endTime: Date;
  type: BreakType;
  breakDuration: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface StartBreakDto {
  type: BreakType;
}

export interface BreakHistoryCompanyDto {
  totalBreakCount: number;
  departmentBreakCounts: {
    [key: string]: number;
  };
  branchBreakCounts: {
    [key: string]: number;
  };
  breaks: BreakHistoryDto[];
}

export interface BreakHistoryDto {
    breakId: number;
    userId: number;
    userName: string;
    type: string;
    startTime: string;
    endTime: string;
    breakDuration: number;
    department: string;
    branch: string;
    profilePicture: string;
}