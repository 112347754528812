import {useAsync} from "./useAsync";
    import { LeaveCreateDTO, LeaveDto, LeaveStats, LeaveStatus } from "../types/leaves.types";
import leaveService from "../services/leave/leave.service";
import {useMemo, useCallback} from "react";

export const useLeaves = () => {
    const {data:leaves, loading, error:leavesError, execute} = useAsync<LeaveDto[]>();

    const fetchLeaves = useCallback(async ()=>{
        
        return execute(()=>leaveService.getLeaves());
    },[execute]);
    
    const stats:LeaveStats = useMemo(()=>{
        if(!leaves) return {total:0, approved:0, pending:0, rejected:0};

        return leaves.reduce((acc, leave)=>{
            acc.total++;
            acc[leave.status]++;
            return acc;
        }, {total:0, approved:0, pending:0, rejected:0});
    }, [leaves]);

    const updateLeaveStatus = async (leaveId:number, status:{status:string})=>{
        await leaveService.updateLeave(leaveId, status);
        await fetchLeaves();
    }

    const createLeave = async (leave:LeaveCreateDTO)=>{
        await leaveService.createLeaveAdmin(leave);
        await fetchLeaves();
    }
    
    return {
        leaves,
        stats,
        updateLeaveStatus,
        loading,
        leavesError,
        fetchLeaves,
        createLeave
    }
}
