import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Select,
  Divider,
  Switch,
  message,
  Alert,
  Space,
  TimePicker,
  Checkbox,
} from "antd";
import { Company, CompanyStatus } from "../../../types/company.types";
import companyService from "../../../services/company/company.service";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import {
  BuildOutlined,
  SaveOutlined,
  CloseOutlined,
  PhoneOutlined,
  MailOutlined,
  BankOutlined,
  NumberOutlined,
  SettingOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import "./styles.css";
import moment from "moment";
import { handleApiError } from "../../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import FooterSection from "../../shared/components/footerSection";
const { Option } = Select;
const { TextArea } = Input;

const CreateCompany = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [useFixedShift, setUseFixedShift] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  // Şirket oluşturma işlemi
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      setErrorMessage(null);

      // Vardiya zamanlarını string formatına dönüştürme
      if (values.settings?.useFixedShift && values.settings?.fixedShiftStartTime) {
        values.settings.fixedShiftStartTime = values.settings.fixedShiftStartTime.format("HH:mm");
      }
      
      if (values.settings?.useFixedShift && values.settings?.fixedShiftEndTime) {
        values.settings.fixedShiftEndTime = values.settings.fixedShiftEndTime.format("HH:mm");
      }

      // Şirket oluşturma API çağrısı
      await companyService.createCompany(values);
      message.success("Şirket başarıyla oluşturuldu!");
      navigate(all_routes.superAdminCompanies);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  // Varsayılan değerleri ayarlama
  const initialValues = {
    name: "",
    address: "",
    phone: "",
    email: "",
    taxNumber: "",
    taxOffice: "",
    status: CompanyStatus.ACTIVE,
    defaultBreakDuration: 60,
    settings: {
      workOnHolidays: false,
      workOnWeekends: false,
      lateTolerance: true,
      lateTolerationMinutes: 0,
      sendLeaveRequestEmails: true,
      ipControl: false,
      annualLeaveLimit: 20,
      shiftMode: "assignment",
      useFixedShift: false,
      fixedShiftDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      fixedShiftStartTime: moment("09:00", "HH:mm"),
      fixedShiftEndTime: moment("18:00", "HH:mm"),
    }
  };

  // Hafta günleri
  const weekDays = [
    { label: "Pazartesi", value: "Monday" },
    { label: "Salı", value: "Tuesday" },
    { label: "Çarşamba", value: "Wednesday" },
    { label: "Perşembe", value: "Thursday" },
    { label: "Cuma", value: "Friday" },
    { label: "Cumartesi", value: "Saturday" },
    { label: "Pazar", value: "Sunday" },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Yeni Şirket Oluştur</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminCompanies}>Şirketler</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Yeni Şirket
                  </li>
                </ol>
              </nav>
            </div>
            <div className="head-icons ms-2">
            </div>
          </div>
          {/* /Breadcrumb */}

          {errorMessage && (
            <Alert
              message="Hata"
              description={errorMessage}
              type="error"
              showIcon
              closable
              className="mb-4"
            />
          )}

          <Card className="company-list-card">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialValues}
              onFinish={handleSubmit}
              className="company-form"
              requiredMark="optional"
            >
              {/* Temel Bilgiler */}
              <div className="form-section">
                <h3 className="section-title">
                  <BuildOutlined /> Temel Bilgiler
                </h3>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="name"
                      label="Şirket Adı"
                      rules={[
                        { required: true, message: "Şirket adı gereklidir" },
                      ]}
                    >
                      <Input placeholder="Şirket adını girin" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="email" label="E-posta">
                      <Input
                        placeholder="E-posta adresini girin"
                        prefix={<MailOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="phone" label="Telefon">
                      <Input
                        placeholder="Telefon numarasını girin"
                        prefix={<PhoneOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="status" label="Durum">
                      <Select>
                        <Option value={CompanyStatus.ACTIVE}>Aktif</Option>
                        <Option value={CompanyStatus.INACTIVE}>Pasif</Option>
                        <Option value={CompanyStatus.SUSPENDED}>
                          Askıya Alınmış
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="address" label="Adres">
                      <TextArea
                        placeholder="Şirket adresini girin"
                        rows={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Vergi Bilgileri */}
              <div className="form-section">
                <h3 className="section-title">
                  <BankOutlined /> Vergi Bilgileri
                </h3>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="taxNumber"
                      label="Vergi No"
                      rules={[
                        { required: true, message: "Vergi no gereklidir" },
                      ]}
                    >
                      <Input
                        placeholder="Vergi numarasını girin"
                        prefix={<NumberOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="taxOffice"
                      label="Vergi Dairesi"
                      rules={[
                        { required: true, message: "Vergi dairesi gereklidir" },
                      ]}
                    >
                      <Input placeholder="Vergi dairesini girin" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Sistem Ayarları */}
              <div className="form-section">
                <h3 className="section-title">
                  <SettingOutlined /> Şirket Ayarları
                </h3>
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "workOnHolidays"]}
                      label="Resmi Tatillerde Çalışma"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "workOnWeekends"]}
                      label="Hafta Sonları Çalışma"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "lateTolerance"]}
                      label="Geç Kalma Toleransı"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.settings?.lateTolerance !==
                    currentValues.settings?.lateTolerance
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue(["settings", "lateTolerance"]) ? (
                      <Row gutter={16}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            name={["settings", "lateTolerationMinutes"]}
                            label="Geç Kalma Tolerans Süresi (Dakika)"
                          >
                            <Input type="number" min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : null;
                  }}
                </Form.Item>

                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "sendLeaveRequestEmails"]}
                      label="İzin Talepleri için E-posta Bildirimi"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "ipControl"]}
                      label="IP Kontrolü"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name={["settings", "annualLeaveLimit"]}
                      label="Yıllık İzin Limiti (Gün)"
                    >
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">Vardiya Ayarları</Divider>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name={["settings", "shiftMode"]}
                      label="Vardiya Modu"
                    >
                      <Select>
                        <Option value="assignment">Atama Bazlı</Option>
                        <Option value="fixed">Sabit</Option>
                        <Option value="both">Her İkisi</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="defaultBreakDuration"
                      label="Varsayılan Mola Süresi (Dakika)"
                    >
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name={["settings", "useFixedShift"]}
                  label="Sabit Vardiya Kullan"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) => setUseFixedShift(checked)}
                  />
                </Form.Item>

                {useFixedShift && (
                  <>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name={["settings", "fixedShiftDays"]}
                          label="Sabit Vardiya Günleri"
                        >
                          <Checkbox.Group options={weekDays} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name={["settings", "fixedShiftStartTime"]}
                          label="Sabit Vardiya Başlangıç Saati"
                        >
                          <TimePicker
                            format="HH:mm"
                            placeholder="Başlangıç saati"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name={["settings", "fixedShiftEndTime"]}
                          label="Sabit Vardiya Bitiş Saati"
                        >
                          <TimePicker
                            format="HH:mm"
                            placeholder="Bitiş saati"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </div>

              {/* Form İşlemleri */}
              <div className="d-flex justify-content-end">
                <Button
                  className="me-2"
                  onClick={() => navigate(all_routes.superAdminCompanies)}
                >
                  İptal
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  loading={loading}
                >
                  Şirketi Oluştur
                </Button>
              </div>
            </Form>
          </Card>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CreateCompany;
