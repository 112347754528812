import apiService from '../api/api.service';
import { ShiftInfo, ShiftCreateDTO, ShiftUpdateDTO, ShiftAssignmentCreateDTO, ShiftResponse, UserWithShifts, UserShift } from '../../types/shift.types';
import dayjs from 'dayjs';

class ShiftService {
  private static instance: ShiftService;

  private constructor() {}

  public static getInstance(): ShiftService {
    if (!ShiftService.instance) {
      ShiftService.instance = new ShiftService();
    }
    return ShiftService.instance;
  }

  getCompanyShifts = async (): Promise<ShiftInfo[]> => {
    try {
      const response = await apiService.get<ShiftInfo[]>('/shifts');
      console.log(response);
      
      return response;
    } catch (error) {
      throw error;
    }
  };

  getUserShifts = async (): Promise<ShiftInfo[]> => {
    try {
      const response = await apiService.get<ShiftInfo[]>('/shifts/user');
      return response;
    } catch (error) {
      throw error;
    }
  };

  getUserShiftAssignments = async (): Promise<ShiftResponse> => {
    try {
      const response = await apiService.get<ShiftResponse>('/shifts/company/assignments');
      return response;
    } catch (error) {
      throw error;
    }
  };

  createShift = async (shiftData: ShiftCreateDTO): Promise<ShiftInfo> => {
    try {
      const response = await apiService.post<ShiftInfo>('/shifts', shiftData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateShift = async (id: number, shiftData: ShiftUpdateDTO): Promise<ShiftInfo> => {
    try {
      const response = await apiService.put<ShiftInfo>(`/shifts/${id}`, shiftData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  deleteShift = async (id: number): Promise<void> => {
    try {
      await apiService.delete(`/shifts/${id}`);
    } catch (error) {
      throw error;
    }
  };

  assignShift = async (assignmentData: ShiftAssignmentCreateDTO): Promise<void> => {
    try {
      await apiService.post('/shifts/assign', assignmentData);
    } catch (error) {
      throw error;
    }
  };

  getShiftAssignments = async (startDate: string, endDate: string): Promise<UserWithShifts[]> => {
    try {
      const response = await this.getUserShiftAssignments();
      
      const shifts = response.shifts;
      const userAssignments = response.userAssignments;

      // Arşivlenmiş vardiyalar için filtreleme
      const filteredUserAssignments = userAssignments.map((user: UserWithShifts) => {
        // Her kullanıcı için vardiyaları filtrele
        const filteredShifts = user.shifts.filter((shift: UserShift) => {
          // Vardiya bilgisini bul
          
          const shiftInfo = shifts.find((s: any) => s.shiftId === shift.shiftId);
          
          // Tarih aralığı kontrolü
          const assignmentStartDate = dayjs(shift.startDate);
          const assignmentEndDate = dayjs(shift.endDate);
          const rangeStartDate = dayjs(startDate);
          const rangeEndDate = dayjs(endDate);
          
          // Tarih aralığı dışındaysa filtreleme
          if (assignmentEndDate.isBefore(rangeStartDate) || assignmentStartDate.isAfter(rangeEndDate)) {
            return false;
          }
          // Arşivlenmiş vardiya kontrolü
          if (shiftInfo?.status === 'archived') {
            const updateDate = dayjs(shiftInfo.updatedAt);
            
            // Vardiya tarihi, arşivlenme tarihinden sonra mı?
            // Eğer vardiya başlangıç tarihi, arşivlenme tarihinden sonraysa gösterme
            if (assignmentStartDate.isAfter(updateDate)) {
              return false;
            }
            
            // Eğer aralık içinde bir gün arşivlenme tarihinden sonraysa,
            // vardiya bitiş tarihini arşivlenme tarihi olarak güncelle
            if (assignmentEndDate.isAfter(updateDate)) {
              shift.endDate = updateDate.format('YYYY-MM-DD');
            }
          }
          
          return true;
        });
        
        // Filtrelenmiş vardiyalarla kullanıcıyı döndür
        return {
          ...user,
          shifts: filteredShifts
        };
      }).filter((user: UserWithShifts) => user.shifts.length > 0); // Vardiyası kalmayan kullanıcıları filtreleme
      
      return filteredUserAssignments;
    } catch (error) {
      console.error('Vardiya atamaları yüklenirken hata oluştu:', error);
      throw error;
    }
  };
}

const shiftService = ShiftService.getInstance();

export default shiftService;
