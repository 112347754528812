import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Modal, Form, Select, DatePicker, Badge, Tooltip, Input, message, Popconfirm, TimePicker as AntTimePicker } from "antd";
import { Button, Card, Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import 'dayjs/locale/tr';
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { useUsers } from "../../../hooks/useUsers";
import Table from "../../../components/shared/dataTable";
import { ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, CameraOutlined, FilePdfOutlined } from "@ant-design/icons";
import { User } from "../../../types/user.types";
import shiftService from "../../../services/shift/shift.service";
import { ShiftInfo, ShiftAssignmentCreateDTO, UserWithShifts, UserShift, ShiftCreateDTO } from "../../../types/shift.types";
import './styles/style.css';
import Swal from "sweetalert2";
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import FooterSection from "../../shared/components/footerSection";
ChartJS.register(ArcElement, ChartTooltip, Legend, CategoryScale, LinearScale, BarElement);

dayjs.locale('tr');

// Vardiya renk kodları
const shiftColors: Record<string, string> = {
  default: "#2196F3", // Varsayılan mavi
};

// Günlük atama tipi
interface DailyAssignment extends UserShift {
  userId: number;
  userName: string;
}

const ShiftPage: React.FC = () => {
  const { t } = useTranslation();
  const { users, loading: usersLoading } = useUsers();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());
  const [currentWeek, setCurrentWeek] = useState<dayjs.Dayjs[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | null>(null);
  const [form] = Form.useForm();
  const [dailyAssignments, setDailyAssignments] = useState<DailyAssignment[]>([]);
  
  // Haftalık takvim referansı
  const calendarRef = useRef<HTMLDivElement>(null);
  
  // Vardiya tipleri ve atamaları için state'ler
  const [shifts, setShifts] = useState<ShiftInfo[]>([]);
  const [weeklyAssignments, setWeeklyAssignments] = useState<UserWithShifts[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Vardiya oluşturma modalı için state'ler
  const [isShiftTypeModalVisible, setIsShiftTypeModalVisible] = useState(false);
  const [shiftTypeForm] = Form.useForm();
  const [editingShift, setEditingShift] = useState<ShiftInfo | null>(null);

  // Haftalık tarihleri hesapla
  useEffect(() => {
    const startOfWeek = selectedDate.startOf('week');
    const weekDays = Array.from({ length: 7 }, (_, i) => startOfWeek.add(i, 'day'));
    setCurrentWeek(weekDays);
  }, [selectedDate]);

  // Vardiyaları ve haftalık atamaları getir
  const fetchShifts = useCallback(async () => {
    try {
      setIsLoading(true);
      const shiftsData = await shiftService.getCompanyShifts();
      setShifts(shiftsData);
      
      // Vardiya renklerini ayarla
      shiftsData.forEach((shift, index) => {
        console.log(shift);
        
        const hue = (index * 137) % 360; // Altın oran ile çeşitli renkler
        shiftColors[shift.id.toString()] = `hsl(${hue}, 70%, 50%)`;
      });
      
    } catch (err: any) {
      handleApiError(err,t);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchWeeklyAssignments = useCallback(async () => {
    if (currentWeek.length === 0) return;
    try {
      setIsLoading(true);
      const startDate = currentWeek[0].format('YYYY-MM-DD');
      const endDate = currentWeek[6].format('YYYY-MM-DD');
      
      const assignmentsData = await shiftService.getShiftAssignments(startDate, endDate);
      console.log(assignmentsData);
      
      setWeeklyAssignments(assignmentsData);
    } catch (err: any) {
      handleApiError(err,t);
    } finally {
      setIsLoading(false);
    }
  }, [currentWeek]);

  useEffect(() => {
    fetchShifts();
  }, [fetchShifts]);

  useEffect(() => {
    if (currentWeek.length > 0) {
      fetchWeeklyAssignments();
    }
  }, [currentWeek, fetchWeeklyAssignments]);

  // Takvim görünümü için güncellenmiş veri filtresi
  const getDayAssignments = (day: dayjs.Dayjs): DailyAssignment[] => {
    const dayString = day.format('YYYY-MM-DD');
    const assignments: DailyAssignment[] = [];

    weeklyAssignments.forEach(user => {
      user.shifts.forEach(shift => {
        const shiftStartDate = dayjs(shift.startDate);
        const shiftEndDate = dayjs(shift.endDate);
        const currentDate = dayjs(dayString);

        // Vardiya bilgisini bul
        const shiftInfo = shifts.find(s => s.id === shift.shiftId);

        // Eğer vardiya arşivlenmişse ve güncel tarih arşivlenme tarihinden sonraysa, gösterme
        if (shiftInfo?.status === 'archived') {
          const updateDate = dayjs(shiftInfo.updatedAt);
          if (currentDate.isAfter(updateDate)) {
            return;
          }
        }

        // Tarih aralığı kontrolü
        if (currentDate.isSame(shiftStartDate, 'day') || 
            (currentDate.isAfter(shiftStartDate) && currentDate.isBefore(shiftEndDate)) || 
            currentDate.isSame(shiftEndDate, 'day')) {
          assignments.push({
            ...shift,
            userId: user.userId,
            userName: user.userName
          });
        }
      });
    });

    return assignments;
  };

  // Gün seçildiğinde modal'ı aç
  const handleDayClick = (day: dayjs.Dayjs) => {
    setSelectedDay(day);
    setIsModalVisible(true);
    
    // Seçilen güne ait vardiyaları getir
    const assignmentsForDay = getDayAssignments(day);
    setDailyAssignments(assignmentsForDay);
  };

  // Önceki/sonraki hafta navigasyonu
  const goToPreviousWeek = () => {
    setSelectedDate(selectedDate.subtract(1, 'week'));
  };

  const goToNextWeek = () => {
    setSelectedDate(selectedDate.add(1, 'week'));
  };

  // Modal kapatma
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // Vardiya atama
  const handleAssignShift = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      
      // Seçilen gün için vardiya ataması
      const selectedDate = dayjs(values.date).format('YYYY-MM-DD');
      const userId = values.employeeId;
      const shiftId = values.shiftId;
      if(dayjs(selectedDate).isBefore(dayjs(Date.now()))){
        message.error("Geçmiş tarihe vardiya atanamaz");
        return;
      }
      const assignmentData: ShiftAssignmentCreateDTO = {
        userId: values.employeeId,
        shiftId: values.shiftId,
        startDate: selectedDate,
        endDate: selectedDate,
        startTime: values.startTime,
        endTime: values.endTime
      };

      // Kullanıcının aynı gün ve aynı vardiya için zaten bir ataması var mı kontrol et
      const existingAssignment = weeklyAssignments.find(user => 
        user.userId === userId &&
        user.shifts.some(shift => 
          shift.shiftId === shiftId &&
          dayjs(shift.startDate).format('YYYY-MM-DD') <= selectedDate &&
          dayjs(shift.endDate).format('YYYY-MM-DD') >= selectedDate
        )
      );
      
      const existingAssigmentOnDay = weeklyAssignments.find(user => 
        user.userId === userId &&
        user.shifts.some(shift => 
          dayjs(shift.startDate).format('YYYY-MM-DD') === selectedDate
        )
      );

      // if (existingAssignment) {
      //   message.warning("Bu çalışan için seçilen tarihte aynı vardiya zaten atanmış!");
      //   return;
      // }
      
      if (existingAssigmentOnDay) {
        const result = await Swal.fire({
          icon: 'warning',
          title: 'Uyarı',
          text: `Bu çalışana bugün için zaten vardiya atanmış tekrar atamak istediğinize emin misiniz?`,
          confirmButtonText: 'Evet',
          cancelButtonText: 'Hayır',
          showCancelButton: true,
          showConfirmButton: true,
        })

          if (result.isConfirmed) {
            await shiftService.assignShift(assignmentData);
            message.success("Vardiya başarıyla atandı");
            // Atamaları yenile
            if (currentWeek.length > 0) {
              fetchWeeklyAssignments();
            }
          }
          else{
            return;
          }
      }

  

      
      setIsLoading(true);
      await shiftService.assignShift(assignmentData);
      message.success("Vardiya başarıyla atandı");
      
      // Atamaları yenile
      if (currentWeek.length > 0) {
        fetchWeeklyAssignments();
      }
      
      setIsModalVisible(false);
      form.resetFields();
    } catch (err: any) {
      handleApiError(err,t);
    } finally {
      setIsLoading(false);
    }
  };

  // Vardiya tipi oluştur/düzenle modalını aç
  const openShiftTypeModal = (shift: ShiftInfo | null = null) => {
    setEditingShift(shift);
    if (shift) {
      shiftTypeForm.setFieldsValue({
        name: shift.name,
        startTime: dayjs(shift.startTime, 'HH:mm'),
        endTime: dayjs(shift.endTime, 'HH:mm'),
        status: shift.status
      });
    } else {
      shiftTypeForm.resetFields();
    }
    setIsShiftTypeModalVisible(true);
  };

  // Vardiya tipi oluştur/düzenle
  const handleShiftTypeSubmit = async () => {
    try {
      const values = await shiftTypeForm.validateFields();
      
      const shiftData: ShiftCreateDTO = {
        name: values.name,
        startTime: values.startTime?.format ? values.startTime.format('HH:mm') : values.startTime,
        endTime: values.endTime?.format ? values.endTime.format('HH:mm') : values.endTime,
        status: values.status,
        validFrom: values.validFrom?.format ? values.validFrom.format('YYYY-MM-DD') : values.validFrom,
        validTo: values.validTo?.format ? values.validTo.format('YYYY-MM-DD') : values.validTo,
      };
      
      setIsLoading(true);
      
      if (editingShift) {
        await shiftService.updateShift(editingShift.id, shiftData);
        message.success("Vardiya tipi başarıyla güncellendi");
      } else {
        await shiftService.createShift(shiftData);
        message.success("Vardiya tipi başarıyla oluşturuldu");
      }
      
      fetchShifts();
      setIsShiftTypeModalVisible(false);
      shiftTypeForm.resetFields();
    } catch (err: any) {
      handleApiError(err,t);
    } finally {
      setIsLoading(false);
    }
  };

  // Vardiya tipini sil
  const handleDeleteShift = async (id: number) => {
    try {
      setIsLoading(true);
      await shiftService.deleteShift(id);
      message.success("Vardiya tipi başarıyla silindi");
      fetchShifts();
    } catch (err: any) {
      handleApiError(err,t);
    } finally {
      setIsLoading(false);
    }
  };

  // Ekran görüntüsü alma fonksiyonu
  const captureScreenshot = async () => {
    if (!calendarRef.current) return;
    
    try {
      message.loading({
        content: "Ekran görüntüsü alınıyor...",
        key: "screenshot",
      });
      
      const canvas = await html2canvas(calendarRef.current, {
        scale: 2, // Daha yüksek kalite için
        useCORS: true,
        logging: false,
        allowTaint: true,
        backgroundColor: "#ffffff"
      });
      
      // Canvas'ı resme dönüştür
      const imageUrl = canvas.toDataURL("image/png");
      
      // Resmi indir
      const link = document.createElement("a");
      link.download = `Vardiya-Takvimi-${currentWeek[0].format('DD-MM-YYYY')}-${currentWeek[6].format('DD-MM-YYYY')}.png`;
      link.href = imageUrl;
      link.click();
      
      message.success({
        content: "Ekran görüntüsü başarıyla kaydedildi",
        key: "screenshot",
      });
    } catch (error) {
      console.error("Ekran görüntüsü alınırken hata oluştu:", error);
      message.error({
        content: "Ekran görüntüsü alınırken bir hata oluştu",
        key: "screenshot",
      });
    }
  };
  
  // PDF oluşturma fonksiyonu
  const exportToPdf = async () => {
    if (!calendarRef.current) return;
    
    try {
      message.loading({
        content: "PDF oluşturuluyor...",
        key: "pdf",
      });
      
      const canvas = await html2canvas(calendarRef.current, {
        scale: 1.5,
        useCORS: true,
        logging: false,
        allowTaint: true,
        backgroundColor: "#ffffff"
      });
      
      const imgData = canvas.toDataURL("image/png");
      
      // Oluşturulan resmin boyutlarını al
      const imgWidth = 210; // A4 genişliği (mm)
      const pageHeight = 297; // A4 yüksekliği (mm)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      
      // PDF oluştur
      const pdf = new jsPDF("p", "mm", "a4");
      let position = 20;
      
      // İlk sayfayı ekle
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      
      // Gerekirse birden fazla sayfa ekle
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      
      // Başlık ekle
      pdf.setFontSize(16);
      pdf.text(`Haftalik Takvim (${currentWeek[0].format('DD.MM.YYYY')} - ${currentWeek[6].format('DD.MM.YYYY')})`, 105, 15, { align: 'center' });
      
      // PDF'i indir
      pdf.save(`Vardiya-Takvimi-${currentWeek[0].format('DD-MM-YYYY')}-${currentWeek[6].format('DD-MM-YYYY')}.pdf`);
      
      message.success({
        content: "PDF başarıyla oluşturuldu",
        key: "pdf",
      });
    } catch (error) {
      console.error("PDF oluşturulurken hata oluştu:", error);
      message.error({
        content: "PDF oluşturulurken bir hata oluştu",
        key: "pdf",
      });
    }
  };

  // Vardiya dağılımı için grafik verileri
  const pieChartData = {
    labels: shifts.filter(shift => shift.status==='active').map(shift => `${shift.name}`),
    datasets: [
      {
        data: shifts.filter(shift => shift.status==='active').map(shift => {
          return weeklyAssignments.reduce((count, user) => 
            count + user.shifts.filter(s => s.shiftId === shift.id).length, 0
          );
        }),
        backgroundColor: shifts.filter(shift => shift.status==='active').map(shift => {
          const color = shiftColors[shift?.id?.toString()] || shiftColors.default;
          return color;
        }),
        borderWidth: 1,
      },
    ],
  };

  // Günlük vardiya dağılımı için grafik verileri
  const barChartData = {
    labels: currentWeek.map(day => day.format('dddd')),
    datasets: [
      {
        label: 'Vardiya Sayısı',
        data: currentWeek.map(day => getDayAssignments(day).length),
        backgroundColor: '#4CAF50',
      },
    ],
  };

  // Vardiya tablosu için sütunlar
  const shiftColumns = [
    {
      title: "Çalışan",
      dataIndex: "userName",
      sorter: (a: DailyAssignment, b: DailyAssignment) => a.userName.localeCompare(b.userName)
    },
    {
      title: "Vardiya",
      render: (record: DailyAssignment) => {
        const shiftInfo = shifts.find(s => s.id === record.shiftId);
        return (
          <div>
            {shiftInfo?.name}
            {shiftInfo?.status === 'archived' && (
              <Badge 
                style={{ marginLeft: 8 }}
                count="Arşiv" 
                color="default"
              />
            )}
          </div>
        );
      }
    },
    {
      title: "Saat",
      render: (record: DailyAssignment) => `${record.startTime} - ${record.endTime}`
    },
  ];

  // Vardiya tipleri modalı için sütunlar
  const shiftTypeColumns = [
    {
      title: "Vardiya Adı",
      dataIndex: "name",
      sorter: (a: ShiftInfo, b: ShiftInfo) => a.name.localeCompare(b.name),
    },
    {
      title: "Başlangıç",
      dataIndex: "startTime",
      sorter: (a: ShiftInfo, b: ShiftInfo) => a.startTime.localeCompare(b.startTime),
    },
    {
      title: "Bitiş",
      dataIndex: "endTime",
      sorter: (a: ShiftInfo, b: ShiftInfo) => a.endTime.localeCompare(b.endTime),
    },
    {
      title: "Durum",
      dataIndex: "status",
      render: (status: string) => (
        <Badge 
          status={status === 'active' ? 'success' : 'default'} 
          text={status === 'active' ? 'Aktif' : 'Arşivlenmiş'} 
        />
      ),
    },
    {
      title: "Son Güncelleme",
      dataIndex: "updated",
      render: (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm'),
      sorter: (a: ShiftInfo, b: ShiftInfo) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix(),
    },
    {
      title: "İşlemler",
      render: (_: any, record: ShiftInfo) => (
        <div className="d-flex gap-2">
          <Button
            variant="primary"
            size="sm"
            onClick={() => openShiftTypeModal(record)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Bu vardiya tipini silmek istediğinize emin misiniz?"
            onConfirm={() => handleDeleteShift(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button variant="danger" size="sm">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // İstatistik kartlarının tasarımını iyileştir
  const StatisticCard = ({ 
    title, 
    value, 
    subtitle = "", 
    icon, 
    iconBg, 
    trend = null 
  }: { 
    title: string, 
    value: string | number, 
    subtitle?: string, 
    icon: React.ReactNode, 
    iconBg: string,
    trend?: { value: number, isPositive: boolean } | null 
  }) => (
    <Card className="h-100 border-0 shadow-sm">
      <Card.Body className="d-flex align-items-center p-4">
        <div 
          className="me-3 rounded-circle d-flex align-items-center justify-content-center"
          style={{ 
            width: "60px", 
            height: "60px", 
            backgroundColor: iconBg,
            color: "white"
          }}
        >
          {icon}
        </div>
        <div>
          <h6 className="text-muted mb-1 fs-sm">{title}</h6>
          <h3 className="mb-0 fw-bold">{value}</h3>
          {subtitle && <small className="text-muted">{subtitle}</small>}
          {trend && (
            <div className={`mt-2 text-${trend.isPositive ? 'success' : 'danger'} d-flex align-items-center`}>
              <i className={`ti ti-trend-${trend.isPositive ? 'up' : 'down'} me-1`}></i>
              <span>{trend.value}% geçen haftaya göre</span>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );

  // Günlük vardiyaları sınırla ve göster
  const renderDayAssignments = (day: dayjs.Dayjs) => {
    const assignments = getDayAssignments(day);
    
    if (assignments.length === 0) {
      return <div className="text-muted text-center mt-2">Vardiya yok</div>;
    }
    
    // Maksimum 3 kişi göster
    const visibleAssignments = assignments.slice(0, 3);
    const remainingCount = assignments.length - 3;
    
    return (
      <>
        {visibleAssignments.map((assignment, assignmentIndex) => {
          const shift = shifts.find(s => s.id === assignment.shiftId);
          return shift && assignment.userName ? (
            <div 
              key={assignmentIndex} 
              className="mb-1 p-1 rounded"
              style={{ backgroundColor: `${shiftColors[shift.id.toString()] || shiftColors.default}20` }}
            >
              <Badge 
                color={shiftColors[shift.id.toString()] || shiftColors.default} 
                text={`${assignment.userName} (${shift.name})`} 
              />
            </div>
          ) : null;
        })}
        
        {remainingCount > 0 && (
          <div className="text-center mt-2">
            <Badge color="blue" text={`+ ${remainingCount} kişi daha`} />
          </div>
        )}
      </>
    );
  };

  // Haftalık görünüm için vardiyaları filtrele
  const getFilteredShifts = useCallback((): ShiftInfo[] => {
    if (!currentWeek.length) return shifts;

    return shifts.filter(shift => {
      // Vardiya oluşturulma tarihinden önceki haftaları kontrol et
      const shiftCreatedDate = dayjs(shift.createdAt
        
      );
      const weekStart = currentWeek[1];
            
      // Eğer hafta başlangıcı vardiya oluşturulma tarihinden önceyse, bu vardiyayı gösterme
      if (weekStart.isBefore(shiftCreatedDate, 'day')) {
        return false;
      }

      // Eğer vardiya aktifse göster
      if (shift.status === 'active') return true;

      // Eğer vardiya arşivlenmişse, o hafta için atama var mı kontrol et
      const hasAssignmentsInWeek = weeklyAssignments.some(user =>
        user.shifts.some(userShift => {
          // Vardiya ID'si eşleşiyor mu?
          if (userShift.shiftId !== shift.id) return false;

          // Vardiya tarihleri hafta içinde mi?
          const shiftStartDate = dayjs(userShift.startDate);
          const shiftEndDate = dayjs(userShift.endDate);
          const weekEnd = currentWeek[6];

          // Arşivlenme tarihinden sonraki atamalar gösterilmeyecek
          if (shift.status === 'archived') {
            const archiveDate = dayjs(shift.updatedAt);
            if (shiftStartDate.isAfter(archiveDate)) {
              return false;
            }
          }

          return (
            (shiftStartDate.isSame(weekStart, 'day') || shiftStartDate.isAfter(weekStart)) &&
            (shiftEndDate.isSame(weekEnd, 'day') || shiftEndDate.isBefore(weekEnd))
          );
        })
      );

      return hasAssignmentsInWeek;
    });
  }, [shifts, weeklyAssignments, currentWeek]);

  return (
    <>
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Vardiya Yönetimi</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Personel</li>
                  <li className="breadcrumb-item active">Vardiya Yönetimi</li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <div className="d-flex flex-column align-items-stretch w-100" style={{ gap: '12px' }}>
                <div className="d-flex flex-column flex-sm-row align-items-stretch" style={{ gap: '10px' }}>
                  <Button 
                    variant="primary" 
                    className="d-flex align-items-center justify-content-center w-100"
                    onClick={() => {
                      setSelectedDay(dayjs());
                      setIsModalVisible(true);
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <PlusOutlined className="me-2" /> 
                    <span>Yeni Vardiya</span>
                  </Button>
                  <Button 
                    variant="success" 
                    className="d-flex align-items-center justify-content-center w-100"
                    onClick={() => openShiftTypeModal()}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <i className="fa fa-cog me-2"></i>
                    <span>Vardiya Tipleri</span>
                  </Button>
                </div>
                <div className="d-flex align-items-center w-100">
                  <DatePicker 
                    picker="week" 
                    value={selectedDate}
                    onChange={(date) => date && setSelectedDate(date)}
                    format="YYYY - [Hafta] w"
                    allowClear={false}
                    className="flex-grow-1"
                    style={{
                      width: '100%',
                      marginBottom: '0px',
                    }}
                  />
                  <i className="ti ti-calendar ms-2 fs-3"></i>
                </div>
              </div>
            </div>
          </div>

          {/* İstatistik Kartları */}
          <Row className="mb-4">
            <Col md={3}>
              <StatisticCard
                title="Vardiya Tipi"
                value={shifts.length}
                icon={<i className="ti ti-alarm fs-3"></i>}
                iconBg="#4A6CF7"
              />
            </Col>
            <Col md={3}>
              <StatisticCard
                title="Toplam Atama"
                value={weeklyAssignments.length}
                subtitle={`Haftalık: ${currentWeek.length > 0 ? weeklyAssignments.length : 0}`}
                icon={<i className="ti ti-calendar-event fs-3"></i>}
                iconBg="#6AD2Ff"
              />
            </Col>
            <Col md={3}>
              <StatisticCard
                title="Ortalama"
                value={(weeklyAssignments.length / (users?.length || 1)).toFixed(1)}
                subtitle="Kişi başına vardiya"
                icon={<i className="ti ti-chart-bar fs-3"></i>}
                iconBg="#F7C32E"
              />
            </Col>
            <Col md={3}>
              <StatisticCard
                title="En Yoğun Gün"
                value={currentWeek.length > 0 ? (
                  [...currentWeek].map(day => ({
                    day,
                    count: weeklyAssignments.reduce((total, user) => 
                      total + user.shifts.filter(shift => 
                        dayjs(shift.startDate).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
                      ).length
                    , 0)
                  })).sort((a, b) => b.count - a.count)[0]?.day.format('dddd') || "-"
                ) : "-"}
                icon={<i className="ti ti-stack-2 fs-3"></i>}
                iconBg="#4CAF50"
              />
            </Col>
          </Row>

          {/* Grafikler */}
          <Row className="mb-4">
            <Col md={6}>
              <Card className="h-100">
                <Card.Body>
                  <h5 className="card-title">Vardiya Dağılımı</h5>
                  <div style={{ height: "250px" }}>
                    {shifts.length > 0 ? (
                      <Pie 
                        data={pieChartData} 
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <p className="text-muted">Henüz vardiya tipi tanımlanmamış</p>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="h-100">
                <Card.Body>
                  <h5 className="card-title">Haftalık Vardiya Dağılımı</h5>
                  <div style={{ height: "250px" }}>
                    {currentWeek.length > 0 ? (
                      <Bar 
                        data={barChartData} 
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                        }}
                      />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <p className="text-muted">Yükleniyor...</p>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Haftalık Takvim */}
          <Card className="mb-0">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <h4 className="mb-0">Haftalık Vardiya Takvimi</h4>
                <span className="ms-2 text-muted">
                  {currentWeek.length > 0 ? (
                    `${currentWeek[0].format('DD MMMM')} - ${currentWeek[6].format('DD MMMM YYYY')}`
                  ) : (
                    "Yükleniyor..."
                  )}
                </span>
              </div>
              <div className="d-flex">
              <Button 
                  variant="outline-info" 
                  className="me-2 d-flex align-items-center" 
                  onClick={captureScreenshot}
                >
                  <CameraOutlined className="me-1" /> PNG
                </Button>
                <Button 
                  variant="outline-danger" 
                  className="d-flex align-items-center me-2" 
                  onClick={exportToPdf}
                >
                  <FilePdfOutlined className="me-1" /> PDF
                </Button>
                <Button variant="light" className="me-2" onClick={goToPreviousWeek}>
                  <ArrowLeftOutlined />
                </Button>
                <Button variant="light" className="me-2" onClick={goToNextWeek}>
                  <ArrowRightOutlined />
                </Button>
                
              </div>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <div className="text-center p-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                  </div>
                </div>
              ) : (
                <div className="table-responsive" ref={calendarRef}>
                  <table className="table table-bordered shift-table">
                    <thead>
                      <tr>
                        <th style={{ width: '200px', backgroundColor: '#f8f9fa' }}>Vardiyalar</th>
                        {currentWeek.map((day, index) => (
                          <th 
                            key={index} 
                            className="text-center"
                            style={{
                              backgroundColor: day.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') 
                                ? '#e6f7ff' 
                                : day.day() === 0 || day.day() === 6 ? '#f9f9f9' : '#fff'
                            }}
                          >
                            <div className="mb-1 fw-bold">{day.format('dddd')}</div>
                            <div className="small text-muted">{day.format('DD MMMM')}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getFilteredShifts().map((shift: ShiftInfo) => (
                        <tr key={shift.id}>
                          <td 
                            className="align-middle fw-bold" 
                            style={{ 
                              backgroundColor: `${shiftColors[shift.id.toString()] || shiftColors.default}15`,
                              borderLeft: `4px solid ${shiftColors[shift.id.toString()] || shiftColors.default}`
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div 
                                className="rounded-circle me-2" 
                                style={{ 
                                  width: '12px', 
                                  height: '12px', 
                                  backgroundColor: shiftColors[shift.id.toString()] || shiftColors.default 
                                }}
                              ></div>
                              <div>
                                <div>{shift.name}</div>
                                <small className="text-muted">{shift.startTime} - {shift.endTime}</small>
                              </div>
                            </div>
                          </td>
                          
                          {/* Haftanın her günü için hücre oluştur */}
                          {currentWeek.map((day, dayIndex) => {
                            // Bu gün ve vardiya için atanan personeli bul
                            const dayStr = day.format('YYYY-MM-DD');
                            const cellAssignments = getDayAssignments(day).filter(a => a.shiftId === shift.id);
                            const isArchived = shift.status === 'archived';
                            
                            return (
                              <td 
                                key={dayIndex} 
                                className={`position-relative shift-cell ${isArchived ? 'archived-shift' : ''}`}
                                style={{
                                  backgroundColor: cellAssignments.length > 0 
                                    ? `${shiftColors[shift.id.toString()] || shiftColors.default}10` 
                                    : 'transparent',
                                  cursor: isArchived ? 'not-allowed' : 'pointer',
                                  minHeight: '100px',
                                  opacity: isArchived ? 0.6 : 1
                                }}
                                onClick={() => {
                                  if (isArchived) {
                                    message.warning('Arşivlenmiş vardiyaya atama yapılamaz!');
                                    return;
                                  }
                                  setSelectedDay(day);
                                  setIsModalVisible(true);
                                  form.setFieldsValue({
                                    date: day,
                                    shiftId: shift.id
                                  });
                                  // Seçilen güne ait vardiyaları getir
                                  const assignmentsForDay = getDayAssignments(day);
                                  setDailyAssignments(assignmentsForDay.filter(a => a.shiftId === shift.id));
                                }}
                              >
                                {cellAssignments.length > 0 ? (
                                  <div className="p-2">
                                    {cellAssignments.slice(0, 3).map((assignment, idx) => (
                                      assignment.userName && (
                                        <div 
                                          key={idx} 
                                          className="mb-1 p-1 rounded d-flex align-items-center"
                                          style={{ 
                                            backgroundColor: `${shiftColors[shift.id.toString()] || shiftColors.default}15`,
                                            border: `1px solid ${shiftColors[shift.id.toString()] || shiftColors.default}30`,
                                          }}
                                        >
                                          <div className="avatar-xs me-2">
                                            <div 
                                              className="rounded-circle d-flex align-items-center justify-content-center"
                                              style={{ 
                                                width: '24px', 
                                                height: '24px', 
                                                backgroundColor: shiftColors[shift.id.toString()] || shiftColors.default,
                                                color: 'white',
                                                fontSize: '10px',
                                                fontWeight: 'bold'
                                              }}
                                            >
                                              {assignment.userName.charAt(0)}
                                            </div>
                                          </div>
                                          <div className="text-truncate small" style={{ maxWidth: '120px' }}>
                                            {assignment.userName}
                                          </div>
                                        </div>
                                      )
                                    ))}
                                    
                                    {cellAssignments.length > 3 && (
                                      <div className="text-center mt-1">
                                        <Badge 
                                          color={shiftColors[shift.id.toString()] || shiftColors.default} 
                                          text={`+ ${cellAssignments.length - 3} kişi daha`} 
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-center h-100 p-3 text-muted">
                                    <i className="ti ti-plus me-1"></i> Ekle
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* Vardiya Atama Modalı */}
      <Modal
        title={selectedDay ? `${selectedDay.format('DD MMMM YYYY - dddd')} Vardiya Ataması` : "Vardiya Ataması"}
        open={isModalVisible}
        onCancel={handleCancel}
        confirmLoading={isLoading}
        okText="Vardiyayı Ata"
        cancelText="İptal"
        onOk={handleAssignShift}
        width={700}
      >
        <div className="mb-4">
          <h5>Mevcut Vardiyalar</h5>
          <Table 
            dataSource={dailyAssignments}
            columns={shiftColumns}
            Selection={false}
          />
        </div>

        <h5>Yeni Vardiya Ataması</h5>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ date: selectedDay }}
        >
          <Form.Item
            name="employeeId"
            label="Çalışan"
            rules={[{ required: true, message: 'Lütfen çalışan seçiniz!' }]}
          >
            <Select 
              placeholder="Çalışan seçiniz"
              loading={usersLoading}
              showSearch
              optionFilterProp="children"
            >
              {users && users.map((user: User) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="shiftId"
            label="Vardiya Tipi"
            rules={[{ required: true, message: 'Lütfen vardiya tipi seçiniz!' }]}
          >
            <Select placeholder="Vardiya tipi seçiniz">
              {shifts.filter(shift => shift.status === 'active').map(shift => (
                <Select.Option key={shift.id} value={shift.id}>
                  <Badge 
                    color={shiftColors[shift?.id?.toString()] || shiftColors.default} 
                    text={`${shift.name} (${shift.startTime} - ${shift.endTime})`} 
                  />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item 
            name="date" 
            label="Tarih"
            rules={[{ required: true, message: 'Lütfen tarih seçiniz!' }]}
          >
            <DatePicker 
              className="w-100" 
              format="DD.MM.YYYY"
              value={selectedDay || undefined}
            />
          </Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item
                name="timeRange"
                label="Vardiya Saatleri (Opsiyonel)"
                rules={[{ required: false, message: 'Lütfen vardiya saatlerini seçiniz!' }]}
              >
                <AntTimePicker.RangePicker 
                  className="w-100"
                  format="HH:mm"
                  placeholder={["Başlangıç", "Bitiş"]}
                  onChange={(times) => {
                    if (times && times[0] && times[1]) {
                      form.setFieldsValue({
                        startTime: times[0],
                        endTime: times[1]
                      });
                    }
                  }}
                />
              </Form.Item>
              
              {/* Gizli alanlar */}
              <Form.Item name="startTime" hidden={true} noStyle>
                <Input />
              </Form.Item>
              <Form.Item name="endTime" hidden={true} noStyle>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Vardiya Tipi Ekleme/Düzenleme Modalı */}
      <Modal
        title={editingShift ? "Vardiya Tipini Düzenle" : "Yeni Vardiya Tipi Ekle"}
        open={isShiftTypeModalVisible}
        onCancel={() => setIsShiftTypeModalVisible(false)}
        confirmLoading={isLoading}
        okText={editingShift ? "Güncelle" : "Ekle"}
        cancelText="İptal"
        onOk={handleShiftTypeSubmit}
        width={600}
      >
        <Form
          form={shiftTypeForm}
          layout="vertical"
          initialValues={{ flexTime: 0 }}
        >
          <Form.Item
            name="name"
            label="Vardiya Adı"
            rules={[{ required: true, message: 'Lütfen vardiya adı giriniz!' }]}
          >
            <Input placeholder="Örn: Sabah Vardiyası" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="startTime"
                label="Başlangıç Saati"
                rules={[{ required: true, message: 'Lütfen başlangıç saati seçiniz!' }]}
              >
                <AntTimePicker 
                  className="w-100"
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="endTime"
                label="Bitiş Saati"
                rules={[{ required: true, message: 'Lütfen bitiş saati seçiniz!' }]}
              >
                <AntTimePicker 
                  className="w-100"
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
           
          </Row>
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                name="validFrom"
                label="Ne zaman başlasın"
              >
                <DatePicker className="w-100" format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
            <Col span={12}> 
              <Form.Item
                name="validTo"
                label="Ne zaman bitsin"
              >
                <DatePicker className="w-100" format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {shifts.length > 0 && (
          <div className="mt-4">
            <h5>Mevcut Vardiya Tipleri</h5>
            <Table 
              dataSource={shifts.filter(shift => shift.status === 'active')}
              columns={shiftTypeColumns}
              Selection={false}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ShiftPage;
