export const attendance_employee_details = [
  {
    Date: "14 Jan 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "06:45 PM",
    Break: "30 Min",
    Late: "32 Min",
    Overtime: "20 Min",
    Production: 1,
    ProductionHours: "8.55 Hrs",
  },
  {
    Date: "21 Jan 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "06:12 PM",
    Break: "20 Min",
    Late: "-",
    Overtime: "45 Min",
    Production: 3,
    ProductionHours: "7.54 Hrs",
  },
  {
    Date: "20 Feb 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "06:13 PM",
    Break: "50 Min",
    Late: "-",
    Overtime: "33 Min",
    Production: 1,
    ProductionHours: "8.45 Hrs",
  },
  {
    Date: "15 Mar 2024",
    CheckIn: "09:00 AM",
    Status: "Absent",
    CheckOut: "06:23 PM",
    Break: "41 Min",
    Late: "-",
    Overtime: "50 Min",
    Production: 1,
    ProductionHours: "8.35 Hrs",
  },
  {
    Date: "12 Apr 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "06:43 PM",
    Break: "23 Min",
    Late: "-",
    Overtime: "10 Min",
    Production: 1,
    ProductionHours: "8.22 Hrs",
  },
  {
    Date: "20 May 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "07:15 PM",
    Break: "03 Min",
    Late: "-",
    Overtime: "-",
    Production: 1,
    ProductionHours: "8.32 Hrs",
  },
  {
    Date: "06 Jul 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "07:13 PM",
    Break: "32 Min",
    Late: "-",
    Overtime: "-",
    Production: 1,
    ProductionHours: "9.15 Hrs",
  },
  {
    Date: "02 Sep 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "09:17 PM",
    Break: "14 Min",
    Late: "12 Min",
    Overtime: "-",
    Production: 1,
    ProductionHours: "8.35 Hrs",
  },
  {
    Date: "10 Dec 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "09:23 PM",
    Break: "10 Min",
    Late: "-",
    Overtime: "45 Min",
    Production: 1,
    ProductionHours: "9.25 Hrs",
  },
  {
    Date: "15 Nov 2024",
    CheckIn: "09:00 AM",
    Status: "Present",
    CheckOut: "08:15 PM",
    Break: "12 Min",
    Late: "-",
    Overtime: "-",
    Production: 3,
    ProductionHours: "8.35 Hrs",
  },
];
