import React from 'react';
import { Select, DatePicker } from 'antd';
import { DepartmentDto } from '../../../../types/department.types';
import { Store } from '../../../../types/shop.types';
import { BreakType } from '../../../../types/break.types';
import dayjs from 'dayjs';
import FilterDropdown from '../../../shared/components/filter/FilterDropdown';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface BreakFiltersProps {
  selectedDateRange: [string, string] | null;
  selectedDepartment: string;
  selectedShop: string;
  selectedBreakType: string | null;
  selectedGender: string;
  selectedDuration: string | null;
  departments: DepartmentDto[];
  shops: Store[];
  onDateRangeChange: (dates: [string, string] | null) => void;
  onDepartmentChange: (departmentId: string) => void;
  onShopChange: (shopId: string) => void;
  onBreakTypeChange: (type: string | null) => void;
  onGenderChange: (gender: string) => void;
  onDurationChange: (duration: string | null) => void;
  onReset: () => void;
}

const BreakFilters: React.FC<BreakFiltersProps> = ({
  selectedDateRange,
  selectedDepartment,
  selectedShop,
  selectedBreakType,
  selectedGender,
  selectedDuration,
  departments,
  shops,
  onDateRangeChange,
  onDepartmentChange,
  onShopChange,
  onBreakTypeChange,
  onGenderChange,
  onDurationChange,
  onReset,
}) => {
  const { t } = useTranslation();

  // Mola tipi seçeneklerini oluştur
  const getBreakTypeOptions = () => {
    return [
      { value: 'all', label: t('breakReport.filters.allBreakTypes') },
      { value: BreakType.BREAK, label: t('breakTypes.short') },
      { value: BreakType.LUNCH, label: t('breakTypes.lunch') },
      { value: BreakType.PERSONAL, label: t('breakTypes.personal') },
      { value: BreakType.OTHER, label: t('breakTypes.other') }
    ];
  };
  
  const filterGroups = [
    {
      label: t('breakReport.filters.dateRange'),
      type: 'dateRange' as const,
      value: selectedDateRange ? [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])] : null,
      onChange: (dates: any) => {
        if (dates) {
          onDateRangeChange([dates[0].toISOString(), dates[1].toISOString()]);
        } else {
          onDateRangeChange(null);
        }
      }
    },
    {
      label: t('breakReport.filters.department'),
      type: 'select' as const,
      value: selectedDepartment,
      onChange: onDepartmentChange,
      options: [
        { value: 'all', label: t('breakReport.filters.allDepartments') },
        ...departments.map(dept => ({
          value: dept.id.toString(),
          label: dept.name
        }))
      ]
    },
    {
      label: t('breakReport.filters.branch'),
      type: 'select' as const,
      value: selectedShop,
      onChange: onShopChange,
      options: [
        { value: 'all', label: t('breakReport.filters.allBranches') },
        ...shops.map(shop => ({
          value: shop.id.toString(),
          label: shop.name
        }))
      ]
    },
    {
      label: t('breakReport.filters.breakType'),
      type: 'select' as const,
      value: selectedBreakType,
      onChange: onBreakTypeChange,
      options: getBreakTypeOptions()
    },
    {
      label: t('breakReport.filters.gender'),
      type: 'select' as const,
      value: selectedGender,
      onChange: onGenderChange,
      options: [
        { value: 'all', label: t('breakReport.filters.allGenders') },
        { value: 'male', label: t('common.gender.male') },
        { value: 'female', label: t('common.gender.female') }
      ]
    },
    {
      label: t('breakReport.filters.duration'),
      type: 'select' as const,
      value: selectedDuration,
      onChange: onDurationChange,
      options: [
        { value: 'all', label: t('breakReport.filters.allDurations') },
        { value: '15', label: t('breakReport.filters.duration15min') },
        { value: '30', label: t('breakReport.filters.duration15to30min') },
        { value: '60', label: t('breakReport.filters.duration30to60min') },
        { value: '60+', label: t('breakReport.filters.duration60minPlus') }
      ]
    }
  ];

  return (
    <FilterDropdown
      groups={filterGroups}
      onReset={onReset}
      buttonType="primary"
    />
  );
};

export default BreakFilters; 