import React from 'react';
import FilterDropdown from '../../../shared/components/filter/FilterDropdown';
import { DepartmentDto } from '../../../../types/department.types';
import { Store } from '../../../../types/shop.types';
import { UserStatus } from '../../../../types/user.types';
import { useTranslation } from 'react-i18next';

interface EmployeeFiltersProps {
  departments: DepartmentDto[];
  shops: Store[];
  selectedDepartment: string;
  selectedShop: string;
  selectedStatus: string | UserStatus;
  onDepartmentChange: (departmentId: string) => void;
  onShopChange: (shopId: string) => void;
  onStatusChange: (status: string | UserStatus) => void;
  onReset: () => void;
}

const EmployeeFilters: React.FC<EmployeeFiltersProps> = ({
  departments,
  shops,
  selectedDepartment,
  selectedShop,
  selectedStatus,
  onDepartmentChange,
  onShopChange,
  onStatusChange,
  onReset
}) => {
  const { t } = useTranslation();
  const filterGroups = [
    {
      label: t('employees.filters.department'),
      type: 'select' as const,
      value: selectedDepartment,
      onChange: onDepartmentChange,
      options: [
        { value: 'all', label: t('employees.filters.allDepartments') },
        ...departments.map(dept => ({
          value: dept.id.toString(),
          label: dept.name
        }))
      ]
    },
    {
      label: t('employees.filters.shop'),
      type: 'select' as const,
      value: selectedShop,
      onChange: onShopChange,
      options: [
        { value: 'all', label: t('employees.filters.allShops') },
        ...shops.map(shop => ({
          value: shop.id.toString(),
          label: shop.name
        }))
      ]
    },
    {
      label: t('employees.filters.status'),
      type: 'select' as const,
      value: selectedStatus,
      onChange: onStatusChange,
      options: [
        { value: 'all', label: t('employees.filters.allStatuses') },
        { value: 'active', label: t('employees.filters.active') },
        { value: 'resigned', label: t('employees.filters.resigned') },
        { value: 'deleted', label: t('employees.filters.deleted') }
      ]
    }
  ];

  return (
    <FilterDropdown
      groups={filterGroups}
      onReset={onReset}
      buttonType="primary"
    />
  );
};

export default EmployeeFilters; 