import React from "react";
import { Link } from "react-router-dom";
import { LeaveAdminUpdateDTO, LeaveDto, LeaveStatus } from "../../../../types/leaves.types";
import Table from "../../../shared/dataTable/index";
import { LEAVE_STATUSES, LEAVE_TYPES } from "../../../../constants/leaveConstants";
import { formatDate } from "../../../../utils/date";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import LeaveStatusDropdown from './LeaveStatusDropdown';
import { renderUserNameCell } from "../../../../utils/tableUtils";
import { getFileUrl } from "../../../../utils/file.utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface LeaveTableProps {
    leaves: LeaveDto[];
    onStatusChange: (leaveId: number, dto: LeaveAdminUpdateDTO) => void; // Update type
}

export const LeaveTable: React.FC<LeaveTableProps> = ({ leaves, onStatusChange }) => {

  const { t } = useTranslation();

const columns = [
    {
      title: t('leaves.table.user'),
      dataIndex: ["user", "firstName"],
      render: (_:string|undefined,record: LeaveDto) => (
        <div className="d-flex align-items-center">
          {record.user && (
            <>
              {record.user.avatar ? (
                <Avatar 
                  src={record.user.avatar} 
                  className="me-2"
                  size="small"
                />
              ) : (
                <Avatar 
                  icon={<UserOutlined />} 
                  className="me-2"
                  size="small"
                />
              )}
              <span>
                {renderUserNameCell(record.user.firstName, record.user)}
              </span>
            </>
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.user.firstName.length - b.user.firstName.length,
    },
    {
      title: t('leaves.table.leaveType'),
      dataIndex: "type",
      render: (text: string, record: LeaveDto) => (
        <div className="d-flex align-items-center">
          <p className="fs-14 fw-medium d-flex align-items-center mb-0">
            {t(`leaveTypes.${record.type}`)}
          </p>
        </div>
      ),
      sorter: (a: any, b: any) => a.type.length - b.type.length,
    },
    {
      title: t('leaves.table.date'),
      dataIndex: "startDate",
      render: (_: string, record: LeaveDto) => (
        record.type === 'hourly' ? (
          <p className="fs-14 mb-0">
            {formatDate(record.startDate)}
            <br />
            <small className="text-muted">
              {record.startTime} - {record.endTime}
            </small>
          </p>
        ) : (
          <p className="fs-14 mb-0">
            {formatDate(record.startDate)} -
            <br />
            {formatDate(record.endDate)}
          </p>
        )
      ),
    },
    {
      title: t('leaves.table.description'),
      dataIndex: "reason", // Assuming `Description` is the field in your data
      render: (reason: string) => (
        reason.length > 30 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip title={reason} placement="top">{reason}</Tooltip>}
          >
            <div className="position-relative">
              <p 
                className="fs-14 mb-0" 
                style={{ 
                  maxWidth: "100px", 
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "22px"
                }}
              >
                {reason}
                <span 
                  className="text-primary"
                  style={{ 
                    position: "absolute", 
                    right: 0, 
                    top: "50%", 
                    transform: "translateY(-50%)",
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    opacity: 0.8
                  }}
                >
                  <i className="ti ti-info-circle" />
                </span>
              </p>
            </div>
          </OverlayTrigger>
        ) : (
          <p className="fs-14 mb-0">{reason}</p>
        )
      ),
      sorter: (a: any, b: any) => a.reason.length - b.reason.length,
    },
    {
      title: t('leaves.table.file'),
      dataIndex: "file",
      render: (_: string, record: LeaveDto) => (
        <p className="fs-14 mb-0">
          {record.documentUrl ?  
          <a 
            className="dropdown-item" 
            href={getFileUrl(record.documentUrl)} 
            target="_blank"
            rel="noopener noreferrer"
          >
            Görüntüle
          </a> : '-'}
        </p>
      ),
    },
    {
      title: t('leaves.table.status'),
      dataIndex: "status", // Assuming `AdminStatus` is the field in your data
      render: (status: LeaveStatus, record: LeaveDto) => (
        <LeaveStatusDropdown
          status={status}
          onStatusChange={(dto) => onStatusChange(record.id, dto)}
          disabled={!canUpdateStatus(record)}
        />
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (text: string, record: LeaveDto) => (
    //     <div className="action-icon d-inline-flex">
    //       <Link
    //         to="#"
    //         className="me-2"
    //         data-bs-toggle="modal"
    //         data-bs-target="#admin_leave_modal"
    //         onClick={() => handleEditClick(record)} // Edit butonuna tıklanınca handleEditClick fonksiyonu çalışacak
    //       >
    //         <i className="ti ti-edit" />
    //       </Link>
    //       <Link to="#" data-bs-toggle="modal" data-bs-target="#delete_modal">
    //         <i className="ti ti-trash" />
    //       </Link>
    //     </div>
    //   ),
    // }
  ];

  return (
    <Table columns={columns} dataSource={leaves} Selection={false}/>
  );
}

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'approved': return 'success';
      case 'rejected': return 'danger';
      default: return 'warning';
    }
  };

  const canUpdateStatus = (leave: LeaveDto): boolean => {
    // Implement the logic to determine if the status can be updated
    return true; // Placeholder return, actual implementation needed
  };