import React from 'react';
import { Navigate, Route } from "react-router";
import { all_routes } from "./all_routes";
import DataTable from "../components/features/dashboard/employee-dashboard";
import BasicTable from "../components/features/dashboard/employee-dashboard";
import Login from "../components/features/auth/login/login";
import Register from "../components/features/auth/register/register";
import TwoStepVerification from "../components/features/auth/twoStepVerification/twoStepVerification";
import EmailVerification from "../components/features/auth/emailVerification/emailVerification";
import ResetPassword from "../components/features/auth/resetPassword/resetPassword";
import ForgotPassword from "../components/features/auth/forgotPassword/forgotPassword";
import Accordion from "../components/shared/uiInterface/base-ui/accordion";
import Avatar from "../components/shared/uiInterface/base-ui/avatar";
import Borders from "../components/shared/uiInterface/base-ui/borders";
import Breadcrumb from "../components/shared/uiInterface/base-ui/breadcrumb";
import Buttons from "../components/shared/uiInterface/base-ui/buttons";
  import ButtonsGroup from "../components/shared/uiInterface/base-ui/buttonsgroup";
import Cards from "../components/shared/uiInterface/base-ui/cards";
import Carousel from "../components/shared/uiInterface/base-ui/carousel";
import Colors from "../components/shared/uiInterface/base-ui/colors";
import Dropdowns from "../components/shared/uiInterface/base-ui/dropdowns";
import Grid from "../components/shared/uiInterface/base-ui/grid";
import Images from "../components/shared/uiInterface/base-ui/images";
import Lightboxes from "../components/shared/uiInterface/base-ui/lightbox";
import Media from "../components/shared/uiInterface/base-ui/media";
  import Modals from "../components/shared/uiInterface/base-ui/modals";
import NavTabs from "../components/shared/uiInterface/base-ui/navtabs";
import Offcanvas from "../components/shared/uiInterface/base-ui/offcanvas";
import Pagination from "../components/shared/uiInterface/base-ui/pagination";
import Popovers from "../components/shared/uiInterface/base-ui/popover";
import RangeSlides from "../components/shared/uiInterface/base-ui/rangeslider";
import Progress from "../components/shared/uiInterface/base-ui/progress";
import Spinner from "../components/shared/uiInterface/base-ui/spinner";
import Toasts from "../components/shared/uiInterface/base-ui/toasts";
import Typography from "../components/shared/uiInterface/base-ui/typography";
import Video from "../components/shared/uiInterface/base-ui/video";
import Error404 from "../components/shared/error/error-404";
import Error500 from "../components/shared/error/error-500";
import ClipBoard from "../components/shared/uiInterface/advanced-ui/clipboard";
import Counter from "../components/shared/uiInterface/advanced-ui/counter";
import DragAndDrop from "../components/shared/uiInterface/advanced-ui/dragdrop";
import Rating from "../components/shared/uiInterface/advanced-ui/rating";
import Stickynote from "../components/shared/uiInterface/advanced-ui/stickynote";
import TextEditor from "../components/shared/uiInterface/advanced-ui/texteditor";
import Timeline from "../components/shared/uiInterface/advanced-ui/timeline";
  import Scrollbar from "../components/shared/uiInterface/advanced-ui/uiscrollbar";
import Apexchart from "../components/shared/uiInterface/charts/apexcharts";
import FeatherIcons from "../components/shared/uiInterface/icons/feathericon";
import FontawesomeIcons from "../components/shared/uiInterface/icons/fontawesome";
import MaterialIcons from "../components/shared/uiInterface/icons/materialicon";
import PE7Icons from "../components/shared/uiInterface/icons/pe7icons";
import SimplelineIcons from "../components/shared/uiInterface/icons/simplelineicon";
    import ThemifyIcons from "../components/shared/uiInterface/icons/themify";
import TypiconIcons from "../components/shared/uiInterface/icons/typicons";
import WeatherIcons from "../components/shared/uiInterface/icons/weathericons";
import BasicInputs from "../components/shared/uiInterface/forms/formelements/basic-inputs";
import CheckboxRadios from "../components/shared/uiInterface/forms/formelements/checkbox-radios";
import InputGroup from "../components/shared/uiInterface/forms/formelements/input-group";
import GridGutters from "../components/shared/uiInterface/forms/formelements/grid-gutters";
import FormSelect from "../components/shared/uiInterface/forms/formelements/form-select";
import FormMask from "../components/shared/uiInterface/forms/formelements/form-mask";
import FileUpload from "../components/shared/uiInterface/forms/formelements/fileupload";
import FormHorizontal from "../components/shared/uiInterface/forms/formelements/layouts/form-horizontal";
import FormVertical from "../components/shared/uiInterface/forms/formelements/layouts/form-vertical";
import FloatingLabel from "../components/shared/uiInterface/forms/formelements/layouts/floating-label";
import FormValidation from "../components/shared/uiInterface/forms/formelements/layouts/form-validation";
import FormSelect2 from "../components/shared/uiInterface/forms/formelements/layouts/form-select2";
import FormWizard from "../components/shared/uiInterface/forms/formelements/form-wizard";
import DataTables from "../components/shared/uiInterface/table/data-tables";
import TablesBasic from "../components/shared/uiInterface/table/tables-basic";
import IonicIcons from "../components/shared/uiInterface/icons/ionicicons";
import Badges from "../components/shared/uiInterface/base-ui/badges";
import Placeholder from "../components/shared/uiInterface/base-ui/placeholder";
import Alert from "../components/shared/uiInterface/base-ui/alert";
import Tooltips from "../components/shared/uiInterface/base-ui/tooltips";
import Ribbon from "../components/shared/uiInterface/advanced-ui/ribbon";
import AlertUi from "../components/shared/uiInterface/base-ui/alert-ui";
import AdminDashboard from "../components/features/dashboard/index";

import Login2 from "../components/features/auth/login/login-2";
import Login3 from "../components/features/auth/login/login-3";
import ResetPassword2 from "../components/features/auth/resetPassword/resetPassword-2";
import ResetPassword3 from "../components/features/auth/resetPassword/resetPassword-3";
import TwoStepVerification2 from "../components/features/auth/twoStepVerification/twoStepVerification-2";
import TwoStepVerification3 from "../components/features/auth/twoStepVerification/twoStepVerification-3";
import Register2 from "../components/features/auth/register/register-2";
import Register3 from "../components/features/auth/register/register-3";
import ForgotPassword2 from "../components/features/auth/forgotPassword/forgotPassword-2";
import ForgotPassword3 from "../components/features/auth/forgotPassword/forgotPassword-3";
import ResetPasswordSuccess from "../components/features/auth/resetPasswordSuccess/resetPasswordSuccess";
import ResetPasswordSuccess2 from "../components/features/auth/resetPasswordSuccess/resetPasswordSuccess-2";
import ResetPasswordSuccess3 from "../components/features/auth/resetPasswordSuccess/resetPasswordSuccess-3";

import Profile from "../components/features/profile";
import LockScreen from "../components/features/auth/lockScreen";
import EmailVerification2 from "../components/features/auth/emailVerification/emailVerification-2";
import EmailVerification3 from "../components/features/auth/emailVerification/emailVerification-3";
import EmployeeDashboard from "../components/features/dashboard/employee-dashboard";
import Leaflet from "../components/shared/uiInterface/map/leaflet";
import BootstrapIcons from "../components/shared/uiInterface/icons/bootstrapicons";
import RemixIcons from "../components/shared/uiInterface/icons/remixIcons";
import FlagIcons from "../components/shared/uiInterface/icons/flagicons";
import Swiperjs from "../components/shared/uiInterface/base-ui/swiperjs";
import Sortable from "../components/shared/uiInterface/base-ui/ui-sortable";
import PrimeReactChart from "../components/shared/uiInterface/charts/prime-react-chart";
import ChartJSExample from "../components/shared/uiInterface/charts/chartjs";
import FormPikers from "../components/shared/uiInterface/forms/formelements/formpickers";
import ExpensesReport from "../components/features/reports/expensereport";
import InvoiceReport from "../components/features/reports/invoicereport";
import PaymentReport from "../components/features/reports/paymentreport";
import ProjectReport from "../components/features/reports/projectreport";
import TaskReport from "../components/features/reports/taskreport";
import UserReports from "../components/features/reports/userreports";
import EmployeeReports from "../components/features/reports/employeereports";
import PayslipReport from "../components/features/reports/payslipreport";
import AttendanceReport from "../components/features/reports/attendance-report";
import DailyReport from "../components/features/reports/dailyreport";

import Tickets from "../components/features/tickets/tickets";
import TicketGrid from "../components/features/tickets/tickets-grid";
import TicketDetails from "../components/features/tickets/ticket-details";
import EmployeeList from "../components/features/employees/employeesList";
import EmployeesGrid from "../components/features/employees/employeesGrid";
import Department from "../components/features/employees/deparment";
import Designations from "../components/features/employees/designations";
import Policy from "../components/features/employees/policy";

import LeaveAdmin from "../components/features/leaves/leaveAdmin";
import LeaveEmployee from "../components/features/leaves/leaveEmployee";
import LeaveSettings from "../components/features/leaves/leavesettings";
import AttendanceAdmin from "../components/features/attendance/attendanceadmin";
import AttendanceEmployee from "../components/features/attendance/attendance_employee";
import TimeSheet from "../components/features/attendance/timesheet";
import ScheduleTiming from "../components/features/attendance/scheduletiming";
import OverTime from "../components/features/attendance/overtime";
import { RolesEnum } from '../types/route.types';
import Branch from '../components/features/branch/branch';
import LeaveEmployee2 from '../components/features/leaves/pages/leaveEmployee2';
import LeaveAdmin2 from '../components/features/leaves/pages/leaveAdmin2';
import EmployeeDetails from '../components/features/employees/employeedetails';
import TicketsAdmin from '../components/features/tickets/ticketsAdmin';
import TicketsEmployee from '../components/features/tickets/ticketsEmployee';
import Shift from '../components/features/shift/shift';
import BreakReport from '../components/features/reports/breakReport';
import AttendanceReports from '../components/features/reports/attendanceReport';
import CompanySettings from '../components/features/settings/CompanySettings';
import EmployeeReport from '../components/features/reports/employee-report';
import LeaveReport from '../components/features/reports/leave-report';
import ShiftReport from '../components/features/reports/shift-report';
import TicketReport from '../components/features/reports/ticket-report';
import Notification from '../components/features/notification/notification';
import AllAttendanceUser from '../components/features/employees/components/allEmployeePage/allAttendanceUse';
import AllLeaveUser from '../components/features/employees/components/allEmployeePage/allLeaveUser';
import AllTicketsUser from '../components/features/employees/components/allEmployeePage/allTicketsUser';
import AllNotification from '../components/features/notification/allNotification';
import CompanyDetails from '../components/features/superadmin/companyDetails';
import CreateCompany from '../components/features/superadmin/createCompany';
import AllCompanies from '../components/features/superadmin/allCompanies';
import { PermissionType } from '../types/user.types';
const routes = all_routes;

export const publicRoutes = [
  {
    path: "/",
    name: "Root",
    element: <Navigate to="/index" />,
    route: Route,
  },
  {
    path: routes.superAdminCompanyDetails,
    element: <CompanyDetails />,
    route: Route,
    roles:[PermissionType.MANAGE_COMPANY]
  },
  {
    path: routes.superAdminCompanyCreate,
    element: <CreateCompany />,
    route: Route,
    roles:[PermissionType.MANAGE_COMPANY]
  },
  {
    path: routes.superAdminCompanies,
    element: <AllCompanies />,
    route: Route,
    roles:[PermissionType.MANAGE_COMPANY]
  },
  {
    path: routes.adminDashboard,
    element: <AdminDashboard />,
    route: Route,
    roles:[PermissionType.CONTROL_PANEL_ADMIN]
  },
  {
    path: routes.employeeDashboard,
    element: <EmployeeDashboard />,
    route: Route,
    roles:[PermissionType.CONTROL_PANEL_USER]
  },
  {
    path: routes.branches,
    element: <Branch />,
    roles:[PermissionType.VIEW_BRANCHES]
  },
  {
    path: routes.shift,
    element: <Shift />,
    roles:[PermissionType.MANAGE_SHIFTS]
  },
  { 
    path: routes.attendanceReport,
    element: <AttendanceReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.notificationList,
    element: <AllNotification />,
    roles:[PermissionType.CREATE_NOTIFICATIONS]
  },
  {
    path: routes.attendance,
    element: <AttendanceReports />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.breakReport,
    element: <BreakReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.companySettings,
    element: <CompanySettings />,
    roles:[PermissionType.COMPANY]
  },
  {
    path: routes.employeeReport,
    element: <EmployeeReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.shiftReport,
    element: <ShiftReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.ticketReport,
    element: <TicketReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.notification,
    element: <Notification />,
    roles:[PermissionType.CREATE_NOTIFICATIONS]
  },
  {
    path: routes.userAttendance,
    element: <AllAttendanceUser />,
    roles:[PermissionType.VIEW_USERS]
  },
  {
    path: routes.userLeaves,
    element: <AllLeaveUser />,
    roles:[PermissionType.VIEW_LEAVE]
  },
  {
    path: routes.userTickets,
    element: <AllTicketsUser />,
    roles:[PermissionType.VIEW_TICKETS_ADMIN]
  },
  
  
  
  //Application

  {
    path: routes.dataTables,
    element: <DataTable />,
    route: Route,
  },
  {
    path: routes.tablesBasic,
    element: <BasicTable />,
    route: Route,
  },


  {
    path: routes.accordion,
    element: <Accordion />,
    route: Route,
  },
  {
    path: routes.avatar,
    element: <Avatar />,
    route: Route,
  },
  {
    path: routes.badges,
    element: <Badges />,
    route: Route,
  },
  {
    path: routes.border,
    element: <Borders />,
    route: Route,
  },
  {
    path: routes.breadcrums,
    element: <Breadcrumb />,
    route: Route,
  },
  {
    path: routes.button,
    element: <Buttons />,
    route: Route,
  },
  {
    path: routes.buttonGroup,
    element: <ButtonsGroup />,
    route: Route,
  },
  {
    path: routes.cards,
    element: <Cards />,
    route: Route,
  },
  {
    path: routes.carousel,
    element: <Carousel />,
    route: Route,
  },
  {
    path: routes.colors,
    element: <Colors />,
    route: Route,
  },
  {
    path: routes.dropdowns,
    element: <Dropdowns />,
    route: Route,
  },
  {
    path: routes.grid,
    element: <Grid />,
    route: Route,
  },
  {
    path: routes.images,
    element: <Images />,
    route: Route,
  },
  {
    path: routes.lightbox,
    element: <Lightboxes />,
    route: Route,
  },
  {
    path: routes.media,
    element: <Media />,
    route: Route,
  },
  {
    path: routes.modals,
    element: <Modals />,
    route: Route,
  },
  {
    path: routes.navTabs,
    element: <NavTabs />,
    route: Route,
  },
  {
    path: routes.offcanvas,
    element: <Offcanvas />,
    route: Route,
  },
  {
    path: routes.pagination,
    element: <Pagination />,
    route: Route,
  },
  {
    path: routes.popover,
    element: <Popovers />,
    route: Route,
  },
  {
    path: routes.rangeSlider,
    element: <RangeSlides />,
    route: Route,
  },
  {
    path: routes.progress,
    element: <Progress />,
    route: Route,
  },
  {
    path: routes.spinner,
    element: <Spinner />,
    route: Route,
  },

  {
    path: routes.typography,
    element: <Typography />,
    route: Route,
  },
  {
    path: routes.video,
    element: <Video />,
    route: Route,
  },
  {
    path: routes.sortable,
    element: <Sortable />,
    route: Route,
  },
  {
    path: routes.swiperjs,
    element: <Swiperjs />,
    route: Route,
  },
  {
    path: routes.bootstrapIcons,
    element: <BootstrapIcons />,
    route: Route,
  },
  {
    path: routes.toasts,
    element: <Toasts />,
    route: Route,
  },
  {
    path: routes.mapLeaflet,
    element: <Leaflet />,
    route: Route,
  },
  {
    path: routes.RemixIcons,
    element: <RemixIcons />,
    route: Route,
  },
  {
    path: routes.FlagIcons,
    element: <FlagIcons />,
    route: Route,
  },
  {
    path: routes.clipboard,
    element: <ClipBoard />,
    route: Route,
  },
  {
    path: routes.counter,
    element: <Counter />,
    route: Route,
  },
  {
    path: routes.dragandDrop,
    element: <DragAndDrop />,
    route: Route,
  },
  {
    path: routes.rating,
    element: <Rating />,
    route: Route,
  },
  {
    path: routes.stickyNotes,
    element: <Stickynote />,
    route: Route,
  },
  {
    path: routes.textEditor,
    element: <TextEditor />,
    route: Route,
  },
  {
    path: routes.timeLine,
    element: <Timeline />,
    route: Route,
  },
  {
    path: routes.scrollBar,
    element: <Scrollbar />,
    route: Route,
  },
  {
    path: routes.apexChart,
    element: <Apexchart />,
    route: Route,
  },
  {
    path: routes.primeChart,
    element: <PrimeReactChart />,
    route: Route,
  },
  {
    path: routes.chartJs,
    element: <ChartJSExample />,
    route: Route,
  },
  {
    path: routes.featherIcons,
    element: <FeatherIcons />,
    route: Route,
  },
  {
    path: routes.fontawesome,
    element: <FontawesomeIcons />,
    route: Route,
  },
  {
    path: routes.materialIcon,
    element: <MaterialIcons />,
    route: Route,
  },
  {
    path: routes.pe7icon,
    element: <PE7Icons />,
    route: Route,
  },
  {
    path: routes.simpleLineIcon,
    element: <SimplelineIcons />,
    route: Route,
  },
  {
    path: routes.themifyIcon,
    element: <ThemifyIcons />,
    route: Route,
  },
  {
    path: routes.typicon,
    element: <TypiconIcons />,
    route: Route,
  },
  {
    path: routes.basicInput,
    element: <BasicInputs />,
    route: Route,
  },
  {
    path: routes.weatherIcon,
    element: <WeatherIcons />,
    route: Route,
  },
  {
    path: routes.checkboxandRadion,
    element: <CheckboxRadios />,
    route: Route,
  },
  {
    path: routes.inputGroup,
    element: <InputGroup />,
    route: Route,
  },
  {
    path: routes.gridandGutters,
    element: <GridGutters />,
    route: Route,
  },
  {
    path: routes.formSelect,
    element: <FormSelect />,
    route: Route,
  },
  {
    path: routes.formMask,
    element: <FormMask />,
    route: Route,
  },
  {
    path: routes.fileUpload,
    element: <FileUpload />,
    route: Route,
  },
  {
    path: routes.horizontalForm,
    element: <FormHorizontal />,
    route: Route,
  },
  {
    path: routes.verticalForm,
    element: <FormVertical />,
    route: Route,
  },
  {
    path: routes.floatingLable,
    element: <FloatingLabel />,
    route: Route,
  },
  {
    path: routes.formValidation,
    element: <FormValidation />,
    route: Route,
  },
  {
    path: routes.reactSelect,
    element: <FormSelect2 />,
    route: Route,
  },
  {
    path: routes.formWizard,
    element: <FormWizard />,
    route: Route,
  },
  {
    path: routes.formPicker,
    element: <FormPikers />,
    route: Route,
  },
  {
    path: routes.dataTables,
    element: <DataTables />,
    route: Route,
  },
  {
    path: routes.tablesBasic,
    element: <TablesBasic />,
    route: Route,
  },
  {
    path: routes.iconicIcon,
    element: <IonicIcons />,
    route: Route,
  },
  // {
  //   path: routes.chart,
  //   element: <ChartJs />,
  //   route: Route,
  // },

  {
    path: routes.placeholder,
    element: <Placeholder />,
    route: Route,
  },
  {
    path: routes.sweetalert,
    element: <Alert />,
    route: Route,
  },
  {
    path: routes.alert,
    element: <AlertUi />,
    route: Route,
  },
  {
    path: routes.tooltip,
    element: <Tooltips />,
    route: Route,
  },
  {
    path: routes.ribbon,
    element: <Ribbon />,
    route: Route,
  },
  {
    path: routes.tickets,
    element: <Tickets />,
    route: Route,
    //roles:[PermissionType.VIEW_TICKETS]
  },
  {
    path: routes.ticketAdmin,
    element: <TicketsAdmin />,
    route: Route,
    roles:[PermissionType.VIEW_TICKETS_ADMIN]
  },
  {
    path: routes.ticketEmployee,
    element: <TicketsEmployee />,
    route: Route,
    roles:[PermissionType.VIEW_TICKETS_USER]
  },
  {
    path: routes.ticketGrid,
    element: <TicketGrid />,
    route: Route,
    roles:[PermissionType.VIEW_TICKETS_ADMIN]

  },
  {
    path: routes.ticketDetails,
    element: <TicketDetails />,
    route: Route,
    roles:[PermissionType.VIEW_TICKETS_ADMIN]

  },


  //Settings

  {
    path: routes.expensesreport,
    element: <ExpensesReport />,
  },
  {
    path: routes.invoicereport,
    element: <InvoiceReport />,
  },
  {
    path: routes.paymentreport,
    element: <PaymentReport />,
  },
  {
    path: routes.projectreport,
    element: <ProjectReport />,
  },

  {
    path: routes.profile,
    element: <Profile />,
    roles: [PermissionType.CONTROL_PANEL_USER, PermissionType.CONTROL_PANEL_ADMIN]
  },
  
  {
    path: routes.taskreport,
    element: <TaskReport />,
    // roles:[RolesEnum.ADMIN]
  },
  {
    path: routes.userreport,
    element: <UserReports />,
    // roles:[RolesEnum.ADMIN]
  },
  {
    path: routes.payslipreport,
    element: <PayslipReport />,
    route: Route,
  },
  {
    path: routes.employeedetails,
    element: <EmployeeDetails />,
    roles: [PermissionType.VIEW_USERS]
  },
  {
    path: routes.leaveReport,
    element: <LeaveReport />,
    roles:[PermissionType.VIEW_REPORTS]
  },


  {
    path: routes.employeeList,
    element: <EmployeeList />,
    route: Route,
    roles:[PermissionType.VIEW_USERS]
  },
  {
    path: routes.employeeGrid,
    element: <EmployeesGrid />,
    route: Route,
    roles:[PermissionType.VIEW_USERS]
  },
  {
    path: routes.departments,
    element: <Department />,
    route: Route,
    roles:[PermissionType.VIEW_DEPARTMENTS]
  },
  {
    path: routes.designations,
    element: <Designations />,
    route: Route,
  },
  {
    path: routes.policy,
    element: <Policy />,
    route: Route,
  },
  {
    path: routes.leaveadmin,
    element: <LeaveAdmin2 />,
    route: Route,
    roles:[PermissionType.VIEW_LEAVE]
  },
  {
    path: routes.leaveemployee,
    element: <LeaveEmployee2 />,
    route: Route,
    roles:[PermissionType.LEAVE_REQUEST]
  },
  {
    path: routes.leavesettings,
    element: <LeaveSettings />,
    route: Route,
    roles:[PermissionType.VIEW_LEAVE]
  },
  {
    path: routes.attendanceadmin,
    element: <AttendanceAdmin />,
    route: Route,
    // roles:[PermissionType.VIEW_REPORTS]
  },
  {
    path: routes.attendanceemployee,
    element: <AttendanceEmployee />,
    route: Route,
    // roles:[RolesEnum.USER]
  },
  {
    path: routes.timesheet,
    element: <TimeSheet />,
    route: Route,
    // roles:[RolesEnum.ADMIN]
  },
  {
    path: routes.scheduletiming,
    element: <ScheduleTiming />,
    route: Route,
    // roles:[RolesEnum.ADMIN]
  },
  {
    path: routes.overtime,
    element: <OverTime />,
    route: Route,
    // roles:[RolesEnum.ADMIN]
  },

  {
    path: routes.employeedetails,
    element: <EmployeeDetails />,
    roles: [PermissionType.VIEW_USERS]
  },
];

export const authRoutes = [

  {
    path: routes.login,
    element: <Login />,
    route: Route,
  },
  {
    path: routes.login2,
    element: <Login2 />,
    route: Route,
  },
  {
    path: routes.login3,
    element: <Login3 />,
    route: Route,
  },
  {
    path: routes.register,
    element: <Register />,
    route: Route,
  },
  {
    path: routes.twoStepVerification,
    element: <TwoStepVerification />,
    route: Route,
  },
  {
    path: routes.twoStepVerification2,
    element: <TwoStepVerification2 />,
    route: Route,
  },
  {
    path: routes.twoStepVerification3,
    element: <TwoStepVerification3 />,
    route: Route,
  },
  {
    path: routes.emailVerification,
    element: <EmailVerification />,
    route: Route,
  },
  {
    path: routes.emailVerification2,
    element: <EmailVerification2 />,
    route: Route,
  },
  {
    path: routes.emailVerification3,
    element: <EmailVerification3 />,
    route: Route,
  },
  {
    path: routes.register,
    element: <Register />,
    route: Route,
  },
  {
    path: routes.register2,
    element: <Register2 />,
    route: Route,
  },
  {
    path: routes.register3,
    element: <Register3 />,
    route: Route,
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
    route: Route,
  },
  {
    path: routes.resetPassword2,
    element: <ResetPassword2 />,
    route: Route,
  },
  {
    path: routes.resetPassword3,
    element: <ResetPassword3 />,
    route: Route,
  },
  {
    path: routes.forgotPassword,
    element: <ForgotPassword />,
    route: Route,
  },
  {
    path: routes.forgotPassword2,
    element: <ForgotPassword2 />,
    route: Route,
  },
  {
    path: routes.forgotPassword3,
    element: <ForgotPassword3 />,
    route: Route,
  },
  {
    path: routes.error404,
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.error500,
    element: <Error500 />,
    route: Route,
  },
  {
    path: routes.lockScreen,
    element: <LockScreen />,
  },
  {
    path: routes.resetPasswordSuccess,
    element: <ResetPasswordSuccess />,
  },
  {
    path: routes.resetPasswordSuccess2,
    element: <ResetPasswordSuccess2 />,
  },
  {
    path: routes.resetPasswordSuccess3,
    element: <ResetPasswordSuccess3 />,
  },
];
