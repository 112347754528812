import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Card, Table, Tag, Badge, Space, Typography, Button, Row, Col, Select, DatePicker, Input, Tooltip as AntdTooltip } from 'antd';
import { 
    BellOutlined, MailOutlined, PhoneOutlined, MessageOutlined, 
    UserOutlined, TeamOutlined, HomeOutlined, LaptopOutlined,
    ArrowUpOutlined, MinusOutlined, ArrowDownOutlined,
    SearchOutlined, CalendarOutlined, FilterOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import notificationService from "../../../services/notification/notification.service";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import moment from 'moment';
import { OverlayTrigger, Popover, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { handleApiError } from '../../../utils/errorHandler';
import FooterSection from "../../shared/components/footerSection";
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface NotificationTarget {
    id: number;
    targetType: 'department' | 'branch' | 'user' | 'all';
    targetIds: number[];
    createdAt: string;
    updatedAt: string;
}

interface NotificationData {
    id: number;
    title: string;
    content: string;
    priority: 'low' | 'medium' | 'high';
    types: string[];
    createdAt: string;
    updatedAt: string;
    targets: NotificationTarget[];
}

const AllNotification = () => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState<NotificationData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState({
        search: '',
        priority: 'all',
        type: 'all',
        dateRange: null as any
    });

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await notificationService.getNotifications();
            setNotifications(response as unknown as NotificationData[]);
        } catch (error: any) {
            handleApiError(error,t);
        } finally {
            setLoading(false);
        }
    };

    const getTypeIcon = (type: string) => {
        switch (type) {
            case 'notification':
                return <BellOutlined style={{ color: '#1890ff' }} />;
            case 'email':
                return <MailOutlined style={{ color: '#52c41a' }} />;
            case 'sms':
                return <PhoneOutlined style={{ color: '#fa8c16' }} />;
            case 'whatsapp':
                return <MessageOutlined style={{ color: '#13c2c2' }} />;
            default:
                return null;
        }
    };

    const getPriorityTag = (priority: string) => {
        switch (priority) {
            case 'high':
                return (
                    <Tag color="red" icon={<ArrowUpOutlined />}>
                        {t('notification.high')}
                    </Tag>
                );
            case 'medium':
                return (
                    <Tag color="orange" icon={<MinusOutlined />}>
                        {t('notification.medium')}
                    </Tag>
                );
            case 'low':
                return (
                    <Tag color="green" icon={<ArrowDownOutlined />}>
                        {t('notification.low')}
                    </Tag>
                );
            default:
                return null;
        }
    };

    const getTargetIcons = (targets: NotificationTarget[]) => {
        return targets.map((target, index) => {
            let icon;
            let color;
            let text;

            switch (target.targetType) {
                case 'department':
                    icon = <LaptopOutlined />;
                    color = 'blue';
                    text = t('notification.department');
                    break;
                case 'branch':
                    icon = <HomeOutlined />;
                    color = 'green';
                    text = t('notification.branch');
                    break;
                case 'user':
                    icon = <UserOutlined />;
                    color = 'purple';
                    text = t('notification.user');
                    break;
                case 'all':
                    icon = <TeamOutlined />;
                    color = 'cyan';
                    text = t('notification.all');
                    break;
                default:
                    icon = null;
                    color = 'default';
                    text = '';
            }

            return (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <BootstrapTooltip id={`tooltip-${index}`}>
                            {target.targetIds ? `${text}${target.targetIds.length ? ` (${target.targetIds.length})` : ''}` : ''}
                        </BootstrapTooltip>
                    }
                >
                    <Tag color={color} icon={icon}>
                        {text}
                    </Tag>
                </OverlayTrigger>
            );
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: t('notification.title'),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: NotificationData) => (
                // <Tooltip title={record.content}>
                //     <Text strong>{text}</Text>
                // </Tooltip>
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Popover id="popover-top-performers">
                            <Popover.Header as="h3">Bildirim İçeriği</Popover.Header>
                            <Popover.Body>
                                <Text>{record.content}</Text>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <div>
                    <Text className="me-2" strong>{text}</Text>
                    <i className="ti ti-info-circle"></i>
                    </div>
                </OverlayTrigger>
            </div>
            ),
        },
        {
            title: t('notification.priority'),
            dataIndex: 'priority',
            key: 'priority',
            width: 120,
            render: (priority: string) => getPriorityTag(priority),
        },
        {
            title: t('notification.types'),
            dataIndex: 'types',
            key: 'types',
            width: 200,
            render: (types: string[]) => (
                <Space size={[0, 4]} wrap>
                    {types.map((type, index) => (
                        <AntdTooltip key={index} title={t(`notification.${type}`)}>
                            <Tag icon={getTypeIcon(type)} color={
                                type === 'notification' ? 'blue' :
                                type === 'email' ? 'green' :
                                type === 'sms' ? 'orange' : 'cyan'
                            }>
                                {t(`notification.${type}`)}
                            </Tag>
                        </AntdTooltip>
                    ))}
                </Space>
            ),
        },
        {
            title: t('notification.targets'),
            dataIndex: 'targets',
            key: 'targets',
            width: 200,
            render: (targets: NotificationTarget[]) => (
                <Space size={[0, 4]} wrap>
                    {getTargetIcons(targets)}
                </Space>
            ),
        },
        {
            title: t('notification.createdAt'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 160,
            render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm'),
        }
    ];

    const filteredNotifications = notifications.filter(notification => {
        // Arama filtreleme
        if (filters.search && !notification.title.toLowerCase().includes(filters.search.toLowerCase()) &&
            !notification.content.toLowerCase().includes(filters.search.toLowerCase())) {
            return false;
        }

        // Öncelik filtreleme
        if (filters.priority !== 'all' && notification.priority !== filters.priority) {
            return false;
        }

        // Tip filtreleme
        if (filters.type !== 'all' && !notification.types.includes(filters.type)) {
            return false;
        }

        // Tarih aralığı filtreleme
        if (filters.dateRange && filters.dateRange.length === 2) {
            const notificationDate = moment(notification.createdAt);
            const [startDate, endDate] = filters.dateRange;
            if (notificationDate.isBefore(startDate) || notificationDate.isAfter(endDate)) {
                return false;
            }
        }

        return true;
    });

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({
            ...filters,
            search: e.target.value
        });
    };

    const handlePriorityChange = (value: string) => {
        setFilters({
            ...filters,
            priority: value
        });
    };

    const handleTypeChange = (value: string) => {
        setFilters({
            ...filters,
            type: value
        });
    };

    const handleDateRangeChange = (dates: any) => {
        setFilters({
            ...filters,
            dateRange: dates
        });
    };

    const resetFilters = () => {
        setFilters({
            search: '',
            priority: 'all',
            type: 'all',
            dateRange: null
        });
    };

    return (
        <>
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <div className="content flex-fill h-100">
                    {/* Breadcrumb */}
                    <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                        <div className="my-auto mb-2">
                            <h2 className="mb-1">{t('notification.breadcrumb.list')}</h2>
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to={all_routes.adminDashboard}>
                                            <i className="ti ti-smart-home" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">{t('notification.breadcrumb.management')}</li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t('notification.breadcrumb.list')}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div>
                            <Link to={all_routes.notification} className="btn btn-primary">
                                <i className="ti ti-plus" /> {t('notification.sendNew')}
                            </Link>
                        </div>
                    </div>
                    {/* /Breadcrumb */}

                    <Card className="mb-4">
                        <Row gutter={[16, 16]} className="mb-4">
                            <Col xs={24} md={6}>
                                <Input
                                    placeholder={t('notification.searchPlaceholder')}
                                    value={filters.search}
                                    onChange={handleSearchChange}
                                    prefix={<SearchOutlined />}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder={t('notification.filterByPriority')}
                                    value={filters.priority}
                                    onChange={handlePriorityChange}
                                >
                                    <Option value="all">{t('notification.allPriorities')}</Option>
                                    <Option value="high">{t('notification.high')}</Option>
                                    <Option value="medium">{t('notification.medium')}</Option>
                                    <Option value="low">{t('notification.low')}</Option>
                                </Select>
                            </Col>
                            <Col xs={12} md={4}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder={t('notification.filterByType')}
                                    value={filters.type}
                                    onChange={handleTypeChange}
                                >
                                    <Option value="all">{t('notification.allTypes')}</Option>
                                    <Option value="notification">{t('notification.notification')}</Option>
                                    <Option value="email">{t('notification.email')}</Option>
                                    <Option value="sms">SMS</Option>
                                    <Option value="whatsapp">WhatsApp</Option>
                                </Select>
                            </Col>
                            <Col xs={8} md={4}>
                                <Button 
                                    type="default" 
                                    onClick={resetFilters}
                                >
                                    {t('notification.resetFilters')}
                                </Button>
                            </Col>
                        </Row>

                        <Table
                            dataSource={filteredNotifications}
                            columns={columns}
                            rowKey="id"
                            loading={loading}
                            pagination={{
                                defaultPageSize: 10,
                            }}
                        />
                    </Card>
                </div>
                <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                    <FooterSection />
                </div>
            </div>
            {/* /Page Wrapper */}
        </>
    );
};

export default AllNotification;
 
