import React from 'react';
import { Link } from "react-router-dom";
import { LeaveDto, UserYearlyLeaveSummary } from "../../../../../types/leaves.types";
import Table from "../../../../shared/dataTable/index";
import { LEAVE_STATUSES, LEAVE_TYPES } from "../../../../../constants/leaveConstants";
import { User } from "../../../../../types/user.types";
import { useTranslation } from 'react-i18next';
interface LeaveHistoryProps {
    leaves: LeaveDto[];
    formatDate: (date: string | Date | undefined) => string;
    leaveSummary: UserYearlyLeaveSummary;
    onCreateLeave?: () => void;
    userData: User;
}

const LeaveHistory: React.FC<LeaveHistoryProps> = ({ leaves, formatDate, leaveSummary, onCreateLeave, userData }) => {
    
    const { t } = useTranslation();
    const leaveColumns = [
        {
            title: t('employeesDetail.leaves.type'),
            dataIndex: "type",
            render: (text: string, record: any) => (
                <div className="d-flex align-items-center">
                    <p className="fs-14 fw-medium d-flex align-items-center mb-0">
                        {t(`leaveTypes.${record.type}`)}
                    </p>
                </div>
            ),
            sorter: (a: any, b: any) => a.LeaveType.length - b.LeaveType.length,
        },
        {
            title: t('employeesDetail.leaves.date'),
            dataIndex: "startDate", // Assuming `LeaveDate` is the actual field in your data
            render: (text: string, record: any) => (
                <div className="d-flex align-items-center">
                    <p className="fs-14 fw-medium d-flex align-items-center mb-0">
                        {record.startDate.split('T')[0]}
                    </p>
                </div>
            ),
            sorter: (a: any, b: any) => a.startDate.localeCompare(b.startDate),
        },
        {
            title: t('employeesDetail.leaves.status'),
            dataIndex: "status", // Assuming `AdminStatus` is the field in your data
            render: (text: string, record: any) => (
                <div className="d-flex align-items-center">
                    <p className="fs-14 fw-medium d-flex align-items-center mb-0">
                        {LEAVE_STATUSES[record.status as keyof typeof LEAVE_STATUSES]}
                    </p>
                </div>
            ),
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
        },
        {
            title: t('employeesDetail.leaves.description'),
            dataIndex: "reason", // Assuming `Description` is the field in your data
            render: (text: string, record: any) => (
                <div className="d-flex align-items-center">
                    <p className="fs-14 fw-medium d-flex align-items-center mb-0 truncate max-w-xs overflow-ellipsis overflow-hidden whitespace-nowrap"
                        style={{ maxWidth: "200px" }} // Genişlik sınırı
                    >
                        {record.reason}
                    </p>
                </div>
            ),
            sorter: (a: any, b: any) => a.reason.length - b.reason.length,
        },
    ];
    
    
    return (
        <div className="accordion-item">
        <div className="accordion-header" id="headingTwo">
            <div className="accordion-button">
                <div className="d-flex align-items-center justify-content-between flex-fill">
                    <h5>{t('employeesDetail.leaves.title')}</h5>
                    <div className="d-flex">
                        <Link
                            to="#"
                            className="btn btn-icon btn-sm"
                            onClick={(e) => {
                                e.preventDefault();
                                onCreateLeave?.();
                            }}
                        >
                            <i className="ti ti-edit" />
                        </Link>
                        <Link
                            to="#"
                            className="d-flex align-items-center collapsed collapse-arrow"
                            data-bs-toggle="collapse"
                            data-bs-target="#primaryBorderTwo"
                            aria-expanded="false"
                            aria-controls="primaryBorderTwo"
                        >
                            <i className="ti ti-chevron-down fs-18" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="primaryBorderTwo"
            className="accordion-collapse collapse border-top"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
        >
            <div className="accordion-body">
                <div className="row">
                    <div className="col-md-9">
                        <div className="card h-100">
                            <div className="card-body text-center">
                            <div className="row">
                                <div className="col-4">
                                <p className="mb-1"><i className="ti ti-check-circle text-primary me-1" />{t('employeesDetail.leaves.totalAnnualLeaveLimit')}</p>
                                <h4>{leaveSummary?.totalAnnualLeaveLimit} {t('employeesDetail.leaves.days')}</h4>
                                </div>
                                <div className="col-4">
                                <p className="mb-1"><i className="ti ti-calendar text-danger me-1" />{t('employeesDetail.leaves.usedAnnualLeaveDays')}</p>
                                <h4>{leaveSummary?.usedAnnualLeaveDays} {t('employeesDetail.leaves.days')}</h4>
                                </div>
                                <div className="col-4">
                                <p className="mb-1"><i className="ti ti-clock text-success me-1" />{t('employeesDetail.leaves.remainingAnnualLeaveDays')}</p>
                                <h4>{leaveSummary?.remainingAnnualLeaveDays} {t('employeesDetail.leaves.days')}</h4>
                                </div>
                            </div>
                            <div className="progress mt-3" style={{ height: 8 }}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${(leaveSummary?.usedAnnualLeaveDays / leaveSummary?.totalAnnualLeaveLimit) * 100}%` }} />
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${(leaveSummary?.remainingAnnualLeaveDays / leaveSummary?.totalAnnualLeaveLimit) * 100}%` }} />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="card h-100">
                            <div className="card-body d-flex align-items-center justify-content-center">
                                <div>
                                    <p className="mb-1"><i className="ti ti-check-circle text-primary me-1" />{t('employeesDetail.leaves.usedMedicalLeaves')}</p>
                                    <h4 className="text-center">{leaveSummary?.approvedMedicalLeaves} {t('employeesDetail.leaves.days')}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body p-0">
                        <Table
                            dataSource={leaves || []} 
                            columns={leaveColumns} 
                            Selection={false} 
                        />
                    </div>
                </div>
            </div>
            <div className="text-center" style={{ marginBottom: "20px" }}>
                <Link to={`/user-leaves?user=${userData.id}`} className="">
                    {t('employeesDetail.leaves.viewAll')}
                </Link>
            </div>
        </div>
    </div>
    );
};

const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
        case 'onaylandi':
            return 'success';
        case 'beklemede':
            return 'warning';
        case 'reddedildi':
            return 'danger';
        default:
            return 'secondary';
    }
};

export default LeaveHistory;