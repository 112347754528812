export const overtime_details = [
  {
    key: "1",
    EmpImage: "user-32.jpg",
    Employee: "Anthony Lewis",
    Role: "UI/UX Team",
    Date: "14 Jan 2024",
    OvertimeHours: "32",
    Project: "Office Management",
    Image: "user-39.jpg",
    Name: "Michael Walker",
    Status: "Accepted",
  },
  {
    key: "2",
    EmpImage: "user-09.jpg",
    Employee: "Brian Villalobos",
    Role: "Development",
    Date: "21 Jan 2024",
    OvertimeHours: "45",
    Project: "Project Management",
    Image: "user-02.jpg",
    Name: "Sophie Headrick",
    Status: "Accepted",
  },
  {
    key: "3",
    EmpImage: "user-01.jpg",
    Employee: "Harvey Smith",
    Role: "HR",
    Date: "20 Feb 2024",
    OvertimeHours: "31",
    Project: "Project Management",
    Image: "user-03.jpg",
    Name: "Cameron Drake",
    Status: "Accepted",
  },
  {
    key: "4",
    EmpImage: "user-33.jpg",
    Employee: "Stephan Peralt",
    Role: "Management",
    Date: "15 Mar 2024",
    OvertimeHours: "45",
    Project: "Hospital Administration",
    Image: "user-04.jpg",
    Name: "Doris Crowley",
    Status: "Rejected",
  },
  {
    key: "5",
    EmpImage: "user-34.jpg",
    Employee: "Doglas Martini",
    Role: "Development",
    Date: "12 Apr 2024",
    OvertimeHours: "36",
    Project: "Office Management",
    Image: "user-06.jpg",
    Name: "Thomas Bordelon",
    Status: "Accepted",
  },
  {
    key: "6",
    EmpImage: "user-02.jpg",
    Employee: "Linda Ray",
    Role: "UI/UX Team",
    Date: "20 May 2024",
    OvertimeHours: "49",
    Project: "Hospital Administration",
    Image: "user-06.jpg",
    Name: "Kathleen Gutierrez",
    Status: "Accepted",
  },
  {
    key: "7",
    EmpImage: "user-35.jpg",
    Employee: "Elliot Murray",
    Role: "Developer",
    Date: "06 Jul 2024",
    OvertimeHours: "57",
    Project: "Video Calling App",
    Image: "user-07.jpg",
    Name: "Bruce Wright",
    Status: "Accepted",
  },
  {
    key: "8",
    EmpImage: "user-36.jpg",
    Employee: "Rebecca Smtih",
    Role: "UI/UX Team",
    Date: "02 Sep 2024",
    OvertimeHours: "21",
    Project: "Office Management",
    Image: "user-09.jpg",
    Name: "Estelle Morgan",
    Status: "Rejected",
  },
  {
    key: "9",
    EmpImage: "user-37.jpg",
    Employee: "Connie Waters",
    Role: "Management",
    Date: "15 Nov 2024",
    OvertimeHours: "32",
    Project: "Project Management",
    Image: "user-10.jpg",
    Name: "Stephen Dias",
    Status: "Accepted",
  },
  {
    key: "10",
    EmpImage: "user-38.jpg",
    Employee: "Connie Waters",
    Role: "Management",
    Date: "15 Nov 2024",
    OvertimeHours: "66",
    Project: "Ware house developement",
    Image: "user-05.jpg",
    Name: "Angela Thomas",
    Status: "Accepted",
  },
];
