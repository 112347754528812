export const schedule_timing = [
  {
    key:"1",
    Image: "user-32.jpg",
    Name: "Anthony Lewis",
    JobTitle: "Accountant",
  },
  {
    key:"2",
    Image: "user-09.jpg",
    Name: "Brian Villalobos",
    JobTitle: "Accountant",
  },
  {
    key:"3",
    Image: "user-01.jpg",
    Name: "Harvey Smith",
    JobTitle: "Accountant",
  },
];
