import React from 'react';
import { Select, DatePicker } from 'antd';
import { FilterDropdownProps } from './types';
import Dropdown from '../dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  groups,
  onReset,
  className = '',
  buttonType = 'primary',
  icon = 'ti ti-filter'
}) => {
  const { t } = useTranslation();
  const getActiveFilterCount = () => {
    return groups.reduce((count, group) => {
      if (group.value && group.value !== 'all') {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const filterContent = (
    <div 
      className="p-3" 
      style={{ 
        minWidth: 300,
        maxWidth: 350,
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
      }}
      onClick={handleClick}
      onMouseDown={handleClick}
      onMouseUp={handleClick}
    >
      {groups.map((group, index) => (
        <div key={index} className="mb-3">
          <label className="form-label d-block mb-2">{group.label}</label>
          {group.type === 'select' ? (
            <Select
              value={group.value}
              onChange={group.onChange}
              options={group.options}
              style={{ width: '100%' }}
              placeholder={group.label}
              onClick={handleClick}
              dropdownStyle={{ 
                zIndex: 9999,
                maxHeight: 200
              }}
              getPopupContainer={(trigger) => trigger.parentElement || document.body}
              className="w-100"
            />
          ) : (
            <RangePicker
              value={group.value}
              onChange={group.onChange}
              style={{ width: '100%' }}
              onClick={handleClick}
              getPopupContainer={(trigger) => trigger.parentElement || document.body}
              className="w-100"
            />
          )}
        </div>
      ))}

      {onReset && (
        <div className="d-flex justify-content-end mt-4 pt-2 border-top">
          <button
            className="btn btn-light btn-sm"
            onClick={onReset}
          >
            <i className="ti ti-refresh me-1" />
            {t('employees.filters.reset')}
          </button>
        </div>
      )}
    </div>
  );

  return (
    <Dropdown
      label={t('employees.filters.title')}
      icon={icon}
      items={[
        {
          label: filterContent,
          value: 'filters',
        }
      ]}
      buttonType={buttonType as 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'}
      className={`${className} ${getActiveFilterCount() > 0 ? 'has-badge' : ''}`}
      menuClassName="p-0"
    />
  );
};

export default FilterDropdown; 