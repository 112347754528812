import {
  DepartmentDto,
  DepartmentCreateDTO,
  DepartmentUpdateDTO,
} from "../../types/department.types";
import apiService from "../api/api.service";

class DepartmentService {
  private static instance: DepartmentService;

  private constructor() {}

  public static getInstance(): DepartmentService {
    if (!DepartmentService.instance) {
      DepartmentService.instance = new DepartmentService();
    }
    return DepartmentService.instance;
  }

  public async getDepartments(): Promise<DepartmentDto[]> {
    const response = await apiService.get<DepartmentDto[]>("/departments");
    return response;
  }

  public async getDepartmentById(id: number): Promise<DepartmentDto> {
    const response = await apiService.get<DepartmentDto>(`/departments/${id}`);
    return response;
  }

  public async createDepartment(
    data: DepartmentCreateDTO
  ): Promise<DepartmentDto> {
    const response = await apiService.post<DepartmentDto>("/departments", data);
    return response;
  }

  public async updateDepartment(
    id: number,
    data: DepartmentUpdateDTO
  ): Promise<DepartmentDto> {
    const response = await apiService.put<DepartmentDto>(
      `/departments/${id}`,
      data
    );
    return response;
  }

  public async deleteDepartment(id: number): Promise<void> {
    await apiService.delete(`/departments/${id}`);
  }

  public async getDepartmentByBranchId(
    branchId: number
  ): Promise<DepartmentDto[]> {
    const response = await apiService.get<DepartmentDto[]>(
      `/departments/branch/${branchId}`
    );
    return response;
  }
}

export default DepartmentService.getInstance();
