import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface SocketState {
    connected: boolean;
    notification: any[]

}

const initialState: SocketState = {
    connected: false,
    notification: []
}

export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setConnected: (state, action:PayloadAction<boolean>) =>{
            state.connected = action.payload;
        },
        addNotification: (state, action:PayloadAction<any>) =>{
            state.notification.unshift(action.payload);
        },
        clearNotifications: (state) =>{
            state.notification = [];
        }
    }
})

export const { setConnected, addNotification, clearNotifications } = socketSlice.actions;
export default socketSlice.reducer;



