import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
export type Option = {
  value: string | boolean | number;
  label: string;
};

export interface SelectProps {
  options: Option[];
  value?: Option;
  defaultValue?: Option;
  className?: string;
  styles?: any; 
  onChange?: (selected: Option) => void;
  disabled?: boolean;
}

const CommonSelect: React.FC<SelectProps> = ({ options, value, className,defaultValue, onChange, disabled=false}) => {
  const {t} = useTranslation();
  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      options={options}
      value={value&&options.find(option => option.value === value?.value)} // ✅ Doğru seçili değeri ayarla
      onChange={(option:any) => {
        if (option && onChange) {
          onChange(option); // ✅ Seçimi dışarıya bildir
        }
      }}
      placeholder={t('common.select')}
      isDisabled={disabled}
    />
  );
};

export default CommonSelect;
