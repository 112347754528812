import { Link } from "react-router-dom";
import { User } from "../../../../../types/user.types";
import { GENDERS, ROLES } from "../../../../../constants/userConstants";
import { getFileUrl } from "../../../../../utils/file.utils";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
interface ProfileCardProps {
    userData: User;
    formatDate: (date: string | Date | undefined) => string;
    onEdit: (user: User) => void;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({ userData, formatDate, onEdit }) => {
    const { t } = useTranslation();
    return (
        <div className="card card-bg-1">
        <div className="card-body p-0">
            <span className="avatar avatar-xl avatar-rounded  m-auto d-flex mb-2">
                <Avatar
                    size={50}
                    src={getFileUrl(userData?.profilePicture)}
                    className="rounded-circle"
                    alt="Img"
                />
            </span>
            <div className="text-center px-3 pb-3 border-bottom">
                <div className="mb-3">
                    <h5 className="d-flex align-items-center justify-content-center mb-1">
                        {userData?.firstName} {userData?.lastName}
                        {/* {userData?.isActive ? (
                            <i className="ti ti-discount-check-filled text-success ms-1" /> // Yeşil Tik
                        ) : (
                            <i className="ti ti-circle-x-filled text-danger ms-1" /> // Kırmızı Çarpı
                        )} */}
                    </h5>
                    <span className="badge badge-soft-dark fw-medium me-2">
                        <i className="ti ti-point-filled me-1" />
                        {userData?.title}
                    </span>
                    <span className="badge badge-soft-secondary fw-medium">
                        {/* Şirkette {experienceText} */}
                    </span>
                </div>
                <div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="d-inline-flex align-items-center">
                            <i className="ti ti-star me-2" />
                            {t('employeesDetail.profileCard.department')}
                        </span>
                        <p className="text-dark">{userData?.department?.name}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="d-inline-flex align-items-center">
                            <i className="ti ti-calendar-check me-2" />
                            {t('employeesDetail.profileCard.hireDate')}
                        </span>
                        <p className="text-dark">
                            {formatDate(userData?.hireDate)}
                        </p>
                    </div>
                    <div className="row gx-2 mt-3">
                        <div className="col-12">
                            <div>
                                <Link
                                    to="#"
                                    className="btn btn-dark w-100"
                                    onClick={() => onEdit(userData)}                                >
                                    <i className="ti ti-edit me-1" />
                                    {t('employeesDetail.profileCard.editInfo')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 border-bottom">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>{t('employeesDetail.profileCard.personalInfo')}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-phone me-2" />
                        {t('employeesDetail.profileCard.phone')}
                    </span>
                    <p className="text-dark">{userData?.phone}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-mail-check me-2" />
                        {t('employeesDetail.profileCard.email')}
                    </span>
                    <Link
                        to="#"
                        className="text-info d-inline-flex align-items-center"
                    >
                        {userData?.email}
                        <i className="ti ti-copy text-dark ms-2" />
                    </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-gender-male me-2" />
                        {t('employeesDetail.profileCard.gender')}
                    </span>
                    <p className="text-dark text-end">{GENDERS.find(g => g.value === userData?.gender)?.label}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-calendar me-2" />
                        {t('employeesDetail.profileCard.birthDate')}
                    </span>
                    <p className="text-dark">
                        {formatDate(userData?.birthDate)}
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-map-pin-check me-2" />
                        {t('employeesDetail.profileCard.address')}
                    </span>
                    <p className="text-dark text-end">
                        {userData?.address}
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-phone me-2" />
                        {t('employeesDetail.profileCard.emergencyNumber')}
                    </span>
                    <p className="text-dark">{userData?.emergencyContact}</p>
                </div>
            </div>
            <div className="p-3 border-bottom">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>{t('employeesDetail.profileCard.workInfo')}</h6>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-e-passport me-2" />
                        {t('employeesDetail.profileCard.salary')}
                    </span>
                    <p className="text-dark">{userData?.salaries?.find(salary => salary.isActive)?.amount}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-bookmark-plus me-2" />
                        {t('employeesDetail.profileCard.branch')}
                    </span>
                    <p className="text-dark text-end">{userData?.branch?.name}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="d-inline-flex align-items-center">
                        <i className="ti ti-briefcase-2 me-2" />
                        {t('employeesDetail.profileCard.workType')}
                    </span>
                    <p className="text-dark text-end">{ROLES.find(role => role.value === userData?.role)?.label}</p>
                </div>
            </div>
        </div>
    </div>
    )
};
