import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSocket } from "../../../../hooks/useSocket";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import dailyRecordService from "../../../../services/dailyRecord/dailyRecord.service";
import { CompanyActivityDto } from "../../../../types/attendanceDto.types";
import { Modal, Button, message, Avatar } from "antd";
import TicketService from "../../../../services/ticket/ticket.service";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../../../../utils/file.utils";
import { UserOutlined } from "@ant-design/icons";
import { all_routes } from "../../../../router/all_routes";
import { handleApiError } from "../../../../utils/errorHandler";
const TicketStatus: React.FC = () => {
  const { t } = useTranslation();

  const [activities, setActivities] = useState<CompanyActivityDto[]>([]);
  const { connected, on } = useSocket();
  const {companyId} = useSelector((state: RootState) => state.auth);
  const [activeTicketCount, setActiveTicketCount] = useState<number>(0);
  const [selectedTicket, setSelectedTicket] = useState<CompanyActivityDto | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const routes = all_routes;
  useEffect(() => {
    if (companyId) {
      const fetchCompanyActivity = async () => {
        try {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type === "ticket_request"
          );
          
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setActivities(sortedActivities);
          setActiveTicketCount(sortedActivities.length);
        } catch (error: any) {
          handleApiError(error,t);
        }
      };
      
      fetchCompanyActivity();
    }
  }, [companyId]);

  useEffect(() => {
    if (connected) {
      const handleNewActivity = (data: CompanyActivityDto) => {
        if (data.type === "ticket_request") {
          
          setActivities(prev => {
            if (prev.some(a => a.id === data.id)) {
              return prev;
            }
            
            const newActivities = [data, ...prev];
            setActiveTicketCount(newActivities.length);
            return newActivities;
          });
        }
      };

      on("newActivity", handleNewActivity);
    }
  }, [connected, on]);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
  };

  const showTicketModal = (activity: CompanyActivityDto) => {
    setSelectedTicket(activity);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setSelectedTicket(null);
    setIsModalVisible(false);
  };

  const handleApproveTicket = async () => {
    if (!selectedTicket) return;
    
    try {
      setIsLoading(true);
      console.log("selectedTicket", selectedTicket);
      const response = await TicketService.approveTicket("reviewed", selectedTicket?.extraData?.ticketId as number);
      if (response) {
        message.success("Talep başarıyla onaylandı");
        // Aktiviteleri güncelle
        if (companyId) {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type === "ticket_request"
          );
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => 
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setActivities(sortedActivities);
        }
        hideModal();
      }
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-xxl-4 col-xl-6 d-flex">
      <div className="card flex-fill">
        <div className="card-header pb-2 d-flex align-items-center justify-content-between flex-wrap">
          <h5 className="mb-2">{t("dashboard.ticketRequests.title")}</h5>
        </div>
        <div className="card-body">
          <div style={{ maxHeight: '350px', overflowY: 'auto', marginBottom: '10px' }}>
            {activities.length > 0 ? (
              activities.map((activity) => (
                <div 
                  key={activity.id} 
                  className="ticket-item d-flex align-items-center justify-content-between mb-3 p-2 border border-dashed br-5"
                  onClick={() => showTicketModal(activity)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="d-flex align-items-center">
                  <Link to="#" className="avatar flex-shrink-0">
                    {activity.profilePicture ? (
                      <Avatar src={getFileUrl(activity.profilePicture)} size="large" />
                    ) : (
                      <Avatar icon={<UserOutlined />} size="default" />
                    )}
                  </Link>
                    <div className="ms-2">
                      <h6 className="fs-14 fw-medium text-truncate">
                        {activity.user.firstName + " " + activity.user.lastName}
                      </h6>
                      <p className="fs-13">
                        {activity.description}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="link-default me-2">
                      <i className="ti ti-ticket" />
                    </span>
                    <span className={`fs-10 fw-medium d-inline-flex align-items-center badge ${activity.extraData?.status === 'created' ? 'badge-warning' : 'badge-success'}`}>
                      <i className="ti ti-circle-filled fs-5 me-1" />
                      {formatTime(activity.createdAt)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-3">
                <p className="mb-0">{t("dashboard.ticketRequests.noRequests")}</p>
              </div>
            )}
          </div>
          
          <Link
            to={routes.ticketAdmin}
            className="btn btn-light btn-md w-100 mt-2"
          >
            {t("dashboard.ticketRequests.allRequests")}
          </Link>
        </div>
      </div>

      {/* Ticket Detay Modal */}
      <Modal
        title={
          <div className="d-flex align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center flex-grow-1">
              <span className="badge bg-warning me-3" style={{ fontSize: '1rem', padding: '8px 12px' }}>
                #{selectedTicket?.id}
              </span>
              <h5 className="mb-0">{selectedTicket?.description}</h5>
            </div>
          </div>
        }
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Button key="close" onClick={hideModal}>
            {t("dashboard.ticketRequests.close")}
          </Button>,
          selectedTicket?.extraData?.status === 'created' && (
            <Button
              key="approve"
              type="primary"
              loading={isLoading}
              onClick={handleApproveTicket}
            >
              {t("dashboard.ticketRequests.approve")}
            </Button>
          )
        ]}
        width={700}
      >
        <div className="ticket-details-modal">
          <div className="user-info mb-4 p-3 bg-light rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-wrapper me-3">
                <ImageWithBasePath
                  src={selectedTicket?.user.avatar || "assets/img/profiles/avatar-09.jpg"}
                  alt="User"
                  className="avatar avatar-md rounded-circle border border-2 shadow-sm"
                />
              </div>
              <div className="user-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">{selectedTicket?.user.firstName} {selectedTicket?.user.lastName}</h6>
                    <p className="text-muted mb-0 small">{selectedTicket?.user.email}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-calendar me-1"></i> 
                      {selectedTicket && new Date(selectedTicket.createdAt).toLocaleDateString('tr-TR', { 
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-clock me-1"></i>
                      {selectedTicket && new Date(selectedTicket.createdAt).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="ticket-content">
            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-file-description me-2"></i>
                {t("dashboard.ticketRequests.description")}
              </h6>
              <div className="description p-3 border rounded bg-white">
                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                  {selectedTicket?.description || t("dashboard.ticketRequests.noDescription")}
                </p>
              </div>
            </div>

            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-info-circle me-2"></i>
                {t("dashboard.ticketRequests.statusInfo")}
              </h6>
              <div className="status-info p-3 border rounded bg-white">
                <div className="d-flex align-items-center">
                  <span 
                    className={`status-indicator ${selectedTicket?.extraData?.status === 'created' ? 'bg-warning' : 'bg-success'} me-2`}
                    style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}
                  ></span>
                  <span className="status-text">
                    {selectedTicket?.extraData?.status === 'created' 
                      ? t("dashboard.ticketRequests.created") 
                      : t("dashboard.ticketRequests.solved")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      
    </div>
  );
};

export default TicketStatus; 