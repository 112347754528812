import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class ApiService {
    private static instance: ApiService;
    private api: AxiosInstance;

    private constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL || 'http://localhost:7575/api',
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.setupInterceptors();
    }

    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    }

    private setupInterceptors(): void {
        this.api.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    const currentPath = window.location.pathname;
                    const loginPaths = ['/login', '/login-2'];
                    
                    if (!loginPaths.some(path => currentPath.includes(path))) {
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    private extractData<T>(response: { data: { code: number; data: T } }): T {
        // console.log(response);
        
        return response.data.data;
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.get<{ code: number; data: T }>(url, config);
        return this.extractData(response);
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        // console.log(data);
        
        const response = await this.api.post<{ code: number; data: T }>(url, data, config);
        // console.log(response);
        
        return this.extractData(response);
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.put<{ code: number; data: T }>(url, data, config);
        return this.extractData(response);
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.delete<{ code: number; data: T }>(url, config);
        return this.extractData(response);
    }

    public async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.patch<{ code: number; data: T }>(url, data, config);
        return this.extractData(response);
    }
}

export default ApiService.getInstance(); 