import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Select, Input, Upload, Modal, message, TimePicker } from 'antd';
import { LeaveType, LeaveStatus, LeaveDto } from '../../../../types/leaves.types';
import { LEAVE_TYPES, LEAVE_STATUSES } from '../../../../constants/leaveConstants';
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { useUsers } from '../../../../hooks/useUsers';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../../utils/errorHandler';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface AdminLeaveModalProps {
  leave?: LeaveDto;
  onSubmit: (values: any) => void;
  onClose: () => void;
  open: boolean;
  selectedEmployeeId?: number;
}

const AdminLeaveModal: React.FC<AdminLeaveModalProps> = ({
  leave,
  onSubmit,
  onClose,
  open,
  selectedEmployeeId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType | null>(null);
  const { users, loading: usersLoading, fetchUsers } = useUsers();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (selectedEmployeeId) {
      form.setFieldsValue({ employeeId: selectedEmployeeId });
    }
  }, [selectedEmployeeId, form]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      let startDate, endDate;
      if (values.type === 'hourly') {
        const date = values.date;
        const [startTime, endTime] = values.timeRange;
        
        startDate = date.clone()
          .hour(startTime.hour())
          .minute(startTime.minute())
          .toISOString();
        
        endDate = date.clone()
          .hour(endTime.hour())
          .minute(endTime.minute())
          .toISOString();
      } else {
        [startDate, endDate] = values.dateRange;
        startDate = startDate.toISOString();
        endDate = endDate.toISOString();
      }

      // Dosya yükleme işlemi simülasyonu
      const uploadPromises = fileList.map(file => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ url: `uploaded/${file.name}` });
          }, 1000);
        });
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      
      onSubmit({
        ...values,
        startDate,
        endDate,
        dateRange: undefined,
        date: undefined,
        timeRange: undefined,
        documents: uploadedFiles
      });

      form.resetFields();
      setFileList([]);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    onClose();
  };

  // Tarih validasyonu
  const validateDates = (_: any, value: [dayjs.Dayjs, dayjs.Dayjs]) => {
    if (!value || !value[0] || !value[1]) {
      return Promise.reject('Tarih aralığı seçiniz');
    }

    const [start, end] = value;
    if (end.diff(start, 'days') > 30) {
      return Promise.reject('İzin süresi 30 günden fazla olamaz');
    }

    return Promise.resolve();
  };

  // İzin türü değiştiğinde form alanlarını sıfırla
  const handleLeaveTypeChange = (value: LeaveType) => {
    setSelectedLeaveType(value);
    form.setFieldsValue({
      dateRange: undefined,
      date: undefined,
      timeRange: undefined
    });
  };

  return (
    <Modal
      title={leave ? t('leaves.editLeave') : t('leaves.addLeave')}
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={[
        <button
          key="cancel"
          type="button"
          className="btn btn-light"
          onClick={handleCancel}
          disabled={loading}
        >
          {t('common.buttons.cancel')}
        </button>,
        <button
          key="submit"
          type="button"
          className="btn btn-primary ms-2"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm me-1" />
              {t('common.buttons.saving')}
            </>
          ) : t('common.buttons.save')}
        </button>
      ]}
      width={600}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...leave ? {
            type: leave.type,
            status: leave.status,
            dateRange: leave.type === 'hourly' 
              ? undefined 
              : [dayjs(leave.startDate), dayjs(leave.endDate)],
            date: leave.type === 'hourly' ? dayjs(leave.startDate) : undefined,
            timeRange: leave.type === 'hourly' 
              ? [dayjs(leave.startDate), dayjs(leave.endDate)] 
              : undefined,
            reason: leave.reason,
            employeeId: selectedEmployeeId
          } : {
            status: 'pending' as LeaveStatus,
            employeeId: selectedEmployeeId
          }
        }}
      >
        {!selectedEmployeeId && (
          <Form.Item
            name="employeeId"
            label={t('leaves.selectEmployee')}
            rules={[{ required: true, message: t('leaves.selectEmployee') }]}
          >
            <Select placeholder={t('leaves.selectEmployee')}>
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="type"
          label={t('leaves.leaveType')}
          rules={[{ required: true, message: t('leaves.leaveTypeRequired') }]}
        >
          <Select 
            placeholder={t('leaves.leaveTypeRequired')}
            onChange={handleLeaveTypeChange}
          >
            {Object.keys(LEAVE_TYPES).map((value) => (
              <Select.Option key={value} value={value}>
                {t(`leaveTypes.${value}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedLeaveType === 'hourly' ? (
          <>
            <Form.Item
              name="date"
              label={t('leaves.date')}
              rules={[{ required: true, message: t('leaves.dateRequired') }]}
            >
              <DatePicker 
                className="w-100"
                format="DD.MM.YYYY"
                placeholder={t('leaves.dateRequired')}
                disabledDate={(current) => current && current < dayjs().startOf('day')}
              />
            </Form.Item>

            <Form.Item
              name="timeRange"
              label={t('leaves.timeRange')}
              rules={[{ required: true, message: t('leaves.timeRangeRequired') }]}
            >
              <TimePicker.RangePicker 
                className="w-100"
                format="HH:mm"
                minuteStep={30}
              />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            name="dateRange"
            label={t('leaves.dateRange')}
            rules={[
              { required: true, message: t('leaves.dateRangeRequired') },
              { validator: validateDates }
            ]}
          >
            <RangePicker 
              className="w-100"
              format="DD.MM.YYYY"
              placeholder={[t('leaves.startDate'), t('leaves.endDate')]}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
            />
          </Form.Item>
        )}

        {/* <Form.Item
          name="status"
          label="Durum"
          rules={[{ required: true, message: 'Durum seçiniz' }]}
        >
          <Select placeholder="Durum seçiniz">
            {Object.entries(LEAVE_STATUSES).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          name="reason"
          label={t('leaves.description')}
          rules={[
            { required: true, message: t('leaves.descriptionRequired') },
            { min: 10, message: t('leaves.descriptionMinLength') }
          ]}
        >
          <TextArea 
            rows={4} 
            placeholder={t('leaves.descriptionRequired')}
            showCount
            maxLength={500}
          />
        </Form.Item>

        <Form.Item
          name="documents"
          label={t('leaves.documents')}
        >
          <Upload
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
            beforeUpload={() => false}
            multiple
          >
            <button type="button" className="btn btn-outline-primary">
              <UploadOutlined /> {t('leaves.uploadDocuments')}
            </button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminLeaveModal; 