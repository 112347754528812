import React, { useState, useEffect } from 'react';
import { Card, DatePicker, Table, Button, Row, Col, Spin, Typography, Select, Input, Space, Tag, Avatar } from 'antd';
import { SearchOutlined, FileExcelOutlined, FilePdfOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import dailyRecordService from '../../../services/dailyRecord/dailyRecord.service';
import { AttendanceDto } from '../../../types/attendanceDto.types';
import { createPdf, exportToExcel } from '../../../utils/exportUtils';
import FilterDropdown from '../../shared/components/dropdown/FilterDropdown';
import { ExportColumn } from '../../../types/export.types';
import AttendanceFilters from './components/AttendanceFilters';
import departmentService from '../../../services/department/department.service';
import shopService from '../../../services/shop/shop.service';
import { DepartmentDto } from '../../../types/department.types';
import { Store } from '../../../types/shop.types';
import { getFileUrl } from '../../../utils/file.utils';
import LoadingSpinner from '../../shared/uiInterface/base-ui/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../utils/errorHandler';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FooterSection from '../../shared/components/footerSection';
// Day.js ayarları
dayjs.locale('tr');
dayjs.extend(localizedFormat);

const { Title } = Typography;
const { RangePicker } = DatePicker;

const AttendanceReports: React.FC = () => {
  // State tanımlamaları
  const [attendanceRecords, setAttendanceRecords] = useState<AttendanceDto[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<AttendanceDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null);
  const [filterLate, setFilterLate] = useState<boolean | null>(null);
  const [departments, setDepartments] = useState<DepartmentDto[]>([]);
  const [shops, setShops] = useState<Store[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [selectedShop, setSelectedShop] = useState<string>('all');
  const [selectedGender, setSelectedGender] = useState<string>('all');
  const { t } = useTranslation();
  // Verileri getiren fonksiyon
  const fetchAttendanceRecords = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      // Test için 1 saniyelik gecikme ekleyerek yükleme göstergesini görebilelim
      setTimeout(async () => {
        try {
          const data = await dailyRecordService.getAllDailyRecord(startDate, endDate);
          setAttendanceRecords(data);
          setFilteredRecords(data);
        } catch (error: any) {
          handleApiError(error,t);
        } finally {
          setLoading(false);
        }
      }, 1000);
    } catch (error: any) {
      handleApiError(error,t);
      setLoading(false);
    }
  };

  // Departman ve şube verilerini getir
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [deptData, shopData] = await Promise.all([
          departmentService.getDepartments(),
          shopService.getShops()
        ]);
        setDepartments(deptData);
        setShops(shopData);
      } catch (error: any) {
        handleApiError(error,t);
      }
    };
    fetchData();
  }, []);

  // Sayfa yüklendiğinde verileri getir
  useEffect(() => {
    const startDate = dayjs().startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs().endOf('day').format('YYYY-MM-DD');
    fetchAttendanceRecords(startDate, endDate);
  }, []);

  // Filtreleri uygula
  const applyFilters = () => {
    if (!attendanceRecords) return;
    
    let result = [...attendanceRecords];

    if (dateRange) {
      const [start, end] = dateRange;
      result = result.filter(record => {
        const recordDate = dayjs(record.entryTime);
        return recordDate.isAfter(start.startOf('day'), 'day') && recordDate.isBefore(end.endOf('day'), 'day');
      });
    }

    if (selectedDepartment !== 'all') {
      result = result.filter(record => 
        departments.find(d => d.id.toString() === selectedDepartment)?.name === record.user?.department?.name
      );
    }

    if (selectedShop !== 'all') {
      result = result.filter(record => 
        shops.find(s => s.id.toString() === selectedShop)?.name === record.user?.branch?.name
      );
    }

    if (selectedGender !== 'all') {
      result = result.filter(record => 
        record.user?.gender === selectedGender
      );
    }

    if (filterLate !== null) {
      result = result.filter(record => record.isLate === filterLate);
    }

    setFilteredRecords(result);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedDepartment, selectedShop, selectedGender, filterLate, dateRange, attendanceRecords]);

  // Filtreleri sıfırla
  const handleReset = () => {
    setDateRange(null);
    setSelectedDepartment('all');
    setSelectedShop('all');
    setSelectedGender('all');
    setFilterLate(null);
    setFilteredRecords(attendanceRecords);
  };

  // Tarihi formatla
  const formatTime = (time: string) => {
    if (!time) return '-';
    return dayjs(time).format('HH:mm:ss');
  };

  const formatDate = (date: string) => {
    if (!date) return '-';
    return dayjs(date).format('DD MMMM YYYY');
  };

  const formatDuration = (minutes: number) => {
    if (minutes === 0) return '0 dk';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0 ? `${hours} ${t('common.hours')} ${mins} ${t('common.minutes')}` : `${mins} ${t('common.minutes')}`;
  };

  // PDF dışa aktarma
  const handleExportPdf = () => {
    type PdfDataType = {
      name: string;
      date: string;
      entryTime: string;
      exitTime: string;
      workTime: string;
      lateDuration: string;
      department: string;
      branch: string;
    };

    const columnsForPdf = [
      { header: 'Ad Soyad', property: 'name' as keyof PdfDataType, width: 120 },
      { header: 'Tarih', property: 'date' as keyof PdfDataType, width: 100 },
      { header: 'Giris Saati', property: 'entryTime' as keyof PdfDataType, width: 60 },
      { header: 'Cikis Saati', property: 'exitTime' as keyof PdfDataType, width: 60 },
      { header: 'Calisma Suresi', property: 'workTime' as keyof PdfDataType, width: 60 },
      { header: 'Gec Kalma', property: 'lateDuration' as keyof PdfDataType, width: 60 },
      { header: 'Departman', property: 'department' as keyof PdfDataType, width: 100 },
      { header: 'Sube', property: 'branch' as keyof PdfDataType, width: 120 }
    ];

    const data = filteredRecords.map(record => ({
      name: `${record.user?.firstName || ''} ${record.user?.lastName || ''}`,
      date: formatDate(record.entryTime),
      entryTime: formatTime(record.entryTime),
      exitTime: formatTime(record.exitTime),
      workTime: formatDuration(record.totalWorkTime),
      lateDuration: record.isLate ? formatDuration(record.lateDuration) : '-',
      department: record.user?.department?.name || '-',
      branch: record.user?.branch?.name || '-'
    }));

    // Filtre açıklaması
    let description = `Giris-Cikis Kayitlari Raporu - Tarih Araligi: ${dateRange ? `${dateRange[0].format('DD.MM.YYYY')} - ${dateRange[1].format('DD.MM.YYYY')}` : 'Tarih Araligi Belirtilmemis'}`;
    
    if (dateRange) {
      description += ` - ${dateRange[0].format('DD.MM.YYYY')} - ${dateRange[1].format('DD.MM.YYYY')}`;
    }
    
    if (selectedDepartment !== 'all' && departments.length > 0) {
      const deptName = departments.find(d => d.id.toString() === selectedDepartment)?.name;
      description += ` - Departman: ${deptName}`;
    }
    
    if (selectedShop !== 'all' && shops.length > 0) {
      const shopName = shops.find(s => s.id.toString() === selectedShop)?.name;
      description += ` - Sube: ${shopName}`;
    }

    // createPdf fonksiyonunu çağır
    createPdf(data, {
      title: 'Giris-Cikis Kayitlari Raporu',
      description: description,
      headerColor: '#3e5569', // Koyu mavi
      accentColor: '#3498db', // Mavi
      columns: columnsForPdf,
      showStatistics: false, // İstatistik kutuları gösterme
      footer: {
        text: `© ${new Date().getFullYear()} DINOX - Tum Haklari Saklidir`,
        showPageNumbers: true
      }
    });
  };

  // Excel dışa aktarma
  const handleExportExcel = () => {
    type ExcelColumns = {
      header: string;
      property: 'name' | 'date' | 'entryTime' | 'exitTime' | 'workTime' | 'lateDuration' | 'isLate' | 'department' | 'branch';
      width?: number;
    };

    const columnsForExcel: ExcelColumns[] = [
      { header: 'Ad Soyad', property: 'name' },
      { header: 'Tarih', property: 'date' },
      { header: 'Giris Saati', property: 'entryTime' },
      { header: 'Cikis Saati', property: 'exitTime' },
      { header: 'Calisma Suresi', property: 'workTime' },
      { header: 'Gec Kalma', property: 'lateDuration' },
      { header: 'Geç Kaldı mı?', property: 'isLate' },
      { header: 'Departman', property: 'department' },
      { header: 'Sube', property: 'branch' }
    ];

    const data = filteredRecords.map(record => ({
      name: `${record.user?.firstName || ''} ${record.user?.lastName || ''}`,
      date: formatDate(record.entryTime),
      entryTime: formatTime(record.entryTime),
      exitTime: formatTime(record.exitTime),
      workTime: formatDuration(record.totalWorkTime),
      lateDuration: record.isLate ? formatDuration(record.lateDuration) : '-',
      isLate: record.isLate ? 'Evet' : 'Hayır',
      department: record.user?.department?.name || '-',
      branch: record.user?.branch?.name || '-'
    }));

    exportToExcel(data, 'Giris-Cikis Kayitlari Raporu', columnsForExcel);
  };

  // Tablo sütunları
  const columns = [
    {
      title: t('attendanceReport.employee'),
      dataIndex: 'user',
      key: 'user',
      render: (user: any) => (
        <div className="d-flex align-items-center">
          <div className="avatar me-2">
            {user?.profilePicture ? (
                        <Avatar src={getFileUrl(user.profilePicture)} size="large" />
                      ) : (
                      <Avatar icon={<UserOutlined />} size="default" />
            )}
          </div>
          <div>
            <Link to={all_routes.employeedetails.replace(':id', user?.id.toString() || '')}>
              <h6 className="mb-0">{user?.firstName} {user?.lastName}</h6>
              <small className="text-muted">{user?.department?.name}</small>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: t('attendanceReport.shop'),
      dataIndex: ['user', 'branch', 'name'],
      key: 'branch',
    },
    {
      title: t('attendanceReport.date'),
      dataIndex: 'entryTime',
      key: 'date',
      render: (text: string) => formatDate(text),
    },
    {
      title: t('attendanceReport.entryTime'),
      dataIndex: 'entryTime',
      key: 'entryTime',
      render: (text: string) => formatTime(text),
    },
    {
      title: t('attendanceReport.exitTime'),
      dataIndex: 'exitTime',
      key: 'exitTime',
      render: (text: string) => formatTime(text),
    },
    {
      title: t('attendanceReport.workTime'),
      dataIndex: 'totalWorkTime',
      key: 'totalWorkTime',
      render: (minutes: number) => formatDuration(minutes),
    },
    {
      title: t('attendanceReport.status'),
      dataIndex: 'isLate',
      key: 'isLate',
      render: (isLate: boolean, record: AttendanceDto) => (
        isLate ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${record.id}`}>
                {record.excuses && record.excuses.length > 0 
                  ? record.excuses.map(excuse => excuse.reason).join(', ')
                  : t('attendanceReport.noExcuse')}
              </Tooltip>
            }
          >
            <Tag color="red">
              {t('attendanceReport.late')} ({formatDuration(record.lateDuration)})
            </Tag>
          </OverlayTrigger>
        ) : (
          <Tag color="green">{t('attendanceReport.onTime')}</Tag>
        )
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Breadcrumb */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">{t('attendanceReport.pageTitle')}</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={all_routes.adminDashboard}>
                    <i className="ti ti-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">{t('attendanceReport.breadcrumb.reports')}</li>
                <li className="breadcrumb-item active">{t('attendanceReport.breadcrumb.attendanceReport')}</li>
              </ul>
            </div>
          </div>
          <div className="d-flex my-xl-auto right-content align-items-center flex-wrap  ">
            <div className="dropdown me-2">
              <Link
                to="#"
                className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                data-bs-toggle="dropdown"
              >
                <i className="ti ti-file-export me-1" />
                {t('common.buttons.export')}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end p-3">
                <li>
                  <Link to="#" className="dropdown-item rounded-2 d-flex align-items-center py-2"
                  onClick={handleExportPdf}
                  >
                    <i className="ti ti-file-type-pdf me-2 text-danger" />
                    {t('common.buttons.pdf')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="#" className="dropdown-item rounded-2 d-flex align-items-center py-2"
                  onClick={handleExportExcel}
                  >
                    <i className="ti ti-file-type-xls me-2 text-success" />
                    {t('common.buttons.excel')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* İstatistikler */}
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-primary">
                    <i className="ti ti-users text-primary fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('attendanceReport.totalRecords')}</div>
                    <div className="dash-counts">
                      <h4>{filteredRecords.length}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-warning">
                    <i className="ti ti-clock-exclamation text-warning fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('attendanceReport.lateRecords')}</div>
                    <div className="dash-counts">
                      <h4>{filteredRecords.filter(r => r.isLate).length}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-success">
                    <i className="ti ti-clock-check text-success fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('attendanceReport.onTimeRecords')}</div>
                    <div className="dash-counts">
                      <h4>{filteredRecords.filter(r => !r.isLate).length}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <div className="avatar bg-light-info">
                    <i className="ti ti-chart-bar text-info fs-20" />
                  </div>
                  <div className="dash-count">
                    <div className="dash-title">{t('attendanceReport.averageLate')}</div>
                    <div className="dash-counts">
                      <h4>
                        {formatDuration(
                          Math.round(
                            filteredRecords.reduce((acc, curr) => acc + (curr.isLate ? curr.lateDuration : 0), 0) /
                            (filteredRecords.filter(r => r.isLate).length || 1)
                          )
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Filtreler ve Tablo */}
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-end flex-wrap row-gap-3">
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">
                  <AttendanceFilters
                    selectedDateRange={dateRange ? [dateRange[0].toISOString(), dateRange[1].toISOString()] : null}
                    selectedDepartment={selectedDepartment}
                    selectedShop={selectedShop}
                    selectedGender={selectedGender}
                    selectedLateStatus={filterLate}
                    departments={departments}
                    shops={shops}
                    onDateRangeChange={(dates) => {
                      if (dates) {
                        const [start, end] = dates;
                        setDateRange([dayjs(start), dayjs(end)]);
                        fetchAttendanceRecords(dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD'));
                      } else {
                        setDateRange(null);
                        const today = dayjs();
                        fetchAttendanceRecords(
                          today.startOf('day').format('YYYY-MM-DD'),
                          today.endOf('day').format('YYYY-MM-DD')
                        );
                      }
                    }}
                    onDepartmentChange={setSelectedDepartment}
                    onShopChange={setSelectedShop}
                    onGenderChange={setSelectedGender}
                    onLateStatusChange={setFilterLate}
                    onReset={handleReset}
                  />
                </div>
              </div>

              <div className="table-responsive">
                {loading ? (
                  <LoadingSpinner centered={true} />
                ) : (
                  <Table 
                    columns={columns} 
                    dataSource={filteredRecords} 
                    rowKey="id"
                    pagination={{ 
                      pageSize: 10,
                      //showSizeChanger: true,
                      //showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} kayıt` 
                    }}
                  />
                )}
              </div>
        </div>
      </div>
      <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
    </div>
  );
};

export default AttendanceReports; 