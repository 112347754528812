import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthState, LoginRequest } from "../../types/auth.types";
import authService from "../../services/auth/auth.service";

const savedUser = localStorage.getItem('user')
const savedToken = localStorage.getItem('token')
const savedCompanyId = localStorage.getItem('companyId')
const initialState: AuthState = {
  user: savedUser? JSON.parse(savedUser) : null,
  token: savedToken?savedToken:null,
  isAuthenticated: !!savedUser,
  loading: false,
  error: null,
  companyId: savedCompanyId?savedCompanyId:null,
};

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: LoginRequest, { rejectWithValue }) => {
    try {
      const response = await authService.login(credentials);
      console.log(response);
      if (response.tokens.accessToken && response.user) {
        authService.setToken(response.tokens.accessToken);
        authService.setUser(JSON.stringify(response.user));
        localStorage.setItem('companyId', response.user.companyId?.toString() || '');
        return response;
      }

      throw new Error("Giriş başarısız");
    } catch (error: any) {
      return rejectWithValue({
        standartError: {
          message: "Giriş başarısız",
          description: error.response?.data?.error?.description || "Giriş bilgileri hatalı",
          code: error.response?.status || "AUTH_ERROR"
        }
      });
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    checkAuth: (state) => {
      const token = authService.getToken();
      const user = authService.getUser();
      const companyId = localStorage.getItem('companyId');
      state.isAuthenticated = !!token;
      state.token = token;
      state.user = user ? JSON.parse(user) : null;
      state.companyId = companyId?companyId:null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.token = action.payload.tokens.accessToken;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.isAuthenticated = false;
        state.error = null;
      });
  },
});

export const { clearError, checkAuth } = authSlice.actions;
export default authSlice.reducer;
