import { Break, BreakHistoryCompanyDto, StartBreakDto } from "../../types/break.types";
import apiService from "../api/api.service";

class BreakService {
  private static instance: BreakService;

  private constructor() {}

  public static getInstance(): BreakService {
    if (!BreakService.instance) {
      BreakService.instance = new BreakService();
    }
    return BreakService.instance;
  }

  public async startBreak(dto: StartBreakDto): Promise<Break> {
    const response = await apiService.post<Break>("/break/start", dto);
    return response;
  }

  public async endBreak(): Promise<Break> {
    const response = await apiService.post<Break>("/break/end");
    return response;
  }

  public async getBreakHistoryCompany(startDate: string, endDate: string): Promise<BreakHistoryCompanyDto[]> {
    const response = await apiService.get<BreakHistoryCompanyDto[]>(`/break/company?startDate=${startDate}&endDate=${endDate}`);
    return response;
  }
}

export default BreakService.getInstance();