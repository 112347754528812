import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import reportService from '../../../services/reports/report.service';
import { EmployeeReportDto, AgeDetails } from '../../../types/report.types';
import { Table, Select, Button, Tag, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../utils/errorHandler';
import { createPdf, exportToExcel } from '../../../utils/exportUtils';
import dayjs from 'dayjs';
import FooterSection from '../../shared/components/footerSection';
const { Option } = Select;

// AgeDetails'ı genişleterek department alanı ekle
interface ExtendedAgeDetails extends AgeDetails {
    department: string;
}

const EmployeeReport = () => {
    const [reportData, setReportData] = useState<EmployeeReportDto | null>(null);
    const [filteredData, setFilteredData] = useState<ExtendedAgeDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const [branches, setBranches] = useState<string[]>([]);
    const [departments, setDepartments] = useState<string[]>([]);
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
    const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await reportService.getEmployeeReport();
                setReportData(data);
                
                // Şube listesini oluştur
                const uniqueBranches = Array.from(new Set(data.ageDetails.map(item => item.branch)));
                setBranches(uniqueBranches);
                
                // Çalışanlar için departman bilgisi ekle (veri yoksa varsayılan olarak şube bilgisini kullan)
                // Not: Gerçekte bu bilgi API'den gelmeli, burada örnek amaçlı yapıyoruz
                const processedData = data.ageDetails.map(item => ({
                    ...item,
                    department: `${item.branch} Departmanı` // Örnek bir departman adı
                }));
                
                setFilteredData(processedData);
                
                // Departman listesini oluştur
                const uniqueDepartments = Array.from(new Set(processedData.map(item => item.department)));
                setDepartments(uniqueDepartments);
            } catch (error: any) {
                handleApiError(error,t);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Filtre değiştiğinde veriyi güncelle
    useEffect(() => {
        if (reportData) {
            filterData();
        }
    }, [selectedBranch, selectedDepartment, reportData]);

    // Filtreleme fonksiyonu
    const filterData = () => {
        if (!reportData) return;
        
        // Departman alanı eklenen veri
        const dataWithDepartment = reportData.ageDetails.map(item => ({
            ...item,
            department: `${item.branch} Departmanı` // Örnek bir departman adı
        }));

        let filtered = [...dataWithDepartment];
        
        if (selectedBranch) {
            filtered = filtered.filter(item => item.branch === selectedBranch);
        }
        
        if (selectedDepartment) {
            filtered = filtered.filter(item => item.department === selectedDepartment);
        }
        
        setFilteredData(filtered);
    };

    // Filtre sıfırlama
    const resetFilters = () => {
        setSelectedBranch(undefined);
        setSelectedDepartment(undefined);
    };

    // Çalışma süresini metin olarak formatlama
    const formatWorkDuration = (duration: { days: number; hours: number; minutes: number }) => {
        // Günleri yıl ve kalan günlere dönüştür
        let years = 0;
        let remainingDays = duration.days;
        
        if (remainingDays >= 365) {
            years = Math.floor(remainingDays / 365);
            remainingDays = remainingDays % 365;
        }
        
        const parts = [];
        if (years > 0) parts.push(`${years} yıl`);
        if (remainingDays > 0) parts.push(`${remainingDays} gün`);
        if (duration.hours > 0) parts.push(`${duration.hours} saat`);
        if (duration.minutes > 0) parts.push(`${duration.minutes} dakika`);
        return parts.join(', ');
    };

    // Çalışma süresini sıralamak için tam dakika cinsinden toplam süre
    const getTotalMinutes = (duration: { days: number; hours: number; minutes: number }) => {
        return duration.days * 24 * 60 + duration.hours * 60 + duration.minutes;
    };

    // Tablo için sütun tanımları
    const columns: ColumnsType<ExtendedAgeDetails> = [
        {
            title: t('employeeReport.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('employeeReport.age'),
            dataIndex: 'age',
            key: 'age',
            sorter: (a, b) => a.age - b.age,
            render: (age) => (
                <Tag color="geekblue">{age}</Tag>
            )
        },
        {
            title: t('employeeReport.branch'),
            dataIndex: 'branch',
            key: 'branch',
            filters: branches.map(branch => ({ text: branch, value: branch })),
            onFilter: (value, record) => record.branch === value,
        },
        {
            title: t('employeeReport.workDuration'),
            dataIndex: 'workDuration',
            key: 'workDuration',
            sorter: (a, b) => getTotalMinutes(a.workDuration) - getTotalMinutes(b.workDuration),
            render: (workDuration) => {
                // Günleri yıl ve kalan günlere dönüştür
                let years = 0;
                let remainingDays = workDuration.days;
                
                if (remainingDays >= 365) {
                    years = Math.floor(remainingDays / 365);
                    remainingDays = remainingDays % 365;
                }
                
                let tooltipText = "";
                if (years > 0) tooltipText += `${years} ${t('employeeReport.years')}, `;
                if (remainingDays > 0) tooltipText += `${remainingDays} ${t('employeeReport.days')}, `;
                tooltipText += `${workDuration.hours} ${t('employeeReport.hours')}, ${workDuration.minutes} ${t('employeeReport.minutes')}`;
                
                return (
                    <Tooltip title={tooltipText}>
                        <span>{formatWorkDuration(workDuration)}</span>
                    </Tooltip>
                );
            }
        },
    ];

    // Gender Distribution Chart
    const genderChartOptions: ApexOptions = {
        chart: {
            type: 'pie' as const,
            height: 300
        },
        labels: [t('employeeReport.male'), t('employeeReport.female')],
        colors: ['#4318FF', '#6AD2FF'],
        legend: {
            position: 'bottom'
        }
    };

    // Marital Status Chart
    const maritalChartOptions: ApexOptions = {
        chart: {
            type: 'pie' as const,
            height: 300
        },
        labels: [t('employeeReport.married'), t('employeeReport.unMarried')],
        colors: ['#05CD99', '#FFB547'],
        legend: {
            position: 'bottom'
        }
    };

    // Branch Distribution Chart
    const getBranchDistribution = () => {
        if (!reportData?.ageDetails) return { categories: [], data: [] };
        
        const branchCounts: { [key: string]: number } = {};
        reportData.ageDetails.forEach(detail => {
            branchCounts[detail.branch] = (branchCounts[detail.branch] || 0) + 1;
        });

        return {
            categories: Object.keys(branchCounts),
            data: Object.values(branchCounts)
        };
    };

    const branchChartOptions: ApexOptions = {
        chart: {
            type: 'bar' as const,
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        colors: ['#4318FF'],
        xaxis: {
            categories: getBranchDistribution().categories
        }
    };

    return (
        <div className="page-wrapper">
            <div className="content">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{t('employeeReport.pageTitle')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">{t('employeeReport.breadcrumb.reports')}</li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('employeeReport.breadcrumb.employeeReport')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center">
                        <div className="mb-2">
                            <div className="dropdown me-2">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="ti ti-file-export me-1" />
                                    {t('common.buttons.export')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                        onClick={() => {
                                            // Çalışan verileri için sütun tanımları
                                            type EmployeeDataType = {
                                                name: string;
                                                age: number;
                                                branch: string;
                                                workDuration: string;
                                            };

                                            // PDF için sütun tanımları
                                            const columnsForPdf = [
                                                { header: 'Ad Soyad', property: 'name' as keyof EmployeeDataType, width: 120 },
                                                { header: 'Yas', property: 'age' as keyof EmployeeDataType, width: 50 },
                                                { header: 'Sube', property: 'branch' as keyof EmployeeDataType, width: 120 },
                                                { header: 'Calisma Suresi', property: 'workDuration' as keyof EmployeeDataType, width: 160 }
                                            ];

                                            // PDF için formatlanmış veri
                                            const formattedData = filteredData.map(employee => {
                                                // Çalışma süresini metin olarak formatlama
                                                let formattedDuration = '';
                                                const duration = employee.workDuration;
                                                
                                                // Günleri yıl ve kalan günlere dönüştür
                                                let years = 0;
                                                let remainingDays = duration.days;
                                                
                                                if (remainingDays >= 365) {
                                                    years = Math.floor(remainingDays / 365);
                                                    remainingDays = remainingDays % 365;
                                                }
                                                
                                                const parts = [];
                                                if (years > 0) parts.push(`${years} yil`);
                                                if (remainingDays > 0) parts.push(`${remainingDays} gun`);
                                                if (duration.hours > 0) parts.push(`${duration.hours} saat`);
                                                if (duration.minutes > 0) parts.push(`${duration.minutes} dakika`);
                                                formattedDuration = parts.join(', ');

                                                return {
                                                    name: employee.name,
                                                    age: employee.age,
                                                    branch: employee.branch,
                                                    workDuration: formattedDuration
                                                };
                                            });

                                            // Filtre açıklaması
                                            let description = 'Calisan Raporu';
                                            
                                            if (selectedBranch) {
                                                description += ` - Sube: ${selectedBranch}`;
                                            }
                                            
                                            if (selectedDepartment) {
                                                description += ` - Departman: ${selectedDepartment}`;
                                            }

                                            // createPdf fonksiyonunu çağır
                                            createPdf(formattedData, {
                                                title: 'Calisan Raporu',
                                                description: description,
                                                headerColor: '#2c3e50', // Koyu lacivert
                                                accentColor: '#3498db', // Mavi
                                                columns: columnsForPdf,
                                                showStatistics: false, // İstatistik kutuları gösterme
                                                footer: {
                                                    text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
                                                    showPageNumbers: true
                                                }
                                            });
                                        }}
                                    >
                                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                                        {t('common.buttons.pdf')}
                                    </Link>
                                    </li>
                                    <li className="mt-1">
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                        onClick={() => {
                                            // Excel için sütun tanımları
                                            type EmployeeExcelType = {
                                                name: string;
                                                age: number;
                                                branch: string;
                                                department: string;
                                                workDuration: string;
                                                yearsOfExperience: number;
                                            };

                                            const columnsForExcel = [
                                                { header: 'Ad Soyad', property: 'name' as keyof EmployeeExcelType, width: 30 },
                                                { header: 'Yaş', property: 'age' as keyof EmployeeExcelType, width: 10 },
                                                { header: 'Şube', property: 'branch' as keyof EmployeeExcelType, width: 20 },
                                                { header: 'Departman', property: 'department' as keyof EmployeeExcelType, width: 20 },
                                                { header: 'Çalışma Süresi', property: 'workDuration' as keyof EmployeeExcelType, width: 40 },
                                                { header: 'Tecrübe (Yıl)', property: 'yearsOfExperience' as keyof EmployeeExcelType, width: 15 }
                                            ];

                                            // Excel için formatlanmış veri
                                            const formattedData = filteredData.map(employee => {
                                                // Çalışma süresini metin olarak formatlama
                                                let formattedDuration = '';
                                                const duration = employee.workDuration;
                                                
                                                // Günleri yıl ve kalan günlere dönüştür
                                                let years = 0;
                                                let remainingDays = duration.days;
                                                
                                                if (remainingDays >= 365) {
                                                    years = Math.floor(remainingDays / 365);
                                                    remainingDays = remainingDays % 365;
                                                }
                                                
                                                const parts = [];
                                                if (years > 0) parts.push(`${years} yıl`);
                                                if (remainingDays > 0) parts.push(`${remainingDays} gün`);
                                                if (duration.hours > 0) parts.push(`${duration.hours} saat`);
                                                if (duration.minutes > 0) parts.push(`${duration.minutes} dakika`);
                                                formattedDuration = parts.join(', ');

                                                return {
                                                    name: employee.name,
                                                    age: employee.age,
                                                    branch: employee.branch,
                                                    department: employee.department,
                                                    workDuration: formattedDuration,
                                                    yearsOfExperience: years
                                                };
                                            });

                                            // Excel dosyasını oluştur
                                            exportToExcel(formattedData, 'Calisan_Raporu', columnsForExcel);
                                        }}
                                    >
                                        <i className="ti ti-file-type-xls me-2 text-success" />
                                        {t('common.buttons.excel')}
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Yükleniyor...</span>
                        </div>
                    </div>
                ) : reportData ? (
                    <div className="row">
                        {/* Cinsiyet ve Medeni Durum Dağılımı */}
                        <div className="col-xl-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">{t('employeeReport.genderDistribution')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={genderChartOptions}
                                        series={[reportData.genderDistribution.male, reportData.genderDistribution.female]}
                                        type="pie"
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">{t('employeeReport.maritalStatusDistribution')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={maritalChartOptions}
                                        series={[reportData.maritalStatusDistribution.married, reportData.maritalStatusDistribution.single]}
                                        type="pie"
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Şube Bazlı Dağılım */}
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{t('employeeReport.branchBasedDistribution')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={branchChartOptions}
                                        series={[{
                                            name: t('employeeReport.employeeCount'),
                                            data: getBranchDistribution().data
                                        }]}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Çalışan Detay Tablosu - Ant Design Table */}
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                 
                                </div>
                                <div className="card-body">
                                    <Table
                                        columns={columns}
                                        dataSource={filteredData}
                                        rowKey="userId"
                                        pagination={{ 
                                            pageSize: 10,
                                        }}
                                        bordered
                                        size="middle"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-danger">
                        {t('employeeReport.defaultErrorMessage')}
                    </div>
                )}
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default EmployeeReport; 