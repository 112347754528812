import { AttendanceDto, CompanyActiveBreakUsersDto, CompanyActivityDto, CompanyDailyLateUsersDto, CompanyDailyLeaveUsersDto, CompanyDailyStatsDto, DailyRecordControl, LateInDto, UserRecordDto } from "../../types/attendanceDto.types";
import apiService from "../api/api.service";

class DailyRecordService {
  private static instance: DailyRecordService;

  private constructor() {}

  public static getInstance(): DailyRecordService {
    if (!DailyRecordService.instance) {
      DailyRecordService.instance = new DailyRecordService();
    }
    return DailyRecordService.instance;
  }

  public async getDailyRecordControl(): Promise<DailyRecordControl> {
    const response = await apiService.get<DailyRecordControl>(
      "/daily-records/control"
    );
    return response;
  }

  public async getAllDailyRecord(startDate: string, endDate: string): Promise<AttendanceDto[]> {
    const response = await apiService.get<AttendanceDto[]>(`/daily-records?startDate=${startDate}&endDate=${endDate}`);
    return response;
  }

  public async checkIn(): Promise<AttendanceDto> {
    const response = await apiService.post<AttendanceDto>(`/daily-records/check-in`);
    return response;
  }

  public async checkOut(): Promise<AttendanceDto> {
    try {
      const response = await apiService.post<AttendanceDto>(`/daily-records/check-out`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getLateIn(): Promise<LateInDto> {
    const response = await apiService.get<LateInDto>(`/daily-records/late`);
    return response;
  }


  public async getCompanyDailyStats(): Promise<CompanyDailyStatsDto> {
    const response = await apiService.get<CompanyDailyStatsDto>(`/daily-records/company/daily-stats`);
    return response;
  }

  public async getCompanyActivity(companyId: string): Promise<CompanyActivityDto[]> {
    const response = await apiService.get<CompanyActivityDto[]>(`/companies/activities/${companyId}`);
    return response;
  }

  public async getCompanyDailyLeaveUsers(companyId: number): Promise<CompanyDailyLeaveUsersDto[]> {
    const response = await apiService.get<CompanyDailyLeaveUsersDto[]>(`/leaves/today/${companyId}`);
    return response;
  }

  public async getCompanyActiveBreakUsers(): Promise<CompanyActiveBreakUsersDto[]> {
    const response = await apiService.get<CompanyActiveBreakUsersDto[]>(`/break/currently`);
    return response;
  }

  public async getCompanyDailyLateUsers(date: string): Promise<CompanyDailyLateUsersDto[]> {
    const response = await apiService.get<CompanyDailyLateUsersDto[]>(`/daily-records/late-employees?startDate=${date}&endDate=${date}`);
    return response;
  }

  public async getUserRecords(userId: number, startDate: string, endDate: string): Promise<UserRecordDto> {
    const response = await apiService.get<UserRecordDto>(`/daily-records/user/${userId}?startDate=${startDate}&endDate=${endDate}`);
    return response;
  }

  public async getCheckedInEmployees(): Promise<AttendanceDto[]> {
    const response = await apiService.get<AttendanceDto[]>(`/daily-records/checked-in-employees`);
    return response;
  }

  public async checkInEmployees(): Promise<AttendanceDto[]> {
    const response = await apiService.get<AttendanceDto[]>(`/daily-records/checked-in-employees`);
    return response;
  }
}

export default DailyRecordService.getInstance();
