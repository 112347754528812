import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import ReactApexChart from "react-apexcharts";
import { TicketDto, TicketResponse } from "../../../types/ticket.types";
import TicketService from "../../../services/ticket/ticket.service";
import { User } from "../../../types/user.types";
import { message, Tooltip, Modal, Button, Avatar } from "antd";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getFileUrl } from "../../../utils/file.utils";
import { UserOutlined } from "@ant-design/icons";
import { handleApiError } from "../../../utils/errorHandler";
import dayjs from 'dayjs';
import FooterSection from "../../shared/components/footerSection";

const TicketsAdmin = () => {
  const { t } = useTranslation();
  const routes = all_routes;
  const {companyId} = useSelector((state: RootState) => state.auth);
  const { getAllTickets } = TicketService;
  const [tickets, setTickets] = useState<TicketDto[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<TicketDto[]>([]);
  const [branchTicketCounts, setBranchTicketCounts] = useState<Record<string, number>>({});
  const [departmentTicketCounts, setDepartmentTicketCounts] = useState<Record<string, number>>({});
  const [totalTickets, setTotalTickets] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [visibleTickets, setVisibleTickets] = useState(6);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);

  const fetchTickets = async () => {
    try {
      setLoading(true);
      const response = await getAllTickets(Number(companyId));
      setTickets(response.tickets);
      setFilteredTickets(response.tickets);
      setBranchTicketCounts(response.branchTicketCounts);
      setDepartmentTicketCounts(response.departmentTicketCounts);
      setTotalTickets(response.totalTickets);
      setChartData(calculateChartData(response));
      setLoading(false);
    } catch (error: any) {
      handleApiError(error,t);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [t]);

  const handleTicketUpdate = async ()=>{
    await fetchTickets();
  }


  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#4361ee", "#38cb89", "#ef4b4b", "#ffab00"],
    plotOptions: {
      bar: {
        columnWidth: "50%",
        borderRadius: 2,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const calculateChartData = (response: TicketResponse) => {
    const totalTickets = response.totalTickets;
    const tickets = response.tickets;
    const openTickets = tickets.filter(ticket => ticket.status === "reviewed").length;
    const pendingTickets = tickets.filter(ticket => ticket.status === "created").length;

    return [
      {
        title: t('tickets.totalTickets'),
        value: totalTickets,
        badgeColor: "bg-transparent-purple",
        badgeIcon: "ti-mail",
        seriesData: [openTickets, pendingTickets, totalTickets],
      },
      {
        title: t('tickets.openTickets'),
        value: openTickets,
        badgeColor: "bg-transparent-dark",
        badgeIcon: "ti-book",
        seriesData: [openTickets, pendingTickets, totalTickets],
      },
 
      {
        title: t('tickets.pendingTickets'),
        value: pendingTickets,
        badgeColor: "bg-secondary-transparent",
        badgeIcon: "ti-clock",
        seriesData: [openTickets, pendingTickets, totalTickets],
      },
    ];
  };

  const TicketChart = ({
    title,
    value,
    badgeColor,
    badgeIcon,
    seriesData,
    chartOptions,
  }: {
    title: string;
    value: number;
    badgeColor: string;
    badgeIcon: string;
    seriesData: number[];
    chartOptions: any;
  }) => {
    return (
      <div className="col-xl-4 col-md-6 d-flex">
        <div className="card flex-fill">
          <div className="card-body">
            <div className="row">
              <div className="col-6 d-flex">
                <div className="flex-fill">
                  <div
                    className={`border border-dashed ${badgeColor} rounded-circle d-inline-flex align-items-center justify-content-center p-1 mb-3`}
                  >
                    <span
                      className="avatar avatar-lg avatar-rounded bg-primary-transparent"
                      data-bs-toggle="tooltip"
                      title={badgeIcon}
                    >
                      <i className={`ti ${badgeIcon} fs-20`} />
                    </span>
                  </div>
                  <p className="fw-medium fs-12 mb-1">{title}</p>
                  <h4>{value}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleOnExportExcel = () => {
    // Excel için veri hazırlama - daha detaylı
    const excelData = filteredTickets.map(ticket => ({
      'Talep No': `#${ticket.id}`,
      'Baslik': ticket.title,
      'Aciklama': ticket.description,
      'Durum': ticket.status === 'created' ? 'Beklemede' : ticket.status === 'reviewed' ? 'Incelendi' : 'Çözümlendi',
      'Öncelik': 'Normal', // Sabit değer olarak ayarlandı
      'Olusturan': `${ticket.user.firstName} ${ticket.user.lastName}`,
      'E-posta': ticket.user.email,
      'Departman': ticket.user.title || 'Belirtilmemiş',
      'Olusturulma Tarihi': new Date(ticket.createdAt).toLocaleDateString('tr-TR'),
      'Olusturulma Saati': new Date(ticket.createdAt).toLocaleTimeString('tr-TR'),
      'Son Güncelleme': ticket.updatedAt ? new Date(ticket.updatedAt).toLocaleString('tr-TR') : '-'
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);

    ws['!cols'] = [
      { wch: 10 },  // Talep No
      { wch: 30 },  // Başlık
      { wch: 50 },  // Açıklama
      { wch: 15 },  // Durum
      { wch: 15 },  // Öncelik
      { wch: 25 },  // Oluşturan
      { wch: 30 },  // E-posta
      { wch: 20 },  // Departman
      { wch: 15 },  // Oluşturulma Tarihi
      { wch: 15 },  // Oluşturulma Saati
      { wch: 20 }   // Son Güncelleme
    ];

    const workbookProps = {
      Title: "Talep Raporu",
      Subject: "Talep Sistemi",
      Author: "DINOX",
      CreatedDate: new Date()
    };
    
    wb.Props = workbookProps;
    XLSX.utils.book_append_sheet(wb, ws, "Talep Raporu");
    
    // Dosya adına filtre bilgisini ekle
    const statusText = selectedStatus === "all" ? "Tüm" : 
                      selectedStatus === "created" ? "Bekleyen" : 
                      selectedStatus === "reviewed" ? "Incelenen" : "";
    
    XLSX.writeFile(wb, `Talepler_Raporu_${statusText}_${new Date().toISOString().slice(0,10)}.xlsx`);
  };

  const handleOnExportPdf = () => {
    // Landscape (yatay) modda PDF oluştur
    const doc = new jsPDF('l', 'pt');
    const statusText = selectedStatus === "all" ? "TÜM" : 
    selectedStatus === "created" ? "BEKLEYEN" : 
    selectedStatus === "reviewed" ? "INCELENEN" : "";

    // Türkçe karakter desteği
    doc.setLanguage("tr");
    
    // PDF metaverileri ayarla - Türkçe karakter desteği için önemli
    doc.setProperties({
      title: "Talep Raporu",
      subject: "Talep Sistemi Raporu",
      author: "DINOX",
      keywords: "Talep, Rapor, Destek",
      creator: "DINOX Talep Yönetim Sistemi"
    });
    
    // Sayfa boyutları
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    
    // Profesyonel görünümlü header oluştur
    doc.setFillColor(44, 62, 80); // Koyu lacivert
    doc.rect(0, 0, width, 60, 'F');
    
    // Logo alanı
    doc.setFillColor(25, 80, 120); // Koyu mavi
    doc.roundedRect(20, 10, 50, 40, 3, 3, 'F');
    
    // Logo metni
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.setTextColor(255, 255, 255);
    doc.text("DINOX", 32, 35);
    
    // Başlık metni
    doc.setFont("helvetica", "bold");
    doc.setFontSize(22);
    doc.setTextColor(255, 255, 255);
    
    // Başlığı merkeze hizala
    const titleText = `${statusText} TALEP RAPORU`;
    const titleWidth = doc.getTextWidth(titleText);
    doc.text(titleText, (width - titleWidth) / 2, 35);
    
    // Tarih bilgisi
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.setTextColor(255, 255, 255);
    const tarih = dayjs().format('DD MMMM YYYY');
    const dateText = "Olusturulma Tarihi: " + tarih;
    doc.text(dateText, width - doc.getTextWidth(dateText) - 30, 35);
    
    // Alt başlık - açıklama alanı
    doc.setFillColor(245, 245, 245);
    doc.rect(0, 60, width, 30, 'F');
    
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.setTextColor(80, 80, 80);
    
    // İstatistik bilgileri
    const pendingCount = filteredTickets.filter(ticket => ticket.status === 'created').length;
    const reviewedCount = filteredTickets.filter(ticket => ticket.status === 'reviewed').length;
    
    const description = `Bu raporda ${statusText.toLowerCase()} talepler listelenmektedir. Toplam: ${filteredTickets.length}, Bekleyen: ${pendingCount}, Incelenen: ${reviewedCount}`;
    doc.text(description, 30, 80);
    
    // İstatistik kutuları
    const boxesY = 110;
    const boxWidth = 150;
    const boxHeight = 60;
    const boxMargin = 20;

    // Tablo verilerini hazırla
    const tableData = filteredTickets.map(ticket => {
      return [
        `#${ticket.id}`,
        ticket.title,
        ticket.description.length > 100 
          ? ticket.description.substring(0, 97) + '...' 
          : ticket.description,
        ticket.status === 'created' 
          ? 'Beklemede' 
          : ticket.status === 'reviewed' 
            ? 'Incelendi' 
            : 'Çözümlendi',
        `${ticket.user.firstName} ${ticket.user.lastName}`,
        ticket.user.title || 'Belirtilmemiş',
        ticket.user.branch?.name || 'Belirtilmemiş',
        dayjs(ticket.createdAt).format('DD.MM.YYYY')
      ];
    });

    // Tablo başlıkları
    const headers = [
      'Talep No',
      'Baslik',
      'Aciklama',
      'Durum',
      'Olusturan',
      'Departman',
      'Sube',
      'Tarih'
    ];

    // Tablo stilleri ve oluşturma
    autoTable(doc, {
      head: [headers],
      body: tableData,
      startY: boxesY + 20, // İstatistik kutularının altından başla
      styles: {
        fontSize: 10,
        cellPadding: 6,
        lineColor: [220, 220, 220],
        lineWidth: 0.5,
        font: "helvetica"
      },
      headStyles: {
        fillColor: [44, 62, 80], // Koyu lacivert - başlıkla uyumlu
        textColor: 255,
        fontSize: 11,
        fontStyle: 'bold',
        halign: 'center',
        valign: 'middle',
        minCellHeight: 30
      },
      bodyStyles: {
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 50, halign: 'center' },  // Talep No
        1: { cellWidth: 120 },                   // Başlık
        2: { cellWidth: 160 },                   // Açıklama
        3: { cellWidth: 70, halign: 'center' },  // Durum
        4: { cellWidth: 80 },                    // Oluşturan
        5: { cellWidth: 80 },                    // Departman
        6: { cellWidth: 80 },                    // Şube
        7: { cellWidth: 70, halign: 'center' }   // Tarih
      },
      alternateRowStyles: {
        fillColor: [240, 245, 250] // Açık mavi - daha iyi okunabilirlik
      },
      didDrawPage: (data) => {
        if (data.pageNumber > 1) {
          // Sonraki sayfalarda üst bilgi
          doc.setFillColor(44, 62, 80); // Koyu lacivert
          doc.rect(0, 0, width, 40, 'F');
          
          // Logo metni
          doc.setFont("helvetica", "bold");
          doc.setFontSize(16);
          doc.setTextColor(255, 255, 255);
          doc.text("DINOX", 30, 25);
          
          // Başlık metni
          doc.setFontSize(18);
          
          // Başlığı merkeze hizala
          const titleText = `${statusText} TALEP RAPORU`;
          const titleWidth = doc.getTextWidth(titleText);
          doc.text(titleText, (width - titleWidth) / 2, 25);
        }
        
        // Sayfada footer 
        doc.setFillColor(240, 240, 240);
        doc.rect(0, height - 35, width, 35, 'F');
        
        // Sayfa numarası
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.setTextColor(80, 80, 80);
        doc.text(
          `Sayfa ${data.pageNumber} / ${doc.getNumberOfPages()}`,
          width - 80,
          height - 15
        );
        
        // Footer bilgisi
        doc.setFontSize(10);
        doc.text(
          "© " + new Date().getFullYear() + " DINOX - Tüm Haklari Saklidir",
          30,
          height - 15
        );
      },
      margin: { top: boxesY + 20, bottom: 40 }
    });

    // Dosya adına filtre bilgisini ekle
    doc.save(`Talep_Raporu_${statusText}_${dayjs().format('YYYY-MM-DD')}.pdf`);
  };

  const displayedTickets = filteredTickets.slice(0, visibleTickets);

  const loadMoreTickets = () => {
    setVisibleTickets(prev => prev + 6);
  };

  // Status filtreleme fonksiyonu
  const filterTicketsByStatus = (status: string) => {
    setSelectedStatus(status);
    
    if (status === "all") {
      setFilteredTickets(tickets);
    } else {
      const filtered = tickets.filter(ticket => ticket.status === status);
      setFilteredTickets(filtered);
    }
    
    // Filtre değiştiğinde görünen bilet sayısını sıfırla
    setVisibleTickets(6);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('tickets.pageTitle')}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('tickets.breadcrumb.employee')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('tickets.breadcrumb.tickets')}
                  </li>
                </ol>
              </nav>
            </div>
            
          </div>
          <div className="row">
            {!loading && chartData && chartData.map((item, index) => (
              <TicketChart
                key={index}
                title={item.title}
                value={item.value}
                badgeColor={item.badgeColor}
                badgeIcon={item.badgeIcon}
                seriesData={item.seriesData}
                chartOptions={chartOptions}
              />
            ))}
          </div>
          
          {/* Şube ve Departman İstatistikleri */}
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header border-bottom-0 pb-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">{t('tickets.branchStats')}</h4>
                  </div>
                </div>
                <div className="card-body">
                  {Object.keys(branchTicketCounts).length > 0 ? (
                    <div>
                      <ReactApexChart
                        options={{
                          chart: {
                            type: 'bar',
                            toolbar: {
                              show: false
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              borderRadius: 4,
                              barHeight: '60%',
                              distributed: true
                            }
                          },
                          colors: ['#4361ee', '#0fb77b', '#ffc107', '#f86f3b', '#8e59ff'],
                          dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                              fontSize: '12px',
                              fontWeight: 'bold',
                            },
                            formatter: function (val: number) {
                              return val;
                            },
                            offsetX: 0
                          },
                          xaxis: {
                            categories: Object.keys(branchTicketCounts),
                            labels: {
                              style: {
                                fontSize: '12px'
                              }
                            }
                          },
                          yaxis: {
                            labels: {
                              style: {
                                fontSize: '12px'
                              }
                            }
                          },
                          grid: {
                            borderColor: '#f1f1f1',
                            xaxis: {
                              lines: {
                                show: false
                              }
                            }
                          },
                          legend: {
                            show: false
                          }
                        }}
                        series={[{
                          name: t('tickets.pageTitle'),
                          data: Object.values(branchTicketCounts)
                        }]}
                        type="bar"
                        height={350}
                      />
                      <div className="branch-legend mt-4">
                        {Object.entries(branchTicketCounts).map(([branch, count], index) => (
                          <div key={index} className="legend-item d-inline-flex align-items-center me-4 mb-2">
                            <span className="legend-dot me-2" style={{ 
                              width: '8px', 
                              height: '8px', 
                              borderRadius: '50%', 
                              backgroundColor: ['#4361ee', '#0fb77b', '#ffc107', '#f86f3b', '#8e59ff'][index % 5],
                              display: 'inline-block'
                            }}></span>
                            <span className="fs-12">{branch}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">{t('tickets.branchNotFound')}</p>
                  )}
                </div>
              </div>
            </div>
            
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header border-bottom-0 pb-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">{t('tickets.departmentStats')}</h4>
                  </div>
                </div>
                <div className="card-body">
                  {Object.keys(departmentTicketCounts).length > 0 ? (
                    <div>
                      <ReactApexChart
                        options={{
                          chart: {
                            type: 'bar',
                            toolbar: {
                              show: false
                            }
                          },
                          plotOptions: {
                            bar: {
                              horizontal: true,
                              borderRadius: 4,
                              barHeight: '60%',
                              distributed: true
                            }
                          },
                          colors: ['#4361ee', '#0fb77b', '#ffc107', '#f86f3b', '#8e59ff'],
                          dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                              fontSize: '12px',
                              fontWeight: 'bold',
                            },
                            formatter: function (val: number) {
                              return val + ' ' + t('tickets.ticket');
                            },
                            offsetX: 0
                          },
                          xaxis: {
                            categories: Object.keys(departmentTicketCounts),
                            labels: {
                              style: {
                                fontSize: '12px'
                              }
                            }
                          },
                          yaxis: {
                            labels: {
                              style: {
                                fontSize: '12px'
                              }
                            }
                          },
                          grid: {
                            borderColor: '#f1f1f1',
                            xaxis: {
                              lines: {
                                show: false
                              }
                            }
                          },
                          legend: {
                            show: false
                          }
                        }}
                        series={[{
                          name: 'Talepler',
                          data: Object.values(departmentTicketCounts)
                        }]}
                        type="bar"
                        height={350}
                      />
                      <div className="department-legend mt-4">
                        {Object.entries(departmentTicketCounts).map(([department, count], index) => (
                          <div key={index} className="legend-item d-inline-flex align-items-center me-4 mb-2">
                            <span className="legend-dot me-2" style={{ 
                              width: '8px', 
                              height: '8px', 
                              borderRadius: '50%', 
                              backgroundColor: ['#4361ee', '#0fb77b', '#ffc107', '#f86f3b', '#8e59ff'][index % 5],
                              display: 'inline-block'
                            }}></span>
                            <span className="fs-12">{department}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">{t('tickets.departmentNotFound')}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <style>
            {`
              .stat-item:hover .progress-bar {
                opacity: 0.8;
              }
              
              .stat-icon .avatar {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .stat-icon i {
                font-size: 1.2rem;
              }

              .progress {
                overflow: visible;
              }

              .progress-bar {
                position: relative;
                transition: all 0.4s ease;
              }

              .department-legend .legend-item {
                cursor: pointer;
                padding: 4px 8px;
                border-radius: 4px;
                transition: all 0.3s ease;
              }

              .department-legend .legend-item:hover {
                background: rgba(0,0,0,0.04);
              }
            `}
          </style>
          
          <div className="card mb-4">
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h5 className="mb-0">{t('tickets.ticketList')}</h5>
                <div className="d-flex align-items-center gap-2">
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle btn btn-sm btn-primary d-inline-flex align-items-center"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-filter me-1"></i>
                      {selectedStatus === "all" ? t('tickets.allTickets') : 
                       selectedStatus === "created" ? t('tickets.pendingTickets') : 
                       selectedStatus === "reviewed" ? t('tickets.reviewedTickets') : 
                       t('tickets.statusSelect')}
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end p-2">
                      <li onClick={() => filterTicketsByStatus("all")}>
                        <Link
                          to="#"
                          className={`dropdown-item rounded-1 ${selectedStatus === "all" ? "active" : ""}`}
                        >
                          <i className="ti ti-list me-1"></i> {t('tickets.allTickets')}
                        </Link>
                      </li>
                      <li onClick={() => filterTicketsByStatus("created")}>
                        <Link
                          to="#"
                          className={`dropdown-item rounded-1 ${selectedStatus === "created" ? "active" : ""}`}
                        >
                          <i className="ti ti-clock me-1"></i> {t('tickets.pendingTickets')}
                        </Link>
                      </li>
                      <li onClick={() => filterTicketsByStatus("reviewed")}>
                        <Link
                          to="#"
                          className={`dropdown-item rounded-1 ${selectedStatus === "reviewed" ? "active" : ""}`}
                        >
                          <i className="ti ti-check me-1"></i> {t('tickets.reviewedTickets')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle btn btn-sm btn-outline-primary d-inline-flex align-items-center"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-file-export me-1"></i> {t('common.buttons.export')}
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end p-2">
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                          onClick={handleOnExportPdf}
                        >
                          <i className="ti ti-file-type-pdf me-1" />
                          {t('common.buttons.pdf')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                          onClick={handleOnExportExcel}
                        >
                          <i className="ti ti-file-type-xls me-1" />
                          {t('common.buttons.excel')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            

            
              {displayedTickets.map((ticket) => (
                <TicketCards
                  key={ticket.id}
                  ticketid={ticket.id}
                  ticketmaintitle={ticket.title}
                  ticketdescription={ticket.description}
                  ticketstatus={ticket.status}
                  ticketdate={ticket.createdAt}
                  ticketuser={ticket.user}
                  onTicketUpdate={handleTicketUpdate}
                />
              ))}

              {visibleTickets < tickets.length && (
                <div className="col-12 text-center mt-3 mb-4">
                  <button 
                    className="btn btn-primary" 
                    onClick={loadMoreTickets}
                  >
                    {t('tickets.loadMore')}
                  </button>
                </div>
              )}
           
            <div className="col-xl-3 col-md-4">
            </div>
          </div>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
    </>
  );
};

export default TicketsAdmin;

export const TicketCards = ({ticketid, ticketmaintitle, ticketdescription, ticketstatus, ticketdate, ticketuser, onTicketUpdate}: {
  ticketid: number,
  ticketmaintitle: string,
  ticketdescription: string,
  ticketstatus: string,
  ticketdate: string,
  ticketuser: User,
  onTicketUpdate: () => Promise<void>
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleApproveTicket = async (ticketid: number, status: string) => {
    try {
      setIsLoading(true);
      const response = await TicketService.approveTicket(status, ticketid);
      if(response){
        message.success(t('tickets.addticketRequestModal.statusReviewed'));
        onTicketUpdate();
      }
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const truncateText = (text: string, maxLength: number = 100) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  return (
    <div className="col-xl-4 mb-4">
      <div className="card h-100" style={{ cursor: 'pointer' }} onClick={showModal}>
        <div className="card-header">
          <div className="d-flex align-items-center w-100">
              {ticketuser.profilePicture ? (
                  <Avatar src={getFileUrl(ticketuser.profilePicture)} size="large" />
                ) : (
                <Avatar icon={<UserOutlined />} size="default" />
              )}
            <div className="">
              <div className="fs-15 fw-semibold">{ticketuser.firstName} {ticketuser.lastName}</div>
              <p className="mb-0 text-muted fs-12">{ticketstatus.toLowerCase() === "created" ? t('tickets.cards.created') : t('tickets.cards.reviewed')}</p>
              <p className="mb-0 text-muted fs-11">{ticketuser.email}</p>
            </div>
           
          </div>
        </div>
        <div className="card-body">
          <div className="ticket-title text-truncate">
            {truncateText(ticketmaintitle)}
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-between">
            <div className="fs-semibold fs-14"><i className="ti ti-calendar-bolt me-1" /> Güncellendi: {ticketdate.split('T')[0]}</div>
            <div className="fw-semibold text-success">
              {ticketuser.title}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={
          <div className="d-flex align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center flex-grow-1">
              <span className={`badge ${ticketstatus.toLowerCase() === "created" ? "bg-warning" : "bg-success"} me-3`} style={{ fontSize: '1rem', padding: '8px 12px' }}>
              #{ticketid}
            </span>
              <h5 className="mb-0">{ticketmaintitle}</h5>
            </div>
          </div>
        }
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Button key="close" onClick={hideModal}>
            {t('tickets.addticketRequestModal.buttons.cancel')}
          </Button>,
          ticketstatus.toLowerCase() === "created" && (
            <Button 
              key="approve" 
              type="primary" 
              loading={isLoading}
              onClick={() => {
                handleApproveTicket(ticketid, "reviewed");
                hideModal();
              }}
            >
              {t('tickets.addticketRequestModal.buttons.approve')}
            </Button>
          )
        ]}
        width={700}
      >
        <div className="ticket-details-modal">
          <div className="user-info mb-4 p-3 bg-light rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-wrapper me-3">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-01.jpg"
                alt="User"
                  className="avatar avatar-md rounded-circle border border-2 shadow-sm"
              />
              </div>
              <div className="user-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
              <div>
                    <h6 className="mb-1">{ticketuser.firstName} {ticketuser.lastName}</h6>
                    <p className="text-muted mb-0 small">{ticketuser.email}</p>
              </div>
                  <div className="text-end">
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-calendar me-1"></i> 
                      {new Date(ticketdate).toLocaleDateString('tr-TR', { 
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-clock me-1"></i>
                      {new Date(ticketdate).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="ticket-content">
            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-file-description me-2"></i>
                {t('tickets.addticketRequestModal.explanation')}
              </h6>
              <div className="description p-3 border rounded bg-white">
                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                  {ticketdescription || t('tickets.addticketRequestModal.explanationError')}
                </p>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-6">
                <div className="section mb-4">
                  <h6 className="text-primary mb-3">
                    <i className="ti ti-info-circle me-2"></i>
                    {t('tickets.addticketRequestModal.status')}
                  </h6>
                  <div className="status-info p-3 border rounded bg-white">
                    <div className="d-flex align-items-center">
                      <span className={`status-indicator ${ticketstatus.toLowerCase() === "created" ? "bg-warning" : "bg-success"} me-2`} 
                            style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}></span>
                      <span className="status-text">
                  {ticketstatus.toLowerCase() === "created" 
                    ? t('tickets.addticketRequestModal.statusCreated') 
                    : t('tickets.addticketRequestModal.statusReviewed')}
                </span>
                    </div>
                  </div>
              </div>
            </div>
            
              <div className="col-md-6">
                <div className="section mb-4">
                  <h6 className="text-primary mb-3">
                    <i className="ti ti-building me-2"></i>
                    {t('tickets.addticketRequestModal.department')}
                  </h6>
                  <div className="department-info p-3 border rounded bg-white">
                    <div className="d-flex align-items-center">
                      <span className="department-text">
                        {ticketuser.title || "Belirtilmemiş"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// export const TicketCard = ({ ticketid, ticketmaintitle, tickettitle, ticketstatus, ticketdate, ticketuser, ticketpriority, ticketsupport }:
//   { 
//     ticketid: number, 
//     ticketmaintitle: string, 
//     tickettitle: string, 
//     ticketstatus: string, 
//     ticketdate: string,
//     ticketuser?: User,  // Optional
//     ticketpriority?: string,  // Optional
//     ticketsupport?: string  // Optional
//   }) => {

//   const getStatusBadgeClass = (status: string) => {
//     switch (status) {
//       case "Açık":
//         return "badge bg-outline-pink"; // Pembe
//       case "Beklemede":
//         return "badge bg-outline-warning"; // Sarı
//       case "Çözümlendi":
//         return "badge bg-outline-purple"; // Mor
//       default:
//         return "badge bg-outline-secondary"; // Varsayılan Gri
//     }
//   };

//   const getPriorityBadgeClass = (priority: string) => {
//     switch (priority) {
//       case "Yüksek":
//         return "badge bg-danger"; // Kırmızı
//       case "Orta":
//         return "badge bg-warning"; // Sarı
//       case "Düşük":
//         return "badge bg-success"; // Yeşil
//       default:
//         return "badge bg-secondary"; // Varsayılan Gri
//     }
//   };

//   return (
//     <div className="card">
//       <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
//         <h5 className="text-info fw-medium"> {ticketmaintitle} </h5>
//         <div className="d-flex align-items-center">
//           <span className={`badge ${getPriorityBadgeClass(ticketstatus)} d-inline-flex align-items-center`}>
//             <i className="ti ti-circle-filled fs-5 me-1" />
//             {ticketstatus}
//           </span>
//         </div>
//       </div>
//       <div className="card-body">
//         <div>
//           <span className="badge badge-info rounded-pill mb-2"> BIL - {ticketid} </span>
//           <div className="d-flex align-items-center mb-2">
//             <h6 className="text-muted fw-normal me-2">
//               <Link to={`/tickets/ticket-details/${ticketid}`}>{ticketuser?.firstName} {ticketuser?.lastName}</Link>
//             </h6>
//           </div>
//           <div className="d-flex align-items-center flex-wrap row-gap-2">
//             <p className="d-flex align-items-center mb-0 me-2">
//               <ImageWithBasePath
//                 src="assets/img/profiles/avatar-03.jpg"
//                 className="avatar avatar-xs rounded-circle me-2"
//                 alt="img"
//               />
//             </p>
//             <p className="d-flex align-items-center mb-0 me-2">
//               <i className="ti ti-calendar-bolt me-1" /> Güncellendi: {ticketdate.split('T')[0]}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };