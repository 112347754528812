import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import {
  Table,
  Input,
  Button,
  Space,
  Tag,
  Badge,
  Tooltip,
  Card,
  Row,
  Col,
  Statistic,
  Modal,
  message
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  BuildOutlined,
  TeamOutlined,
  HomeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ApartmentOutlined
} from "@ant-design/icons";
import companyService from "../../../services/company/company.service";
import { Company, CompanyStatus } from "../../../types/company.types";
import CollapseHeader from "../../../core/common/collapse-header/collapse-header";
import type { ColumnsType } from "antd/es/table";
import "./styles.css";
import { handleApiError } from '../../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import FooterSection from "../../shared/components/footerSection";

const { confirm } = Modal;

const AllCompanies = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    handleSearch(searchText);
  }, [companies, searchText]);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const data = await companyService.getAllCompanies();
      setCompanies(data);
      setFilteredCompanies(data);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    const filtered = companies.filter(
      (company) =>
        company.name.toLowerCase().includes(value.toLowerCase()) ||
        company.taxNumber.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleDelete = (id: number) => {
    confirm({
      title: "Bu şirketi silmek istediğinize emin misiniz?",
      icon: <ExclamationCircleOutlined />,
      content: "Bu işlem geri alınamaz!",
      okText: "Evet",
      okType: "danger",
      cancelText: "Hayır",
      onOk: async () => {
        try {
          await companyService.deleteCompany(id);
          message.success("Şirket başarıyla silindi.");
          fetchCompanies();
        } catch (error: any) {
          handleApiError(error,t);
        }
      },
    });
  };

  const getStatusBadge = (status: CompanyStatus) => {
    switch (status) {
      case CompanyStatus.ACTIVE:
        return <Badge status="success" text="Aktif" />;
      case CompanyStatus.INACTIVE:
        return <Badge status="default" text="Pasif" />;
      case CompanyStatus.SUSPENDED:
        return <Badge status="error" text="Askıya Alınmış" />;
      default:
        return <Badge status="processing" text="Bilinmiyor" />;
    }
  };

  const columns: ColumnsType<Company> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
    },
    {
      title: "Şirket Adı",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link 
          to={`${all_routes.superAdminCompanies}/details/${record.id}`} 
          className="company-name-link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Vergi Numarası",
      dataIndex: "taxNumber",
      key: "taxNumber",
    },
    {
      title: "E-posta",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      responsive: ["lg"],
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
      render: (status) => getStatusBadge(status),
    },
    {
      title: "Çalışan Sayısı",
      key: "employeeCount",
      responsive: ["lg"],
      render: (_, record) => (
        <Tag color="blue">
          <TeamOutlined /> {record.users?.length || 0}
        </Tag>
      ),
    },
    {
      title: "Şube Sayısı",
      key: "branchCount",
      responsive: ["lg"],
      render: (_, record) => (
        <Tag color="green">
          <HomeOutlined /> {record.branches?.length || 0}
        </Tag>
      ),
    },
    {
      title: "İşlemler",
      key: "actions",
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Şirket Detayları">
            <Link to={`${all_routes.superAdminCompanyDetails.replace(':id', record.id.toString())}`}>
              <Button type="primary" size="small" icon={<BuildOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Şirketi Düzenle">
              <Button type="default" size="small" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Şirketi Sil">
            <Button
              type="default"
              danger
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper vh-100 d-flex flex-column justify-content-between">
        <div className="content flex-fill h-100">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">Şirketler</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.superAdminDashboard}>
                      <i className="ti ti-smart-home"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Şirketler
                  </li>
                </ol>
              </nav>
            </div>
            <div className=" ms-2">
              <Link
                to={`${all_routes.superAdminCompanyCreate}`}
                className="btn btn-primary"
              >
                <PlusOutlined /> Yeni Şirket
              </Link>
            </div>
          </div>
          {/* /Breadcrumb */}

          {/* İstatistikler */}
          <Row gutter={16} className="mb-4">
            <Col xs={24} sm={8}>
              <Card>
                <Statistic
                  title="Toplam Şirket"
                  value={companies.length}
                  prefix={<BuildOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={8}>
              <Card>
                <Statistic
                  title="Aktif Şirketler"
                  value={
                    companies.filter(
                      (company) => company.status === CompanyStatus.ACTIVE
                    ).length
                  }
                  prefix={<Badge status="success" />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={8}>
              <Card>
                <Statistic
                  title="Pasif Şirketler"
                  value={
                    companies.filter(
                      (company) =>
                        company.status === CompanyStatus.INACTIVE ||
                        company.status === CompanyStatus.SUSPENDED
                    ).length
                  }
                  prefix={<Badge status="default" />}
                />
              </Card>
            </Col>
          </Row>

          {/* Arama ve Tablo */}
          <Card className="company-list-card mb-0">
            <div className="mb-3">
              <Input
                placeholder="Şirket adı veya vergi numarası ile ara"
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchText(e.target.value)}
                className="company-search"
                size="large"
              />
            </div>

            <Table
              columns={columns}
              dataSource={filteredCompanies}
              rowKey="id"
              loading={loading}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50"],
              }}
            />
          </Card>
        </div>
        <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AllCompanies;
