import { UserCreateDTO, UserUpdateDTO, UserRole, EmploymentType, User } from   "../types/user.types";

export const DEFAULT_USER_CREATE_DTO: UserCreateDTO = {
  // Personal Info
  firstName: "",
  lastName: "",
  birthDate: new Date(),
  nationalId: "",
  gender: "male",
  maritalStatus: "single",
  educationLevel: "bachelor",
  photo: "",

  // Contact Info
  city: "",
  email: "",
  phone: "",
  emergencyContact: "",

  // Work Info
  departmentId: 1,
  branchId: 1,
  hireDate: new Date(),
  annualLimit: 0,

  // Security Info
  password: "",
  role: UserRole.USER,
  title: "",
};


export const mapUserToUpdateDTO = (user: User): UserUpdateDTO => ({
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  role: user.role === 'admin' ? UserRole.ADMIN : 
        user.role === 'manager' ? UserRole.MANAGER : 
        UserRole.USER,
  title: user.title || "",
  phone: user.phone || "",
  emergencyContact: user.emergencyContact || "",
  hireDate: new Date(user.hireDate),
  birthDate: user.birthDate ? user.birthDate : undefined,
  maritalStatus: user.maritalStatus || "single",
  educationLevel: user.educationLevel || "bachelor",
  gender: user.gender || "male",
  city: user.city || "",
  nationalId: user.nationalId || "",
  departmentId: user.department?.id,
  branchId: user.branch?.id,
  annualLimit: user.annualLimit || 0
});

export const REQUIRED_USER_FIELDS: (keyof UserCreateDTO)[] = [
  'email',
  'firstName',
  'lastName',
  'role',
  'departmentId',
  'branchId'
];

export const ROLES = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "user", label: "User" },
];

export const GENDERS = [
  { value: "male", label: "Erkek" },
  { value: "female", label: "Kadın" },
  { value: "other", label: "Diğer" },
]; 