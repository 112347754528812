import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Avatar, Select, message, Modal, Checkbox, Spin, Tooltip } from 'antd';
import Table from '../../../shared/dataTable';
import { User, UserStatus, PermissionType, UserPermissions } from '../../../../types/user.types';
import { all_routes } from '../../../../router/all_routes';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { LoadingSpinner } from '../../../../utils/LoadingSpinner';
import { renderUserNameCell } from '../../../../utils/tableUtils';
import { getFileUrl } from '../../../../utils/file.utils';
import authService from '../../../../services/auth/auth.service';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../../utils/errorHandler';
interface EmployeeTableProps {
  users: User[];
  onEdit: (user: User) => void;
  onDelete: (id: number) => void;
  onStatusChange: (id: number, status: UserStatus) => void;
  loading?: boolean;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  users,
  onEdit,
  onDelete,
  onStatusChange,
  loading
}) => {
  // Seçilen statü ve ID'yi saklamak için state'ler
  const [selectedStatus, setSelectedStatus] = useState<UserStatus | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const { t } = useTranslation();

  // İzinler için state'ler
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const [selectedUserForPermissions, setSelectedUserForPermissions] = useState<User | null>(null);
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({ permissions: [] });
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [savingPermissions, setSavingPermissions] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('0');

  // Statü için etiketler
  const statusLabels: Record<string, string> = {
    [UserStatus.ACTIVE]: t('employees.statusOptions.working'),
    [UserStatus.RESIGNED]: t('employees.statusOptions.onLeave'),
    [UserStatus.DELETED]: t('employees.statusOptions.terminated'),
  };

  // Statü renkleri
  const getStatusColor = (status: UserStatus): string => {
    const colors: Record<string, string> = {
      [UserStatus.ACTIVE]: '#5cb85c',    // yeşil
      [UserStatus.RESIGNED]: '#6c757d',  // gri
      [UserStatus.DELETED]: '#d9534f',   // kırmızı
    };
    return colors[status] || '#6c757d';
  };

  // Statü badge'ı için stilize edilmiş sınıf
  const getStatusBadgeClass = (status: UserStatus): string => {
    switch (status) {
      case UserStatus.ACTIVE:
        return 'badge-success';
      case UserStatus.RESIGNED:
        return 'badge-secondary';
      case UserStatus.DELETED:
        return 'badge-danger';
      default:
        return 'badge-secondary';
    }
  };

  // Onay mesajını duruma göre döndüren fonksiyon
  const getConfirmMessage = (status: UserStatus): string => {
    switch (status) {
      case UserStatus.ACTIVE:
        return 'Bu çalışanı aktif olarak işaretlemek istediğinize emin misiniz?';
      case UserStatus.RESIGNED:
        return 'Bu çalışanı "İşten Ayrıldı" olarak işaretlemek istediğinize emin misiniz?';
      case UserStatus.DELETED:
        return 'Bu çalışanı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.';
      default:
        return 'Çalışan durumunu değiştirmek istediğinize emin misiniz?';
    }
  };

  // Durum değişikliğini yönetme
  const handleStatusSelectChange = (userId: number, status: UserStatus) => {
    setSelectedUserId(userId);
    setSelectedStatus(status);
    setConfirmVisible(true);
  };

  // Durum değişikliğini onaylama
  const handleConfirm = () => {
    if (selectedUserId !== null && selectedStatus !== null) {
      onStatusChange(selectedUserId, selectedStatus);
      setConfirmVisible(false);
    }
  };

  // Durum değişikliğini iptal etme
  const handleCancel = () => {
    setSelectedUserId(null);
    setSelectedStatus(null);
    setConfirmVisible(false);
  };

  // Kullanıcı izinlerini getir
  const fetchUserPermissions = async (user: User) => {
    setSelectedUserForPermissions(user);
    setLoadingPermissions(true);
    try {
      const permissions = await authService.getUserPermissions(user.id);
      console.log('Gelen izinler:', permissions);
      
      // API'dan gelen veri zaten doğru formatta olduğu için doğrudan kullanabiliriz
      // Eğer API'dan gelen veri UserPermissions tipinde değilse, uygun formata çevirelim
      if (permissions && 'permissions' in permissions) {
        setUserPermissions(permissions as UserPermissions);
      } else {
        // Eğer permissions doğrudan bir dizi ise veya farklı bir format gelirse
        setUserPermissions({ permissions: Array.isArray(permissions) ? permissions : [] });
      }
      setPermissionModalVisible(true);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setLoadingPermissions(false);
    }
  };

  // İzin değişikliğini ele al
  const handlePermissionChange = (permission: PermissionType, checked: boolean) => {
    if (checked) {
      setUserPermissions({
        permissions: [...userPermissions.permissions, permission]
      });
    } else {
      setUserPermissions({
        permissions: userPermissions.permissions.filter(p => p !== permission)
      });
    }
  };

  // İzinleri kaydet
  const savePermissions = async () => {
    if (!selectedUserForPermissions) return;
    
    setSavingPermissions(true);
    try {
      await authService.updateUserPermissions(selectedUserForPermissions.id, userPermissions.permissions);
      message.success('Kullanıcı izinleri başarıyla güncellendi');
      setPermissionModalVisible(false);
    } catch (error) {
      handleApiError(error,t);
    } finally {
      setSavingPermissions(false);
    }
  };

  // İzin adlarını daha okunabilir hale getir
  const getReadablePermissionName = (permission: string): string => {
    const permissionMap: Record<string, string> = {
      'control_panel_user': 'Kontrol Paneli Kullanıcısı',
      'control_panel_admin': 'Kontrol Paneli Yöneticisi',
      'view_users': 'Kullanıcıları Görüntüleme',
      'edit_users': 'Kullanıcıları Düzenleme',
      'view_departments': 'Departmanları Görüntüleme',
      'edit_departments': 'Departmanları Düzenleme',
      'view_branches': 'Şubeleri Görüntüleme',
      'edit_branches': 'Şubeleri Düzenleme',
      'export': 'Dışa Aktarma',
      'view_reports': 'Raporları Görüntüleme',
      'manage_shifts': 'Vardiyaları Yönetme',
      'leave_request': 'İzin Talebi',
      'view_leave': 'İzinleri Görüntüleme',
      'edit_leave': 'İzinleri Düzenleme',
      'create_notifications': 'Bildirim Oluşturma',
      'view_salaries': 'Maaşları Görüntüleme',
      'manage_company': 'Şirketi Yönetme',
      'check_in_out': 'Giriş/Çıkış Yapma',
      'break': 'Mola',
      'company_settings': 'Şirket Ayarları',
      'file_upload': 'Dosya Yükleme',
      'file_view': 'Dosya Görüntüleme',
      'view_tickets_admin': 'Talepleri Görüntüleme (Yönetici)',
      'view_tickets_user': 'Talepleri Görüntüleme (Kullanıcı)',
      'edit_tickets': 'Talepleri Düzenleme',
    };
    return permissionMap[permission] || permission;
  };

  // İzinleri kategorilere ayır
  const permissionCategories = [
    {
      title: 'Kullanıcı İzinleri',
      permissions: [
        PermissionType.CONTROL_PANEL_USER,
        PermissionType.CONTROL_PANEL_ADMIN,
        PermissionType.VIEW_USERS,
        PermissionType.EDIT_USERS,
      ]
    },
    {
      title: 'Organizasyon İzinleri',
      permissions: [
        PermissionType.VIEW_DEPARTMENTS,
        PermissionType.EDIT_DEPARTMENTS,
        PermissionType.VIEW_BRANCHES,
        PermissionType.EDIT_BRANCHES,
        PermissionType.MANAGE_COMPANY,
        PermissionType.COMPANY,
      ]
    },
    {
      title: 'Raporlama İzinleri',
      permissions: [
        PermissionType.EXPORT,
        PermissionType.VIEW_REPORTS,
        PermissionType.VIEW_SALARIES,
      ]
    },
    {
      title: 'İzin Yönetimi',
      permissions: [
        PermissionType.LEAVE_REQUEST,
        PermissionType.VIEW_LEAVE,
        PermissionType.EDIT_LEAVE,
      ]
    },
    {
      title: 'Vardiya Yönetimi',
      permissions: [
        PermissionType.MANAGE_SHIFTS,
        PermissionType.CHECK_IN_OUT,
        PermissionType.BREAK,
      ]
    },
    {
      title: 'Talep Yönetimi',
      permissions: [
        PermissionType.VIEW_TICKETS_ADMIN,
        PermissionType.VIEW_TICKETS_USER,
        PermissionType.EDIT_TICKETS,
      ]
    },
    {
      title: 'Diğer İzinler',
      permissions: [
        PermissionType.CREATE_NOTIFICATIONS,
        PermissionType.FILE_UPLOAD,
        PermissionType.FILE_VIEW,
      ]
    }
  ];

  const columns = [
    {
      title: t('employees.table.name'),
      dataIndex: ["firstName", "lastName"],
      render: (_: string, record: User) => (
        <div className="d-flex align-items-center">
          {record.profilePicture ? (
            <Avatar src={getFileUrl(record.profilePicture)} className="me-2" size="large" />
          ) : (
            <Avatar icon={<UserOutlined />} className="me-2" size="small" />
          )}
          {renderUserNameCell(record.firstName, record)}
        </div>
      ),
      sorter: (a: User, b: User) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
    },
    {
      title: t('employees.table.email'),
      dataIndex: "email",
      render: (email: string) => (
        <div className="d-flex align-items-center justify-content-between">
          <span>{email}</span>
          <button 
            className="btn btn-sm" 
            onClick={() => navigator.clipboard.writeText(email)}
            title={t('employees.table.copyEmail')}
          >
            <i className="ti ti-copy" />
          </button>
        </div>
      ),
      sorter: (a: User, b: User) => a.email.localeCompare(b.email),
    },
    {
      title: t('employees.table.department'),
      dataIndex: ["department", "name"],
      render: (_: string, record: User) => record.department?.name || '-',
      sorter: (a: User, b: User) => (a.department?.name || '').localeCompare(b.department?.name || ''),
    },
    {
      title: t('employees.table.branch'),
      dataIndex: ["branch", "name"],
      render: (_: string, record: User) => record.branch?.name || '-',
      sorter: (a: User, b: User) => (a.branch?.name || '').localeCompare(b.branch?.name || ''),
    },
    {
      title: t('employees.table.phone'),
      dataIndex: "phone",
      sorter: (a: User, b: User) => a.phone?.localeCompare(b.phone || '') || 0,
    },
    {
      title: t('employees.table.status'),
      dataIndex: "status",
      render: (status: UserStatus, record: User) => {
        // Status değişikliği için dropdown seçenekleri
        const statusOptions = Object.values(UserStatus).map(statusOption => ({
          value: statusOption,
          label: statusLabels[statusOption],
          disabled: statusOption === record.status
        }));

        return (
          <div className="d-flex align-items-center gap-2">
            <Select
              size="small"
              defaultValue={record.status}
              value={statusLabels[record.status]}
              style={{ 
                width: 120,
                fontSize: '0.8rem'
              }}
              onChange={(value) => handleStatusSelectChange(record.id, value as UserStatus)}
              options={statusOptions}
              className="status-dropdown"
              dropdownStyle={{ zIndex: 1100 }}
              bordered={true}
            />
            
            <Popconfirm
              title={t('employees.statusOptions.popconfirmStatus')}
              description={selectedStatus && selectedUserId === record.id ? getConfirmMessage(selectedStatus) : ''}
              open={confirmVisible && selectedUserId === record.id}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              okText={t('employees.statusOptions.popconfirmOk')}
              cancelText={t('employees.statusOptions.popconfirmCancel')}
              okButtonProps={{ 
                style: { 
                  backgroundColor: selectedStatus ? getStatusColor(selectedStatus) : undefined 
                } 
              }}
            >
              {/* Boş div - Popconfirm'i tetiklemek için gerekli */}
              <div />
            </Popconfirm>
          </div>
        );
      },
      sorter: (a: User, b: User) => String(a.status).localeCompare(String(b.status)),
    },
    {
      title: "",
      render: (_: any, record: User) => {
        return (
          <div className="d-flex gap-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => onEdit(record)}
              title={t('employees.statusOptions.edit')}
            >
              <i className="ti ti-edit" />
            </button>
            <Tooltip title="İzinleri Düzenle">
              <button
                className="btn btn-sm btn-linear-primary text-white"
                onClick={() => fetchUserPermissions(record)}
              >
                <i className="ti ti-key" />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // İzin modal içeriği
  const renderPermissionModal = () => {
    if (!selectedUserForPermissions) return null;

    return (
      <Modal
        title={
          <div className="d-flex align-items-center">
            <div className="bg-primary rounded-circle p-2 d-flex align-items-center justify-content-center me-3">
              <KeyOutlined className="text-white" style={{ fontSize: '18px' }} />
            </div>
            <div>
              <h5 className="mb-0">{t('employees.permissions.title', 'Kullanıcı İzinleri')}</h5>
              <small className="text-muted">
                {`${selectedUserForPermissions.firstName} ${selectedUserForPermissions.lastName}`}
              </small>
            </div>
          </div>
        }
        open={permissionModalVisible}
        onCancel={() => setPermissionModalVisible(false)}
        width={800}
        bodyStyle={{ padding: '20px', maxHeight: '60vh', overflowY: 'auto' }}
        footer={[
          <button
            key="cancel"
            type="button"
            className="btn btn-outline-secondary me-2"
            onClick={() => setPermissionModalVisible(false)}
          >
            <i className="ti ti-x me-1"></i>
            {t('employees.permissions.cancel', 'İptal')}
          </button>,
          <button
            key="submit"
            type="button"
            onClick={savePermissions}
            className="btn btn-primary d-flex align-items-center"
            disabled={savingPermissions}
          >
            {savingPermissions ? (
              <>
                <Spin size="small" className="me-2" /> 
                {t('employees.permissions.saving', 'Kaydediliyor...')}
              </>
            ) : (
              <>
                <i className="ti ti-device-floppy me-1"></i>
                {t('employees.permissions.save', 'Kaydet')}
              </>
            )}
          </button>
        ]}
      >
        {loadingPermissions ? (
          <div className="text-center py-5">
            <Spin tip={t('employees.permissions.loading', 'İzinler yükleniyor...')} />
          </div>
        ) : (
          <div className="permission-container">
            <div className="alert alert-info mb-4 d-flex align-items-center">
              <i className="ti ti-info-circle me-2 fs-5"></i>
              <div>{t('employees.permissions.info', 'Bu ekrandan kullanıcının sistem izinlerini yönetebilirsiniz. Değişiklikler kullanıcı kaydedildikten sonra aktif olacaktır.')}</div>
            </div>
            
            <div className="permission-list">
              <div className="mb-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    id="select-all"
                    onChange={(e) => {
                      const allPermissions = permissionCategories.flatMap(c => c.permissions);
                      if (e.target.checked) {
                        setUserPermissions({ permissions: allPermissions });
                      } else {
                        setUserPermissions({ permissions: [] });
                      }
                    }}
                    checked={permissionCategories.flatMap(c => c.permissions).every(p => 
                      userPermissions.permissions.includes(p as PermissionType)
                    )}
                  />
                  <label className="form-check-label fw-bold" htmlFor="select-all">
                    Tümünü Seç
                  </label>
                </div>
              </div>
              
              <div className="row">
                {permissionCategories.map((category, categoryIndex) => (
                  <div key={categoryIndex} className="col-md-4 col-lg-4 mb-3">
                    <div className="card h-100 shadow-sm border-0">
                      <div className="card-header bg-light py-2 px-3 d-flex align-items-center">
                        <div className="category-icon bg-primary text-white rounded p-1 me-2" style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <i className={getCategoryIcon(category.title)} style={{ fontSize: '14px' }}></i>
                        </div>
                        <h6 className="mb-0 fs-7 fw-bold" style={{ fontSize: '0.85rem' }}>{category.title}</h6>
                        <div className="ms-auto">
                          <div className="form-check form-check-sm">
                            <input
                              className="form-check-input form-check-input-sm"
                              type="checkbox"
                              id={`category-${categoryIndex}`}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // Kategorideki tüm izinleri ekle
                                  const newPermissions = [...userPermissions.permissions];
                                  category.permissions.forEach(perm => {
                                    if (!newPermissions.includes(perm)) {
                                      newPermissions.push(perm);
                                    }
                                  });
                                  setUserPermissions({ permissions: newPermissions });
                                } else {
                                  // Kategorideki tüm izinleri çıkar
                                  setUserPermissions({
                                    permissions: userPermissions.permissions.filter(
                                      p => !category.permissions.includes(p)
                                    )
                                  });
                                }
                              }}
                              checked={category.permissions.every(p => 
                                userPermissions.permissions.includes(p as PermissionType)
                              )}
                            />
                            <label className="form-check-label small" htmlFor={`category-${categoryIndex}`} style={{ fontSize: '0.75rem' }}>
                              Tümü
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-2">
                        <div className="permission-items">
                          {category.permissions.map((permission, permIndex) => (
                            <div key={`${permission}-${permIndex}`} className="permission-item mb-1 p-1 rounded">
                              <div className="form-check form-switch form-check-sm mb-0">
                                <input
                                  className="form-check-input form-check-input-sm me-1"
                                  type="checkbox"
                                  id={`perm-${permission}`}
                                  checked={userPermissions.permissions.includes(permission as PermissionType)}
                                  onChange={(e) => handlePermissionChange(permission as PermissionType, e.target.checked)}
                                />
                                <label className="form-check-label small" htmlFor={`perm-${permission}`} style={{ fontSize: '0.75rem' }}>
                                  {getReadablePermissionName(permission)}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  // Kategori ikonlarını döndüren yardımcı fonksiyon
  const getCategoryIcon = (categoryTitle: string): string => {
    const icons: Record<string, string> = {
      'Kullanıcı İzinleri': 'ti ti-users',
      'Organizasyon İzinleri': 'ti ti-building',
      'Raporlama İzinleri': 'ti ti-report',
      'İzin Yönetimi': 'ti ti-calendar',
      'Vardiya Yönetimi': 'ti ti-clock',
      'Talep Yönetimi': 'ti ti-ticket',
      'Diğer İzinler': 'ti ti-settings'
    };
    return icons[categoryTitle] || 'ti ti-category';
  };

  return (
    <>
      <Table 
        dataSource={users} 
        columns={columns} 
      />
      {renderPermissionModal()}
    </>
  );
};

export default EmployeeTable; 