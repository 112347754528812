import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../../../router/all_routes";
import ImageWithBasePath from "../../../../../core/common/imageWithBasePath";
import TicketService from "../../../../../services/ticket/ticket.service";
import { TicketDto } from "../../../../../types/ticket.types";
import { message, Modal, Button } from "antd";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Trans } from "react-i18next";
import LoadingSpinner from "../../../../shared/uiInterface/base-ui/LoadingSpinner";
import { handleApiError } from "../../../../../utils/errorHandler";
import FooterSection from "../../../../shared/components/footerSection";
const AllTicketsUser = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('user');
    const routes = all_routes;
    const { getTicketsByUser } = TicketService;
    
    const [tickets, setTickets] = useState<TicketDto[]>([]);
    const [filteredTickets, setFilteredTickets] = useState<TicketDto[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>("all");
    const [visibleTickets, setVisibleTickets] = useState(6);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [userName, setUserName] = useState<string>("Çalışan");

    // Bilet istatistikleri
    const [totalTickets, setTotalTickets] = useState<number>(0);
    const [createdTickets, setCreatedTickets] = useState<number>(0);
    const [reviewedTickets, setReviewedTickets] = useState<number>(0);

    const fetchTickets = async () => {
        if (!userId) return;
        
        try {
            setLoading(true);
            const response = await getTicketsByUser(Number(userId));
            setTickets(response);
            setFilteredTickets(response);
            setTotalTickets(response.length);
            
            // Bilet durumlarına göre sayıları hesapla
            const created = response.filter(ticket => ticket.status === "created").length;
            const reviewed = response.filter(ticket => ticket.status === "reviewed").length;
            
            setCreatedTickets(created);
            setReviewedTickets(reviewed);
            
            // Kullanıcı adını ayarla (gerçek uygulamada API'den gelmeli)
            if (response.length > 0 && response[0].user) {
                setUserName(`${response[0].user.firstName} ${response[0].user.lastName}`);
            } else {
                setUserName(`Çalışan #${userId}`);
            }
            
            setLoading(false);
        } catch (error: any) {
            handleApiError(error,t);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, [userId]);

    // Status filtreleme fonksiyonu
    const filterTicketsByStatus = (status: string) => {
        setSelectedStatus(status);
        
        if (status === "all") {
            setFilteredTickets(tickets);
        } else {
            const filtered = tickets.filter(ticket => ticket.status === status);
            setFilteredTickets(filtered);
        }
        
        // Filtre değiştiğinde görünen bilet sayısını sıfırla
        setVisibleTickets(6);
    };

    const handleTicketUpdate = async () => {
        await fetchTickets();
    };

    const loadMoreTickets = () => {
        setVisibleTickets(prev => prev + 6);
    };

    const displayedTickets = filteredTickets.slice(0, visibleTickets);

    // Excel dışa aktarma
    const handleOnExportExcel = () => {
        const excelData = filteredTickets.map(ticket => ({
            'Talep No': `#${ticket.id}`,
            'Başlık': ticket.title,
            'Açıklama': ticket.description,
            'Durum': ticket.status === 'created' ? 'Beklemede' : ticket.status === 'reviewed' ? 'İncelendi' : 'Çözümlendi',
            'Oluşturulma Tarihi': new Date(ticket.createdAt).toLocaleDateString('tr-TR'),
            'Son Güncelleme': ticket.updatedAt ? new Date(ticket.updatedAt).toLocaleString('tr-TR') : '-'
        }));

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        ws['!cols'] = [
            { wch: 10 },  // Talep No
            { wch: 30 },  // Başlık
            { wch: 50 },  // Açıklama
            { wch: 15 },  // Durum
            { wch: 15 },  // Oluşturulma Tarihi
            { wch: 20 }   // Son Güncelleme
        ];

        const workbookProps = {
            Title: "Çalışan Talepleri Raporu",
            Subject: "Talep Sistemi",
            Author: "Dinox",
            CreatedDate: new Date()
        };
        
        wb.Props = workbookProps;
        XLSX.utils.book_append_sheet(wb, ws, "Talepler");
        
        // Dosya adına filtre bilgisini ekle
        const statusText = selectedStatus === "all" ? "Tüm" : 
                        selectedStatus === "created" ? "Bekleyen" : 
                        selectedStatus === "reviewed" ? "İncelenen" : "";
        
        XLSX.writeFile(wb, `${userName}_Talepler_${statusText}_${new Date().toISOString().slice(0,10)}.xlsx`);
    };

    // PDF dışa aktarma
    const handleOnExportPdf = () => {
        const doc = new jsPDF('l', 'pt');
        const statusText = selectedStatus === "all" ? "TUM" : 
        selectedStatus === "created" ? "BEKLEYEN" : 
        selectedStatus === "reviewed" ? "INCELENEN" : "";

        // Türkçe karakter desteği
        doc.setLanguage("tr");
        
        // PDF metaverileri ayarla
        doc.setProperties({
            title: `${userName} - Talep Raporu`,
            subject: "Talep Sistemi Raporu",
            author: "Dinox",
            keywords: "Talep, Rapor, Destek",
            creator: "Talep Yönetim Sistemi"
        });
        
        // Header oluştur
        doc.setFillColor(41, 128, 185);
        doc.rect(0, 0, doc.internal.pageSize.width, 60, 'F');
        
        // Başlık metni
        doc.setFont("helvetica", "bold");
        doc.setFontSize(22);
        doc.setTextColor(255, 255, 255);
        doc.text(`${userName} - ${statusText} TALEP RAPORU`, 40, 35);
        
        // Tarih bilgisi
        doc.setFontSize(12);
        const tarih = new Date().toLocaleDateString('tr-TR');
        doc.text("Olusturma Tarihi: " + tarih, doc.internal.pageSize.width - 250, 35);
        
        // Alt başlık
        doc.setFillColor(245, 245, 245);
        doc.rect(0, 60, doc.internal.pageSize.width, 30, 'F');
        
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor(80, 80, 80);
        doc.text("Bu rapor calisanin tum taleplerini icermektedir.", 40, 80);
        
        // Tablo verilerini hazırla
        const tableData = filteredTickets.map(ticket => {
            // Türkçe karakter içeren metinleri ASCII karakterlere dönüştür
            const turkishToAscii = (text: string | null | undefined): string => {
                if (!text) return '';
                return text.toString()
                .replace(/ğ/g, 'g').replace(/Ğ/g, 'G')
                .replace(/ü/g, 'u').replace(/Ü/g, 'U')
                .replace(/ş/g, 's').replace(/Ş/g, 'S')
                .replace(/ı/g, 'i').replace(/İ/g, 'I')
                .replace(/ö/g, 'o').replace(/Ö/g, 'O')
                .replace(/ç/g, 'c').replace(/Ç/g, 'C');
            };
            
            return [
                `#${ticket.id}`,
                turkishToAscii(ticket.title),
                turkishToAscii(ticket.description.length > 100 
                ? ticket.description.substring(0, 97) + '...' 
                : ticket.description),
                ticket.status === 'created' ? 'Beklemede' : 'Incelendi',
                new Date(ticket.createdAt).toLocaleDateString('tr-TR').replace(/\./g, '/'),
                ticket.updatedAt ? new Date(ticket.updatedAt).toLocaleDateString('tr-TR').replace(/\./g, '/') : '-'
            ];
        });

        // Tablo başlıkları
        const headers = [
            'Talep No',
            'Baslik',
            'Aciklama',
            'Durum',
            'Olusturulma Tarihi',
            'Son Guncelleme'
        ];

        // Tablo stilleri ve oluşturma
        autoTable(doc, {
            head: [headers],
            body: tableData,
            startY: 110,
            styles: {
                fontSize: 10,
                cellPadding: 6,
                lineColor: [200, 200, 200],
                lineWidth: 0.5,
                font: "helvetica"
            },
            headStyles: {
                fillColor: [52, 73, 94],
                textColor: 255,
                fontSize: 11,
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle',
                minCellHeight: 30
            },
            bodyStyles: {
                valign: 'middle'
            },
            columnStyles: {
                0: { cellWidth: 60, halign: 'center' },
                1: { cellWidth: 130 },
                2: { cellWidth: 180 },
                3: { cellWidth: 80, halign: 'center' },
                4: { cellWidth: 80, halign: 'center' },
                5: { cellWidth: 80, halign: 'center' }
            },
            alternateRowStyles: {
                fillColor: [240, 245, 250]
            },
            margin: { top: 110, bottom: 35 }
        });

        // Dosya adına filtre bilgisini ekle
        doc.save(`${userName}_Talepler_${statusText}_${new Date().toISOString().slice(0,10)}.pdf`);
    };

    if (loading && tickets.length === 0) {
        return <LoadingSpinner />;
    }

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{userName} - {t('ticketsUserAdminPage.tickets')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={routes.employeeList}>{t('ticketsUserAdminPage.employeeList')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('ticketsUserAdminPage.tickets')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                        <div className="me-2 mb-2">
                            <div className="dropdown">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="ti ti-file-export me-1" />
                                    {t('common.buttons.export')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1" onClick={handleOnExportPdf}>
                                            <i className="ti ti-file-type-pdf me-1" />
                                            {t('common.buttons.pdf')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="dropdown-item rounded-1" onClick={handleOnExportExcel}>
                                            <i className="ti ti-file-type-xls me-1" />
                                            {t('common.buttons.excel')}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* İstatistikler */}
                <div className="row">
                    <div className="col-xl-4 col-md-6 d-flex">
                        <div className="card flex-fill">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 d-flex">
                                        <div className="flex-fill">
                                            <div className="border border-dashed bg-transparent-purple rounded-circle d-inline-flex align-items-center justify-content-center p-1 mb-3">
                                                <span className="avatar avatar-lg avatar-rounded bg-primary-transparent">
                                                    <i className="ti ti-mail fs-20" />
                                                </span>
                                            </div>
                                            <p className="fw-medium fs-12 mb-1">{t('ticketsUserAdminPage.totalTickets')}</p>
                                            <h4>{totalTickets}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 d-flex">
                        <div className="card flex-fill">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 d-flex">
                                        <div className="flex-fill">
                                            <div className="border border-dashed bg-transparent-dark rounded-circle d-inline-flex align-items-center justify-content-center p-1 mb-3">
                                                <span className="avatar avatar-lg avatar-rounded bg-success-transparent">
                                                    <i className="ti ti-check fs-20" />
                                                </span>
                                            </div>
                                            <p className="fw-medium fs-12 mb-1">{t('ticketsUserAdminPage.reviewedTickets')}</p>
                                            <h4>{reviewedTickets}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 d-flex">
                        <div className="card flex-fill">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 d-flex">
                                        <div className="flex-fill">
                                            <div className="border border-dashed bg-secondary-transparent rounded-circle d-inline-flex align-items-center justify-content-center p-1 mb-3">
                                                <span className="avatar avatar-lg avatar-rounded bg-warning-transparent">
                                                    <i className="ti ti-clock fs-20" />
                                                </span>
                                            </div>
                                            <p className="fw-medium fs-12 mb-1">{t('ticketsUserAdminPage.createdTickets')}</p>
                                            <h4>{createdTickets}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Filtre ve Liste Başlığı */}
                <div className="card mb-4">
                    <div className="card-body p-3">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h5 className="mb-0">{t('ticketsUserAdminPage.ticketList')}</h5>
                            <div className="d-flex align-items-center gap-2">
                                <div className="dropdown">
                                    <Link
                                        to="#"
                                        className="dropdown-toggle btn btn-sm btn-primary d-inline-flex align-items-center"
                                        data-bs-toggle="dropdown"
                                    >
                                        <i className="ti ti-filter me-1"></i>
                                        {selectedStatus === "all" ? "Tüm Talepler" : 
                                        selectedStatus === "created" ? "Bekleyen Talepler" : 
                                        selectedStatus === "reviewed" ? "İncelenen Talepler" : 
                                        "Durum Seç"}
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end p-2">
                                        <li onClick={() => filterTicketsByStatus("all")}>
                                            <Link
                                                to="#"
                                                className={`dropdown-item rounded-1 ${selectedStatus === "all" ? "active" : ""}`}
                                            >
                                                <i className="ti ti-list me-1"></i> {t('ticketsUserAdminPage.allTickets')}
                                            </Link>
                                        </li>
                                        <li onClick={() => filterTicketsByStatus("created")}>
                                            <Link
                                                to="#"
                                                className={`dropdown-item rounded-1 ${selectedStatus === "created" ? "active" : ""}`}
                                            >
                                                <i className="ti ti-clock me-1"></i> {t('ticketsUserAdminPage.createdTickets')}
                                            </Link>
                                        </li>
                                        <li onClick={() => filterTicketsByStatus("reviewed")}>
                                            <Link
                                                to="#"
                                                className={`dropdown-item rounded-1 ${selectedStatus === "reviewed" ? "active" : ""}`}
                                            >
                                                <i className="ti ti-check me-1"></i> {t('ticketsUserAdminPage.reviewedTickets')}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bilet Listesi */}
                <div className="row">
                    {loading ? (
                        <div className="col-12 text-center p-5">
                            <LoadingSpinner />
                        </div>
                    ) : tickets.length === 0 ? (
                        <div className="col-12 text-center p-5">
                            <div className="empty-state">
                                <i className="ti ti-ticket-off fs-1 text-muted mb-3"></i>
                                <h5>{t('ticketsUserAdminPage.noTickets')}</h5>
                                <p className="text-muted">{t('ticketsUserAdminPage.noTicketsDescription')}</p>
                            </div>
                        </div>
                    ) : displayedTickets.length === 0 ? (
                        <div className="col-12 text-center p-5">
                            <div className="empty-state">
                                <i className="ti ti-filter-off fs-1 text-muted mb-3"></i>
                                <h5>{t('ticketsUserAdminPage.noTicketsFiltered')}</h5>
                                <p className="text-muted">{t('ticketsUserAdminPage.noTicketsFilteredDescription')}</p>
                                <button className="btn btn-primary" onClick={() => filterTicketsByStatus("all")}>
                                    {t('ticketsUserAdminPage.showAllTickets')}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {displayedTickets.map((ticket) => (
                                <UserTicketCard
                                    key={ticket.id}
                                    ticketid={ticket.id}
                                    ticketmaintitle={ticket.title}
                                    ticketdescription={ticket.description}
                                    ticketstatus={ticket.status}
                                    ticketdate={ticket.createdAt}
                                    ticketupdated={ticket.updatedAt}
                                    onTicketUpdate={handleTicketUpdate}
                                />
                            ))}

                            {visibleTickets < filteredTickets.length && (
                                <div className="col-12 text-center mt-3 mb-4">
                                    <button 
                                        className="btn btn-primary" 
                                        onClick={loadMoreTickets}
                                    >
                                        {t('ticketsUserAdminPage.loadMoreTickets')}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

// Kullanıcı Bilet Kartı Bileşeni
const UserTicketCard = ({
    ticketid,
    ticketmaintitle,
    ticketdescription, 
    ticketstatus,
    ticketdate,
    ticketupdated,
    onTicketUpdate
}: {
    ticketid: number,
    ticketmaintitle: string,
    ticketdescription: string,
    ticketstatus: string,
    ticketdate: string,
    ticketupdated: string,
    onTicketUpdate: () => Promise<void>
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();
    const showModal = () => {
        setIsModalVisible(true);
    };
    const [isLoading, setIsLoading] = useState(false);
    const hideModal = () => {
        setIsModalVisible(false);
    };

    const truncateText = (text: string, maxLength: number = 100) => {
        if (text.length <= maxLength) return text;
        return `${text.substring(0, maxLength)}...`;
    };

    const handleApproveTicket = async (ticketid: number, status: string) => {
        try {
          setIsLoading(true);
          const response = await TicketService.approveTicket(status, ticketid);
          if(response){
            message.success(t('tickets.addticketRequestModal.statusReviewed'));
            onTicketUpdate();
          }
        } catch (error: any) {
          handleApiError(error,t);
        } finally {
          setIsLoading(false);
        }
      };

    return (
        <div className="col-xl-4 mb-4">
            <div className="card h-100" style={{ cursor: 'pointer' }} onClick={showModal}>
                <div className="card-header">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <div className="me-2">
                            <span className={`badge ${ticketstatus === "created" ? "bg-warning" : "bg-success"} py-1 px-2`}>
                                #{ticketid}
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className={`badge ${ticketstatus === "created" ? "bg-warning-transparent text-warning" : "bg-success-transparent text-success"}`}>
                                {ticketstatus === "created" ? t('ticketsUserAdminPage.created') : t('ticketsUserAdminPage.reviewed')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <h5 className="ticket-title">{ticketmaintitle}</h5>
                    <p className="text-muted text-sm mb-3">{truncateText(ticketdescription, 150)}</p>
                </div>
                <div className="card-footer bg-light">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <span className="text-muted small">
                                <i className="ti ti-calendar me-1"></i> 
                                {new Date(ticketdate).toLocaleDateString('tr-TR')}
                            </span>
                        </div>
                        {ticketupdated && ticketupdated !== ticketdate && (
                            <div>
                                <span className="text-muted small">
                                    <i className="ti ti-pencil me-1"></i> 
                                    {new Date(ticketupdated).toLocaleDateString('tr-TR')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Detay Modal */}
            <Modal
                title={
                    <div className="d-flex align-items-center border-bottom pb-3">
                        <div className="d-flex align-items-center flex-grow-1">
                            <span className={`badge ${ticketstatus === "created" ? "bg-warning" : "bg-success"} me-3`} style={{ fontSize: '1rem', padding: '8px 12px' }}>
                                #{ticketid}
                            </span>
                            <h5 className="mb-0">{ticketmaintitle}</h5>
                        </div>
                    </div>
                }
                open={isModalVisible}
                onCancel={hideModal}
                footer={[
                    <>
                    <Button key="close" onClick={hideModal}>
                        {t('common.buttons.close')}
                    </Button>
                    <Button key="update" onClick={() => handleApproveTicket(ticketid, ticketstatus)}>
                        {t('ticketsUserAdminPage.updateTicket')}
                    </Button>
                    </>
                ]}
                width={700}
            >
                <div className="ticket-details-modal">
                    <div className="ticket-content">
                        <div className="section mb-4">
                            <h6 className="text-primary mb-3">
                                <i className="ti ti-file-description me-2"></i>
                                {t('ticketsUserAdminPage.description')}
                            </h6>
                            <div className="description p-3 border rounded bg-white">
                                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                                    {ticketdescription || t('ticketsUserAdminPage.noDescription')}
                                </p>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="section mb-4">
                                    <h6 className="text-primary mb-3">
                                        <i className="ti ti-info-circle me-2"></i>
                                        {t('ticketsUserAdminPage.status')}
                                    </h6>
                                    <div className="status-info p-3 border rounded bg-white">
                                        <div className="d-flex align-items-center">
                                            <span className={`status-indicator ${ticketstatus === "created" ? "bg-warning" : "bg-success"} me-2`} 
                                                style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}></span>
                                            <span className="status-text">
                                                {ticketstatus === "created" ? t('ticketsUserAdminPage.created') : t('ticketsUserAdminPage.reviewed')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="section mb-4">
                                    <h6 className="text-primary mb-3">
                                        <i className="ti ti-calendar me-2"></i>
                                        {t('ticketsUserAdminPage.dates')}
                                    </h6>
                                    <div className="date-info p-3 border rounded bg-white">
                                        <div className="mb-2">
                                            <span className="fw-medium">{t('ticketsUserAdminPage.createdDate')}:</span> 
                                            <span className="ms-2">
                                                {new Date(ticketdate).toLocaleDateString('tr-TR', { 
                                                    day: '2-digit',
                                                    month: 'long',
                                                    year: 'numeric'
                                                })}
                                            </span>
                                        </div>
                                        {ticketupdated && ticketupdated !== ticketdate && (
                                            <div>
                                                <span className="fw-medium">{t('ticketsUserAdminPage.updatedDate')}:</span>
                                                <span className="ms-2">
                                                    {new Date(ticketupdated).toLocaleDateString('tr-TR', { 
                                                        day: '2-digit',
                                                        month: 'long',
                                                        year: 'numeric'
                                                    })}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AllTicketsUser;