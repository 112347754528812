export const userreportDetails = [
  {
    key:"1",
    Image: "assets/img/users/user-32.jpg",
    Name: "Anthony Lewis",
    Email: "anthony@example.com",
    CreatedDate: "12/09/2024",
    Role: "Employee",
    Status: "Active",
  },
  {
    key:"2",
    Image: "assets/img/users/user-09.jpg",
    Name: "Brian Villalobos",
    Email: "brian@example.com",
    CreatedDate: "24/10/2024",
    Role: "Employee",
    Status: "Active",
  },
  {
    key:"3",
    Image: "assets/img/users/user-40.jpg",
    Name: "Sophie Headrick",
    Email: "sophie@example.com",
    CreatedDate: "18/02/2024",
    Role: "Client",
    Status: "Active",
  },
  {
    key:"4",
    Image: "assets/img/users/user-33.jpg",
    Name: "Stephan Peralt",
    Email: "peral@example.com",
    CreatedDate: "17/10/2024",
    Role: "Employee",
    Status: "Active",
  },
  {
    key:"5",
    Image: "assets/img/users/user-44.jpg",
    Name: "Thomas Bordelon",
    Email: "thomas@example.com",
    CreatedDate: "20/07/2024",
    Role: "Client",
    Status: "Active",
  },
  {
    key:"6",
    Image: "assets/img/users/user-34.jpg",
    Name: "Doglas Martini",
    Email: "martniwr@example.com",
    CreatedDate: "10/04/2024",
    Role: "Employee",
    Status: "Active",
  },
  {
    key:"7",
    Image: "assets/img/users/user-41.jpg",
    Name: "Cameron Drake",
    Email: "cameron@example.com",
    CreatedDate: "29/08/2024",
    Role: "Client",
    Status: "Active",
  },
  {
    key:"8",
    Image: "assets/img/users/user-01.jpg",
    Name: "Harvey Smith",
    Email: "harvey@example.com",
    CreatedDate: "22/02/2024",
    Role: "Employee",
    Status: "Inactive",
  },
  {
    key:"9",
    Image: "assets/img/users/user-39.jpg",
    Name: "Michael Walker",
    Email: "michael@example.com",
    CreatedDate: "03/11/2024",
    Role: "Client",
    Status: "Active",
  },
  {
    key:"10",
    Image: "assets/img/users/user-42.jpg",
    Name: "Doris Crowley",
    Email: "doris@example.com",
    CreatedDate: "17/12/2024",
    Role: "Client",
    Status: "Active",
  },
];
