import { LeaveDto, LeaveStatus, LeaveUserCreateDTO, LeaveUserUpdateDTO, LeaveCreateDTO, UserYearlyLeaveSummary, LeaveResponse } from "../../types/leaves.types";
import apiService from "../api/api.service";

class LeaveService {
    private static instance: LeaveService;

    private constructor() {}

    public static getInstance(): LeaveService {
        if (!LeaveService.instance) {
            LeaveService.instance = new LeaveService();
        }
        return LeaveService.instance;
    }

    public async getLeaves(): Promise<LeaveDto[]> {
        const response = await apiService.get<LeaveDto[]>('/leaves');
        return response;
    }

    public async getLeaveById(id: number): Promise<LeaveDto> {
        const response = await apiService.get<LeaveDto>(`/leaves/${id}`);
        return response;
    }

    public async getLeaveByUserId(id: number, startDate: string, endDate: string): Promise<LeaveResponse> {
        const response = await apiService.get<LeaveResponse>(`/leaves/user/${id}?startDate=${startDate}&endDate=${endDate}`);
        return response;
    }
    
    public async createLeave(leave: LeaveUserCreateDTO): Promise<LeaveDto> {
        const response = await apiService.post<LeaveDto>('/leaves', leave);
        return response;
    }
    public async createLeaveAdmin(leave: LeaveCreateDTO): Promise<LeaveDto> {
        const response = await apiService.post<LeaveDto>('/leaves/admin', leave);
        return response;
    }

    public async updateLeave(id: number, leave: { status: string }): Promise<LeaveDto> {
        const response = await apiService.put<LeaveDto>(`/leaves/${id}`, leave);
        return response;
    }

    public async deleteLeave(id: number): Promise<void> {
        await apiService.delete(`/leaves/${id}`);
    }

    public async updateLeaveByUser(id: number,leave:LeaveUserUpdateDTO): Promise<void> {
        await apiService.patch(`/leaves/${id}`,leave);
    }

    public async getUserYearlyLeaveDetails(userId:number):Promise<UserYearlyLeaveSummary>{
        const response = await apiService.get<UserYearlyLeaveSummary>(`/leaves/summary/${userId}`);
        return response;
    }
}

export default LeaveService.getInstance();
