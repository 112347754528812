import React from 'react';
import { Select, DatePicker } from 'antd';
import { LeaveType, LeaveStatus } from '../../../../types/leaves.types';
import { DepartmentDto } from '../../../../types/department.types';
import { Store } from '../../../../types/shop.types';
import dayjs from 'dayjs';
import FilterDropdown from '../../../shared/components/filter/FilterDropdown';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface AttendanceFiltersProps {
  selectedDateRange: [string, string] | null;
  selectedDepartment: string;
  selectedShop: string;
  selectedGender: string;
  selectedLateStatus: boolean | null;
  departments: DepartmentDto[];
  shops: Store[];
  onDateRangeChange: (dates: [string, string] | null) => void;
  onDepartmentChange: (departmentId: string) => void;
  onShopChange: (shopId: string) => void;
  onGenderChange: (gender: string) => void;
  onLateStatusChange: (status: boolean | null) => void;
  onReset: () => void;
}

const AttendanceFilters: React.FC<AttendanceFiltersProps> = ({
  selectedDateRange,
  selectedDepartment,
  selectedShop,
  selectedGender,
  selectedLateStatus,
  departments,
  shops,
  onDateRangeChange,
  onDepartmentChange,
  onShopChange,
  onGenderChange,
  onLateStatusChange,
  onReset,
}) => {
  const { t } = useTranslation();
  const filterGroups = [
    {
      label: t('attendanceReport.dateRange'),
      type: 'dateRange' as const,
      value: selectedDateRange ? [dayjs(selectedDateRange[0]), dayjs(selectedDateRange[1])] : null,
      onChange: (dates: any) => {
        if (dates) {
          onDateRangeChange([dates[0].toISOString(), dates[1].toISOString()]);
        } else {
          onDateRangeChange(null);
        }
      }
    },
    {
      label: t('attendanceReport.department'),
      type: 'select' as const,
      value: selectedDepartment,
      onChange: onDepartmentChange,
      options: [
        { value: 'all', label: t('attendanceReport.allDepartments') },
        ...departments.map(dept => ({
          value: dept.id.toString(),
          label: dept.name
        }))
      ]
    },
    {
      label: t('attendanceReport.shop'),
      type: 'select' as const,
      value: selectedShop,
      onChange: onShopChange,
      options: [
        { value: 'all', label: t('attendanceReport.allShops') },
        ...shops.map(shop => ({
          value: shop.id.toString(),
          label: shop.name
        }))
      ]
    },
    {
      label: t('attendanceReport.gender'),
      type: 'select' as const,
      value: selectedGender,
      onChange: onGenderChange,
      options: [
        { value: 'all', label: t('attendanceReport.all') },
        { value: 'male', label: t('attendanceReport.male') },
        { value: 'female', label: t('attendanceReport.female') }
      ]
    },
    {
      label: t('attendanceReport.lateStatus'),
      type: 'select' as const,
      value: selectedLateStatus === null ? 'all' : selectedLateStatus.toString(),
      onChange: (value: string) => {
        if (value === 'all') {
          onLateStatusChange(null);
        } else {
          onLateStatusChange(value === 'true');
        }
      },
      options: [
        { value: 'all', label: t('attendanceReport.all') },
        { value: 'true', label: t('attendanceReport.lateRecords') },
        { value: 'false', label: t('attendanceReport.onTimeRecords') }
      ]
    }
  ];

  return (
    <FilterDropdown
      groups={filterGroups}
      onReset={onReset}
      buttonType="primary"
    />
  );
};

export default AttendanceFilters; 