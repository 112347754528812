import { useState, useEffect, useCallback } from "react";
import shopService from "../services/shop/shop.service";
import { Store } from "../types/shop.types";

export const useShops = () => {
    const [shops, setShops] = useState<Store[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const {getShops} = shopService;
    
    const fetchShops = useCallback(async () => {
        try {
            setLoading(true);
            const shops = await getShops();
            setShops(shops);
            setError(null);
        } catch (error) {
            setError(error as Error);
        }
    }, []); 

    useEffect(() => {
        fetchShops();
    }, [fetchShops]);

    return {
        shops,
        loading,
        error,
        fetchShops,
    }
    
    
}