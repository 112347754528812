export const LEAVE_TYPES = {
  "sick": "Hastalık İzni",
  "unpaid": "Yoklama İzni",
  "special": "Özel İzin",
  "casual": "Günlük İzin",
  "hourly": "Saatlik İzin",
  "annual": "Yıllık İzin",
  "medical": "Sağlık İzni",
  "personal": "Kişisel İzin",
  "marriage": "Evlilik İzni",
  "educational": "Eğitim İzni",
  "military": "Askeri İzin",
  "maternity": "Doğum İzni",
  "birth":"Doğum Günü İzni",
  "paternity": "Refakat İzni"
};

export const LEAVE_STATUSES = {
  "pending": "Beklemede",
  "approved": "Onaylandı",
  "rejected": "Reddedildi"
}; 