import { useState, useEffect, useCallback } from "react";
import userService from "../services/user/user.service";
import { User } from "../types/user.types";

export const useUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const {getUsers} = userService;
    
    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            const users = await getUsers();
            setUsers(users);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    }, [getUsers]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return {users, loading, error, fetchUsers};
}