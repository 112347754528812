import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataLayout } from "../../../store/slices/theme.slice";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import { HorizontalSidebarData } from "../../../core/data/json/horizontalSidebar";
import {
  setMobileSidebar,
  toggleMiniSidebar,
} from "../../../store/slices/sidebar.slice";
import { AppDispatch, RootState } from "../../../store/store";
import { logout } from "../../../store/slices/auth.slice";
import { setDataTheme } from "../../../store/slices/theme.slice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import React from "react";
import { CompanyActivityDto } from "../../../types/attendanceDto.types";
import dailyRecordService from "../../../services/dailyRecord/dailyRecord.service";
import { Button, message, Modal } from "antd";
import { useSocket } from "../../../hooks/useSocket";
import { useLeaves } from "../../../hooks/useLeaves";
import { getFileUrl } from "../../../utils/file.utils";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import ticketService from "../../../services/ticket/ticket.service";
import { handleApiError } from "../../../utils/errorHandler";
const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="me-1">
      <button
        onClick={() => changeLanguage(i18n.language === 'tr' ? 'en' : 'tr')}
        className="btn btn-menubar"
      >
        {i18n.language === 'tr' ? 'EN' : 'TR'}
      </button>
    </div>
  );
};

const ThemeToggle = () => {
  const { dataTheme } = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  const handleThemeChange = () => {
    const newTheme = dataTheme === 'light' ? 'dark' : 'light';
    dispatch(setDataTheme(newTheme));
  };

  return (
    <div className="me-1">
      <button
        onClick={handleThemeChange}
        className="btn btn-menubar"
      >
        <i className={`ti ti-${dataTheme === 'light' ? 'moon' : 'sun'}`}></i>
      </button>
    </div>
  );
};

const Header: React.FC = () => {
  const routes = all_routes;
  const {t} = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dataLayout = useSelector((state: RootState) => state.theme.dataLayout);
  //console.log(dataLayout);
  const [activities, setActivities] = useState<CompanyActivityDto[]>([]);
  const { connected, on } = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<number | null>(null);

  const Location = useLocation();

  const [subOpen, setSubopen] = useState<any>("");
  const [subsidebar, setSubsidebar] = useState("");
  const { updateLeaveStatus } = useLeaves();

  const { user,companyId } = useSelector((state: RootState) => state.auth);
  const [selectedLeave, setSelectedLeave] = useState<CompanyActivityDto | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<CompanyActivityDto | null>(null);
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);

  const showTicketModal = (activity: CompanyActivityDto) => {
    setSelectedTicket(activity);
    setIsTicketModalVisible(true);
  };



  const hideTicketModal = () => {
    setSelectedTicket(null);
    setIsTicketModalVisible(false);
  };

  const handleApproveTicket = async () => {
    if (!selectedTicket) return;
    
    try {
      setIsLoading(true);
      console.log("selectedTicket", selectedTicket);
      const response = await ticketService.approveTicket("reviewed", selectedTicket?.extraData?.ticketId as number);
      if (response) {
        message.success(t('tickets.successTickets'));
        // Aktiviteleri güncelle
        if (companyId) {
          const response = await dailyRecordService.getCompanyActivity(companyId);
          const filteredActivities = response.filter(
            (activity: CompanyActivityDto) => activity.type === "ticket_request"
          );
          const sortedActivities = filteredActivities.sort(
            (a: CompanyActivityDto, b: CompanyActivityDto) => 
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setActivities(sortedActivities);
        }
        hideModal();
      }
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleStatusChange = async (leaveId: number, dto: any) => {
    try {
      setLoadingId(leaveId)
      setIsLoading(true);
      await updateLeaveStatus(leaveId, dto);
      message.success(t('leaves.successLeave'));
      if (companyId) {
        const response = await dailyRecordService.getCompanyActivity(companyId);
        const filteredActivities = response.filter(
          (activity: CompanyActivityDto) => activity.type === "leave_request"
        );
        const sortedActivities = filteredActivities.sort(
          (a: CompanyActivityDto, b: CompanyActivityDto) => 
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setActivities(sortedActivities);
      }
      setIsModalVisible(false);
    } catch (error: any) {
      handleApiError(error,t);
    } finally {
      setIsLoading(false);
      setLoadingId(null)
    }
  };

  const getActivityIcon = (type: string) => {
    switch (type) {
      case "check_in":
        return "ti ti-login";
      case "check_out":
        return "ti ti-logout";
      case "leave_request":
        return "ti ti-calendar";
      case "ticket_request":
        return "ti ti-ticket";
      default:
        return "ti ti-clock";
    }
  };

  const getStatusIcon = (status: string | number) => {
    const statusStr = String(status);
    switch (statusStr) {
      case 'approved':
        return <CheckOutlined style={{ color: '#52c41a' }} />;
      case 'rejected':
        return <CloseOutlined style={{ color: '#ff4d4f' }} />;
      default:
        return <i className="ti ti-edit" style={{ color: '#faad14', fontSize: '22px' }} />;
    }
  };
  const showModal = (activity: CompanyActivityDto) => {
    setSelectedLeave(activity);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setSelectedLeave(null);
    setIsModalVisible(false);
  };



  const fetchCompanyActivity = async (companyId:string) => {
    try {
      if(user?.role === "admin"){
        const response = await dailyRecordService.getCompanyActivity(companyId)
        const sortedActivities = response.sort(
          (a: CompanyActivityDto, b: CompanyActivityDto) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

        setActivities(sortedActivities)
      }
    } catch (error: any) {
      handleApiError(error,t);
    }
  }
  useEffect(()=>{
    if(companyId){
      fetchCompanyActivity(companyId)
    }
  },[companyId])
  useEffect(() => {
    if (connected) {
      // Sadece newActivity olayını dinle
      const handleNewActivity = (data: CompanyActivityDto) => {
          // Aktiviteyi listeye ekle
          setActivities(prev => {
            // Aynı ID'li aktivite zaten varsa, ekleme
            if (prev.some(a => a.id === data.id)) {
              return prev;
            }
            // Yeni aktiviteyi ekle
            const newActivities = [data, ...prev];

            return newActivities;
          });
        }
      // Sadece newActivity dinleyicisini ekle
      on("newActivity", handleNewActivity);
    }
  }, [connected, on]);
  const toggleSidebar = (title: any) => {
    localStorage.setItem("menuOpened", title);
    if (title === subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem: any) => {
    if (subitem === subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };

  const mobileSidebar = useSelector(
    (state: any) => state.sidebar.mobileSidebar
  );

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };

  const handleToggleMiniSidebar = () => {
    if (dataLayout === "mini_layout") {
      dispatch(setDataLayout("default_layout"));
      localStorage.setItem("dataLayout", "default_layout");
    } else {
      dispatch(toggleMiniSidebar());
    }
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigate(routes.login2);
    } catch (error) {
      handleApiError(error,t);
    }
  };

  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((err) => {});
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen().catch((err) => {});
        }
        setIsFullscreen(false);
      }
    }
  };

  // const handleSidebarToggle = () => {
  //   if (window.innerWidth <= 991) {
  //     toggleMobileSidebar()
  //   }
  //   else {
  //     toggleSidebar
  //   }
  // };

  return (
    <div className={`header `}>
      <div className="main-header">
        <div className="header-left">
          <Link to={routes.adminDashboard} className="logo">
            <ImageWithBasePath src="assets/img/dinox-light.png" alt="Logo" />
          </Link>
          <Link to={routes.adminDashboard} className="dark-logo">
            <ImageWithBasePath src="assets/img/dinox-dark.png" alt="Logo" />
          </Link>
        </div>

        <Link
          id="mobile_btn"
          onClick={toggleMobileSidebar}
          className="mobile_btn"
          to="#sidebar"
          style={{
            padding: '0 8px'
          }}
        >
          {/* <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span> */}
          <img 
            src="assets/img/normal.png" 
            style={{ 
              transform: mobileSidebar ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease' 
            }}

          />
        </Link>

        <div className="header-user">
          <div className="nav user-menu nav-list">
            <div
              className="me-auto d-flex align-items-center"
              id="header-search"
            >
              <button
                // id="toggle_btn"
                onClick={handleToggleMiniSidebar}
                className="btn btn-menubar me-1"
              >
                <i className="ti ti-arrow-bar-to-left"></i>
              </button>
              {
                user?.role === "admin" && (
                  <Link to={routes.companySettings} className="btn btn-menubar">
                    <i className="ti ti-settings-cog"></i>
                  </Link>
                )
              }
            </div>

            <LanguageSelector />
            {/* <ThemeToggle /> */}

            <div className="d-flex align-items-center">
              <div className="me-1">
                <Link
                  to="#"
                  onClick={toggleFullscreen}
                  className="btn btn-menubar btnFullscreen"
                >
                  <i className="ti ti-maximize"></i>
                </Link>
              </div>
              {/* <div className="dropdown me-1">
                <Link
                  to="#"
                  className="btn btn-menubar"
                  data-bs-toggle="dropdown"
                >
                  <i className="ti ti-layout-grid-remove"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="card mb-0 border-0 shadow-none">
                    <div className="card-header">
                      <h4>Applications</h4>
                    </div>
                    <div className="card-body">
                      <Link to={routes.calendar} className="d-block pb-2">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-calendar text-gray-9"></i>
                        </span>
                        Calendar
                      </Link>
                      <Link to={routes.todo} className="d-block py-2">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-subtask text-gray-9"></i>
                        </span>
                        To Do
                      </Link>
                      <Link to={routes.notes} className="d-block py-2">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-notes text-gray-9"></i>
                        </span>
                        Notes
                      </Link>
                      <Link to={routes.fileManager} className="d-block py-2">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-folder text-gray-9"></i>
                        </span>
                        File Manager
                      </Link>
                      <Link to={routes.kanbanView} className="d-block py-2">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-layout-kanban text-gray-9"></i>
                        </span>
                        Kanban
                      </Link>
                      <Link to={routes.invoice} className="d-block py-2 pb-0">
                        <span className="avatar avatar-md bg-transparent-dark me-2">
                          <i className="ti ti-file-invoice text-gray-9"></i>
                        </span>
                        Invoices
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

                {
                  user?.role === "admin" && (
                    <div className="me-1 notification_item">
                      <Link
                        to="#"
                        className="btn btn-menubar position-relative me-1"
                        id="notification_popup"
                        data-bs-toggle="dropdown"
                >
                  <i className="ti ti-bell"></i>
                  {/* <span className="notification-status-dot"></span> */}
                </Link>
                <div className="dropdown-menu dropdown-menu-end notification-dropdown p-4">
                  <div className="d-flex align-items-center justify-content-between border-bottom p-0 pb-3 mb-3">
                    <h4 className="notification-title">{t('header.notifications')}</h4>
                  </div>
                  <div className="noti-content">
                    <div className="d-flex flex-column">
                    {activities.map((activities)=>(

                    <div
                      key={activities.id}
                      className={"border-bottom mb-3 pb-3"}
                    >

                        <div className="d-flex">
                          <span className="avatar-lg me-2 flex-shrink-0">
                            {/* Örnekte sabit bir resim yolu var. Gerekirse user'a göre değiştirebilirsiniz */}
                            <i className={`${getActivityIcon(activities.type)} fs-3`} />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1">
                              {activities.description}
                            </p>
                            <span>{activities.createdAt.split('T')[0]}</span>
                            {activities.type === "ticket_request" && (
                              <div 
                                className="d-flex justify-content-start align-items-center mt-1"
                                onClick={() => showTicketModal(activities)}
                                style={{cursor:'pointer'}}
                              >
                                {activities?.extraData?.status==='created'?(
                                  <i className={'ti ti-hourglass'} style={{color:'#faad14',fontSize:'22px'}}></i>
                                ):(
                                  <CheckOutlined style={{ color: '#52c41a' }} />
                                )}
                              </div>
                            )}
                            {activities.type === 'leave_request'&&(
                               <div 
                                className="d-flex justify-content-start align-items-center mt-1"
                                onClick={() => showModal(activities)}
                                style={{ cursor: 'pointer' }}
                               >
                                {loadingId === activities?.extraData?.leaveId ? (
                                  <LoadingOutlined style={{ fontSize: 16 }} />
                                ) : (
                                  getStatusIcon(activities?.extraData?.status || 'pending')
                                )}
                             </div>
                            )}
                          </div>
                        </div>

                    </div>
                    ))}


                    </div>
                  </div>
                  <div className="d-flex p-0">
                    <Link to="#" className="btn btn-light w-100 me-2">
                      {t('header.cancel')}
                    </Link>
                    <Link
                      to={routes.notificationList}
                      className="btn btn-primary w-100"
                    >
                      {t('header.viewAll')}

                      
                    </Link>
                  </div>
                </div>
              </div>
              )
              }
              <div className="dropdown profile-dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="avatar avatar-sm online">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-12.jpg"
                      alt="Img"
                      className="img-fluid rounded-circle"
                    />
                  </span>
                </Link>
                <div className="dropdown-menu shadow-none">
                  <div className="card mb-0">
                    <div className="card-header">
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-lg me-2 avatar-rounded">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-12.jpg"
                            alt="img"
                          />
                        </span>
                        <div>
                          <h5 className="mb-0">
                            {user?.firstName} {user?.lastName}
                          </h5>
                          <p className="fs-12 fw-medium mb-0">{user?.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <Link
                        className="dropdown-item d-inline-flex align-items-center p-0 py-2"
                        to={routes.profile}
                      >
                        <i className="ti ti-user-circle me-1"></i>{t('header.profile')}
                      </Link>
                      {user?.role === "admin" && (
                        <Link
                          className="dropdown-item d-inline-flex align-items-center p-0 py-2"
                          to={routes.companySettings}
                      >
                          <i className="ti ti-settings me-1"></i>{t('header.settings')}
                        </Link>
                      )}
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={handleLogout}
                        className="dropdown-item d-inline-flex align-items-center p-0 py-2"
                      >
                        <i className="ti ti-login me-2"></i>{t('header.logout')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to={routes.profile} >
              {t('header.profile')}
            </Link>
            {user?.role === "admin" && (
              <Link className="dropdown-item" to={routes.companySettings}>
                {t('header.settings')}
              </Link>
            )}
            <Link className="dropdown-item" to={routes.login} onClick={handleLogout}>
              {t('header.logout')}
            </Link>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="d-flex align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center flex-grow-1">
              <span className={`badge ${selectedLeave?.extraData?.status === 'pending' ? 'bg-warning' : selectedLeave?.extraData?.status === 'approved' ? 'bg-success' : 'bg-danger'} me-3`} style={{ fontSize: '1rem', padding: '8px 12px' }}>
                #{selectedLeave?.extraData?.leaveId}
              </span>
              <h5 className="mb-0">{selectedLeave?.description}</h5>
            </div>
          </div>
        }
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Button key="close" onClick={hideModal}>
            {t("dashboard.leaveRequests.close")}
          </Button>,
          selectedLeave?.extraData?.status === 'pending' && (
            <>
              <Button
                key="reject"
                danger
                loading={isLoading}
                onClick={() => handleStatusChange(Number(selectedLeave.extraData?.leaveId), { status: 'rejected' })}
              >
                {t("dashboard.leaveRequests.reject")}
              </Button>
              <Button
                key="approve"
                type="primary"
                loading={isLoading}
                onClick={() => handleStatusChange(Number(selectedLeave.extraData?.leaveId), { status: 'approved' })}
              >
                {t("dashboard.leaveRequests.approve")}
              </Button>
            </>
          )
        ]}
        width={700}
      >
        <div className="leave-details-modal">
          <div className="user-info mb-4 p-3 bg-light rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-wrapper me-3">
                <ImageWithBasePath
                  src={selectedLeave?.user.avatar || "assets/img/profiles/avatar-09.jpg"}
                  alt="User"
                  className="avatar avatar-md rounded-circle border border-2 shadow-sm"
                />
              </div>
              <div className="user-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">{selectedLeave?.user.firstName} {selectedLeave?.user.lastName}</h6>
                    <p className="text-muted mb-0 small">{selectedLeave?.user.email}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-calendar me-1"></i> 
                      {selectedLeave && new Date(selectedLeave.createdAt).toLocaleDateString('tr-TR', { 
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-clock me-1"></i>
                      {selectedLeave && new Date(selectedLeave.createdAt).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="leave-content">
            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-file-description me-2"></i>
                {t("dashboard.leaveRequests.description")}
              </h6>
              <div className="description p-3 border rounded bg-white">
                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                  {selectedLeave?.description || t("dashboard.leaveRequests.noDescription")}
                </p>
              </div>
            </div>

            {selectedLeave?.extraData?.documentUrl && (
              <div className="section mb-4">
                <h6 className="text-primary mb-3">
                  <i className="ti ti-file me-2"></i>
                  {t("dashboard.leaveRequests.document")}
                </h6>
                <div className="document p-3 border rounded bg-white">
                  <a 
                    href={getFileUrl(selectedLeave?.extraData?.documentUrl as string)} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-light btn-sm"
                  >
                    <i className="ti ti-file-download me-2"></i>
                    {t("dashboard.leaveRequests.viewDocument")}
                  </a>
                </div>
              </div>
            )}

            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-info-circle me-2"></i>
                {t("dashboard.leaveRequests.statusInfo")}
              </h6>
              <div className="status-info p-3 border rounded bg-white">
                <div className="d-flex align-items-center">
                  <span 
                    className={`status-indicator ${selectedLeave?.extraData?.status === 'pending' ? 'bg-warning' : selectedLeave?.extraData?.status === 'approved' ? 'bg-success' : 'bg-danger'} me-2`}
                    style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}
                  ></span>
                  <span className="status-text">
                    {selectedLeave?.extraData?.status === 'pending' 
                      ? t("dashboard.leaveRequests.pending")
                      : selectedLeave?.extraData?.status === 'approved'
                      ? t("dashboard.leaveRequests.approved")
                      : t("dashboard.leaveRequests.rejected")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <div className="d-flex align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center flex-grow-1">
              <span className="badge bg-warning me-3" style={{ fontSize: '1rem', padding: '8px 12px' }}>
                #{selectedTicket?.id}
              </span>
              <h5 className="mb-0">{selectedTicket?.description}</h5>
            </div>
          </div>
        }
        open={isTicketModalVisible}
        onCancel={hideTicketModal}
        footer={[
          <Button key="close" onClick={hideTicketModal}>
            {t("dashboard.ticketRequests.close")}
          </Button>,
          selectedTicket?.extraData?.status === 'created' && (
            <Button
              key="approve"
              type="primary"
              loading={isLoading}
              onClick={handleApproveTicket}
            >
              {t("dashboard.ticketRequests.approve")}
            </Button>
          )
        ]}
        width={700}
      >
        <div className="ticket-details-modal">
          <div className="user-info mb-4 p-3 bg-light rounded">
            <div className="d-flex align-items-center">
              <div className="avatar-wrapper me-3">
                <ImageWithBasePath
                  src={selectedTicket?.user.avatar || "assets/img/profiles/avatar-09.jpg"}
                  alt="User"
                  className="avatar avatar-md rounded-circle border border-2 shadow-sm"
                />
              </div>
              <div className="user-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">{selectedTicket?.user.firstName} {selectedTicket?.user.lastName}</h6>
                    <p className="text-muted mb-0 small">{selectedTicket?.user.email}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-calendar me-1"></i> 
                      {selectedTicket && new Date(selectedTicket.createdAt).toLocaleDateString('tr-TR', { 
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                    <p className="mb-0 text-muted small">
                      <i className="ti ti-clock me-1"></i>
                      {selectedTicket && new Date(selectedTicket.createdAt).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="ticket-content">
            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-file-description me-2"></i>
                {t("dashboard.ticketRequests.description")}
              </h6>
              <div className="description p-3 border rounded bg-white">
                <p className="mb-0" style={{ lineHeight: '1.6' }}>
                  {selectedTicket?.description || t("dashboard.ticketRequests.noDescription")}
                </p>
              </div>
            </div>

            <div className="section mb-4">
              <h6 className="text-primary mb-3">
                <i className="ti ti-info-circle me-2"></i>
                {t("dashboard.ticketRequests.statusInfo")}
              </h6>
              <div className="status-info p-3 border rounded bg-white">
                <div className="d-flex align-items-center">
                  <span 
                    className={`status-indicator ${selectedTicket?.extraData?.status === 'created' ? 'bg-warning' : 'bg-success'} me-2`}
                    style={{ width: '10px', height: '10px', borderRadius: '50%', display: 'inline-block' }}
                  ></span>
                  <span className="status-text">
                    {selectedTicket?.extraData?.status === 'created' 
                      ? t("dashboard.ticketRequests.created") 
                      : t("dashboard.ticketRequests.solved")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
