import { User } from "./user.types";

export interface LeaveDto {
    id: number;
    type: LeaveType;
    startDate: string;  // ISO formatında datetime
    endDate: string;    // ISO formatında datetime
    startTime?: string | null; // Saatlik izin için opsiyonel
    endTime?: string | null;   // Saatlik izin için opsiyonel
    reason: string;
    status: LeaveStatus;
    documentUrl?: string | null;
    approvedBy?: string | null;
    createdAt?: string;
    updatedAt?: string;
    user?: User;
}

export interface LeaveResponse {
    approved: LeaveDto[];
    rejected: LeaveDto[];
    pending: LeaveDto[];
    totalLeaveCount: number;
    approvedCount: number;
    rejectedCount: number;
    pendingCount: number;
}

export interface LeaveAdminUpdateDTO {
    status: LeaveStatus;
}

export interface LeaveCreateDTO extends Omit<LeaveDto, 'id' | 'status'> {
    employeeId: number;
}

export interface LeaveUserCreateDTO {
    type: LeaveType;
    startDate: string;
    endDate: string;
    startTime?: string;
    endTime?: string;
    reason: string;
    documentUrl?: string;
}

export interface LeaveUserUpdateDTO extends LeaveUserCreateDTO {
    id: number;
}

export interface UserYearlyLeaveSummary {
    totalAnnualLeaveLimit: number;
    usedAnnualLeaveDays: number;
    remainingAnnualLeaveDays: number;
    approvedMedicalLeaves: number;
    totalLeaveRequests: number;
}

export enum LeaveType {
    SICK = 'sick',
    ANNUAL = 'annual',
    UNPAID = 'unpaid',
    SPECIAL = 'special',
    HOURLY = 'hourly',
    MEDICAL = 'medical',
    MARRIAGE = 'marriage',
    BIRTH = 'birth',
    DEATH = 'death'
}

export enum LeaveStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected'
}

export interface LeaveStats {
    total: number;
    approved: number;
    pending: number;
    rejected: number;
}