import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import reportService from '../../../services/reports/report.service';
import { ShiftReportDto } from '../../../types/report.types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table, Select, Input, Space, Tag, Button, Progress, Card, Tooltip } from 'antd';
import { SearchOutlined, FilterOutlined, CheckCircleOutlined, CloseCircleOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleApiError } from '../../../utils/errorHandler';
import { createPdf, exportToExcel } from '../../../utils/exportUtils';
import dayjs from 'dayjs';
import FooterSection from '../../shared/components/footerSection';
const { Option } = Select;

const ShiftReport = () => {
    const [reportData, setReportData] = useState<ShiftReportDto[]>([]);
    const [filteredData, setFilteredData] = useState<ShiftReportDto[]>([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [departments, setDepartments] = useState<string[]>([]);
    const [branches, setBranches] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string | undefined>(undefined);
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await reportService.getShiftReport(
                    startDate.toISOString().split('T')[0],
                    endDate.toISOString().split('T')[0]
                );
                const reportList = Array.isArray(data) ? data : [data];
                setReportData(reportList);
                setFilteredData(reportList);
                
                // Departman ve şube listelerini oluştur
                const uniqueDepartments = Array.from(new Set(reportList.map(item => item.department)));
                const uniqueBranches = Array.from(new Set(reportList.map(item => item.branch)));
                setDepartments(uniqueDepartments);
                setBranches(uniqueBranches);
            } catch (error: any) {
                handleApiError(error,t);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate]);

    // Filtre değiştiğinde veriyi güncelle
    useEffect(() => {
        filterData();
    }, [selectedDepartment, selectedBranch, reportData]);

    // Filtreleme fonksiyonu
    const filterData = () => {
        let filtered = [...reportData];
        
        if (selectedDepartment) {
            filtered = filtered.filter(item => item.department === selectedDepartment);
        }
        
        if (selectedBranch) {
            filtered = filtered.filter(item => item.branch === selectedBranch);
        }
        
        setFilteredData(filtered);
    };

    // Filtre sıfırlama
    const resetFilters = () => {
        setSelectedDepartment(undefined);
        setSelectedBranch(undefined);
    };

    // Özet istatistikleri hesapla
    const calculateStats = () => {
        if (!filteredData.length) return { 
            totalLate: 0, 
            totalEarlyLeave: 0, 
            totalAbsent: 0, 
            worstPerformer: '',
            bestPerformer: '',
            totalEmployees: 0,
            compliantEmployees: 0,
            complianceRate: 0,
            totalViolations: 0,
            departmentStats: {} as Record<string, { total: number, late: number, early: number, absent: number, employees: number }>,
            branchStats: {} as Record<string, { total: number, late: number, early: number, absent: number, employees: number }>,
            topPerformers: [] as Array<{name: string, department: string, totalViolations: number}>
        };

        const stats = filteredData.reduce((acc, curr) => ({
            totalLate: acc.totalLate + curr.lateDays,
            totalEarlyLeave: acc.totalEarlyLeave + curr.earlyLeaveDays,
            totalAbsent: acc.totalAbsent + curr.absentDays,
        }), { totalLate: 0, totalEarlyLeave: 0, totalAbsent: 0 });

        // Toplam vardiya ihlali sayısı
        const totalViolations = stats.totalLate + stats.totalEarlyLeave + stats.totalAbsent;

        // En kötü performans gösteren çalışan
        const worstPerformer = filteredData.reduce((a, b) => 
            (a.lateDays + a.earlyLeaveDays + a.absentDays > b.lateDays + b.earlyLeaveDays + b.absentDays) ? a : b
        );

        // Performans sıralamasına göre en başarılı çalışanlar (en az ihlali olanlar)
        const sortedByPerformance = [...filteredData].sort((a, b) => 
            (a.lateDays + a.earlyLeaveDays + a.absentDays) - (b.lateDays + b.earlyLeaveDays + b.absentDays)
        );
        
        const bestPerformer = sortedByPerformance[0];
        
        // En iyi performansa sahip çalışanlar listesi (en fazla ilk 10)
        const topPerformers = sortedByPerformance
            .slice(0, 10)
            .map(emp => ({
                name: emp.name,
                department: emp.department,
                totalViolations: emp.lateDays + emp.earlyLeaveDays + emp.absentDays
            }));

        // Hiç ihlali olmayan çalışanlar
        const compliantEmployees = filteredData.filter(emp => 
            emp.lateDays === 0 && emp.earlyLeaveDays === 0 && emp.absentDays === 0
        );
        
        // Toplam çalışan sayısı
        const totalEmployees = filteredData.length;

        // Uyumlu çalışan sayısı ve oranı
        const complianceRate = totalEmployees > 0 
            ? (compliantEmployees.length / totalEmployees) * 100 
            : 0;

        // Departman bazlı istatistikler
        const departmentStats: Record<string, { total: number, late: number, early: number, absent: number, employees: number }> = {};
        filteredData.forEach(emp => {
            if (!departmentStats[emp.department]) {
                departmentStats[emp.department] = {
                    total: 0,
                    late: 0,
                    early: 0,
                    absent: 0,
                    employees: 0,
                };
            }
            
            departmentStats[emp.department].total += (emp.lateDays + emp.earlyLeaveDays + emp.absentDays);
            departmentStats[emp.department].late += emp.lateDays;
            departmentStats[emp.department].early += emp.earlyLeaveDays;
            departmentStats[emp.department].absent += emp.absentDays;
            departmentStats[emp.department].employees += 1;
        });

        // Şube bazlı istatistikler
        const branchStats: Record<string, { total: number, late: number, early: number, absent: number, employees: number }> = {};
        filteredData.forEach(emp => {
            if (!branchStats[emp.branch]) {
                branchStats[emp.branch] = {
                    total: 0,
                    late: 0,
                    early: 0,
                    absent: 0,
                    employees: 0,
                };
            }
            
            branchStats[emp.branch].total += (emp.lateDays + emp.earlyLeaveDays + emp.absentDays);
            branchStats[emp.branch].late += emp.lateDays;
            branchStats[emp.branch].early += emp.earlyLeaveDays;
            branchStats[emp.branch].absent += emp.absentDays;
            branchStats[emp.branch].employees += 1;
        });

        return { 
            ...stats, 
            worstPerformer: worstPerformer.name,
            bestPerformer: bestPerformer.name,
            totalEmployees,
            compliantEmployees: compliantEmployees.length,
            complianceRate,
            totalViolations,
            departmentStats,
            branchStats,
            topPerformers
        };
    };

    const stats = calculateStats();

    // İhlal dağılımını hesapla
    const violationDistribution = useMemo(() => {
        const total = stats.totalLate + stats.totalEarlyLeave + stats.totalAbsent;
        if (total === 0) return { late: 0, early: 0, absent: 0 };
        
        return {
            late: parseFloat(((stats.totalLate / total) * 100).toFixed(1)),
            early: parseFloat(((stats.totalEarlyLeave / total) * 100).toFixed(1)),
            absent: parseFloat(((stats.totalAbsent / total) * 100).toFixed(1))
        };
    }, [stats]);


    // Departman ve şube verileri için hesaplamalar
    const departmentAnalysis = useMemo(() => {
        // Her departmana ait ihlal sayıları ve ilgili personelleri hazırla
        const departmentData = Object.keys(stats.departmentStats).map(dept => {
            // Bu departmandaki personelleri bul
            const deptEmployees = filteredData.filter(emp => emp.department === dept);
            
            // Departman için toplam ihlaller
            const totalViolations = stats.departmentStats[dept].total;
            const lateCount = stats.departmentStats[dept].late;
            const earlyCount = stats.departmentStats[dept].early;
            const absentCount = stats.departmentStats[dept].absent;
            const employeeCount = stats.departmentStats[dept].employees;
            
            // Bu departmandaki personel listesi (ihlal sayılarına göre sıralı)
            const employeesList = deptEmployees
                .map(emp => ({
                    name: emp.name,
                    totalViolations: emp.lateDays + emp.earlyLeaveDays + emp.absentDays,
                    lateDays: emp.lateDays,
                    earlyLeaveDays: emp.earlyLeaveDays,
                    absentDays: emp.absentDays
                }))
                .sort((a, b) => b.totalViolations - a.totalViolations);
            
            return {
                name: dept,
                totalViolations,
                lateCount,
                earlyCount,
                absentCount,
                employeeCount,
                employeesList
            };
        });
        
        // En çok ihlali olan departmanları sırala
        return departmentData.sort((a, b) => b.totalViolations - a.totalViolations);
    }, [filteredData, stats]);
    
    const branchAnalysis = useMemo(() => {
        // Her şubeye ait ihlal sayıları ve ilgili personelleri hazırla
        const branchData = Object.keys(stats.branchStats).map(branch => {
            // Bu şubedeki personelleri bul
            const branchEmployees = filteredData.filter(emp => emp.branch === branch);
            
            // Şube için toplam ihlaller
            const totalViolations = stats.branchStats[branch].total;
            const lateCount = stats.branchStats[branch].late;
            const earlyCount = stats.branchStats[branch].early;
            const absentCount = stats.branchStats[branch].absent;
            const employeeCount = stats.branchStats[branch].employees;
            
            // Bu şubedeki personel listesi (ihlal sayılarına göre sıralı)
            const employeesList = branchEmployees
                .map(emp => ({
                    name: emp.name,
                    totalViolations: emp.lateDays + emp.earlyLeaveDays + emp.absentDays,
                    lateDays: emp.lateDays,
                    earlyLeaveDays: emp.earlyLeaveDays,
                    absentDays: emp.absentDays
                }))
                .sort((a, b) => b.totalViolations - a.totalViolations);
            
            return {
                name: branch,
                totalViolations,
                lateCount,
                earlyCount,
                absentCount,
                employeeCount,
                employeesList
            };
        });
        
        // En çok ihlali olan şubeleri sırala
        return branchData.sort((a, b) => b.totalViolations - a.totalViolations);
    }, [filteredData, stats]);

    // Zaman bazlı ihlal trendi için veri hazırlama
    const timeSeriesData = useMemo(() => {
        // Departman ve şube bazlı en yüksek ihlale sahip 5 birim
        const topDepartments = departmentAnalysis.slice(0, 5);
        const topBranches = branchAnalysis.slice(0, 5);

        // Trendleri oluşturma
        const getDepartmentSeries = () => {
            return topDepartments.map(dept => ({
                name: `${dept.name} (${t('shiftReport.department')})`,
                data: dept.totalViolations > 0 ? [
                    dept.totalViolations * 0.25, // Örnek trend verileri
                    dept.totalViolations * 0.4,
                    dept.totalViolations * 0.3,
                    dept.totalViolations * 0.55,
                    dept.totalViolations * 0.7,
                    dept.totalViolations * 0.75,
                    dept.totalViolations
                ] : [0, 0, 0, 0, 0, 0, 0],
                type: 'line'
            }));
        };

        const getBranchSeries = () => {
            return topBranches.map(branch => ({
                name: `${branch.name} (${t('shiftReport.branch')})`,
                data: branch.totalViolations > 0 ? [
                    branch.totalViolations * 0.2, // Örnek trend verileri
                    branch.totalViolations * 0.35,
                    branch.totalViolations * 0.4,
                    branch.totalViolations * 0.45,
                    branch.totalViolations * 0.6,
                    branch.totalViolations * 0.8,
                    branch.totalViolations
                ] : [0, 0, 0, 0, 0, 0, 0],
                type: 'line'
            }));
        };

        // Örnek tarih aralığı (son 7 gün)
        const dateLabels = [];
        const endDate = new Date();
        for (let i = 6; i >= 0; i--) {
            const date = new Date();
            date.setDate(endDate.getDate() - i);
            dateLabels.push(date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'short' }));
        }

        return {
            departmentSeries: getDepartmentSeries(),
            branchSeries: getBranchSeries(),
            allSeries: [...getDepartmentSeries(), ...getBranchSeries()],
            dateLabels
        };
    }, [departmentAnalysis, branchAnalysis]);

    // Trend line chart için ayarlar
    // Tablo için sütun tanımları
    const columns: ColumnsType<ShiftReportDto> = [
        {
            title: t('shiftReport.column.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('shiftReport.column.department'),
            dataIndex: 'department',
            key: 'department',
            filters: departments.map(dept => ({ text: dept, value: dept })),
            onFilter: (value, record) => record.department === value,
        },
        {
            title: t('shiftReport.column.branch'),
            dataIndex: 'branch',
            key: 'branch',
            filters: branches.map(branch => ({ text: branch, value: branch })),
            onFilter: (value, record) => record.branch === value,
        },
        {
            title: t('shiftReport.column.lateArrival'),
            dataIndex: 'lateDays',
            key: 'lateDays',
            sorter: (a, b) => a.lateDays - b.lateDays,
            render: (lateDays) => (
                <Tag color="warning" style={{ fontSize: '14px', padding: '2px 10px' }}>
                    {lateDays} gün
                </Tag>
            )
        },
        {
            title: t('shiftReport.column.earlyLeaving'),
            dataIndex: 'earlyLeaveDays',
            key: 'earlyLeaveDays',
            sorter: (a, b) => a.earlyLeaveDays - b.earlyLeaveDays,
            render: (earlyLeaveDays) => (
                <Tag color="error" style={{ fontSize: '14px', padding: '2px 10px' }}>
                    {earlyLeaveDays} gün
                </Tag>
            )
        },
        {
            title: t('shiftReport.column.absence'),
            dataIndex: 'absentDays',
            key: 'absentDays',
            sorter: (a, b) => a.absentDays - b.absentDays,
            render: (absentDays) => (
                <Tag color="processing" style={{ fontSize: '14px', padding: '2px 10px' }}>
                    {absentDays} gün
                </Tag>
            )
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{t('shiftReport.pageTitle')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">{t('shiftReport.breadcrumb.reports')}</li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('shiftReport.breadcrumb.shiftReport')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center">
                        <div className="me-2">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => date && setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => date && setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                        <div className="dropdown me-2">
                                <Link
                                    to="#"
                                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                >
                                    <i className="ti ti-file-export me-1" />
                                    {t('common.buttons.export')}
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-end p-3">
                                    <li>
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                        onClick={() => {
                                            // Vardiya verileri için sütun tanımları
                                            type ShiftDataType = {
                                                name: string;
                                                department: string;
                                                branch: string;
                                                lateDays: string;
                                                earlyLeaveDays: string;
                                                absentDays: string;
                                            };

                                            // PDF için sütun tanımları
                                            const columnsForPdf = [
                                                { header: 'Ad Soyad', property: 'name' as keyof ShiftDataType, width: 130 },
                                                { header: 'Departman', property: 'department' as keyof ShiftDataType, width: 130 },
                                                { header: 'Sube', property: 'branch' as keyof ShiftDataType, width: 130 },
                                                { header: 'Gec Gelme', property: 'lateDays' as keyof ShiftDataType, width: 70 },
                                                { header: 'Erken Cikis', property: 'earlyLeaveDays' as keyof ShiftDataType, width: 70 },
                                                { header: 'Devamsizlik', property: 'absentDays' as keyof ShiftDataType, width: 70 }
                                            ];

                                            // PDF için formatlanmış veri
                                            const formattedData = filteredData.map(employee => {
                                                return {
                                                    name: employee.name,
                                                    department: employee.department,
                                                    branch: employee.branch,
                                                    lateDays: `${employee.lateDays} gün`,
                                                    earlyLeaveDays: `${employee.earlyLeaveDays} gün`,
                                                    absentDays: `${employee.absentDays} gün`
                                                };
                                            });

                                            // Filtre açıklaması
                                            let description = 'Vardiya Raporu';
                                            
                                            if (startDate && endDate) {
                                                const startDateStr = startDate.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'});
                                                const endDateStr = endDate.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'});
                                                description += ` - Tarih: ${startDateStr} - ${endDateStr}`;
                                            }
                                            
                                            if (selectedDepartment) {
                                                description += ` - Departman: ${selectedDepartment}`;
                                            }
                                            
                                            if (selectedBranch) {
                                                description += ` - Sube: ${selectedBranch}`;
                                            }

                                            // İstatistik bilgileri
                                            const statistics = [
                                                { 
                                                    label: 'Toplam Personel', 
                                                    value: stats.totalEmployees.toString() 
                                                },
                                                { 
                                                    label: 'Ihlalsiz Personel', 
                                                    value: stats.compliantEmployees.toString() 
                                                },
                                                { 
                                                    label: 'Toplam Gec Gelme', 
                                                    value: stats.totalLate.toString() 
                                                },
                                                { 
                                                    label: 'Toplam Erken Cikis', 
                                                    value: stats.totalEarlyLeave.toString() 
                                                },
                                                { 
                                                    label: 'Toplam Devamsizlik', 
                                                    value: stats.totalAbsent.toString() 
                                                }
                                            ];

                                            // createPdf fonksiyonunu çağır
                                            createPdf(formattedData, {
                                                title: 'Vardiya Raporu',
                                                description: description,
                                                headerColor: '#2c3e50', // Koyu lacivert
                                                accentColor: '#3498db', // Mavi
                                                columns: columnsForPdf,
                                                showStatistics: true, // İstatistik kutularını göster
                                                statistics: statistics,
                                                footer: {
                                                    text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
                                                    showPageNumbers: true
                                                }
                                            });
                                        }}
                                    >
                                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                                        {t('common.buttons.pdf')}
                                    </Link>
                                    </li>
                                    <li className="mt-1">
                                    <Link
                                        to="#"
                                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                                        onClick={() => {
                                            // Excel için sütun tanımları
                                            type ShiftExcelType = {
                                                name: string;
                                                department: string;
                                                branch: string;
                                                lateDays: number;
                                                earlyLeaveDays: number;
                                                absentDays: number;
                                                totalViolations: number;
                                                complianceRate: string;
                                            };

                                            const columnsForExcel = [
                                                { header: 'Ad Soyad', property: 'name' as keyof ShiftExcelType, width: 30 },
                                                { header: 'Departman', property: 'department' as keyof ShiftExcelType, width: 25 },
                                                { header: 'Şube', property: 'branch' as keyof ShiftExcelType, width: 25 },
                                                { header: 'Geç Gelme (Gün)', property: 'lateDays' as keyof ShiftExcelType, width: 20 },
                                                { header: 'Erken Çıkış (Gün)', property: 'earlyLeaveDays' as keyof ShiftExcelType, width: 20 },
                                                { header: 'Devamsızlık (Gün)', property: 'absentDays' as keyof ShiftExcelType, width: 20 },
                                                { header: 'Toplam İhlal', property: 'totalViolations' as keyof ShiftExcelType, width: 15 },
                                                { header: 'Uyum Oranı', property: 'complianceRate' as keyof ShiftExcelType, width: 15 }
                                            ];

                                            // Excel için formatlanmış veri
                                            const formattedData = filteredData.map(employee => {
                                                const totalViolations = employee.lateDays + employee.earlyLeaveDays + employee.absentDays;
                                                // Varsayalım ki bir ayda 22 işgünü var
                                                const workingDays = 22;
                                                const complianceRate = ((workingDays - totalViolations) / workingDays * 100).toFixed(2) + '%';

                                                return {
                                                    name: employee.name,
                                                    department: employee.department,
                                                    branch: employee.branch,
                                                    lateDays: employee.lateDays,
                                                    earlyLeaveDays: employee.earlyLeaveDays,
                                                    absentDays: employee.absentDays,
                                                    totalViolations: totalViolations,
                                                    complianceRate: complianceRate
                                                };
                                            });

                                            // Excel dosyasını oluştur
                                            exportToExcel(formattedData, 'Vardiya_Raporu', columnsForExcel);
                                        }}
                                    >
                                        <i className="ti ti-file-type-xls me-2 text-success" />
                                        {t('common.buttons.excel')}
                                    </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sayfa Geneli Filtreler */}
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="d-flex flex-wrap justify-content-between align-items-end">
                            <div className="d-flex flex-wrap gap-4 mb-3 mb-md-0">
                                <div>
                                    <label className="form-label mb-2 fs-13 fw-semibold text-dark">
                                        <i className="ti ti-building-skyscraper me-1 text-primary"></i>
                                        {t('shiftReport.departmentFilter')}
                                    </label>
                                    <Select 
                                        placeholder={t('shiftReport.allDepartments')} 
                                        allowClear 
                                        style={{width: 220, boxShadow: '0 2px 6px rgba(0,0,0,0.05)'}}
                                        onChange={(value) => setSelectedDepartment(value)}
                                        value={selectedDepartment}
                                        suffixIcon={<i className="ti ti-chevron-down text-primary"></i>}
                                        className="filter-select m-2"
                                        dropdownStyle={{borderRadius: '8px', boxShadow: '0 6px 16px rgba(0,0,0,0.1)'}}
                                    >
                                        {departments.map(dept => (
                                            <Option key={dept} value={dept}>
                                                <div className="d-flex align-items-center">
                                                    <span className="me-2 rounded-circle d-inline-block" style={{width: "8px", height: "8px", backgroundColor: "#1890ff"}}></span>
                                                    {dept}
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <label className="form-label mb-2 fs-13 fw-semibold text-dark">
                                        <i className="ti ti-map-pin me-1 text-danger"></i>
                                        {t('shiftReport.branchFilter')}
                                    </label>
                                    <Select 
                                        placeholder={t('shiftReport.allBranches')} 
                                        allowClear 
                                        style={{width: 220, boxShadow: '0 2px 6px rgba(0,0,0,0.05)'}}
                                        onChange={(value) => setSelectedBranch(value)}
                                        value={selectedBranch}
                                        suffixIcon={<i className="ti ti-chevron-down text-danger"></i>}
                                        className="filter-select m-2"
                                        dropdownStyle={{borderRadius: '8px', boxShadow: '0 6px 16px rgba(0,0,0,0.1)'}}
                                    >
                                        {branches.map(branch => (
                                            <Option key={branch} value={branch}>
                                                <div className="d-flex align-items-center">
                                                    <span className="me-2 rounded-circle d-inline-block" style={{width: "8px", height: "8px", backgroundColor: "#ff4d4f"}}></span>
                                                    {branch}
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <Button 
                                    type="default"
                                    icon={<FilterOutlined />}
                                    onClick={resetFilters}
                                    className="d-flex align-items-center"
                                    style={{
                                        borderRadius: '8px', 
                                        boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                                        background: 'white',
                                        border: '1px solid #e9ecef'
                                    }}
                                >
                                    <span className="ms-1">{t('shiftReport.resetFilters')}</span>
                                </Button>
                            </div>
                        </div>
                        
                        {(selectedDepartment || selectedBranch) && (
                            <div className="mt-3 pt-2 border-top">
                                <div className="d-flex align-items-center flex-wrap">
                                    <small className="text-muted me-2">{t('shiftReport.activeFilters')}:</small>
                                    <div className="d-flex gap-2 flex-wrap">
                                        {selectedDepartment && (
                                            <Tag 
                                                color="blue" 
                                                closable 
                                                onClose={() => setSelectedDepartment(undefined)}
                                                style={{borderRadius: '20px', padding: '2px 10px'}}
                                            >
                                                <i className="ti ti-building-skyscraper me-1"></i>
                                                {selectedDepartment}
                                            </Tag>
                                        )}
                                        {selectedBranch && (
                                            <Tag 
                                                color="red" 
                                                closable 
                                                onClose={() => setSelectedBranch(undefined)}
                                                style={{borderRadius: '20px', padding: '2px 10px'}}
                                            >
                                                <i className="ti ti-map-pin me-1"></i>
                                                {selectedBranch}
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('shiftReport.loading')}</span>
                        </div>
                    </div>
                ) : filteredData.length > 0 ? (
                    <>
                        <div className="row">
                            {/* Ana Özet Kartları - Geliştirilmiş */}
                            <div className="col-xl-3 col-md-6 d-flex">
                                <div className="card flex-fill hover-border-primary" style={{boxShadow: '0 4px 10px rgba(0,0,0,0.07)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <div>
                                                <h6 className="card-title mb-0 fw-semibold">{t('shiftReport.cards.shiftComplianceRate')}</h6>
                                                <small className="text-muted">{t('shiftReport.cards.complianceRate')}</small>
                                            </div>
                                            <div className="avatar avatar-md bg-success-subtle" style={{borderRadius: '12px'}}>
                                                <CheckCircleOutlined className="text-success fs-18" />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <h1 className="mb-2 fw-bold" style={{fontSize: '2.5rem', background: 'linear-gradient(45deg, #07A35A, #00D68F)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>
                                                %{stats.complianceRate.toFixed(1)}
                                            </h1>
                                            <div className="d-flex align-items-center">
                                                <Progress 
                                                    percent={stats.complianceRate} 
                                                    showInfo={false} 
                                                    status={stats.complianceRate > 70 ? "success" : stats.complianceRate > 40 ? "normal" : "exception"}
                                                    className="mb-0 flex-fill"
                                                    strokeWidth={8}
                                                    strokeColor={{
                                                        '0%': '#10B981',
                                                        '100%': '#34D399',
                                                    }}
                                                />
                                            </div>
                                            <div className="d-flex mt-2 justify-content-between">
                                                <small className="text-muted fw-semibold">{stats.compliantEmployees} / {stats.totalEmployees} {t('shiftReport.cards.employees')}</small>
                                                <small className={stats.complianceRate > 70 ? "text-success fw-bold" : stats.complianceRate > 40 ? "text-warning fw-bold" : "text-danger fw-bold"}>
                                                    {stats.complianceRate > 70 ? t('shiftReport.cards.good') : stats.complianceRate > 40 ? t('shiftReport.cards.medium') : t('shiftReport.cards.low')}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex">
                                <div className="card flex-fill hover-border-success" style={{boxShadow: '0 4px 10px rgba(0,0,0,0.07)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <div>
                                                <h6 className="card-title mb-0 fw-semibold">{t('shiftReport.cards.highestPerformance')}</h6>
                                                <small className="text-muted">{t('shiftReport.cards.lowestPerformance')}</small>
                                            </div>
                                            <div className="avatar avatar-md bg-success-subtle" style={{borderRadius: '12px'}}>
                                                <CheckCircleOutlined className="text-success fs-18" />
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            {stats.topPerformers.length > 0 ? (
                                                <>
                                                    <div className="text-center py-2">
                                                        <div className="avatar avatar-lg mb-3 mx-auto" style={{backgroundColor: '#DCFCE7', border: '2px solid #86EFAC', borderRadius: '15px'}}>
                                                            <span className="avatar-text fw-bold text-success fs-4">{stats.bestPerformer.charAt(0)}</span>
                                                        </div>
                                                        <h4 className="fw-bold mb-1" style={{color: '#16A34A'}}>{stats.bestPerformer}</h4>
                                                        <div className="d-flex justify-content-center align-items-center mb-2">
                                                            <div className="badge bg-light text-dark p-2">
                                                                {(() => {
                                                                    const employee = filteredData.find(emp => emp.name === stats.bestPerformer);
                                                                    return employee ? 
                                                                        (employee.lateDays + employee.earlyLeaveDays + employee.absentDays) : 
                                                                        0;
                                                                })()} {t('shiftReport.cards.totalViolations')}
                                                            </div>
                                                        </div>
                                                        
                                                        {stats.topPerformers.length > 1 && (
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Popover id="popover-top-performers">
                                                                            <Popover.Header as="h3">{t('shiftReport.cards.topPerformers')}</Popover.Header>
                                                                            <Popover.Body>
                                                                                <ul className="ps-3 mb-0">
                                                                                    {stats.topPerformers
                                                                                        .filter((_, idx) => idx > 0) // İlk eleman hariç
                                                                                        .map((emp, idx) => (
                                                                                            <li key={idx} className="mb-1">
                                                                                                {emp.name} - {emp.department} ({emp.totalViolations} {t('shiftReport.cards.violations')})
                                                                                            </li>
                                                                                        ))
                                                                                }
                                                                                </ul>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <div className="badge bg-success px-3 py-2 rounded-pill" style={{fontSize: '0.9rem', cursor: 'pointer'}}>
                                                                        <span className="me-1">+ {stats.topPerformers.length - 1} {t('shiftReport.cards.topPerformers')}</span>
                                                                        <i className="ti ti-info-circle"></i>
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="text-center py-4">
                                                    <div className="avatar avatar-lg mb-3 mx-auto" style={{backgroundColor: '#FEF2F2', border: '2px solid #FECACA', borderRadius: '15px'}}>
                                                        <CloseCircleOutlined className="text-danger fs-18" />
                                                    </div>
                                                    <h6 className="text-danger mb-0">{t('shiftReport.cards.noDataFound')}</h6>
                                                    <p className="text-muted mt-2 mb-0">{t('shiftReport.cards.noDataFound')}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex">
                                <div className="card flex-fill hover-border-danger" style={{boxShadow: '0 4px 10px rgba(0,0,0,0.07)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <div>
                                                <h6 className="card-title mb-0 fw-semibold">{t('shiftReport.cards.lowestPerformance')}</h6>
                                                <small className="text-muted">{t('shiftReport.cards.highestPerformance')}</small>
                                            </div>
                                            <div className="avatar avatar-md bg-danger-subtle" style={{borderRadius: '12px'}}>
                                                <CloseCircleOutlined className="text-danger fs-18" />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="text-center py-2">
                                                <div className="avatar avatar-lg mb-3 mx-auto" style={{backgroundColor: '#FEE2E2', border: '2px solid #FCA5A5', borderRadius: '15px'}}>
                                                    <span className="avatar-text fw-bold text-danger fs-4">{stats.worstPerformer.charAt(0)}</span>
                                                </div>
                                                <h4 className="fw-bold mb-1" style={{color: '#B91C1C'}}>{stats.worstPerformer}</h4>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Tag color="error" className="mx-1">{
                                                        filteredData.find(emp => emp.name === stats.worstPerformer)?.lateDays || 0
                                                    } {t('shiftReport.cards.late')}</Tag>
                                                    <Tag color="warning" className="mx-1">{
                                                        filteredData.find(emp => emp.name === stats.worstPerformer)?.earlyLeaveDays || 0
                                                    } {t('shiftReport.cards.early')}</Tag>
                                                    <Tag color="processing" className="mx-1">{
                                                        filteredData.find(emp => emp.name === stats.worstPerformer)?.absentDays || 0
                                                    } {t('shiftReport.cards.absence')}</Tag>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 d-flex">
                                <div className="card flex-fill hover-border-info" style={{boxShadow: '0 4px 10px rgba(0,0,0,0.07)', borderRadius: '10px', transition: 'all 0.3s ease'}}>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-start mb-3">
                                            <div>
                                                <h6 className="card-title mb-0 fw-semibold">{t('shiftReport.cards.violationDistribution')}</h6>
                                                <small className="text-muted">{t('shiftReport.cards.violationDistribution')}</small>
                                            </div>
                                            <div className="avatar avatar-md bg-info-subtle" style={{borderRadius: '12px'}}>
                                                <InfoCircleOutlined className="text-info fs-18" />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="mb-3">
                                                <div className="d-flex justify-content-between mb-1">
                                                    <small className="fw-semibold">{t('shiftReport.cards.late')}</small>
                                                    <small className="fw-bold text-warning">{violationDistribution.late}%</small>
                                                </div>
                                                <Progress 
                                                    percent={violationDistribution.late} 
                                                    showInfo={false} 
                                                    strokeColor={{
                                                        '0%': '#ffcc00',
                                                        '100%': '#faad14',
                                                    }}
                                                    className="mb-0"
                                                    strokeWidth={8}
                                                    trailColor="#fdf6db"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <div className="d-flex justify-content-between mb-1">
                                                    <small className="fw-semibold">{t('shiftReport.cards.early')}</small>
                                                    <small className="fw-bold text-danger">{violationDistribution.early}%</small>
                                                </div>
                                                <Progress 
                                                    percent={violationDistribution.early} 
                                                    showInfo={false} 
                                                    strokeColor={{
                                                        '0%': '#ff7875',
                                                        '100%': '#ff4d4f',
                                                    }}
                                                    className="mb-0"
                                                    strokeWidth={8}
                                                    trailColor="#fff1f0"
                                                />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between mb-1">
                                                    <small className="fw-semibold">{t('shiftReport.cards.absence')}</small>
                                                    <small className="fw-bold text-primary">{violationDistribution.absent}%</small>
                                                </div>
                                                <Progress 
                                                    percent={violationDistribution.absent} 
                                                    showInfo={false} 
                                                    strokeColor={{
                                                        '0%': '#69c0ff',
                                                        '100%': '#1890ff',
                                                    }}
                                                    className="mb-0"
                                                    strokeWidth={8}
                                                    trailColor="#e6f7ff"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* İhlal Trendi Line Chart */}
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="card-title mb-0">{t('shiftReport.analysis.personelViolationAnalysis')}</h5>
                                            <small className="text-muted">{t('shiftReport.analysis.personelViolationAnalysis')}</small>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mb-4">
                                            <div className="col-md-12">
                                                <div style={{height: "500px"}}>
                                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                                        <h6 className="fw-semibold m-0">{t('shiftReport.analysis.violationTypesDistribution')}</h6>
                                                        <div className="d-flex align-items-center gap-3">
                                                            <div className="d-flex align-items-center">
                                                                <span className="d-inline-block me-1 rounded" style={{width: "10px", height: "10px", backgroundColor: "#FF9B42"}}></span>
                                                                <span className="fs-13">{t('shiftReport.analysis.lateArrival')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <span className="d-inline-block me-1 rounded" style={{width: "10px", height: "10px", backgroundColor: "#E15759"}}></span>
                                                                <span className="fs-13">{t('shiftReport.analysis.earlyLeaving')}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <span className="d-inline-block me-1 rounded" style={{width: "10px", height: "10px", backgroundColor: "#4E79A7"}}></span>
                                                                <span className="fs-13">{t('shiftReport.analysis.absence')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <ReactApexChart 
                                                        options={{
                                                            chart: {
                                                                type: 'bar',
                                                                stacked: true,
                                                                toolbar: {
                                                                    show: true,
                                                                    tools: {
                                                                        download: true,
                                                                        selection: false,
                                                                        zoom: false,
                                                                        zoomin: false,
                                                                        zoomout: false,
                                                                        pan: false,
                                                                        reset: false
                                                                    }
                                                                },
                                                                animations: {
                                                                    enabled: true,
                                                                    speed: 800,
                                                                    dynamicAnimation: {
                                                                        enabled: true,
                                                                        speed: 350
                                                                    }
                                                                },
                                                                fontFamily: 'inherit',
                                                                background: 'transparent'
                                                            },
                                                            plotOptions: {
                                                                bar: {
                                                                    horizontal: true,
                                                                    barHeight: '80%',
                                                                    borderRadius: 6,
                                                                    distributed: false,
                                                                    columnWidth: '70%',
                                                                    dataLabels: {
                                                                        position: 'center'
                                                                    }
                                                                }
                                                            },
                                                            colors: ['#FF9B42', '#E15759', '#4E79A7'],
                                                            dataLabels: {
                                                                enabled: true,
                                                                formatter: function(val: number): string {
                                                                    if (val <= 0) return '';
                                                                    return `${val}`;
                                                                },
                                                                style: {
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    colors: ['#fff']
                                                                },
                                                                dropShadow: {
                                                                    enabled: true,
                                                                    color: '#000',
                                                                    top: 0,
                                                                    left: 0,
                                                                    blur: 3,
                                                                    opacity: 0.2
                                                                }
                                                            },
                                                            stroke: {
                                                                width: 2,
                                                                colors: ['#fff']
                                                            },
                                                            xaxis: {
                                                                categories: filteredData
                                                                    .sort((a, b) => 
                                                                        (b.lateDays + b.earlyLeaveDays + b.absentDays) - 
                                                                        (a.lateDays + a.earlyLeaveDays + a.absentDays)
                                                                    )
                                                                    .slice(0, 10)
                                                                    .map(emp => emp.name),
                                                                labels: {
                                                                    formatter: function(val: string): string {
                                                                        return val.length > 15 ? val.substring(0, 15) + '...' : val;
                                                                    },
                                                                    style: {
                                                                        fontSize: '12px',
                                                                        fontWeight: 500
                                                                    }
                                                                },
                                                                axisBorder: {
                                                                    show: false
                                                                },
                                                                axisTicks: {
                                                                    show: false
                                                                }
                                                            },
                                                            yaxis: {
                                                                labels: {
                                                                    style: {
                                                                        fontSize: '12px'
                                                                    }
                                                                }
                                                            },
                                                            grid: {
                                                                borderColor: '#f1f1f1',
                                                                padding: {
                                                                    top: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    left: 10
                                                                },
                                                                xaxis: {
                                                                    lines: {
                                                                        show: false
                                                                    }
                                                                }
                                                            },
                                                            tooltip: {
                                                                shared: true,
                                                                intersect: false,
                                                                followCursor: true,
                                                                x: {
                                                                    formatter: function(val: any): string {
                                                                        // Değeri string'e çevir
                                                                        const value = String(val);
                                                                        // Personel isminden departman ve şube bilgisini bul
                                                                        const personel = filteredData.find(emp => emp.name === value);
                                                                        if (personel) {
                                                                            return `<div style="padding: 8px 0">
                                                                                <strong>${value}</strong><br/>
                                                                                <span style="font-size: 12px; opacity: 0.7">
                                                                                    ${personel.department} / ${personel.branch}
                                                                                </span>
                                                                            </div>`;
                                                                        }
                                                                        return value;
                                                                    }
                                                                },
                                                                y: {
                                                                    formatter: function(val: number, opts: any): string {
                                                                        const seriesIndex = opts.seriesIndex;
                                                                        const seriesName = opts.w.globals.seriesNames[seriesIndex];
                                                                        return `<strong>${val} ${seriesName}</strong>`;
                                                                    }
                                                                },
                                                                style: {
                                                                    fontSize: '12px'
                                                                },
                                                                marker: {
                                                                    show: false
                                                                }
                                                            },
                                                            legend: {
                                                                show: false
                                                            },
                                                            fill: {
                                                                opacity: 1,
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'horizontal',
                                                                    shadeIntensity: 0.25,
                                                                    gradientToColors: undefined,
                                                                    inverseColors: true,
                                                                    opacityFrom: 0.85,
                                                                    opacityTo: 0.85,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            theme: {
                                                                mode: 'light',
                                                                monochrome: {
                                                                    enabled: false
                                                                }
                                                            }
                                                        }}
                                                        series={[
                                                            {
                                                                name: t('shiftReport.analysis.lateArrival'),
                                                                data: filteredData
                                                                    .sort((a, b) => 
                                                                        (b.lateDays + b.earlyLeaveDays + b.absentDays) - 
                                                                        (a.lateDays + a.earlyLeaveDays + a.absentDays)
                                                                    )
                                                                    .slice(0, 10)
                                                                    .map(emp => emp.lateDays)
                                                            },
                                                            {
                                                                name: t('shiftReport.analysis.earlyLeaving'),
                                                                data: filteredData
                                                                    .sort((a, b) => 
                                                                        (b.lateDays + b.earlyLeaveDays + b.absentDays) - 
                                                                        (a.lateDays + a.earlyLeaveDays + a.absentDays)
                                                                    )
                                                                    .slice(0, 10)
                                                                    .map(emp => emp.earlyLeaveDays)
                                                            },
                                                            {
                                                                name: t('shiftReport.analysis.absence'),
                                                                data: filteredData
                                                                    .sort((a, b) => 
                                                                        (b.lateDays + b.earlyLeaveDays + b.absentDays) - 
                                                                        (a.lateDays + a.earlyLeaveDays + a.absentDays)
                                                                    )
                                                                    .slice(0, 10)
                                                                    .map(emp => emp.absentDays)
                                                            }
                                                        ]}
                                                        type="bar"
                                                        height={500}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-12 col-md-12">
                                                <div className="mb-4">
                                                    <div className="card h-100 border-0 bg-white shadow-sm" style={{borderRadius: '12px'}}>
                                                        <div className="card-body p-4">
                                                            <h6 className="fw-semibold mb-3">{t('shiftReport.analysis.violationAnalysis')}</h6>
                                                            
                                                            <p className="text-muted fs-13 mb-4">
                                                                {t('shiftReport.analysis.violationAnalysisDescription')}
                                                            </p>
                                                            
                                                            <div className="d-flex align-items-center gap-2 mb-4">
                                                                <div className="p-2 rounded-3 bg-primary-subtle text-primary">
                                                                    <i className="ti ti-info-circle"></i>
                                                                </div>
                                                                <p className="fs-13 mb-0">
                                                                    {t('shiftReport.analysis.violationAnalysisGraphDescription')}
                                                                </p>
                                                            </div>
                                                            
                                                            <div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            </div>
                                            
                                            <div className="col-md-12">
                                                <div className="alert alert-info mt-2 mb-0 shadow-sm rounded-3" style={{backgroundColor: '#e6f7ff', border: 'none', borderLeft: '4px solid #1890ff'}}>
                                                    <div className="d-flex">
                                                        <InfoCircleOutlined className="me-3 fs-18" style={{color: '#1890ff'}} />
                                                        <div>
                                                            <p className="mb-0 fs-13">
                                                                {t('shiftReport.analysis.violationAnalysisGraphLegend')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Detay Tablosu - Ant Design Table */}
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="card-title mb-0">{t('shiftReport.personelShiftPerformance')}</h5>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <Table
                                            columns={columns}
                                            dataSource={filteredData}
                                            rowKey="userId"
                                            pagination={{ 
                                                pageSize: 10,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `
                                            }}
                                            bordered
                                            size="middle"
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                    </>
                ) : (
                    <div className="alert alert-info">
                        {t('shiftReport.noDataFound')}
                    </div>
                )}
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default ShiftReport; 