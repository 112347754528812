import { User } from "./user.types";

export enum FileType {
    PROFILE_PICTURE = "profile_picture", // Profil resmi
    ID_PHOTO = "id_photo", // Kimlik fotoğrafı
    DOCUMENT = "document", // Belgeler
    OTHER = "other", // Diğer
    LEAVE = "leave" // İzin belgesi
}

export interface FileDto {

    id: number;
    name: string;
    path: string;
    type: FileType;
    size: number;
    user?: User;
    createdAt: Date;
    updatedAt: Date;
}

export interface FileUploadRequest {
    file: File;
    type: FileType;
    name?: string;
}

export interface FileUploadResponse {
    filePath:{
        
        id: number;
        name: string;
        path: string;
        type: FileType;
        size: number;
        createdAt: Date;
        updatedAt: Date;
    }
}
