import apiService from "../api/api.service";
import { Notification, NotificationForm } from "../../types/notification";
class NotificationService {
    private static instance: NotificationService;

    private constructor() {}

    public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    public async getNotifications(): Promise<Notification[]> {
        const response = await apiService.get<Notification[]>('/notification/company');
        return response;
    }

    public async createNotification(notification: NotificationForm): Promise<NotificationForm> {
        const response = await apiService.post<NotificationForm>('/notification/send', notification);
        return response;
    }
    
    public async getUserNotifications(): Promise<Notification[]> {
        const response = await apiService.get<Notification[]>('/notification/list');
        return response;
    }
}

export default NotificationService.getInstance();