import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {useShops} from '../../../../hooks/useShops';
import {useDepartments} from '../../../../hooks/useDepartments';
import { all_routes } from '../../../../router/all_routes';
import { LeaveType, LeaveStatus, LeaveAdminUpdateDTO, LeaveDto } from '../../../../types/leaves.types';
import { useLeaves } from '../../../../hooks/useLeaves';
import { message } from 'antd';
import LeaveStatsComponent from '../components/LeaveStats';
import { LeaveTable } from '../components/LeaveTable';
import { formatDate, isDateInRange } from '../../../../utils/date';
import dayjs from 'dayjs';
import LeaveFilters from '../components/LeaveFilters';
import AdminLeaveModal from '../components/AdminLeaveModal';
import 'jspdf-autotable';
import LoadingSpinner from '../../../shared/uiInterface/base-ui/LoadingSpinner';
import { handleExportExcelLeave, createPdf } from '../../../../utils/exportUtils';
import { Trans, useTranslation } from 'react-i18next';
import { handleApiError } from '../../../../utils/errorHandler';
import FooterSection from '../../../shared/components/footerSection';
const LeaveAdmin2 = () => {

  const {leaves,loading,leavesError,stats,fetchLeaves,updateLeaveStatus, createLeave} = useLeaves();
  const [selectedStatus,setSelectedStatus] = useState<LeaveStatus | 'all'>('all');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedType,setSelectedType] = useState<LeaveType | 'all'>('all');
  const [dateRange,setDateRange] = useState<[string,string] | null>(null);
  const {shops,loading:shopsLoading,error:shopsError,fetchShops} = useShops();
  const {departments,loading:departmentsLoading,error:departmentsError,fetchDepartments} = useDepartments();
  const [selectedShop,setSelectedShop] = useState<string | null>('all');
  const [selectedDepartment,setSelectedDepartment] = useState<string | null>('all');
  const [error,setError] = useState<string | null>(null);
  const [selectedLeave, setSelectedLeave] = useState<LeaveDto | undefined>();
  const { t } = useTranslation();
  useEffect(()=>{
    fetchLeaves();
  },[fetchLeaves]);

  useEffect(()=>{
    if(leavesError){
      setError(leavesError.message);
    }
    if(shopsError){
      setError(shopsError.message);
    }
    if(departmentsError){
      setError(departmentsError.message);
    }
  },[leavesError,shopsError,departmentsError]);
  const handleOpenLeaveModal = () => {
    setModalVisible(true);
  };

  const handleCloseLeaveModal = () => {
    setModalVisible(false);
    setSelectedLeave(undefined);
  };


  const handleReset = ()=>{
    setSelectedType('all');
    setDateRange(null);
    setSelectedDepartment('all');
    setSelectedShop('all');
    setSelectedStatus('all');
    fetchLeaves();
  }


  const handleSubmit = async (values: any) => {
    try {
      if (selectedLeave) {
        // Düzenleme
        await updateLeaveStatus(selectedLeave.id, values);
      } else {
        // Yeni oluşturma
        await createLeave(values);
      }
      if(selectedLeave){
        message.success(t('leaves.modals.updated'));
      }else{
        message.success(t('leaves.modals.created'));
      }
      handleCloseLeaveModal();
      fetchLeaves(); // Listeyi yenile
    } catch (error) {
      handleApiError(error,t);
    }
  };

  const filteredLeaves = useMemo(() => {
    if (!leaves) return []; 
    
    return leaves.filter(leave => {
      // İzin türü filtresi
      if (selectedType !== 'all' && leave.type !== selectedType) {
        return false;
      }
  
      // Departman filtresi
      if (selectedDepartment !== 'all' && leave.user?.department?.id !== Number(selectedDepartment)) {
        return false;
      }
      if (selectedStatus !== 'all' && leave.status !== selectedStatus) {
        return false;
      }
      // Şube filtresi
      if (selectedShop !== 'all' && leave.user?.branch?.id !== Number(selectedShop)) {
        return false;
      }
  
      // Tarih aralığı filtresi
      if (dateRange) {
        const leaveDate = dayjs(leave.startDate);
        const [startDate, endDate] = dateRange;
        
        if (!isDateInRange(leaveDate.toDate(), startDate, endDate)) {
          return false;
        }
      }
  
      return true;
    });
  }, [leaves, selectedType, selectedDepartment, selectedShop, dateRange, selectedStatus]);

  const handleStatusChange = async (leaveId:number,dto:LeaveAdminUpdateDTO)=>{
    try{
      await updateLeaveStatus(leaveId,dto);
      message.success(t('leaves.modals.statusUpdated'));
    }catch(error: any){
      handleApiError(error,t);
    }
  }
  
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
      <div className="content">
          {/* Breadcrumb */}
          <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
            <div className="my-auto mb-2">
              <h2 className="mb-1">{t('leaves.pageTitle')}</h2>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={all_routes.adminDashboard}>
                      <i className="ti ti-smart-home" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{t('leaves.breadcrumb.employee')}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('leaves.breadcrumb.leaves')}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap ">
              <button
                  className="btn btn-sm btn-primary d-inline-flex align-items-center me-2"
                  onClick={handleOpenLeaveModal}
                >
                  <i className="ti ti-plus me-1" />
                  {t('leaves.addLeave')}
                </button>
                <div className="dropdown me-3">
                  <Link
                    to="#"
                    className="dropdown-toggle btn btn-sm btn-white d-inline-flex align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-file-export me-1" />
                    {t('common.buttons.export')}
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end p-3">
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={() => {
                          // İzin verileri için sütun tanımları
                          type LeaveDataType = {
                            name: string;
                            department: string;
                            branch: string;
                            leaveType: string;
                            startDate: string;
                            endDate: string;
                            times: string;
                            status: string;
                            // reason: string;
                          };

                          // PDF için sütun tanımları
                          const columnsForPdf = [
                            { header: 'Ad Soyad', property: 'name' as keyof LeaveDataType, width: 120 },
                            { header: 'Departman', property: 'department' as keyof LeaveDataType, width: 120 },
                            { header: 'Sube', property: 'branch' as keyof LeaveDataType, width: 120 },
                            { header: 'Izin Turu', property: 'leaveType' as keyof LeaveDataType, width: 120 },
                            { header: 'Baslangic', property: 'startDate' as keyof LeaveDataType, width: 80 },
                            { header: 'Bitis', property: 'endDate' as keyof LeaveDataType, width: 80 },
                            { header: 'Saat', property: 'times' as keyof LeaveDataType, width: 80 },
                            { header: 'Durum', property: 'status' as keyof LeaveDataType, width: 80 },
                            // { header: 'Aciklama', property: 'reason' as keyof LeaveDataType, width: 120 }
                          ];

                          // İzin türü formatı
                          const formatLeaveType = (type: string): string => {
                            switch (type) {
                              case 'sick': return 'Hastalik Izni';
                              case 'annual': return 'Yillik Izni';
                              case 'unpaid': return 'Ucretsiz Izni';
                              case 'special': return 'Ozel Izni';
                              case 'hourly': return 'Saatlik Izni';
                              case 'medical': return 'Saglik Izni';
                              case 'marriage': return 'Evlilik Izni';
                              case 'birth': return 'Dogum Izni';
                              case 'death': return 'Olum Izni';
                              default: return type;
                            }
                          };

                          // İzin durumu formatı
                          const formatLeaveStatus = (status: string): string => {
                            switch (status) {
                              case 'pending': return 'Beklemede';
                              case 'approved': return 'Onaylandi';
                              case 'rejected': return 'Reddedildi';
                              default: return status;
                            }
                          };

                          // PDF için formatlanmış veri
                          const formattedData = filteredLeaves.map(leave => ({
                            name: leave.user ? `${leave.user.firstName} ${leave.user.lastName}` : '-',
                            department: leave.user?.department?.name || '-',
                            branch: leave.user?.branch?.name || '-',
                            leaveType: formatLeaveType(leave.type),
                            startDate: dayjs(leave.startDate).format('DD.MM.YYYY'),
                            endDate: dayjs(leave.endDate).format('DD.MM.YYYY'),
                            times: leave.startTime && leave.endTime ? `${leave.startTime} - ${leave.endTime}` : '-',
                            status: formatLeaveStatus(leave.status),
                            // reason: leave.reason ? (leave.reason.length > 30 ? leave.reason.substring(0, 30) + '...' : leave.reason) : '-'
                          }));

                          // Filtre açıklaması
                          let description = 'Izin Listesi Raporu';
                          
                          if (dateRange) {
                            description += ` - Tarih: ${dayjs(dateRange[0]).format('DD.MM.YYYY')} - ${dayjs(dateRange[1]).format('DD.MM.YYYY')}`;
                          }
                          
                          if (selectedDepartment !== 'all' && departments.length > 0) {
                            const deptName = departments.find(d => d.id.toString() === selectedDepartment)?.name;
                            if (deptName) description += ` - Departman: ${deptName}`;
                          }
                          
                          if (selectedShop !== 'all' && shops.length > 0) {
                            const shopName = shops.find(s => s.id.toString() === selectedShop)?.name;
                            if (shopName) description += ` - Sube: ${shopName}`;
                          }

                          if (selectedType !== 'all') {
                            description += ` - Izin Turu: ${formatLeaveType(selectedType)}`;
                          }

                          if (selectedStatus !== 'all') {
                            description += ` - Durum: ${formatLeaveStatus(selectedStatus)}`;
                          }

                          // createPdf fonksiyonunu çağır
                          createPdf(formattedData, {
                            title: 'Izin Listesi Raporu',
                            description: description,
                            headerColor: '#2980b9', // Koyu mavi
                            accentColor: '#3498db', // Mavi
                            columns: columnsForPdf,
                            showStatistics: false, // İstatistik kutuları gösterme
                            footer: {
                              text: `© ${new Date().getFullYear()} DINOX HR - Tum Haklari Saklidir`,
                              showPageNumbers: true
                            }
                          });
                        }}
                      >
                        <i className="ti ti-file-type-pdf me-2 text-danger" />
                        {t('common.buttons.pdf')}
                      </Link>
                    </li>
                    <li className="mt-1">
                      <Link
                        to="#"
                        className="dropdown-item rounded-2 d-flex align-items-center py-2"
                        onClick={()=>handleExportExcelLeave(filteredLeaves)}
                      >
                        <i className="ti ti-file-type-xls me-2 text-success" />
                        {t('common.buttons.excel')}
                      </Link>
                    </li>
                  </ul>
                </div>
            
            </div>
          </div>
          <LeaveStatsComponent stats={stats}/>
            {/* Filtreler */}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-end flex-wrap row-gap-3">
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap row-gap-3">

              {/* İzin Türü ve Tarih Filtreleri */}
              <LeaveFilters 
                selectedStatus={selectedStatus}
                selectedType={selectedType}
                selectedDateRange={dateRange}
                selectedDepartment={selectedDepartment || 'all'}
                selectedShop={selectedShop || 'all'}
                departments={departments}
                shops={shops}
                onStatusChange={setSelectedStatus}
                onTypeChange={setSelectedType}
                onDateRangeChange={setDateRange}
                onDepartmentChange={setSelectedDepartment}
                onShopChange={setSelectedShop}
                onReset={handleReset}
              />
              </div>
          </div>

          <div className="card-body">
          {loading ? (
              <LoadingSpinner size="lg" color="primary" centered />
          ) : (
            <LeaveTable
              leaves={filteredLeaves || []}
              onStatusChange={handleStatusChange}
            />
          )}
          {leavesError && (
            <div className="alert alert-danger" role="alert">
              {leavesError.message}
            </div>
          )}
          </div>
        </div>
        </div>
          {/* /Leaves list */}
          <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
            <FooterSection />
        </div>
      </div>

      <AdminLeaveModal
        leave={selectedLeave}
        onSubmit={handleSubmit}
        onClose={handleCloseLeaveModal}
        open={modalVisible}
      />

    </>
  )
}

export default LeaveAdmin2;
