import { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import leaveService from "../services/leave/leave.service";
import { LeaveDto, LeaveStatus, LeaveType, LeaveUserCreateDTO, LeaveUserUpdateDTO, LeaveResponse } from "../types/leaves.types";
import { message } from "antd";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { useAsync } from "./useAsync";

dayjs.extend(isBetween);

export const useEmployeeLeave = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const [selectedType, setSelectedType] = useState<LeaveType | 'all'>('all');
    const [selectedStatus, setSelectedStatus] = useState<LeaveStatus | 'all'>('all');
    const [dateRange, setDateRange] = useState<[string, string] | null>(null);

    const { getLeaveByUserId } = leaveService;
    const { data: leaveResponse, loading, error, execute } = useAsync<LeaveResponse>();

    const fetchLeaves = useCallback(async (startDate?: string, endDate?: string) => {
        if (!user?.id) return;
        const today = dayjs();
        const defaultStartDate = startDate || today.subtract(1, 'month').startOf('day').format('YYYY-MM-DD');
        const defaultEndDate = endDate || today.endOf('day').format('YYYY-MM-DD');

        return execute(() => getLeaveByUserId(user.id, defaultStartDate, defaultEndDate));
    }, [execute, user?.id]);

    const createLeave = async (data: LeaveUserCreateDTO) => {
        try {
            await leaveService.createLeave(data);
            message.success('İzin talebi oluşturuldu');
            fetchLeaves();
        } catch (err) {
            message.error('İzin talebi oluşturulurken bir hata oluştu');
            throw err;
        }
    };

    const updateLeave = async (id: number, data: LeaveUserUpdateDTO) => {
        try {
            await leaveService.updateLeaveByUser(id, data);
            message.success('İzin talebi güncellendi');
            fetchLeaves();
        } catch (err) {
            message.error('İzin talebi güncellenirken bir hata oluştu');
            throw err;
        }
    };

    const deleteLeave = async (id: number) => {
        try {
            await leaveService.deleteLeave(id);
            message.success('İzin talebi silindi');
            fetchLeaves();
        } catch (err) {
            message.error('İzin talebi silinirken bir hata oluştu');
            throw err;
        }
    };

    const stats = useMemo(() => {
        if (!leaveResponse) return { approved: 0, pending: 0, rejected: 0, total: 0 };
        return {
            approved: leaveResponse.approvedCount,
            pending: leaveResponse.pendingCount,
            rejected: leaveResponse.rejectedCount,
            total: leaveResponse.totalLeaveCount
        };
    }, [leaveResponse]);

    const allLeaves = useMemo(() => {
        if (!leaveResponse) return [];
        return [
            ...leaveResponse.approved,
            ...leaveResponse.rejected,
            ...leaveResponse.pending
        ];
    }, [leaveResponse]);

    const filteredLeaves = useMemo(() => {
        if (!allLeaves) return [];
        return allLeaves.filter((leave) => {
            if (selectedType !== 'all' && leave.type !== selectedType) {
                return false;
            }
            if (selectedStatus !== 'all' && leave.status !== selectedStatus) {
                return false;
            }
            if (dateRange) {
                const leaveDate = dayjs(leave.startDate);
                const [startDate, endDate] = dateRange;

                if (!dayjs(leaveDate).isBetween(startDate, endDate, 'day', '[]')) {
                    return false;
                }
            }

            return true;
        });
    }, [allLeaves, selectedType, selectedStatus, dateRange]);

    return {
        leaves: filteredLeaves,
        loading,
        error,
        stats,
        selectedType,
        selectedStatus,
        dateRange,
        setSelectedType,
        setSelectedStatus,
        setDateRange,
        fetchLeaves,
        createLeave,
        updateLeave,
        deleteLeave
    };
};


