// export interface DepartmentState {
//   departments: DepartmentDto[];
//   loading: boolean;
//   error: string | null;
//   selectedDepartment: DepartmentDto | null;
// }

export interface DepartmentDto {
  id: number;
  name: string;
  status: DepartmentStatus;
  createdAt: string;
  employeeCount: number;
}

export interface DepartmentCreateDTO {
  name: string;
  status?: DepartmentStatus;
}

export interface DepartmentUpdateDTO extends DepartmentCreateDTO {
  id: number;
}

export enum DepartmentStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted"
}
