import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../router/all_routes';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CollapseHeader from '../../../core/common/collapse-header/collapse-header';
import reportService from '../../../services/reports/report.service';
import { LeaveReportDto } from '../../../types/report.types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { LEAVE_TYPES } from '../../../constants/leaveConstants';
import { handleApiError } from '../../../utils/errorHandler';   
import { useTranslation } from 'react-i18next';
import FooterSection from '../../shared/components/footerSection';
const LeaveReport = () => {
    const {t} = useTranslation();
    const [reportData, setReportData] = useState<LeaveReportDto | null>(null);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await reportService.getLeaveReport(
                    startDate.toISOString().split('T')[0],
                    endDate.toISOString().split('T')[0]
                );
                setReportData(data);
            } catch (error: any) {
                handleApiError(error,t);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate]);

    // İzin Tipi Dağılımı Chart
    const leaveTypeChartOptions: ApexOptions = {
        chart: {
            type: 'donut' as const,
            height: 350
        },
        labels: reportData ? Object.keys(reportData.leaveTypeDistribution).map(key => t(`leaveTypes.${key}`)) : [],
        colors: ['#4318FF', '#6AD2FF', '#05CD99', '#FFB547', '#E31A1A'],
        legend: {
            position: 'bottom'
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%'
                }
            }
        }
    };

    // İzin Trendleri Chart
    const trendChartOptions: ApexOptions = {
        chart: {
            type: 'area' as const,
            height: 350,
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        colors: ['#4318FF'],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.3,
                stops: [0, 90, 100]
            }
        },
        xaxis: {
            categories: reportData ? Object.keys(reportData.leaveTrends) : [],
            labels: {
                rotate: -45,
                rotateAlways: true
            }
        },
        yaxis: {
            title: {
                text: t('leaveReport.leaveCount')
            }
        }
    };

    // Özet Kartlar için Hesaplamalar
    const calculateSummary = () => {
        if (!reportData) return { total: 0, mostUsed: '', avgPerDay: 0 };

        const total = Object.values(reportData.leaveTypeDistribution).reduce((a, b) => a + b, 0);
        const mostUsed = Object.entries(reportData.leaveTypeDistribution)
            .reduce((a, b) => a[1] > b[1] ? a : b)[0];
        const days = Object.values(reportData.leaveTrends).length;
        const avgPerDay = days ? (total / days) : 0;

        return { total, mostUsed, avgPerDay };
    };

    const summary = calculateSummary();

    return (
        <div className="page-wrapper">
            <div className="content">
                {/* Breadcrumb */}
                <div className="d-md-flex d-block align-items-center justify-content-between page-breadcrumb mb-3">
                    <div className="my-auto mb-2">
                        <h2 className="mb-1">{t('leaveReport.pageTitle')}</h2>
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to={all_routes.adminDashboard}>
                                        <i className="ti ti-smart-home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">{t('leaveReport.breadcrumb.reports')}</li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t('leaveReport.pageTitle')}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="d-flex my-xl-auto right-content align-items-center">
                        <div className="me-2">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => date && setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => date && setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control form-control-sm"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                    </div>
                ) : reportData ? (
                    <div className="row">
                        {/* Özet Kartlar */}
                        <div className="col-xl-4 col-md-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="avatar avatar-lg me-3 bg-primary-transparent">
                                            <i className="ti ti-calendar-stats fs-20 text-primary"></i>
                                        </div>
                                        <div>
                                            <span className="fs-14 fw-normal">{t('leaveReport.totalLeaves')}</span>
                                            <h5 className="mb-0">{summary.total}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <div className="d-flex align-items-center">
                                                <span className="fs-13 text-muted">
                                                    {t('leaveReport.totalLeavesInRange')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="avatar avatar-lg me-3 bg-success-transparent">
                                            <i className="ti ti-chart-pie fs-20 text-success"></i>
                                        </div>
                                        <div>
                                            <span className="fs-14 fw-normal">{t('leaveReport.mostUsedLeave')}</span>
                                            <h5 className="mb-0">{t(`leaveTypes.${summary.mostUsed}`)}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <div className="d-flex align-items-center">
                                                <span className="fs-13 text-muted">
                                                    {t('leaveReport.mostPreferredLeaveType')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="avatar avatar-lg me-3 bg-warning-transparent">
                                            <i className="ti ti-chart-arrows-vertical fs-20 text-warning"></i>
                                        </div>
                                        <div>
                                            <span className="fs-14 fw-normal">{t('leaveReport.dailyAverage')}</span>
                                            <h5 className="mb-0">{summary.avgPerDay.toFixed(1)}</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <div className="d-flex align-items-center">
                                                <span className="fs-13 text-muted">
                                                    {t('leaveReport.dailyAverageLeaves')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* İzin Tipi Dağılımı */}
                        <div className="col-xl-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">{t('leaveReport.leaveTypeDistribution')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={leaveTypeChartOptions}
                                        series={Object.values(reportData.leaveTypeDistribution)}
                                        type="donut"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* İzin Trendleri */}
                        <div className="col-xl-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-header">
                                    <h5 className="card-title">{t('leaveReport.leaveTrends')}</h5>
                                </div>
                                <div className="card-body">
                                    <ReactApexChart
                                        options={trendChartOptions}
                                        series={[{
                                            name: t('leaveReport.leaveCount'),
                                            data: Object.values(reportData.leaveTrends)
                                        }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="alert alert-danger">
                        {t('leaveReport.errorLoadingData')}
                    </div>
                )}
            </div>
            <div className="footer d-sm-flex align-items-center justify-content-between border-top bg-white p-3">
                <FooterSection />
            </div>
        </div>
    );
};

export default LeaveReport; 