import { AttendanceDto } from './attendanceDto.types';
import { DepartmentDto } from './department.types';
import { LeaveDto } from './leaves.types';
import { Salary } from './salary.types';
import { Store } from './shop.types';

export interface UserPermissions {
  permissions: PermissionType[];
}

export enum PermissionType {
  CONTROL_PANEL_USER = "control_panel_user",
  CONTROL_PANEL_ADMIN = "control_panel_admin",
  VIEW_USERS = "view_users",
  EDIT_USERS = "edit_users",
  VIEW_DEPARTMENTS = "view_departments",
  EDIT_DEPARTMENTS = "edit_departments",
  VIEW_BRANCHES = "view_branches",
  EDIT_BRANCHES = "edit_branches",
  EXPORT = "export",
  VIEW_REPORTS = "view_reports",
  MANAGE_SHIFTS = "manage_shifts",
  LEAVE_REQUEST = "leave_request",
  VIEW_LEAVE = "view_leave",
  EDIT_LEAVE = "edit_leave",
  CREATE_NOTIFICATIONS = "create_notifications",
  VIEW_SALARIES = "view_salaries",
  MANAGE_COMPANY = "manage_company",
  CHECK_IN_OUT = "check_in_out",
  BREAK = "break",
  COMPANY = "company_settings",
  FILE_UPLOAD = "file_upload",
  FILE_VIEW = "file_view",
  VIEW_TICKETS_ADMIN = "view_tickets_admin",
  VIEW_TICKETS_USER = "view_tickets_user",
  EDIT_TICKETS = "edit_tickets",
}




export enum UserStatus {
  ACTIVE = "active",
  RESIGNED = "resigned",
  DELETED = "deleted"
}

// Temel kullanıcı bilgileri için interface
export interface BaseUserInfo {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  title?: string;
  phone?: string;
  emergencyContact?: string;
  hireDate: Date;
  birthDate?: Date;
  permissions?: PermissionType[];

  maritalStatus?: "single" | "married";
  educationLevel?: 'primary' | 'secondary' | 'bachelor' | 'master' | 'phd';
  gender?: "male" | "female" | "other";
  address?: string;
  city?: string;
  nationalId?: string;
  salaries?: Salary[];
  status: UserStatus;
  companyId?: number;
  annualLimit?: number;
  isFixedShift?: boolean;
}

// Diğer ilişkili varlıklar için interfaces
// interface Shift {
//   id: number;
//   name: string;
//   startTime: string;
//   endTime: string;
//   flexTime: number;
//   createdAt: string;
//   updatedAt: string;
// }

// interface Company {
//   id: number;
//   name: string;
//   address: string;
//   phone: string;
//   email: string;
//   taxNumber: string;
//   taxOffice: string;
//   settings: any | null;
//   createdAt: string;
//   updatedAt: string;
// }

// interface Permissions {
//   id: number;
//   name: string;
//   description: string;
//   permissions: {
//     create: boolean;
//     read: boolean;
//     update: boolean;
//     delete: boolean;
//   };
//   createdAt: string;
//   updatedAt: string;
// }

// Ana User interface'i
export interface User extends BaseUserInfo {
  id: number;
  workDuration: number;
  department: DepartmentDto;
  // shift: Shift;
  profilePicture:string;
  // company: Company;
  branch: Store;
  avatar?: string;
  //permissions: Permissions;
  createdAt: string;
  attendance?: AttendanceDto[];
  leaves?: LeaveDto[];
  updatedAt: string;
}

// Mevcut enumları ekleyelim
export enum EmploymentType {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  INTERN = 'intern'
}

export enum UserRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  USER = 'user'
}



// BaseUserInfo'yu kategorilere ayıralım
export interface PersonalInfo {
  firstName: string;
  lastName: string;
  birthDate?: Date;
  nationalId: string;
  gender: "male" | "female" | "other";
  maritalStatus: "single" | "married";
  educationLevel: 'primary' | 'secondary' | 'bachelor' | 'master' | 'phd';
  photo?: string;
}

export interface ContactInfo {
  city: string;
  email: string;
  phone: string;
  emergencyContact?: string;
}

export interface WorkInfo {
  departmentId: number;
  branchId: number;
  hireDate: Date;
  annualLimit: number;
  isFixedShift?: boolean;
}

export interface SecurityInfo {
  password: string;
  role: UserRole;
  title: string;
}

// UserCreateDTO'yu güncelle
export interface UserCreateDTO extends 
  PersonalInfo,
  ContactInfo,
  WorkInfo,
  SecurityInfo {}

export interface UserUpdateDTO extends Omit<UserCreateDTO, 'password'> {
  password?: string; // Güncelleme sırasında password opsiyonel
}

export interface UserBirthdaysResponseDto {
  today: BirthdayUserDto[];
  thisWeek: BirthdayUserDto[];
  thisMonth: BirthdayUserDto[];
}

export interface BirthdayUserDto {
  userId: number;
  name: string;
  birthDate: string;
  profilePicture: string;
  department: string;
  branch: string;
}

export interface YearlyDetailsForEmployeeResponseDto {
  workDays: number;
  lateDays: number;
  leaveDays: number;
  absentDays: number;
}

export interface YearlyWorkStatsResponseDto {
  todayWorkHours: string;
  requiredShiftHours: string;
  requiredWeeklyHours: string;
  requiredMonthlyHours: string;
  todayVsWeekChange: string;
  totalWeekHours: string;
  weekChange: string;
  totalMonthHours: string;
  monthChange: string;
}

export interface WorkScheduleResponseDto {
  workIntervals: {
    start: string;
    end: string;
  }[];
  breakIntervals: {
    start: string;
    end: string;
  }[];
  overtimeIntervals: {
    start: string;
    end: string;
  }[];
}

